import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {API_HOST} from '../../Settings.js'
import axios from 'axios';
import $ from 'jquery'
import './users.css'
import Select from 'react-select'
import notify from '../../CommonFunctions.js'
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "users_list": [],
            "search_term": "",
            "modal_state": false,
            "source_locations": [],
            "source_domains": [],
            "source_product_category": [],
            "role_or_group": [],
            "new_user_name": "",
            "selected_sourcing_location_ids": [],
            "selected_source_product_category_ids": [],
            "selected_source_domains_ids": [],
            "selected_role_or_group_ids": [],
            "is_name_found":false,
            "user_perm": {},
            "last_sync":''
        }
    }

    componentDidMount() {
        $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
        $('.main-menu-sub-group').find('#users-group').find('.circle-title').addClass('active-navbar')
        this.getData()
    }

    getData(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        $('.inline-loader').fadeIn()
        axios.get(`${API_HOST}/users/list/?search_term=${this.state.search_term}`)
        .then(response => {
            if (response.status === 200) {
                if(!response.data.user_perm.is_superuser){
                    notify("You don't have access to this page",'error')
                    this.props.history.push('/permissiondenied/')
                }
                this.setState({
                    "users_list" : response.data.users_list,
                    "source_locations": response.data.source_locations,
                    "source_domains": response.data.source_domains,
                    "source_product_category": response.data.source_product_category,
                    "role_or_group": response.data.role_or_group,
                    "user_perm":response.data.user_perm,
                    'last_sync':response.data.last_sync
                },()=>{
                    $('.inline-loader').fadeOut('slow')
                })
            }

        }).catch(response => {
                $('.inline-loader').fadeOut('slow')
                // ErrorHandling(response)
            });
    }

    onChange(event){
        this.setState({search_term: event.target.value});
    }

    onSearch(event){
        event.preventDefault()
        let search_term = event.target.value
        this.setState(
                (prevState, props) => ({
                    search_term: search_term
                }), this.getData()
            )
    }

    handleCloseModal(event,value){
        this.setState((prevState, props)=>({
            "modal_state": value
        }),(console.log(value,this.state.modal_state)))
        $('#add-user-model').modal('hide');
    }

    checkUserExists(event){

    }

    handleUserName(event){
        this.setState({
            "new_user_name": event.target.value
        })
    }

    handleDropdownCheckbox(event,value){
        var checkList = document.getElementById('list1');
        if (checkList.classList.contains('visible'))
            checkList.classList.remove('visible');
          else
            checkList.classList.add('visible');
    }

    onChangeSourceLocation(event){
        var value = Array.from(event, option => option.value);
        this.setState({
            "selected_sourcing_location_ids":value
        })
    }

    onChangeSourceProductCategory(event){
        var value = Array.from(event, option => option.value);
        this.setState({
            "selected_source_product_category_ids": value
        })   
    }

    onChangeSourceDomains(event){
        var value = Array.from(event, option => option.value);
        this.setState({
            "selected_source_domains_ids": value
        })   
    }

    onChangeRoleOrGroup(event){
        var value = Array.from(event, option => option.value);
        this.setState({
            "selected_role_or_group_ids": value
        })
    }

    handleAddUser(event){
        return false
        var selected_sourcing_location_ids = this.state.selected_sourcing_location_ids;
        var selected_source_product_category_ids = this.state.selected_source_product_category_ids;
        var selected_source_domains_ids = this.state.selected_source_domains_ids;
        var selected_role_or_group_ids = this.state.selected_role_or_group_ids;
        if(selected_sourcing_location_ids.length<1){
            alert("Select sourcing location");
            return false;
        }
        if(selected_source_product_category_ids.length<1){
            alert("Select product category");
            return false;
        }
        if(selected_source_domains_ids.length<1){
            alert("Select sourcing domain");
            return false;
        }
        if(selected_role_or_group_ids.length<1){
            alert("Select role ")
            return false;
        }
        if(
            selected_sourcing_location_ids.length>0 &&
            selected_source_product_category_ids.length>0 &&
            selected_source_domains_ids.length>0 &&
            selected_role_or_group_ids.length>0 
        ){
            $('#add-user-model').modal('hide');
            $('.inline-loader').fadeIn()
            const data = JSON.stringify({
                selected_sourcing_location_ids: selected_sourcing_location_ids,
                selected_source_product_category_ids: selected_source_product_category_ids,
                selected_source_domains_ids: selected_source_domains_ids,
                selected_role_or_group_ids: selected_role_or_group_ids,
                new_user_name: this.state.new_user_name
            })
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.post['Authorization'] =auth_token
            let url = `${API_HOST}/users/user/dgs/userpermission/add/`;
            axios.post(url, data, {
              headers: {
                'content-type': 'application/json'
              }
            })
            .then(response => {
              if(response.status == 200){
                this.setState({
                    "selected_sourcing_location_ids": [],
                    "selected_source_product_category_ids": [],
                    "selected_source_domains_ids": [],
                    "selected_role_or_group_ids": []
                })
                setTimeout(() =>{
                    window.location.reload()
                },2000)
              }
              
            })
            .catch(response => {
                $('.inline-loader').fadeOut('slow')
            })
        }
        else{
            alert("missing value");
        }
    }
    users_sync_with_ad = (event) =>{
            $('.inline-loader').fadeIn()
           /*const auth_token = "Token "+localStorage.malbar_token*/
           const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.post['Authorization'] =auth_token
            let url = `${API_HOST}/users/sync/with/azure/`;
            axios.get(url)
            .then(response => {
              if(response.status === 200 && response.data.success){
                notify('Sync users with Azure AD successfully!','success')
                this.getData()
              }else{
                notify('somthing went worng','error')
                $('.inline-loader').fadeOut('slow')
              }
              
            })
            .catch(response => {
                $('.inline-loader').fadeOut('slow')
            })

    }

    render() {
        var user_list_row = this.state.users_list.map((user,index) => {
            return (
                <tr>
                    <td className="custom-url">
                        <div className="d-flex justify-content-start">
                          <div className="circle-with-text mr-3">
                            { user.first_name && user.last_name?
                                <span>{user.first_name[0]}{user.last_name[0]}</span>
                                :
                                <span>{user.email[0]+user.email[1]}</span>
                            }
                          </div>
                          <Link to={`/users/${user.id}/details/`}>
                            <div className="m-0 p-0">
                                { user.first_name && user.last_name?
                                    <span>{user.first_name} {user.last_name}</span>
                                    :
                                    <span>{user.email.split('@')[0]}</span>
                                }
                            </div>
                          </Link>
                        </div>
                    </td>
                    <td>{user.email}</td>
                    <td>{user.highest_privileges ? user.highest_privileges :"-"}</td>
                    <td>{user.is_active ? "Active" : "In Active"}</td>
                </tr>
            )   
        });

        var source_locations_row = this.state.source_locations.map((row,index)=>{
            return (
                { value: row.id, label: row.name+row.id }
            )
        })

        var source_domains_row = this.state.source_domains.map((row,index)=>{
            return (
                { value: row.id, label: row.name+row.id}
            )
        })

        var source_product_category_row = this.state.source_product_category.map((row,index)=>{
            return (
                { value: row.id, label: row.name+row.id }
            )
        })
        
        var role_or_group_row = this.state.role_or_group.map((row,index)=>{
            return (
                { value: row.id, label: row.name+row.id }
            )
        })


        return (
            <div className="relative">
                <div className="inline-loader bg-white">
                    <div className="dot-elastic"></div>
                </div>
                <div>
                    <div className="row m-0 p-0">
                        <div className="col-lg-12 pl-0 d-flex justify-content-between pr-0">
                            <div className="col-md-6">
                              <div className="row pl-0">
                                <div className="col-md-9 m-0 p-0" id="job-search-input-group">
                                  <div className="input-group pl-3 pr-3">
                                    <input id="repo-search-input" type="text" className="form-control pl-0 pr-0" 
                                      placeholder="Search by User Name, Role, Email"
                                      autoComplete="off"
                                       onChange={event =>this.onChange(event)} name="search_term"
                                       value={this.state.search_term} onKeyPress={event => {if (event.key=== 'Enter') {this.onSearch(event)}}}/>
                                    <div className="input-group-append ">
                                      <button type="button" onClick={event => this.onSearch(event)} className="repo-search-icon p-0">
                                        <i className="fa fa-search"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <button type="button" class="btn btn-dark float-right" id="user_sync_ad" onClick={this.users_sync_with_ad}>Sync Users With Azure AD</button>
                            </div>                            
                            {/*<div>
                                <button type="button" onClick={event => this.handleCloseModal(event,true)} className="btn btn-dark nohover-btn" data-toggle="modal" data-target="#add-user-model">
                                <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
                                Add User
                                </button>
                                {
                                    this.state.modal_state
                                    ?
                                    <div className="modal fade" id="add-user-model" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered" id="user-model-dialog">
                                          <div className="modal-content pl-4 pr-4" id="user-model-content">
                                            <div className="modal-header dashed-row-bottom pl-0 pr-0">
                                              <div>
                                                <span id="add-user-text">
                                                 Add User
                                                </span>  
                                              </div>
                                              <div>
                                                <button type="button" onClick={event => this.handleCloseModal(event,false)} className="close" data-dismiss="modal">×</button>
                                              </div>
                                            </div>
                                            <div className="modal-body pl-0 pr-0">
                                              <div className="row pb-3 m-0">
                                                <div className="col-md-6 p-0">
                                                  <div>
                                                    <label id="user-name-text">
                                                      User Name / Employee Name
                                                    </label>  
                                                  </div>
                                                  <div className="input-group" id="user-name-input-group">
                                                    <span className="pr-3 pl-3 pt-1 pb-1">
                                                      <i className="fas fa-user"></i>
                                                    </span>
                                                    <input type="text"  
                                                        className="form-control p-0" 
                                                        placeholder="User Name / Employee Name" id="user-name-input"
                                                        onChange={event => this.handleUserName(event)}
                                                        autoComplete="off"/>

                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row pb-3 m-0 d-flex justify-content-between">
                                                <div className=" p-0  form-group option_width">
                                                 <label for="sourcing-location" id="sourcing-location-text">Sourcing Location</label>
                                                  <Select                            
                                                      className="select-attribute-value"
                                                      onChange={event => this.onChangeSourceLocation(event)} 
                                                      options={source_locations_row}
                                                      isMulti={true}

                                                  />
                                                </div>
                                                <div className=" p-0 option_width  form-group">
                                                 <label for="product-category" id="product-category-text">Product Category</label>
                                                  <Select                            
                                                      className="select-attribute-value" 
                                                      onChange={event => this.onChangeSourceProductCategory(event)}
                                                      options={source_product_category_row}
                                                      isMulti={true}
                                                  />
                                                </div>
                                                <div className="p-0 form-group option_width">
                                                 <label for="domain" id="domain-text">Domain</label>
                                                    <Select                            
                                                        className="select-attribute-value"
                                                        onChange={event => this.onChangeSourceDomains(event)}
                                                        options={source_domains_row}
                                                        isMulti={true}
                                                    />
                                                </div>
                                              </div>
                                              <div className="row pb-3 m-0 d-flex justify-content-between">
                                                <div className=" p-0  form-group option_width">
                                                 <label for="role" id="role-text">Role</label>
                                                  <Select                            
                                                      className="select-attribute-value"
                                                      onChange={event => this.onChangeRoleOrGroup(event)}
                                                      options={role_or_group_row}
                                                      isMulti={true}
                                                  />
                                                </div>
                                                <div className=" p-0  form-group option_width">
                                                </div>
                                              </div>
                                              <div className="row pb-3 m-0">
                                                <span id="note-text">
                                                  Note: can assign multiple locations and roles to same user
                                                </span>
                                              </div>
                                            </div>
                                            <div className="modal-footer justify-content-between pl-0 pr-0 dashed-row-top">
                                              <button type="button" onClick={event => this.handleCloseModal(event,true)} className="btn  pl-4 pr-4 m-0" id="cancel-modal">
                                                Cancel
                                              </button>
                                              <button type="button" className="btn btn-dark nohover-btn m-0 pl-5 pr-5" onClick={event => this.handleAddUser(event,true)}>
                                                Add User
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>*/}
                        </div>
                        <div className="row">
                          <div className="col-md-12 pt-4">
                            <span>Last Sync: <b>{this.state.last_sync ? this.state.last_sync:'-'}</b></span>
                          </div>
                        </div>
                    </div>
                    <div className="row mt-3  m-0 p-0 mb-4">
                        <div className="col-md-12 m-0 p-0" id="users-table">
                            {
                                this.state.users_list.length > 0 
                                ?
                                <table className="table table-bordered text-left " id="users-table">
                                   <thead>
                                    <tr>
                                      <th>Users</th>
                                      <th>Email</th>
                                      <th>Role</th>
                                      <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody className="thead-light">
                                        {/*<tr id="table-first-row">
                                          <td colSpan="7" className="m-0 p-0"></td>
                                        </tr>*/}
                                        {user_list_row}
                                    </tbody>
                                </table>
                                :
                                <div>
                                    <span >No users </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Users);