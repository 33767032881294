import "noty/lib/noty.css"; 
import "noty/lib/themes/relax.css"
import './styles/animate.min.css'
// import {API_HOST} from '../settings.config.js'
// import $ from 'jquery'
const Noty = require('noty');
// const axios = require('axios').default;

const notify = (msg,message_type) => {
    var type = message_type
    var position = "bottomCenter"
    new Noty({
        theme: 'relax',
        text: msg,
        type: type,
        timeout: 3000,
        layout: position,
        //closeWith: ['button', 'click'],
        animation: {
            open: 'animated bounceInLeft',
            close: 'animated bounceOutUp'
        }
    }).show()
}

export default notify

