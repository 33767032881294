import React, { Component } from 'react'
import notify from '../../CommonFunctions.js'
import ErrorHandling from '../../ErrorHandler'
import $, { event } from 'jquery'
import axios from 'axios';
import Select from 'react-select'
import { API_HOST } from '../../Settings.js'
import {objectIsNotEmpty} from '../customHelperFunctions'
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class VariantAttribute extends Component {
    constructor(props){
        super(props)
        this.existing_attr_refs = [];
        this.new_attr_refs=[]
        this.state = {
            variant_attributes: [],
            join_multi_attr_val:{},
            join_multi_attr_keys:[],
            attr_score:'',
            new_attribute_list:[],
            attributes: [],
            new_attribute: {name: '', value: '', score: ''},
            dgs_perms:this.props.dgs_perms,
            batch_id:this.props.batchID,
            selected_variant_id: this.props.hasOwnProperty("selected_variant_id")? this.props.selected_variant_id:-1,
            create_or_update:'',
            key_for_existing:true,
            key_for_create:false,
            disable_save_all: false
        }
    }

    componentDidMount(){
        $('.attr-inline-loader').find('.inline-loader').fadeIn()
        if(
            objectIsNotEmpty(this.state.dgs_perms) 
            && (this.state.dgs_perms.group_perms.includes("repo.view_variantattribute")
                ||(this.state.dgs_perms.group_perms.includes("repo.add_variantattribute")
                    ||this.state.dgs_perms.group_perms.includes("repo.change_variantattribute")
                    ||this.state.dgs_perms.group_perms.includes("repo.delete_variantattribute")))
            ){
                /*const auth_token = "Token "+localStorage.malbar_token*/
                const auth_token = "Token "+cookies.get("malabar_token")
                axios.defaults.headers.get['Authorization'] =auth_token  
            	axios.get(`${API_HOST}/repo/design/${this.props.designID}/variant/attributes/list/?selected_variant_id=${this.state.selected_variant_id}&from_batch_batch_id=${this.state.batch_id}`, ).then(response => {
                    if (response.status === 200) {
                        let filtered_variant_obj = {}
                        response.data.variant_attributes.map((variant_attribute) =>{
                            var varint_attr_name = variant_attribute.attribute && variant_attribute.attribute.name
                            var varint_id = variant_attribute.attribute && variant_attribute.attribute.id
                            var attribte_val = variant_attribute.attribute_value
                            if(filtered_variant_obj[varint_attr_name]){
                                filtered_variant_obj[varint_attr_name].valuse.push(attribte_val)
                                filtered_variant_obj[varint_attr_name].original_values.push({"value":variant_attribute.attribute_value,"label":variant_attribute.attribute_value})
                            }else{
                                filtered_variant_obj[varint_attr_name] = {
                                    'variant_id':variant_attribute.id,
                                    'valuse':[attribte_val],'variant_attr_id':varint_id,'score':variant_attribute.score,
                                    'is_editable':false,'default_values':[],'is_multi_value':false,'option_values':[],
                                    "is_atrribute_required":variant_attribute.attribute.required,
                                    "original_values":[{"value":variant_attribute.attribute_value,"label":variant_attribute.attribute_value}]
                                }
                            }                    
                        })
                        var attr_val_keys = Object.keys(filtered_variant_obj)
                        this.setState({
                            variant_attributes: response.data.variant_attributes,
                            attributes: response.data.attributes,
                            join_multi_attr_val:filtered_variant_obj,
                            join_multi_attr_keys:attr_val_keys
                        })
                        $('.attr-inline-loader').find('.inline-loader').fadeOut('slow')
                }
                }).catch(response => {
                    ErrorHandling(response)
                    $('.attr-inline-loader').find('.inline-loader').fadeOut('slow')
                });
            }else{
                notify("You dont't have access","info")
            }
    }

    onAttributeScoreChange = (event) =>{
        let score = event.target.value
        if(score > 1 || score < 0){
            event.preventDefault()
            score = ''
        }
        this.setState({
            attr_score: score
        })
    }

    onAttributeValueChange = (event, multiple, name,create_or_update,type = null) =>{
        var new_attribute_list = this.state.new_attribute_list
        var key_for_existing = null
        var key_for_create = null
        if(create_or_update==='create'){
           key_for_existing = !this.state.key_for_existing
           key_for_create = this.state.key_for_create
        }
        else if(create_or_update==='update'){
            key_for_existing = this.state.key_for_existing
            key_for_create = !this.state.key_for_create
        }
        if(this.state.create_or_update!==create_or_update){
            new_attribute_list = []
            this.editCancel(event)
        }
        var is_valid_value = false
        var value = null
        if(['text','number'].includes(type)){
            value = event.currentTarget.value
            if(type==='number'){
                var is_number=Number(value.trim())
                if(Boolean(is_number)){
                    if(is_number>=0){
                        is_valid_value=true
                    }else{
                        is_valid_value=false
                    }
                }else{
                    is_valid_value=false
                }
            }else if(type==='text'){
                if(Boolean(value.trim())){
                    is_valid_value=true
                }else{
                    is_valid_value=false
                }
            }
            
        }else{
            is_valid_value = true
            if(multiple){
                value = Array.from(event, option => option.value);
                if (value.length===0){
                    is_valid_value = false
                }
            }else{
                value = event.value
            }
        }
        var filterd_indx = []
        /*this.state.new_attribute_list.filter((val,indx)=>{*/
        new_attribute_list.filter((val,indx)=>{
            if(val[name]){
                filterd_indx.push(indx)
            }
        })
        if(filterd_indx.length > 0){
            /*this.state.new_attribute_list[filterd_indx[0]][name] = value*/
            if(is_valid_value){
                new_attribute_list[filterd_indx[0]][name] = value
            }else{
                new_attribute_list.splice(filterd_indx[0])
            }
            
        }else{
            if(is_valid_value){
                var new_obj = {}
                new_obj[name] = value
                /*this.state.new_attribute_list.push(new_obj)*/
                new_attribute_list.push(new_obj) 
            }
            
        }        
        this.setState({
            /*new_attribute: this.state.new_attribute_list*/
            new_attribute:new_attribute_list,
            new_attribute_list:new_attribute_list,
            create_or_update:create_or_update,
            key_for_existing:key_for_existing,
            key_for_create: key_for_create
        })
    }

    onAttributeInputValueChange = (event, name_slug, name, create_or_update) =>{
        var value = event.currentTarget.value
        var new_obj = {}
        new_obj[name_slug] = value
        this.state.new_attribute_list = []
        this.state.new_attribute_list.push(new_obj)
        this.state.join_multi_attr_val[name].input_value = value      
        this.setState({
            new_attribute: this.state.new_attribute_list,
            join_multi_attr_val:this.state.join_multi_attr_val,
            create_or_update:create_or_update
        })
        
    }


    saveAttribute = (event, request_from) =>{
        var new_attributes = null 
        if(request_from === 'all'){
            new_attributes = this.state.new_attribute_list
        }else{
             var name =  $(event.currentTarget).attr('atrribute-name')
             new_attributes = this.state.new_attribute_list.filter((attr,indx)=>{
                 return attr[name]
             })
        }
        if(this.validateAttribute(new_attributes)){
            if(new_attributes.length > 0){
                 this.setState({
                     attributes: []
                 })
                 $('.attr-inline-loader').find('.inline-loader').fadeIn()
                 /*const auth_token = "Token "+localStorage.malbar_token*/
                 const auth_token = "Token "+cookies.get("malabar_token")
                 axios.defaults.headers.post['Authorization'] =auth_token      
                 axios.post(`${API_HOST}/repo/design/${this.props.designID}/variant/attributes/list/?selected_variant_id=${this.state.selected_variant_id}&from_batch_batch_id=${this.state.batch_id}`, new_attributes)
                 .then(response => {
                     if (response.status === 200) {
                         let filtered_variant_obj = {}
                         response.data.variant_attributes.map((variant_attribute) =>{
                             var varint_attr_name = variant_attribute.attribute && variant_attribute.attribute.name
                             var varint_id = variant_attribute.attribute && variant_attribute.attribute.id
                             var attribte_val = variant_attribute.attribute_value
                             if(filtered_variant_obj[varint_attr_name]){
                                 filtered_variant_obj[varint_attr_name].valuse.push(attribte_val)
                                 filtered_variant_obj[varint_attr_name].original_values.push({"value":variant_attribute.attribute_value,"label":variant_attribute.attribute_value})
                             }else{
                                 filtered_variant_obj[varint_attr_name] = {
                                    'variant_id':variant_attribute.id,
                                     'valuse':[attribte_val],'variant_attr_id':varint_id,'score':variant_attribute.score,
                                     'is_editable':false,"is_atrribute_required":variant_attribute.attribute.required,'default_values':[],'is_multi_value':false,'option_values':[],
                                     "original_values":[{"value":variant_attribute.attribute_value,"label":variant_attribute.attribute_value}]
                                 }
                             }                    
                         })
                         var attr_val_keys = Object.keys(filtered_variant_obj)
                         this.setState({
                             variant_attributes: response.data.variant_attributes,
                             attributes: response.data.attributes,
                             join_multi_attr_val:filtered_variant_obj,
                             join_multi_attr_keys:attr_val_keys,
                             new_attribute_list:[],
                             new_attribute: {
                                 name: '',
                                 value: '',
                                 score: '',
                             }
                         })
                         event.target.disabled = false
                         $('.attr-inline-loader').find('.inline-loader').fadeOut('slow')
                         notify('Attribute saved successfully.','success')
                     }
                 }).catch(response => {
                     ErrorHandling(response)
                     event.target.disabled = false
                     setTimeout(() =>{
                         window.location.reload()
                     },2000)
                 });
             }else{
                 notify('please select attribute values','warning')
             }
        }
     }
 

    editVariantAttribute = (event,attribute_id,variant_id) =>{
        this.setState({
            "new_attribute_list": [],
            "create_or_update":'update',
            "key_for_existing":this.state.key_for_existing,
            "key_for_create": !this.state.key_for_create,
            "disable_save_all": true
        })
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token 
        var url =  `${API_HOST}/repo/variant/attribute/${attribute_id}/retrieve/update/destroy/?batch_id=${this.state.batch_id}&variant_id=${variant_id}&selected_variant_id=${this.state.selected_variant_id}&from_batch_batch_id=${this.state.batch_id}`
        axios.get(url)
        .then(response => {
            if (response.status === 200) {
                $('.save-all-attr').attr('disabled',true)
                    let filtered_variant_obj = this.state.join_multi_attr_val
                    let attr_score = 0
                    response.data.variant_attributes.map((variant_attribute) =>{
                        var varint_attr_name = variant_attribute.attribute && variant_attribute.attribute.name
                        if(filtered_variant_obj[varint_attr_name]){
                            filtered_variant_obj[varint_attr_name].is_editable = true
                            filtered_variant_obj[varint_attr_name].attribute_type = variant_attribute.attribute.attribute_type.name_slug
                            if(variant_attribute.attribute.attribute_type.name_slug === 'text' || variant_attribute.attribute.attribute_type.name_slug === 'int' || variant_attribute.attribute.attribute_type.name_slug === 'float'){
                                filtered_variant_obj[varint_attr_name].input_value = variant_attribute.attribute_value
                            }
                            var attr_val = {'value':variant_attribute.attribute_value,'label':variant_attribute.attribute_value}
                            filtered_variant_obj[varint_attr_name].default_values.push(attr_val)
                            filtered_variant_obj[varint_attr_name].is_multi_value = variant_attribute.attribute.is_multi_value
                            filtered_variant_obj[varint_attr_name].attr_name_slug = variant_attribute.attribute.name_slug
                            filtered_variant_obj[varint_attr_name].is_atrribute_required = variant_attribute.attribute.required
                            attr_score = filtered_variant_obj[varint_attr_name].score
                        }                   
                    })
                    let attribute_values = response.data.attributes[0].values.map((value, index)=>{
                        return(
                            { value: value.value, label: value.value }
                            // <option value={value.id}>{value.value}</option>
                        )
                    })
                    filtered_variant_obj[response.data.attributes[0].name].option_values = attribute_values
                    var attr_val_keys = Object.keys(filtered_variant_obj)
                    attr_val_keys.map((attr,inx)=>{
                        if(attribute_id === filtered_variant_obj[attr].variant_attr_id){
                            filtered_variant_obj[attr].is_editable = true
                        }else{
                            filtered_variant_obj[attr].is_editable = false
                        }
                    })
                    this.setState({
                        join_multi_attr_val:filtered_variant_obj,
                        join_multi_attr_keys:attr_val_keys,
                        attr_score:attr_score

                    })

            }
        }).catch(response => {
            ErrorHandling(response)
        });        
    }



    editCancel= (event) =>{
        $('.save-all-attr').attr('disabled',false)
        let filtered_variant_obj = this.state.join_multi_attr_val
        var attr_val_keys = Object.keys(filtered_variant_obj)
        attr_val_keys.map((attr,inx)=>{              
                filtered_variant_obj[attr].is_editable = false
                filtered_variant_obj[attr].default_values = []
        })        
        this.setState({
            join_multi_attr_val:filtered_variant_obj,
            join_multi_attr_keys:attr_val_keys,
            new_attribute_list:[],
            disable_save_all: false
        })

    }

    validateAttribute =(attributes)=>{
        var all_valid = true
        var invalid_atrr_names = []
        attributes.map((each,key)=>{
            var attr = Object.keys(each)[0]
            var value = each[attr]
            if(typeof(value)==="object"){
                if(value.length===0){
                    invalid_atrr_names.push(attr)
                    all_valid = false
                }
            }
            else{
                if(typeof(value)==='string'){
                    if(value.trim()){

                    }else{
                        all_valid=false
                        invalid_atrr_names.push(attr)
                    }
                }
            }

        })
        if(!all_valid){
            if(invalid_atrr_names.length>1){
                notify(invalid_atrr_names.join(',')+" contains invalid values",'warning')
            }else{
                notify(invalid_atrr_names.join(',')+" contains invalid value",'warning')
            }
        }

        return all_valid
    }

    updateAttributeValues = (event,attribute_id) =>{
        var new_attribute = {
            'attributes':this.state.new_attribute_list,
            'design_id':this.props.designID
        }
        if(this.validateAttribute(new_attribute['attributes'])){
            if(this.state.new_attribute_list.length > 0){
                $('.attr-inline-loader').find('.inline-loader').fadeIn()
                /*const auth_token = "Token "+localStorage.malbar_token*/
                const auth_token = "Token "+cookies.get("malabar_token")
                axios.defaults.headers.patch['Authorization'] =auth_token      
                axios({
                    method: 'patch', 
                    url: `${API_HOST}/repo/variant/attribute/${attribute_id}/retrieve/update/destroy/?selected_variant_id=${this.state.selected_variant_id}&from_batch_batch_id=${this.state.batch_id}`,
                    data:new_attribute,                
                })
                .then((response) => {
                    if(response.status === 200){
                        this.setState({
                            disable_save_all: false
                        })
                        $('.save-all-attr').attr('disabled',false)
                        let filtered_variant_obj = {}
                        response.data.variant_attributes.map((variant_attribute) =>{
                            var varint_attr_name = variant_attribute.attribute && variant_attribute.attribute.name
                            var varint_id = variant_attribute.attribute && variant_attribute.attribute.id
                            var attribte_val = variant_attribute.attribute_value
                            if(filtered_variant_obj[varint_attr_name]){
                                filtered_variant_obj[varint_attr_name].valuse.push(attribte_val)
                                filtered_variant_obj[varint_attr_name].original_values.push({"value":variant_attribute.attribute_value,"label":variant_attribute.attribute_value})
                            }else{
                                filtered_variant_obj[varint_attr_name] = {
                                    'variant_id':variant_attribute.id,
                                    'valuse':[attribte_val],'variant_attr_id':varint_id,'score':variant_attribute.score,
                                    'is_editable':false,'default_values':[],'is_multi_value':false,'option_values':[],
                                    "is_atrribute_required":variant_attribute.attribute.required,
                                    "original_values":[{"value":variant_attribute.attribute_value,"label":variant_attribute.attribute_value}]
                                }
                            }                    
                        })
                        var attr_val_keys = Object.keys(filtered_variant_obj)
                        this.setState({
                            variant_attributes: response.data.variant_attributes,
                            join_multi_attr_val:filtered_variant_obj,
                            join_multi_attr_keys:attr_val_keys,
                            new_attribute_list:[],
                            new_attribute: {
                                name: '',
                                value: '',
                                score: '',
                            }
                        })
                        $('.attr-inline-loader').find('.inline-loader').fadeOut('slow')
                        if (response.data.success===true){
                            notify('Attribute updated successfully.','success')
                        }else{
                            notify(response.data.message,'warning')
                        }

                    }
                }).catch(response => {
                    ErrorHandling(response)
                    $('.attr-inline-loader').find('.inline-loader').fadeOut('slow')
                });  
            }else{
                this.editCancel(event,attribute_id)
            }
        }
    }

    deleteAttribute = (event, attribute_id) =>{
    	event.preventDefault()
    	if(window.confirm("Are you sure want to delete Attribute ?")){
            var data={'design_id':this.props.designID}
            $('.attr-inline-loader').find('.inline-loader').fadeIn()
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.delete['Authorization'] =auth_token      
    		axios.delete(`${API_HOST}/repo/variant/attribute/${attribute_id}/retrieve/update/destroy/?selected_variant_id=${this.state.selected_variant_id}&from_batch_batch_id=${this.state.batch_id}`,{data:data}).then(response => {
	            if (response.status === 200) {
		            	let filtered_variant_obj = {}
                        response.data.variant_attributes.map((variant_attribute) =>{
                            var varint_attr_name = variant_attribute.attribute && variant_attribute.attribute.name
                            var varint_id = variant_attribute.attribute && variant_attribute.attribute.id
                            var attribte_val = variant_attribute.attribute_value
                            if(filtered_variant_obj[varint_attr_name]){
                                filtered_variant_obj[varint_attr_name].valuse.push(attribte_val)
                                filtered_variant_obj[varint_attr_name].original_values.push({"value":variant_attribute.attribute_value,"label":variant_attribute.attribute_value})
                            }else{
                                filtered_variant_obj[varint_attr_name] = {
                                    'variant_id':variant_attribute.id,
                                    'valuse':[attribte_val],'variant_attr_id':varint_id,'score':variant_attribute.score,
                                    'is_editable':false,"is_atrribute_required": variant_attribute.attribute.required,'default_values':[],'is_multi_value':false,'option_values':[],
                                    "original_values":[{"value":variant_attribute.attribute_value,"label":variant_attribute.attribute_value}]
                                }
                            }                    
                            })
                        var attr_val_keys = Object.keys(filtered_variant_obj)
                        this.setState({
                            variant_attributes: response.data.variant_attributes,
                            attributes: response.data.attributes,
                            join_multi_attr_val:filtered_variant_obj,
                            join_multi_attr_keys:attr_val_keys
                        })
                        $('.attr-inline-loader').find('.inline-loader').fadeOut('slow')
                        notify('Attribute deleted successfully.','success')

	            }
	        }).catch(response => {
	            ErrorHandling(response)
                $('.attr-inline-loader').find('.inline-loader').fadeOut('slow')
	        });
    	}
    }

    handleClickExistingWheel(event, index){
        this.existing_attr_refs[index].current.addEventListener(
            'wheel',function(e) {e.target.blur()}
            )
    }

    handleClickNewWheel(event, index){
        this.new_attr_refs[index].current.addEventListener(
            'wheel',function(e) {e.target.blur()}
            )
    }


    render() {
        this.existing_attr_refs = []
        this.new_attr_refs = []
        let variant_attributes_tr = this.state.join_multi_attr_keys.map((varint_attr_key, key)=>{
            var variant_attribute = this.state.join_multi_attr_val[varint_attr_key]
            if(variant_attribute.is_editable){
                if(['int','float'].includes(variant_attribute.attribute_type)){
                    var myRef = React.createRef()
                    this.existing_attr_refs.push(myRef)
                    var temp_index = this.existing_attr_refs.length-1
                }
            }
    		return(
    			<tr className={(key + 1) % 2 === 1 ? 'bg-white tpad' :'repo-attribute-table-alt-color'} key={key}>
                    <td scope="row" className="text-left">
                        {varint_attr_key}{variant_attribute.is_atrribute_required &&
                        <sup style={{color: '#d61111', fontWeight: '900', fontSize: 'small'}}>&nbsp;*</sup>
                      }
                    </td>
                    {variant_attribute.is_editable && (this.state.create_or_update==='update'||this.state.create_or_update==='') ?
                        variant_attribute.attribute_type === 'dropdown'?
                            <td className="text-left" key={this.state.key_for_existing===true?'up'+key:key}>
                                <div className="form-group">
                                <Select                            
                                    className="select-attribute-value" id="new-value"
                                    onChange={event => this.onAttributeValueChange(event, variant_attribute.is_multi_value, variant_attribute.attr_name_slug,"update")}
                                    options={variant_attribute.option_values}
                                    defaultValue={variant_attribute.is_multi_value?variant_attribute.original_values:variant_attribute.original_values[0]}
                                    isMulti={variant_attribute.is_multi_value ? true: false}                                
                                />
                                </div>
                            </td>
                        :
                          variant_attribute.attribute_type === 'text'?
                            <input type="text" 
                                name="edit-attribute-value" 
                                className="form-control"
                                onChange={event => this.onAttributeInputValueChange(event, variant_attribute.attr_name_slug, varint_attr_key,'update' )}
                                value={variant_attribute.input_value}
                                key={this.state.key_for_existing===true?'up'+key:key}
                            />
                        :
                            <input type="number" 
                                name="edit-attribute-value" 
                                className="form-control"
                                autoComplete="off"
                                onChange={event => this.onAttributeInputValueChange(event, variant_attribute.attr_name_slug, varint_attr_key,'update' )}
                                value={variant_attribute.input_value}
                                min="0"
                                ref = {myRef}
                                onClick={event=>this.handleClickExistingWheel(event, temp_index)}
                                key={this.state.key_for_existing===true?'up'+key:key}
                            />
                    :
                        <td className="vari-attr-values">{variant_attribute.valuse.join(', ') }</td>
                    }
                    {/* {variant_attribute.is_editable?
                        <td className="text-right"> 
                        <div className="form-group">
                          <input className="form-control text-right" type="number" 
                              placeholder="Score"
                              autoComplete="off"
                              value={this.state.attr_score}
                             onChange={event => this.onAttributeScoreChange(event)}
                             id="example-number-input" min="0" max="1" />
                        </div>
                      </td> */}
                    {/* // : */}
                        <td className="text-right">{parseFloat(variant_attribute.score)?parseFloat(variant_attribute.score).toFixed(2):'-'}</td>
                    {/* // } */}
                    <td>
                    { variant_attribute.is_editable?
                       <div>
                            {
                                objectIsNotEmpty(this.state.dgs_perms) 
                                && this.state.dgs_perms.group_perms.includes("repo.change_variantattribute")
                                ?
                                <button type="button" className="btn attribte-save" 
                                    atrribute-name={variant_attribute.attr_name_slug}
                                    onClick={event => this.updateAttributeValues(event,variant_attribute.variant_attr_id)}                        
                                    //disabled={!(this.state.new_attribute.name && this.state.new_attribute.value && this.state.new_attribute.score)}
                                    >
                                    <i className="fas fa-save fa-lg"></i>
                                </button>
                                :null
                            }
                            <button type="button" className="btn"
                            	onClick={event =>this.editCancel(event)}>
                                <i className="fas fa-times"></i>
                            </button>                            
                       </div>
                    :
                        <div>
                            {
                                objectIsNotEmpty(this.state.dgs_perms) 
                                && this.state.dgs_perms.group_perms.includes("repo.change_variantattribute")
                                && varint_attr_key!=='Attributes Validated'
                                ?
                                <button type="button" className="btn"
                                    onClick={event =>this.editVariantAttribute(event,variant_attribute.variant_attr_id,variant_attribute.variant_id)}
                                >
                                    <i className="fas fa-pen"></i>
                                </button>
                                :null
                            }
                            {
                                objectIsNotEmpty(this.state.dgs_perms) 
                                && this.state.dgs_perms.group_perms.includes("repo.delete_variantattribute")
                                && variant_attribute.is_atrribute_required===false
                                ?
                                <button type="button" className="btn"
                                    onClick={event =>this.deleteAttribute(event, variant_attribute.variant_attr_id)}>
                                    <i className="fas fa-trash"></i>
                                </button>
                                :null
                            }
                        </div>
                     }
                    </td>
                </tr>
    		)
    	})
        let new_attributes_tr = this.state.attributes.map((attribute, key)=>{
            let attribute_values = attribute.values.map((value, index)=>{
                return(
                    { value: value.value, label: value.value }
                    // <option value={value.id}>{value.value}</option>
                )
            })
            if(['int','float'].includes(attribute.attribute_type.name_slug)){
                var myRef = React.createRef()
                this.new_attr_refs.push(myRef)
                var temp_index = this.new_attr_refs.length-1
            }
            return(
                <tr className={(this.state.join_multi_attr_keys.length + key + 1) % 2 === 1 ? 'bg-white tpad' : 'repo-attribute-table-alt-color'}>
                    <td className="text-left" >
                      {attribute.name}{attribute.required &&
                        <sup style={{color: '#d61111', fontWeight: '900', fontSize: 'small'}}>&nbsp;*</sup>
                      }
                    </td>
                    <td className="text-left" key={this.state.key_for_create===true?'cr'+key:key}>
                        {attribute.attribute_type && attribute.attribute_type.name_slug === 'dropdown'?
                            <div className="form-group">
                                <Select                            
                                    className="select-attribute-value" id="new-value"
                                    onChange={event => this.onAttributeValueChange(event, attribute.is_multi_value, attribute.name_slug, 'create')}
                                    options={attribute_values}
                                    isMulti={attribute.is_multi_value ? true: false}
                                />
                            </div>
                        :attribute.attribute_type.name_slug === 'text'?
                            <input type="text" 
                                name="attribute-value" 
                                className="form-control"
                                onChange={event => this.onAttributeValueChange(event, attribute.is_multi_value, attribute.name_slug,'create',"text")}
                            />
                        :
                            <input type="number" 
                                name="attribute-value" 
                                className="form-control"
                                min="0"
                                ref={myRef}
                                onClick={event=>this.handleClickNewWheel(event, temp_index)}
                                autoComplete="off"
                                onChange={event => this.onAttributeValueChange(event, attribute.is_multi_value, attribute.name_slug, 'create' ,"number")}
                            />
                        }
                    </td>
                    <td className="text-right"> 
                      {/* <div className="form-group">
                        <input className="form-control text-right" type="number" 
                            placeholder="Score"
                        //value={this.state.new_attribute.score}
                        // onChange={event => this.onAttributeScoreChange(event)}
                           id="example-number-input" min="0" max="1" />
                      </div> */}
                    </td>
                    <td className="text-left">
                      <button type="button" className="btn  attribte-save" 
                        atrribute-name={attribute.name_slug}
                        onClick={event => this.saveAttribute(event)}                        
                        //disabled={!(this.state.new_attribute.name && this.state.new_attribute.value && this.state.new_attribute.score)}
                        >
                        <i className="fas fa-save fa-lg"></i>
                      </button>
                    </td>
                  </tr>
            )

        })
        return (
            <div className="col-md-12">
                <div className="attribute-background">
                    {
                        objectIsNotEmpty(this.state.dgs_perms) 
                        && (this.state.dgs_perms.group_perms.includes("repo.view_variantattribute")
                            || this.state.dgs_perms.group_perms.includes("repo.add_variantattribute")
                            || this.state.dgs_perms.group_perms.includes("repo.change_variantattribute")
                            || this.state.dgs_perms.group_perms.includes("repo.delete_variantattribute")

                            )
                        ?
                        <table className="table table-bordered text-left tabl">
                            <thead className="table-borderless bg-white text-dark tab tpad head">
                                <th scope="col" className="text-left" width="30%" >Attribute</th>
                                <th scope="col" width="40%" >Value</th>
                                <th scope="col" className="text-right" width="15%">Score</th>
                                <th width="15%"> 
                                    {
                                        objectIsNotEmpty(this.state.dgs_perms) 
                                        && this.state.dgs_perms.group_perms.includes("repo.add_variantattribute")
                                        ?
                                        this.state.disable_save_all?
                                        <button className="btn btn-dark save-all-attr" id="batches_attribute_saveall_btn" disabled>
                                            Save All
                                        </button>
                                        :
                                        <button className="btn btn-dark save-all-attr" id="batches_attribute_saveall_btn" 
                                            onClick={event => this.saveAttribute(event,'all')}>
                                            Save All
                                        </button>
                                        :null
                                    }
                                </th>
                            </thead>
                            <tbody className="table-borderless text-secondary small" key={'va'}>
                                { variant_attributes_tr }
                                {
                                    objectIsNotEmpty(this.state.dgs_perms) 
                                    && this.state.dgs_perms.group_perms.includes("repo.add_variantattribute")
                                    ?
                                    new_attributes_tr
                                    :null
                                }    
                                                  
                            </tbody>
                        </table>
                        :
                        <h3>Permission Denied</h3>
                    }
                </div>
            </div>
        )
    }
}
