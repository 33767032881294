import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery'
import ErrorHandling from '../../ErrorHandler'
import {API_HOST} from '../../Settings.js'
// import Paginator from '../../Paginator.js'
import axios from 'axios';
import './roles_list.css'
import notify from '../../CommonFunctions.js'
import {objectIsNotEmpty} from '../customHelperFunctions'
import Cookies from "universal-cookie";
const cookies = new Cookies();


class Roles extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      search_term: "",
      search_in:[],
      user_perm:{},
      page_loading: true,
      page_data: {
        page_number: 1,
        page_size: 10,
        no_of_pages: 0,
        total: 0,
        default_page_number: 1
      },
      order_by: {
        created_date: '-created_date',
      },
      
    }
  }


  getData = (page_number, page_size, order_by) =>{
      $('.inline-loader').fadeIn()
      this.setState({
        "roles":[],
      })
      /*const auth_token = "Token "+localStorage.malbar_token*/
      const auth_token = "Token "+cookies.get("malabar_token")
      axios.defaults.headers.get['Authorization'] =auth_token
      axios.get(`${API_HOST}/users/groups/list/`,
        ).then(response => {
        if(response.status === 200){
            // var get_all_permissions = response.data.user_perm.get_all_permissions
            if (true){
                this.setState({
                  roles: response.data.groups,
                  page_loading: false,
                  user_perm:response.data.user_perm,
                })
            }else{
                notify("You don't have permission to aceess this page",'error')
                setTimeout(() =>{
                    this.props.history.push('/permissiondenied/')
                },1000)
            }
          
          $('.inline-loader').fadeOut('slow')

        }
      }).catch(response => {
          ErrorHandling(response)
      });
  }
  componentDidMount(){
    $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
    $('.main-menu-sub-group').find('#roles-group').find('.circle-title').addClass('active-navbar')
    $('.inline-loader').fadeIn()
    this.getUserPermissions()
  }

  getUserPermissions(){
      /*const auth_token = "Token "+localStorage.malbar_token*/
      const auth_token = "Token "+cookies.get("malabar_token")
      axios.defaults.headers.get['Authorization'] =auth_token
      axios.get(`${API_HOST}/users/user/permissions/read/`,
          ).then(response => {
              var get_all_permissions = response.data.user_perm.get_all_permissions;
              if(!response.data.user_perm.is_superuser){
                  notify("You don't have access to this page",'error')
                  this.props.history.push('/permissiondenied/')
              }
              else if(true || get_all_permissions.includes('batches.view_batch')){
                  this.setState({
                      user_perm: response.data.user_perm
                  })
                  this.getData(this.state.page_data.page_number, this.state.page_data.page_size, this.state.order_by.created_date)
              }else{
                    setTimeout(() =>{
                        this.props.history.push('/permissiondenied/')
                    },1000)
                    notify("You don't have permission to aceess this page", "error")
              }
          }).catch(response => {
              $('.inline-loader').fadeOut('slow')
              ErrorHandling(response)
          });
  }
  

  render(){

    let roles_list_data = this.state.roles.map((role, key)=>{
        return (
            <div class="m-0 row bg-white permission-row mt-1">
                <div class="col-md-2">
                    <div className="vertical-center">
                        <div class="d-flex justify-content-start">
                            <span class="mr-3 batch-url"><Link to={`/roles/${role.id}/update/`}>{role.name}</Link></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    if (objectIsNotEmpty(this.state.user_perm)){
        return(
          <div className="batch-list-content relative">
            { this.state.roles.length > 0 ?
              <div className="batch-main-content">
                <div className="inline-loader" ><div className="dot-elastic"></div></div>            
                <div className="mb-2">
                  <div className="col-md d-flex justify-content-end p-0">
                      {objectIsNotEmpty(this.state.user_perm) && this.state.user_perm.is_superuser?
                      <Link to="/roles/add/">
                      <button type="button" className="btn btn-dark addb">Add Role</button>
                      </Link>:null}
                  </div>
                </div>
                <div class="m-0 row bg-white permission-row">
                    <div class="col-md-2">
                        <div className="vertical-center">
                            <div class="d-flex justify-content-start">
                                <span class="mr-3"><b>Role</b></span>
                            </div>
                        </div>
                    </div>
                </div>
                {roles_list_data}
              </div>:
              <div>
              <div className="inline-loader" ><div className="dot-elastic"></div></div>
              <div className="mb-2">
                <div className="col-md d-flex justify-content-end p-0">
                    {objectIsNotEmpty(this.state.user_perm) && this.state.user_perm.is_superuser?
                    <Link to="/roles/add/">
                    <button type="button" className="btn btn-dark addb">Add Role</button>
                    </Link>:null}
                </div>
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px'}}>No Roles found</div>
              </div>
            }
          </div>
        )    
    }else{
        return(
            <div>
                
            </div>
        )
    }
    
  }
}

export default withRouter(Roles);