import React from 'react';
import Logo from './images/logo.svg'
// import NotificationIcon from './images/ios-notifications-outline.svg'
import PersonIcon from './images/ios-person.svg'
import ProfileIcon from './images/person.svg'
import ArrowDownIcon from './images/ios-arrow-down.svg'
import { Link, withRouter } from 'react-router-dom';
import {signOut} from './pages/Login/Logout.js'
import CheckToken from './pages/Login/CheckToken.js'
import CustomProgressBar from './pages/CustomProgressBar'
import {ProgressBarsContext} from './pages/ProgressBarsContext'
// import $ from 'jquery'

class NavBar extends React.Component {
  changeActiveBar = (event) =>{
    /*$('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
    $(event.currentTarget).find('.circle-title').addClass('active-navbar')*/
  }
  render() {
    var progress_bars_data = this.context['progress_bars']
    var progress_show_all = this.context['progress_show_all']
    var keys_array = Object.keys(this.context['progress_bars'])
    var progress_bars_for_ui = keys_array.map((key, index) => {
        var custom_class = ''
        if (progress_show_all===false && keys_array.length>1 && index!==0){
            custom_class = ' hide-progress '
        }
        var bar = progress_bars_data[key]
        var progress_id = bar['progress_id']
        var custom_id = 'progress_'+progress_id
        var progress_icon_type = bar['progress_icon_type']
        return <CustomProgressBar
                        message={bar['progress_text']} valuenow={bar['valuenow']}
                        custom_id={custom_id}  custom_class={custom_class} progress_icon_type={progress_icon_type} />
    })
    var anchors_for_hidden_download = keys_array.map((key, index) => {
        var progress_id = progress_bars_data[key]['progress_id']
        return <a id={"hidden_anchor_"+progress_id} style={{"display":"none"}}></a>
    })
    return(
      <div>
        <CheckToken/>
          <nav className="navbar navbar-expand-sm fixed-top">
            <ul className="navbar-nav">
              <a href="#" className="nav-item">
                <img src={Logo} id="brand-icon" alt="logo" />
              </a>
            </ul>
            <ul className="navbar-nav ml-auto">
              {/*
              <a href="#" className="nav-item">
                <img src={NotificationIcon} id="notification-icon" />
              </a>
              <div className="nav-item nav-item-breaker"></div>*/}
              <div className="profile nav-item">
                <img src={PersonIcon} id="profile-icon" />
              </div>
              <div className="nav-item text-center" style={{'alignSelf':'center'}}>
                <label id="user-name-nav" className="m-0">{localStorage.malbar_first_name?localStorage.malbar_first_name:localStorage.malbar_last_name?localStorage.malbar_last_name:'Username'}</label>
              </div>
                <li key="nav_arrowdown_icon" className="nav-item dropdown" style={{'alignSelf':'center'}}>
                <a className="nav-link" href="#" id="navbardrop" data-toggle="dropdown">
                <img  src={ArrowDownIcon} id="arrow-icon" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                <Link to="/profile/" className="dropdown-item logout-btn">

                <img src={ProfileIcon} id="profileview"/>Profile
                </Link>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item logout-btn" href={void(0)} onClick={signOut}>Log out</a>
                </div>
                </li>
            </ul>
          </nav>
          <div className="sidenav" id="main-menu">
            <div className="main-menu-sub-group" onClick={this.changeActiveBar}>
              <div id="batch-group" >
                <Link to="/batches/">
                  <div className="text-center">
                    <span className="circle">
                    </span>
                  </div>
                  <div className="text-center">
                    <label className="circle-title " id="batches-title">Batches</label>
                  </div>
                </Link>
              </div>
            </div>
            <div className="main-menu-sub-group" onClick={this.changeActiveBar}>
              <div id="repo-group" >
                <Link to="/repo/">
                  <div className="text-center">
                    <span className="circle">
                    </span>
                  </div>
                  <div className="text-center">
                    <label className="circle-title" id="repo-title">Repo</label>
                  </div>
                </Link>
              </div>
            </div>
            <div className="main-menu-sub-group" onClick={this.changeActiveBar}>
              <div id="job-group" >
                <Link to="/jobs/">
                  <div className="text-center">
                    <span className="circle">
                    </span>
                  </div>
                  <div className="text-center">
                    <label className="circle-title" id="jobs-nav-title">Jobs</label>
                  </div>
                </Link>
              </div>
            </div>
            <div className="main-menu-sub-group" onClick={this.changeActiveBar}>
              <div id="find-group" className=""  title='Search Similar Images'>
                <Link to="/find/similar/images/">
                  <div className="text-center">
                    <span className="circle">
                    </span>
                  </div>
                  <div className="text-center">
                    <label className="circle-title" id="roles-nav-title">Search</label>
                  </div>
                </Link>
              </div>
            </div>
            <div className="main-menu-sub-group" onClick={this.changeActiveBar}>
              <div id="users-group" className={localStorage.malbar_is_superuser.toLowerCase()=="true"? "":"d-none"} >
                <Link to="/users/">
                  <div className="text-center">
                    <span className="circle">
                    </span>
                  </div>
                  <div className="text-center">
                    <label className="circle-title" id="users-nav-title">Users</label>
                  </div>
                </Link>
              </div>
            </div>
            <div className="main-menu-sub-group" onClick={this.changeActiveBar}>
              <div id="roles-group" className={localStorage.malbar_is_superuser.toLowerCase()==="true"? "":"d-none"} >
                <Link to="/roles/list/">
                  <div className="text-center">
                    <span className="circle">
                    </span>
                  </div>
                  <div className="text-center">
                    <label className="circle-title" id="roles-nav-title">Roles</label>
                  </div>
                </Link>
              </div>
            </div>       
          </div>
          <div className="main left-margin-menu mb-4">
            <div  id="main-content">
              {this.props.children }
            </div>
            <div id="footer_content" className="d-flex justify-content-between footer-padding">
                <div className="d-flex justify-content-end ">
                    <div className="bg-white d-flex flex-column  pl-3 pr-3 justify-content-between" >
                        <div>
                            <ProgressBarsContext.Consumer>
                                {({progress_bars, progress_show_all, updateProgressShowAll}) => {
                                    if (progress_bars_for_ui.length>0){
                                        if (progress_show_all===true){
                                            return (
                                                <i class="fa fa-window-minimize" id="minimize_or_maximize_progress" aria-hidden="true" onClick={event => updateProgressShowAll(!progress_show_all)}></i>
                                            )
                                        }
                                        else if(progress_show_all===false){
                                            return (
                                                <i class="fa fa-window-maximize" id="minimize_or_maximize_progress" aria-hidden="true" onClick={event => updateProgressShowAll(!progress_show_all)}></i>
                                            )
                                        }
                                    }
                                }}
                            </ProgressBarsContext.Consumer>
                        </div>
                        <div className="mr-1" id="progressbars">
                            {progress_bars_for_ui}
                        </div>
                    </div>
                    {/*<div className="mr-1" id="progressbars">
                        {progress_bars_for_ui}
                    </div>*/}
                    <div className="d-flex justify-content-end">
                        <ProgressBarsContext.Consumer>
                            {({progress_bars, progress_show_all, updateProgressShowAll}) => {
                                if(false && progress_bars_for_ui.length>1){
                                    if(progress_show_all===true){
                                        return(
                                                <button className="mr-1 p-0 btn shadow-none align-self-end" onClick={event => updateProgressShowAll(false)}>...less</button>
                                            )
                                    }else{
                                        return (
                                            <button className="mr-1 p-0 btn shadow-none align-self-end" onClick={event => updateProgressShowAll(true)}>...more</button>
                                        )
                                    }
                                }
                            }}
                        </ProgressBarsContext.Consumer>
                    </div>
                </div>
                <div className="ml-2" style={{"display":"none"}}>
                    <div>
                        {anchors_for_hidden_download}
                    </div>
                </div>
            </div>
          </div>  
      </div>
      )
  }
}

NavBar.contextType = ProgressBarsContext

export default withRouter(NavBar);