import React from 'react';
import { withRouter } from 'react-router-dom';
// import ErrorHandling from '../../CommonFunctions'
import {API_HOST} from '../../Settings.js'
import axios from 'axios';
import './add_batch.css'
import $ from 'jquery'
// import { Redirect } from "react-router-dom";
import notify from '../../CommonFunctions.js';
import ErrorHandling from '../../ErrorHandler'
import {objectIsNotEmpty} from '../customHelperFunctions'
import {ProgressBarsContext} from '../ProgressBarsContext'
import Cookies from "universal-cookie";


const cookies = new Cookies();



class AddBatch extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        batch_json_file: null,
        batch_zip_file:null,
        user_perm:{},
        show_upload:true,
      }
    }  

    componentDidMount(){
        $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
        $('.main-menu-sub-group').find('#batch-group').find('.circle-title').addClass('active-navbar')
      this.getUserPermissions()
    }

    getUserPermissions(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        $('.inline-loader').fadeIn()
        axios.get(`${API_HOST}/users/user/permissions/read/`,
            ).then(response => {
                var get_all_permissions = response.data.user_perm.get_all_permissions;
                if(get_all_permissions.includes('batches.add_batch')){
                    this.setState({
                        user_perm: response.data.user_perm
                    })
                }else{
                    setTimeout(() =>{
                        this.props.history.push('/permissiondenied/')
                    },1000)
                    notify("You don't have permission to aceess this page", "error")
                }
                $('.inline-loader').fadeOut('slow')
            }).catch(response => {
                $('.inline-loader').fadeOut('slow')
                ErrorHandling(response)
            });
    }

    getFileInputDetails = (event, file_type) =>{
        var warning_message = "" 
        var input_file_element = null
        // var input_file = null
        var div_element = null
        if(file_type === 'xlsx'){
          input_file_element = document.getElementById('drag-drop-file-upload-xlsx')
          // input_file = input_file_element.value;
          div_element = document.getElementById('drag-drop-file-div-xlsx');
          warning_message = "xlsx"
        }
        else if(file_type==="zip"){
          input_file_element = document.getElementById('drag-drop-file-upload-zip') 
          // input_file = input_file_element.value;
          div_element = document.getElementById('drag-drop-file-div-zip');
          warning_message = "zip"
        }
        // var x = input_file.replace(/C:\\fakepath\\/i, '');       
        var is_valid_file = false
        if (event.target.files && event.target.files[0]) {
            let imgName = event.target.files[0].name;
            var temp_name = imgName.toLowerCase();
            if(file_type==="xlsx"){
                is_valid_file = temp_name.endsWith('.xlsx')
            }
            else if(file_type==="zip"){
                is_valid_file = temp_name.endsWith(".zip")   
            }
            if(is_valid_file){
                /*let reader = new FileReader();
                reader.onload = (e) => {
                    let imgData = e.target.result;
                    
                }
                reader.readAsDataURL(event.target.files[0]);*/
                div_element.setAttribute('data-title',imgName);
                if(file_type === 'xlsx'){
                  this.setState({
                    batch_json_file: event.target.files[0]
                  })
                }
                if(file_type==="zip"){
                  this.setState({
                    batch_zip_file: event.target.files[0]
                  })
                }
            }else{
                var message = "Only " + warning_message + " file accepted"
                alert(message)
                input_file_element.value = input_file_element.defaultValue;
                if(file_type === 'xlsx'){
                    div_element.setAttribute('data-title',"Drag & Drop an xlsx file here")
                  this.setState({
                    batch_json_file: null
                  })
                }
                if(file_type==='zip'){
                    div_element.setAttribute('data-title',"Drag & Drop an zip file here")
                    this.setState({
                        batch_zip_file: null
                    })
                }
            }
        }
    };

    isvalidFiles(event){
        /*returns true, true (isvalid , has_zip file)*/
       var xlsx_file_name = this.state.batch_json_file.name
       var zip_file_name = this.state.batch_zip_file
       if(this.state.batch_zip_file){
            zip_file_name = this.state.batch_zip_file.name 
       }

        if(!xlsx_file_name){
            /*notify('missing xlsx file','warning')*/
            return [false, false]
        }else if(!(xlsx_file_name.toLowerCase().endsWith('.xlsx'))){
            /*notify('invalid xlsx file', 'warning')*/
            return [false, false]
        }else if(zip_file_name){
            if(zip_file_name.toLowerCase().endsWith('.zip')){
                /*notify('zip file available', 'information')*/
                return [true, true]
            }else{
                /*notify('Invalid zip file', 'information')*/
                return [false, false]
            }
        }else{
            /*notify('Continue without zip file', 'information')*/
            return [true, false]
        }
       
    }


    handleSubmitWithContext = (event, progress_bars, addNewProgress, updatePercentValue, updateProgressText, removeProgress) => {
        event.preventDefault();
        var valid_response = this.isvalidFiles()
        if(valid_response[0]){
            // this.setState({"show_upload":false})
            $('.inline-loader').fadeIn()
            var job_id = null
            var job_number = null
            var progress_id = null

            let form_data_django = new FormData();
            form_data_django.append('batch_json_file', this.state.batch_json_file, this.state.batch_json_file.name);
            let url = `${API_HOST}/batches/upload/new/batch/to/bucket/`;
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.get['Authorization'] =auth_token  
            axios.get(url,{"params": {"filename": this.state.batch_zip_file ? this.state.batch_zip_file.name : '', "has_zip_file": valid_response[1] } })
            .then(response => {
              var signed_url = response.data.signed_url;
              var timestamp = response.data.timestamp;
              if(response.status === 200){
                  job_id = response.data.job_id
                  localStorage.setItem('uploaded_job_id',job_id)
                  job_number = response.data.job_number
                  progress_id = 'upload_batch'+job_number

                  addNewProgress(job_number, 3, job_number, progress_id, 'UPLOAD')

                  form_data_django.append('job_id', job_id)
                  form_data_django.append("response_file_type", "xlsx")
                  form_data_django.append('timestamp',timestamp)
                  form_data_django.append('has_zip_available', valid_response[1])

                  var config = {

                      onUploadProgress: (progressEvent) => {
                          var percentCompleted = Math.round( 
                                  (progressEvent.loaded * 100) / progressEvent.total 
                              );
                          // console.log("percent uploaded")
                          // console.log(percentCompleted)
                          updatePercentValue(progress_id, percentCompleted)
                          // var progress_bar =  $('#add_batch_progress_bar')
                          // if(progress_bar){
                          //     progress_bar.css('width', percentCompleted+'%').attr('aria-valuenow', percentCompleted);
                          //     progress_bar.text(percentCompleted+"%");
                          //     if (percentCompleted>=100){
                          //         // console.log("100% done")
                          //     }
                          // }
                      },
                      headers: {'content-type': 'application/octet-stream'}
                  };

                  axios.defaults.headers.post['Authorization'] =auth_token
                  return axios.post(url,form_data_django,
                  {   headers: {'content-type': 'multipart/form-data'} } 
                  ).then(response_django=>{
                      notify("xlsx file successfully uploaded",'information')
                      if(valid_response[1]){
                          var zip_file_name = this.state.batch_zip_file.name
                          new Promise(()=>{
                            return axios.put(
                                  signed_url,this.state.batch_zip_file,config
                              ).then(response_cloud => {
                                  let form_data_zip_response = new FormData();
                                  form_data_zip_response.append('job_id', job_id)
                                  form_data_zip_response.append("response_file_type", "zip")
                                  form_data_zip_response.append("message", "success")
                                  form_data_zip_response.append('filename', zip_file_name)
                                  form_data_zip_response.append('has_zip_available', valid_response[1])
                                  axios.defaults.headers.post['Authorization'] =auth_token
                                  axios.post(url,form_data_zip_response,
                                      {   headers: {'content-type': 'multipart/form-data'} } 
                                  )

                                  removeProgress(progress_id)

                                  notify("zip file successfully uploaded",'information')
                                  // localStorage.setItem('uploaded_job_id',job_id)
                                  this.props.history.push('/jobs/')

                              }).catch(error_cloud => {
                                  $('.inline-loader').fadeOut('slow')
                                  // console.log("error while uploading zip file")
                                  // console.log("job id")
                                  // console.log(job_id)
                                  // console.log(error_cloud)

                                  removeProgress(progress_id)

                                  let form_data_zip_response = new FormData();
                                  form_data_zip_response.append('job_id', job_id)
                                  form_data_zip_response.append("response_file_type", "zip")
                                  form_data_zip_response.append("message", "failed")
                                  form_data_zip_response.append('filename', zip_file_name)
                                  form_data_zip_response.append('has_zip_available', valid_response[1])
                                  axios.defaults.headers.post['Authorization'] =auth_token
                                  axios.post(url,form_data_zip_response,
                                      {   headers: {'content-type': 'multipart/form-data'} } 
                                  )
                                  if (this.state){
                                      this.setState({"show_upload": true})
                                  }
                                  alert("Network error, uploading failed")
                              })
                          })
                          this.props.history.push('/jobs/')
                      }else{
                          removeProgress(progress_id)
                          // localStorage.setItem('uploaded_job_id',job_id)
                          this.props.history.push('/jobs/')
                      }

                  }).catch(error_xlsx => {
                      $('.inline-loader').fadeOut('slow')
                      // console.log("error while sending xlsx file")
                      // console.log("job id")
                      // console.log(job_id)
                      // console.log(error_xlsx)

                      removeProgress(progress_id)

                      if (this.state){
                          this.setState({"show_upload": true})
                      }
                      alert("error occur while uploading xlsx file")
                  })
                  
                notify('Files Upload In Progress...','information')
                                       
              }          
            })
            .catch(error => {
              // console.log(error);
              $('.inline-loader').fadeOut('slow')
            })

        }else{
            alert("Invalid files selected")
        }
    }

    handleSubmit = (event) =>  {
        event.preventDefault();
        var valid_response = this.isvalidFiles()
        if(valid_response[0]){
          /*$('.inline-loader').fadeIn()*/
          this.setState({"show_upload":false})
          
          let form_data_django = new FormData();
          form_data_django.append('batch_json_file', this.state.batch_json_file, this.state.batch_json_file.name);
          let url = `${API_HOST}/batches/upload/new/batch/to/bucket/`;
          /*const auth_token = "Token "+localStorage.malbar_token*/
          const auth_token = "Token "+cookies.get("malabar_token")
          axios.defaults.headers.get['Authorization'] =auth_token  
          axios.get(url,{"params": {"filename": this.state.batch_zip_file ? this.state.batch_zip_file.name : '', "has_zip_file": valid_response[1] } })
          .then(response => {
            var signed_url = response.data.signed_url;
            var timestamp = response.data.timestamp;
            if(response.status === 200){
                var job_id = response.data.job_id
                localStorage.setItem('uploaded_job_id',job_id)
                form_data_django.append('job_id', job_id)
                form_data_django.append("response_file_type", "xlsx")
                form_data_django.append('timestamp',timestamp)
                form_data_django.append('has_zip_available', valid_response[1])

                var config = {

                    onUploadProgress: (progressEvent) => {
                        var percentCompleted = Math.round( 
                                (progressEvent.loaded * 100) / progressEvent.total 
                            );
                        // console.log("percent uploaded")
                        // console.log(percentCompleted)
                        var progress_bar =  $('#add_batch_progress_bar')
                        if(progress_bar){
                            progress_bar.css('width', percentCompleted+'%').attr('aria-valuenow', percentCompleted);
                            progress_bar.text(percentCompleted+"%");
                            if (percentCompleted>=100){
                                // console.log("100% done")
                            }
                        }
                    },
                    headers: {'content-type': 'application/octet-stream'}
                };

                axios.defaults.headers.post['Authorization'] =auth_token
                return axios.post(url,form_data_django,
                {   headers: {'content-type': 'multipart/form-data'} } 
                ).then(response_django=>{
                    notify("xlsx file successfully uploaded",'information')
                    if(valid_response[1]){
                        var zip_file_name = this.state.batch_zip_file.name
                        new Promise(()=>{
                          return axios.put(
                                signed_url,this.state.batch_zip_file,config
                            ).then(response_cloud => {
                                let form_data_zip_response = new FormData();
                                form_data_zip_response.append('job_id', job_id)
                                form_data_zip_response.append("response_file_type", "zip")
                                form_data_zip_response.append("message", "success")
                                form_data_zip_response.append('filename', zip_file_name)
                                form_data_zip_response.append('has_zip_available', valid_response[1])
                                axios.defaults.headers.post['Authorization'] =auth_token
                                axios.post(url,form_data_zip_response,
                                    {   headers: {'content-type': 'multipart/form-data'} } 
                                )
                                notify("zip file successfully uploaded",'information')
                                // localStorage.setItem('uploaded_job_id',job_id)
                                this.props.history.push('/jobs/')

                            }).catch(error_cloud => {
                                $('.inline-loader').fadeOut('slow')
                                // console.log("error while uploading zip file")
                                // console.log("job id")
                                // console.log(job_id)
                                // console.log(error_cloud)
                                let form_data_zip_response = new FormData();
                                form_data_zip_response.append('job_id', job_id)
                                form_data_zip_response.append("response_file_type", "zip")
                                form_data_zip_response.append("message", "failed")
                                form_data_zip_response.append('filename', zip_file_name)
                                form_data_zip_response.append('has_zip_available', valid_response[1])
                                axios.defaults.headers.post['Authorization'] =auth_token
                                axios.post(url,form_data_zip_response,
                                    {   headers: {'content-type': 'multipart/form-data'} } 
                                )
                                if (this.state){
                                    this.setState({"show_upload": true})
                                }
                                alert("Network error, uploading failed")
                            })
                        })
                    }else{
                        // localStorage.setItem('uploaded_job_id',job_id)
                        this.props.history.push('/jobs/')
                    }

                }).catch(error_xlsx => {
                    $('.inline-loader').fadeOut('slow')
                    // console.log("error while sending xlsx file")
                    // console.log("job id")
                    // console.log(job_id)
                    // console.log(error_xlsx)
                    if (this.state){
                        this.setState({"show_upload": true})
                    }
                    alert("error occur while uploading xlsx file")
                })
                
              notify('Files Upload In Progress...','information')
                                     
            }          
          })
          .catch(error => {
            // console.log(error);
            $('.inline-loader').fadeOut('slow')
          })
        }else{
            alert("Invalid files selected")
        }
  };


    render() {
        return (
            <div className="col-md-12" id="main-content">
              <div className="inline-loader" ><div className="dot-elastic"></div></div>
            { objectIsNotEmpty(this.state.user_perm) && this.state.user_perm.get_all_permissions.includes('batches.add_batch')
                ?
                <div>
                    {this.state.show_upload ?
                        <ProgressBarsContext.Consumer>
                            {({progress_bars, addNewProgress, updatePercentValue, updateProgressText, removeProgress}) => {
                                return (
                                    <form method="post" action="#" id="#" onSubmit={ event => this.handleSubmitWithContext(event, progress_bars, addNewProgress, updatePercentValue, updateProgressText, removeProgress)}>
                                        <div className="row m-0 justify-content-center bg-white pb-5">
                                            <div className="mr-4">
                                                <div className="mb-4 mt-3">
                                                    <span id="add-new-batch-title">Add New Batch</span>
                                                </div>
                                                <div className="file-upload-wrapper d-flex justify-content-center align-items-center">

                                                    <div className="form-group files" data-title="Drag & Drop an xlsx file here" id="drag-drop-file-div-xlsx">
                                                        <label id="drag-drop-file-label">
                                                        <input type="file"  name="new_batch_file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={event =>this.getFileInputDetails(event,'xlsx')} className="form-0 custom-file-input" id="drag-drop-file-upload-xlsx" multiple required/>
                                                        </label>
                                                    </div>
                                                </div>          
                                            </div> 
                                            <div className=" ml-4">
                                                <div className="mb-4 mt-3 zip-title">
                                                </div>
                                                <div className="file-upload-wrapper d-flex justify-content-center align-items-center">
                                                    <div className="form-group files" data-title="Drag & Drop an zip file here" id="drag-drop-file-div-zip">
                                                        <label id="drag-drop-file-label">
                                                            <input type="file"  name="new_batch_file" accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" onChange={event =>this.getFileInputDetails(event,'zip')} className="form-0 custom-file-input" id="drag-drop-file-upload-zip" multiple />
                                                        </label>
                                                    </div>
                                                </div>          
                                            </div>            
                                        </div>
                                        <div class="row m-0 justify-content-center bg-white pb-5">
                                            <div className="col-md-4"></div>   
                                        
                                            <button type="submit" className="btn btn-primary col-md ml-2" id="upload-btn">
                                              Upload
                                            </button>
                                        
                                            <div className="col-md-4"></div> 
                                        </div>
                                    </form>
                                )
                            }}
                        </ProgressBarsContext.Consumer>
                        :
                        <div>
                            <div className="row m-0 pb-5 justify-content-center">
                                <div>
                                    <span>Please wait uploading in process</span>
                                </div>
                            </div>
                            <div className="row m-0 pb-5 justify-content-center">
                                <div className="progress w-75">
                                  <div className="progress-bar" id="add_batch_progress_bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">0%</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                :null
            }
            </div>
        )
    }
}

export default withRouter(AddBatch);

