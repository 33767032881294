import React from 'react';
import { Link, withRouter } from 'react-router-dom';
// import ErrorHandling from '../../CommonFunctions'
import { API_HOST } from '../../Settings.js'
import ApprovedDesign from '../../images/check-circle.svg'
import RejectedDesign from '../../images/x-circle.svg'
import PendingDesign from '../../images/clock.svg'
// import DesignImage from '../../images/image_placeholder.jpg'
import Paginator from '../../Paginator.js'
import notify from '../../CommonFunctions.js'
import ErrorHandling from '../../ErrorHandler'
import RejectModal from './RejectModal'
import $ from 'jquery'
import axios from 'axios';
import './batch_details.css'
import RepoRectangleStatus from '.././Repos/RepoRectangleStatus'
import {objectIsNotEmpty} from '../customHelperFunctions'
import DownloadIcon from '../../images/download.svg'
import AttributeFilters from '../../AttributeFilters.js'
import CustomProgressBar from '../CustomProgressBar'
import { renderToString } from 'react-dom/server'
import {ProgressBarsContext} from '../ProgressBarsContext'

import Cookies from "universal-cookie";
const cookies = new Cookies();


class BatchDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            batch: {},
            statuses: {},
            designs: [],
            batchID: this.props.match.params.batchID,
			user_perm:{},
			is_designs_approved:'',
			is_approvals:'',
            page_loading: true,
			rejectModal:false,
			comment_msg:'',
	        page_data: {
		        page_number: 1,
		        page_size: 10,
		        no_of_pages: 0,
		        total: 0
		    },
            roles_for_this_batch:[],
            dgs_perms: {},
            show:false,
            selected_attribute_filters:{}
		}
    }

    getData = (page_number, page_size, status='') =>{
		// $('.inline-loader').fadeIn()
        // this.setState({
        //   "designs":[]
        // })
		/*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
		axios.defaults.headers.get['Authorization'] =auth_token  
    	axios.get(`${API_HOST}/repo/batch/${this.state.batchID}/designs/list/?page_number=${page_number}&page_size=${page_size}&status=${status}&attribute_values=${JSON.stringify(this.state.selected_attribute_filters)}`, )
    	.then(response => {
	        if (response.status === 200) {
				if(status){
					this.setState({
						designs: response.data.designs,
						page_loading: false,
						page_data: {
						  ...this.state.page_data,
						  no_of_pages: response.data.no_of_pages,
						},
                        "roles_for_this_batch": response.data.roles_for_this_batch,
					})

				}else{
					this.setState({
						designs: response.data.designs,
						statuses: response.data.statuses,
						page_loading: false,
						user_perm:response.data.user_perm,
						is_designs_approved:response.data.all_design_approved,
						is_approvals:response.data.batch_approval,
						comment_msg:response.data.comment_msg,
						page_data: {
						  ...this.state.page_data,
						  total: response.data.total,
						  no_of_pages: response.data.no_of_pages,						  
						},
                        "roles_for_this_batch": response.data.roles_for_this_batch
					})

				}
	            
				$('.inline-loader').fadeOut('slow')
                $('.card-loader').fadeOut('slow')

	        }
        }).catch(response => {
            ErrorHandling(response)
        });
    }
    componentDidMount() {
		$('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
		$('.main-menu-sub-group').find('#batches-title').addClass('active-navbar')	
		this.getUserPermissions()
    }

    handleAttributeFilters = (attribute_name = null, seleted_attr_values=null)=>{
        $('.designs-list-div').find('.card-loader').fadeIn()
        if (attribute_name && seleted_attr_values){
          var selected_attributes = this.state.selected_attribute_filters
          selected_attributes[attribute_name] = seleted_attr_values
          this.setState({
            selected_attribute_filters :selected_attributes
          })
          this.getData(this.state.page_data.page_number, this.state.page_data.page_size)
        }else if(attribute_name){
            var selected_attributes = this.state.selected_attribute_filters
            selected_attributes[attribute_name] = null
            this.setState({
              selected_attribute_filters :selected_attributes
            })
            this.getData(this.state.page_data.page_number, this.state.page_data.page_size)
        }
    }
    

    getUserPermissions(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        $('.batch-details-content').find('.inline-loader').fadeIn()
        axios.get(`${API_HOST}/users/user/permissions/read/`,
            ).then(response => {
                var get_all_permissions = response.data.user_perm.get_all_permissions;
                if(get_all_permissions.includes('batches.view_batch')||get_all_permissions.includes('batches.add_batch')){
                    this.setState({
                        user_perm: response.data.user_perm
                    })
                    this.getBatchDetails()
                }else{
                    setTimeout(() =>{
                        this.props.history.push('/permissiondenied/')
                    },1000)
                    notify("You don't have permission to aceess this page", "error")
                }
                $('.inline-loader').fadeOut('slow')
                $('.card-loader').fadeOut('slow')
            }).catch(response => {
                $('.inline-loader').fadeOut('slow')
                $('.card-loader').fadeOut('slow')
                ErrorHandling(response)
            });
    }

    getBatchDetails(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        $('.batch-details-content').find('.inline-loader').fadeIn()
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token  
        axios.get(`${API_HOST}/batches/batch/${this.state.batchID}/detail/`, ).then(response => {
            if (response.status === 200) {
                var group_perms = response.data.dgs_perms.group_perms;
                if (group_perms.includes('batches.view_batch')||group_perms.includes('batches.add_batch')){
                    this.setState({
                        batch: response.data.data,
                        "roles_for_this_batch": response.data.roles_for_this_batch,
                        "dgs_perms": response.data.dgs_perms
                    })
                    this.getData(this.state.page_data.page_number, this.state.page_data.page_size)
                }else{
                    notify("You don't have permission to aceess this page",'error')
                    setTimeout(() =>{
                        this.props.history.push('/permissiondenied/')
                    },1000)
                }
                
            }
        }).catch(response => {
            ErrorHandling(response)
        });
    }

    onPageNumberChange = (event, page_number) =>{
        $('.designs-list-div').find('.card-loader').fadeIn()
	    this.setState({
	      page_data: {...this.state.page_data, page_number: page_number}
	    })
	    this.getData(page_number, this.state.page_data.page_size)
	}
    onPageSizeChange = (event) =>{
        $('.designs-list-div').find('.card-loader').fadeIn()
	    this.setState({
	      page_data: {...this.state.page_data, page_size: parseInt(event.target.value), page_number: 1}
	    })
	    this.getData(1, event.target.value)
	}

	/*filterWithStatus = (event,status) =>{
		debugger
		if($(event.currentTarget).hasClass('active')){
			$(event.currentTarget).removeClass('active')
		this.getData(this.state.page_data.page_number, this.state.page_data.page_size)
		}else{
			$('.create-rectangle ').removeClass('active')
			$(event.currentTarget).addClass('active')
			this.getData(this.state.page_data.page_number, this.state.page_data.page_size,status)
		}
		
	}*/

	filterWithStatus = (event,status) =>{
        $('.designs-list-div').find('.card-loader').fadeIn()
		if($(event.currentTarget).hasClass('repo-index-status-selected')){
			$(event.currentTarget).removeClass('repo-index-status-selected')
		this.getData(this.state.page_data.page_number, this.state.page_data.page_size)
		}else{
			$('.repo-index-status-selected ').removeClass('repo-index-status-selected')
			$(event.currentTarget).addClass('repo-index-status-selected')
			this.getData(this.state.page_data.page_number, this.state.page_data.page_size,status)
		}
		
	}

    batchBulkApprove = (event)=>{
        $('.batch-details-content').find('.inline-loader').fadeIn()
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token  
        axios.get(`${API_HOST}/repo/batch/${this.state.batchID}/superuser/bulk/approve/`)
        .then(response => {
            if (response.status === 200 && response.data.success){
                notify(response.data.message,'success')               
                setTimeout(() =>{
                    window.location.reload()
                },2000)
            }else{
                notify(response.data.message, 'warning')
                $('.inline-loader').fadeOut('slow')
                $('.card-loader').fadeOut('slow')
            }


        })
        .catch(response => {
            $('.inline-loader').fadeOut('slow')
            $('.card-loader').fadeOut('slow')
            ErrorHandling(response)
        })

    }

	batchApproveOrRejectDesign = (event,status,msg='') =>{
        var confir_msg = ''
        var success_msg = ''
        if(status === 'approved'){
            confir_msg = 'Are you sure, you want approve this batch?'
            success_msg = 'Batch approved successfully!'
        }else{
            confir_msg = 'Are you sure, you want reject this batch?'
            success_msg = 'Batch rejected successfully!'
        }
        if(window.confirm(confir_msg)){
			$('.batch-details-content').find('.inline-loader').fadeIn()
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.get['Authorization'] =auth_token  
            axios.get(`${API_HOST}/repo/batch/${this.state.batchID}/supervisor/approve/or/reject/?status=${status}&msg=${msg}`).then(response => {
                if (response.status === 200 && response.data.success) {
                    // this.setState({
                    //     is_designs_approved:response.data.all_design_approved,
					// 	is_approvals:response.data.batch_approval,
					// 	batch:response.data.batch
                    // })
					notify(success_msg,'success')
					$('.inline-loader').fadeOut('slow') 
                    $('.card-loader').fadeOut('slow')                
					setTimeout(() =>{
						window.location.reload()
					},2000)
                }else if (response.status === 200 && response.data.success===false){
                    notify(response.data.message, 'error')
                    $('.inline-loader').fadeOut('slow')
                    $('.card-loader').fadeOut('slow')
                }else{
					notify('Something went wrong, Try again later.','error')
				}
            }).catch(response => {
				$('.inline-loader').fadeOut('slow')
                $('.card-loader').fadeOut('slow')
                ErrorHandling(response)
            });
        }
    }

	showRejectModal = () =>{
        this.setState({rejectModal:true})
    }

    hideRejectModal = () =>{
        this.setState({rejectModal:false})
    }

    update_progress_bar_ui_js = () => {
        $('#progressbars').children().addClass('hide-progress')
        $('#progressbars').children().first().removeClass('hide-progress');
        // $("#progressbars").children().first().addClass('show-progress');
    }

    change_value_of_notification = (count, message) => {
        var notification_menu_count = Number($('#notification_menu_count').text());
        if (message==='increment'){
            notification_menu_count  = notification_menu_count + count;
        }else if(message==='decrement'){
            notification_menu_count = notification_menu_count - 1;
        }
        $('#notification_menu_count').text(notification_menu_count)
    }

    update_prgress_bar_value = (bar_id, text_element_id, value) => {
        $('#'+text_element_id).text(value+'%')
        $('#'+bar_id).css('width', value+'%')
    } 

    downloadAttributesReportPromiseWithContext = (event, progress_bars, addNewProgress, updatePercentValue, updateProgressText, removeProgress) =>{
        var batch_name = this.state.batch.name
        var progress_id = 'download_batch_'+batch_name
        addNewProgress(batch_name, 3, batch_name, progress_id, 'DOWNLOAD')

        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.post['Authorization'] =auth_token
        let form_data = new FormData();
        form_data.append('batch_name', batch_name);
        new Promise(()=>{
          return axios.post(
                    `${API_HOST}/batches/batch/attributes/report/download/`,
                    form_data,{
                    responseType: 'arraybuffer',
                    onDownloadProgress: (progressEvent) => {
                            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            if (percentCompleted>3){
                                updatePercentValue(progress_id, percentCompleted)
                            }
                        }
                }).then(res => {
                    // console.log("resp.data.length:", res.data.length);
                    // console.log("content-length:", res.headers["content-length"]);
                    var file_name_from_response = res.headers['content-disposition'] ? res.headers['content-disposition'].split('filename=')[1].split(';')[0]:null
                    var the_response_data = res.data
                    var blob = new Blob(
                        [the_response_data]
                    );
                    var url = URL.createObjectURL(blob);
                    // var a = document.createElement('a');
                    var a = document.getElementById('hidden_anchor_'+progress_id);
                    // document.body.appendChild(a);
                    // a.style = "display: none";
                    a.href = url;
                    a.download = file_name_from_response ? file_name_from_response: "report_of_attributes_batch_level.xlsx";
                    a.click();
                    /*setTimeout(function() {
                        URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                     }, 100);*/
                    URL.revokeObjectURL(url);
                    // document.body.removeChild(a);
                    removeProgress(progress_id)
                     
                  }).catch(err => {
                        alert('Error while downloading')
                        removeProgress(progress_id)
                  })
        })
    }

    downloadAttributesReportPromise = (event) =>{
        var batch_name = this.state.batch.name
        var unique_id = ((((new Date()).toJSON().replaceAll('-', '')).replaceAll(':','')).replaceAll('.',''))
        var custom_id = 'progress_'+batch_name+'_'+unique_id
        var bar_id = 'bar_'+batch_name+'_'+unique_id
        var id_span_percent = 'percent_'+batch_name+'_'+unique_id
        $('#progressbars').append(renderToString(<CustomProgressBar name={batch_name} valuenow={0} custom_id={custom_id} bar_id={bar_id} id_span_percent={id_span_percent} />))

        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.post['Authorization'] =auth_token
        let form_data = new FormData();
        form_data.append('batch_name', batch_name);
        // var new_element = '<li className="dropdown-item">'+this.state.batch.name+'</li> <div className="dropdown-divider"></div>'
        // $('#notification_menu').append(new_element)
        
        // this.update_progress_bar_ui_js()
        // this.change_value_of_notification(1, 'increment')
        this.update_prgress_bar_value(bar_id, id_span_percent, 10)
        new Promise(()=>{
          return axios.post(
                    `${API_HOST}/batches/batch/attributes/report/download/`,
                    form_data,{
                    responseType: 'arraybuffer',
                    onDownloadProgress: (progressEvent) => {
                            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            // $('#'+id_span_percent).text(percentCompleted+'%')
                            this.update_prgress_bar_value(bar_id, id_span_percent, percentCompleted)
                        }
                }).then(res => {
                    // console.log("resp.data.length:", res.data.length);
                    // console.log("content-length:", res.headers["content-length"]);
                    var file_name_from_response = res.headers['content-disposition'] ? res.headers['content-disposition'].split('filename=')[1].split(';')[0]:null
                    var the_response_data = res.data
                    var blob = new Blob(
                        [the_response_data]
                    );
                    var url = URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = url;
                    a.download = file_name_from_response ? file_name_from_response: "report_of_attributes_batch_level.xlsx";
                    a.click();
                    setTimeout(function() {
                        URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                     }, 100);
                     $('#'+custom_id).remove()
                     // this.update_progress_bar_ui_js()
                     // this.change_value_of_notification(1, 'decrement')
                  }).catch(err => {
                        alert('Error while downloading')
                        $('#'+custom_id).remove()
                        // this.update_progress_bar_ui_js()
                        // this.change_value_of_notification(1, 'decrement')
                  })
        })
        // notify('File downloading In Progress...','information')
    }

    downloadAttributesReport = (event) =>{
      /*const auth_token = "Token "+localStorage.malbar_token*/
      $('.batch-details-content').find('.inline-loader').fadeIn()
      const auth_token = "Token "+cookies.get("malabar_token")
      axios.defaults.headers.post['Authorization'] =auth_token
      let form_data = new FormData();
      form_data.append('batch_name',this.state.batch.name);

      axios.post(`${API_HOST}/batches/batch/attributes/report/download/`, form_data,{
              responseType: 'arraybuffer',
          }).then(res => {
            var file_name_from_response = res.headers['content-disposition'] ? res.headers['content-disposition'].split('filename=')[1].split(';')[0]:null
              var the_response_data = res.data
              var blob = new Blob(
                      [the_response_data]
                  );
              var url = URL.createObjectURL(blob);
                     var a = document.createElement('a');
                     document.body.appendChild(a);
                     a.style = "display: none";
                     a.href = url;
                     a.download = file_name_from_response ? file_name_from_response: "report_of_attributes_batch_level.xlsx";
                     a.click();

                     setTimeout(function() {
                         URL.revokeObjectURL(url);
                         document.body.removeChild(a);
                     }, 100);
                     $('.inline-loader').fadeOut('slow')
                     $('.card-loader').fadeOut('slow')
          }).catch(err => {
                $('.inline-loader').fadeOut('slow')
                $('.card-loader').fadeOut('slow')
          })
    }

    toggleFilters = (event) =>{
        if(this.state.show){
          this.setState({
            show:false
          })
        }else{
          this.setState({
            show:true
          })
        }
      }

    // getImageUrlFromBucket = (event,image_name,bucket_name, batch_name,image_type) =>{

    // }
    render() {
    	var approved = this.state.statuses && this.state.statuses.approved
        var process_failed = 0 
		if(this.state.statuses['process-failed']){
			process_failed += this.state.statuses['process-failed']
		}
		if(this.state.statuses['rejected']){
			process_failed += this.state.statuses['rejected']
		}
        var pending = 0
		if(this.state.statuses.new){
			pending += this.state.statuses.new
		}
		if(this.state.statuses.processed){
			pending += this.state.statuses.processed
		}
		if(this.state.statuses.hold){
			pending += this.state.statuses.hold
		}
    	let cards_list = this.state.designs.map((design, key)=>{
			var status_icon = null
			if(design.variant_status === 'new' || design.variant_status === 'hold' || design.variant_status === 'processed'){
				status_icon = PendingDesign
			}else if(design.variant_status === 'approved'){
				status_icon = ApprovedDesign
			}else if(design.variant_status === 'rejected' || design.variant_status === 'process-failed'){
				status_icon = RejectedDesign
			}
    		return(
				<div className="col-lg-3 variant-card" key={key}>
					<div className="card"  >
						<div className="varinat-image-event">
						<Link to={`/batch/${this.state.batchID}/design/${design.design.id}/details/`} >
							{/* <img src={design.image_signed_url ? design.image_signed_url: DesignImage} className="card-img-center mx-auto d-block" style={{"width":"240px", "height":"auto", "maxHeight":"100%", "objectFit": "cover", "objectPosition": "center center"}} /> */}
							<img src={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${design.bucket_name}&batch_name=${this.state.batch.name}&image_name=${design.image_name}&image_type=thumbnails`} className="card-img-center mx-auto d-block" style={{"width":"240px", "height":"auto", "maxHeight":"100%", "objectFit": "cover", "objectPosition": "center center"}} alt="design" />
							<div className="card-img-overlay  text-right m-0 status-icon-div" style={{right: '-8px', top: '-13px'}}>
								{status_icon?
								<img src={status_icon} width="15px" height="15px" className="status-icon" alt="status0-icon" />:null}
							</div>
						</Link>
						</div>
						<div className="image-description-divider"></div>
						<div className="design-description-div">
							{design.vdin?
							<div className="row m-0 design-description-each-row"><label className="p3-vdi-number">VDI # <b>{design.vdin}</b></label></div>
							:null}
							<div className="row m-0 design-description-each-row">
							<label className="p3-product-name">{design.name}</label>
							</div>
							{design.design.gd_number?
							<div className="row m-0">
							<label className="p3-vendor-design-number">GD # {design.design.gd_number}</label>
							</div>:null}
						</div>
					</div>
				</div>
    		)
    	})
        if(objectIsNotEmpty(this.state.dgs_perms) && (this.state.dgs_perms.group_perms.includes("batches.view_batch")||this.state.dgs_perms.group_perms.includes("batches.add_batch")) ){
                    return (
                      <div className="relative batch-details-content">
                          <div className="inline-loader main-loader" ><div className="dot-elastic"></div></div>  
                        <div className="d-flex justify-content-between mb-0 mt-0" style={{"alignItems": 'center'}} id="gos-group">
                            <label id="current-url-total-path" className="mb-0">
                              <Link to="/batches/" className="current-url">Batches</Link> /&nbsp;
                              <a href="#" className="current-url">{this.state.batch.name}</a>
                            </label>
                            <div className='d-flex justify-content-between'>
                            {false && this.state.user_perm.is_superuser && this.state.batch && ['unprocessed','new'].includes(this.state.batch.status.name_slug)
                                ?
                                <button type="button" className="btn status-button" onClick={event =>this.batchBulkApprove(event)} id="batch-status-bulk-approve">Bulk Approve</button>
                                :null
                            }
                          { this.state.dgs_perms && objectIsNotEmpty(this.state.dgs_perms) && (
                                this.state.dgs_perms.group_perms.includes('batches.can_approve_batch')
                                || this.state.dgs_perms.group_perms.includes('batches.can_reject_batch')
                            )?
                            this.state.is_designs_approved && this.state.is_approvals === false?
                            <div className="status-group">  
                                {
                                    this.state.dgs_perms.group_perms.includes('batches.can_approve_batch')
                                    ?
                                    <button type="button" class="btn status-button " id="batch-status-approve" onClick={event =>this.batchApproveOrRejectDesign(event,'approved')}>Approve</button>
                                    :null
                                }
                                {
                                    this.state.dgs_perms.group_perms.includes('batches.can_reject_batch')
                                    ?
                                    <button type="button" class="btn status-button" id="batch-status-reject" onClick={this.showRejectModal}>Reject</button>
                                    :null
                                }
                            </div>:null
                         :null}
                         </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3" style={{"alignItems": "center"}} >

                          <label id="product-date" className="m-0 p-0">{this.state.batch.created_date} </label>
                            {this.state.comment_msg?
                            <label className="m-0">Reject Comment : <b>{this.state.comment_msg}</b></label>:null}
                           <label className="m-0">Status : <b>{this.state.batch.status && this.state.batch.status.name}</b></label>
                           {
                              this.state.batch.status //&& this.state.batch.status.name_slug==="approved"
                              ?
                              <div  className="status-group mr-2">
                                  <div data-toggle="tooltip" data-placement="top" title="Attributes Report">
                                    <ProgressBarsContext.Consumer>
                                        {({progress_bars, addNewProgress, updatePercentValue, updateProgressText, removeProgress}) => {
                                            if(!progress_bars.hasOwnProperty('download_batch_'+this.state.batch.name)){
                                                return (
                                                    <button type="button" className="btn btn-sm shadow-none"  onClick={event => this.downloadAttributesReportPromiseWithContext(event, progress_bars, addNewProgress, updatePercentValue, updateProgressText, removeProgress)}>
                                                        <img src={DownloadIcon} className="" width="20px" height="20px" alt="download-icon"/>
                                                    </button>
                                                )
                                            }else{
                                                return null
                                            }
                                            
                                        }}
                                    </ProgressBarsContext.Consumer>
                                  </div>
                              </div>
                              :null
                           }
                        </div>
                        <div className="batch-details-filter">
                            <AttributeFilters handleAttributeFilters = {this.handleAttributeFilters} />
                        </div>
                        <div className="row p-0 mt-4 mb-0" id="status-and-filter-row">

                            <div className="mr-2 repo-rectangle-status-border repo-rectangle-status-color" onClick={event => this.filterWithStatus(event,'approved')}>
                               <RepoRectangleStatus statusData={{title:"Approved",value:approved}} />
                            </div>
                            <div className="mr-2 repo-rectangle-status-border repo-rectangle-status-color" onClick={event => this.filterWithStatus(event,'rejected')}>
                               <RepoRectangleStatus statusData={{title:"Rejected",value:process_failed}} />
                            </div>
                            <div className="mr-2 repo-rectangle-status-border repo-rectangle-status-color" onClick={event => this.filterWithStatus(event,'pending')}>
                               <RepoRectangleStatus statusData={{title:"Pending",value:pending}} />
                            </div>

                            {/*
                                <div className="mr-3">              
                                <div className="d-flex flex-column create-rectangle" onClick={event => this.filterWithStatus(event,'approved')}>
                                    <div className="info-number"><b>{approved}</b></div>
                                    <div className="info-text">Approved</div>
                                </div>
                                </div>
                                <div className="mr-3">
                                    <div className="d-flex flex-column create-rectangle" onClick={event => this.filterWithStatus(event,'rejected')}>
                                        <div className="info-number"><b>{process_failed}</b></div>
                                        <div className="info-text">Rejected</div>
                                    </div>
                                </div>
                                <div className="mr-3">
                                    <div className="d-flex flex-column create-rectangle" onClick={event => this.filterWithStatus(event,'pending')}>
                                        <div className="info-number"><b>{pending}</b></div>
                                        <div className="info-text">Pending</div>
                                    </div>
                                </div>
                            */}
                            
                        {/* <div className="col-md text-right">
                            <div>
                              <div className="row justify-content-start dropdown" >
                                  <button className="btn col-md-11 d-flex justify-content-between"
                                    id="filter-dropdown" data-toggle="dropdown">
                                    <i className="fa fa-filter filt"></i>
                                    <div >
                                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    </div>
                                  </button>
                                  <div className="dropdown-menu">
                                      <a className="dropdown-item" href="#">Link 1</a>
                                      <a className="dropdown-item" href="#">Link 2</a>
                                      <a className="dropdown-item" href="#">Link 3</a>
                                    </div>
                              </div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-9" id="status-column">
                          <div className="row p-0 m-0">
                            <div className="col-md-3">

                                <span className="text-center">Status : <b>{this.state.batch.status && this.state.batch.status.name}</b></span>

                            </div>
                            <div className="col-md-2">
                                  <span className="text-center">Approved : <b>{approved}</b></span>

                            </div>
                            <div className="col-md-3">

                                <span className="text-center">Failed on Process : <b>{process_failed}</b></span>

                            </div>
                            <div className="col-md-3">
                                <span className="text-center">Pending to Review : <b>{pending}</b></span>
                            </div>
                            <div className="col-md"></div>
                          </div>
                        </div>            */}
                        
                        </div>
                        
                            <div>
                                { this.state.designs.length > 0 ?
                                    <div className="row d-flex justify-content-end ml-3 mr-3" style={{ 'paddingTop':'10px'}}>
                                    <label>Show&nbsp;
                                        <select onChange={event => this.onPageSizeChange(event)}
                                            value={this.state.page_data.page_size}>
                                        <option value="1">1</option>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        </select> entries
                                    </label>
                                    </div>:null
                                }
                                <div className="row p-0  card-row-color designs-list-div">
                                <div className="card-loader"  style={{display:"none"}}><div className="dot-elastic"></div></div>
                                { this.state.designs.length > 0 ?cards_list:<div className='text-center' style={{width:"100%"}}>No Designs found</div>}
                                {/* { cards_list } */}
                                </div>
                                { this.state.designs.length > 0 ?
                                <div>
                                    {this.state.page_data.total > 0 ?
                                      <Paginator page_data={this.state.page_data}
                                        onPageNumberChange={this.onPageNumberChange}/> : null
                                    }
                                </div>:null}
                            </div> 
                            {/* : <div className='text-center'>No Designs found</div>
                        } */}
                        { this.state.rejectModal ? <RejectModal hideModal={this.hideRejectModal}
                                                    rejectAction={this.batchApproveOrRejectDesign}
                                                    actionFrom='batch'
                                                   />:null

                        }
                      </div>
                    )
                }else{
                    return(
                        <div></div>
                    )
                }
    }
}

export default withRouter(BatchDetails);