import React, { Component } from 'react';

class DesignCardImageCheckbox extends Component {
  render() {
      /*const {image} = this.props.image*/

      return (

      	<div className="img-parent">
    	     <img className="image image-inside-div " id={this.props.id_component_name+"_"+this.props.img_id}
    	     	 src={this.props.image} alt="new" />
    	     <input type="checkbox" id={this.props.id_component_name+"_checkbox_"+this.props.checkbox_id+"_"+this.props.img_id}
    	      onClick={this.props.onChildCheckboxClick} className={"status-change-checkbox checkbox_"+this.props.id_component_name}/>
        </div>
      )
  }
}

export default DesignCardImageCheckbox
