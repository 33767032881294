import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {API_HOST} from '../../Settings.js'
import Paginator from '../../Paginator.js'
import axios from 'axios';
import RepoRectangleStatus from './RepoRectangleStatus'
import DesignCardFooter from "./DesignCardFooter"
// import ImagePlaceholder from '../../images/image_placeholder.jpg'
import DesignCardImage from './DesignCardImage'
import './repoindex.css'
import $ from 'jquery'
import ApprovedDesign from '../../images/check-circle.svg'
import RejectedDesign from '../../images/x-circle.svg'
import PendingDesign from '../../images/clock.svg'
import DesignCardImageWithStatus from './DesignCardImageWithStatus'
import RepoDesignCardFooterStudio from './RepoDesignCardFooterStudio'
import RepoDesignCardImageWithCount from './RepoDesignCardImageWithCount'
import ErrorHandling from '../../ErrorHandler'
import notify from '../../CommonFunctions.js'
import AttributeFilters from '../../AttributeFilters.js'
import Cookies from "universal-cookie";
const cookies = new Cookies();


class Repo extends Component {
  constructor(props) {
        super(props);
        this.state = {
            designs: [],
            search_term: "",
            search_in:[],
            page_loading: true,
            statuses: {},
            user_perm:{},
            page_data: {
              page_number: 1,
              page_size: 10,
              default_page_number: 1,
              no_of_pages: 0,
              total: 0
            },
            designs_request_images_stage1: [],
            designs_request_images_stage2: [],
            designs_request_images_stage1_count: 0,
            designs_request_images_stage2_count: 0,
            designs_showing: "active",
            page_view_as: "product_manager",
            show:false,
            selected_attribute_filters:{}
        }
        // this.handleAttributeFilters = this.handleAttributeFilters.bind(this)        
  }


    addOrRemoveItemFromSearchIn(condition,item,placeholder) {
          var search_in = this.state.search_in
          var element = $('#repo-search-input')
          var seperator = "";
          if (search_in.length===0){
              element.attr("placeholder",'Search By ')
          }else{
              seperator = ", "
          }
          var prev_placeholder = element.attr("placeholder");
          const index = search_in.indexOf(item);
          if(condition){
              if(index<0){
                  search_in.push(item)
                  element.attr("placeholder",prev_placeholder+seperator+placeholder)
              }            
          }
          else{
              if (index > -1) {
                  search_in.splice(index, 1);
                  if (prev_placeholder.includes(seperator+placeholder)){
                      element.attr("placeholder",prev_placeholder.replace(seperator+placeholder,""))    
                  }
                  else{
                      element.attr("placeholder",prev_placeholder.replace(placeholder+",",""))
                  }
                  
              }
          }
          if (search_in.length===0){

              element.attr("placeholder", "Search by GD#, Sourcing Location, Domain")
          }
          return search_in
    }

    handleFilterOption = (event) =>{
      const target = event.target;
      const name = target.name;
      var someProperty = ''
      if(name === 'filter_location_checkbox'){
          someProperty = this.addOrRemoveItemFromSearchIn(target.checked,"source_location","Source Location")

          this.setState({
            search_in:someProperty
          }) 
      }
      else if(name==="filter_domain_checkbox"){
          someProperty = this.addOrRemoveItemFromSearchIn(target.checked,"source_domain","Domain")

          this.setState({
            search_in:someProperty
          })
      }
      else if(name==="filter_gd_number_checkbox"){
          someProperty = this.addOrRemoveItemFromSearchIn(target.checked,"gd_number","GD#")

          this.setState({
            search_in:someProperty
          })
      }
    }


  getActive(event){
    $('.card-loader').fadeIn()
    var designs_showing = "active"
    if($(event.currentTarget).hasClass('repo-index-status-selected')){
      $(event.currentTarget).removeClass('repo-index-status-selected')
            designs_showing = "all";
      /*this.activeTotalDesigns()*/
    }else{
      $('.repo-index-status-selected ').removeClass('repo-index-status-selected')
      $(event.currentTarget).addClass('repo-index-status-selected')
            designs_showing= "active"
    }
    this.setState({
      page_data: {...this.state.page_data, page_number: this.state.page_data.default_page_number, 
                    page_size: this.state.page_data.page_size},
      designs_showing: designs_showing
    },() =>{
        this.getData(this.state.page_data.default_page_number, this.state.page_data.page_size)
    })
  }

  getActiveAndInactive(event){
    $('.card-loader').fadeIn()
    var designs_showing = "active"
    if($(event.currentTarget).hasClass('repo-index-status-selected')){
      $(event.currentTarget).removeClass('repo-index-status-selected')
      designs_showing = "active"
    }
    else {
      designs_showing = "all"
      $('.repo-index-status-selected ').removeClass('repo-index-status-selected')
      $(event.currentTarget).addClass('repo-index-status-selected')
      
    }
    this.setState({
      page_data: {...this.state.page_data, page_number: this.state.page_data.default_page_number, 
        page_size: this.state.page_data.page_size},
      designs_showing: designs_showing
    },() =>{
        this.getData(this.state.page_data.default_page_number, this.state.page_data.page_size)
    })
    
  }

  activeTotalDesigns(){
    $('#repo-index-total-designs').addClass("repo-index-status-selected");
  }

  getInactive(event){
    $('.card-loader').fadeIn()
    var designs_showing = "active"
    if($(event.currentTarget).hasClass('repo-index-status-selected')){
      $(event.currentTarget).removeClass('repo-index-status-selected')
      designs_showing = "all"
      /*this.activeTotalDesigns()*/
    }else{
      $('.repo-index-status-selected ').removeClass('repo-index-status-selected')
      $(event.currentTarget).addClass('repo-index-status-selected')
      designs_showing = "inactive"
    }
    this.setState({
      page_data: {...this.state.page_data, page_number: this.state.page_data.default_page_number,
                    page_size: this.state.page_data.page_size},
      designs_showing: designs_showing
    },() =>{
        this.getData(this.state.page_data.default_page_number, this.state.page_data.page_size)
    })
  }

  getPendingOrUploadedDesigns(event,request_type){
    $('.card-loader').fadeIn()
    var designs_showing = "active"
    if($(event.currentTarget).hasClass('repo-index-status-selected')){
      $(event.currentTarget).removeClass('repo-index-status-selected')
    }else{
        if(request_type==="pending_designs"){
            designs_showing = "pending_designs"
        }
        else if(request_type==="uploaded_designs"){
            designs_showing = "uploaded_designs"
        }
      $('.repo-index-status-selected ').removeClass('repo-index-status-selected')
      $(event.currentTarget).addClass('repo-index-status-selected')
    }

    this.setState({
                    "designs_showing":designs_showing,
                    page_data: {...this.state.page_data, page_number: this.state.page_data.default_page_number,
                                    page_size: this.state.page_data.page_size}
                },() => {
                        this.getData(this.state.page_data.default_page_number, this.state.page_data.page_size)
                    }
                )
  }

  getData = (page_number, page_size) =>{
    // $('.repo-inline-loader').fadeIn()
    /*const auth_token = "Token "+localStorage.malbar_token*/
    const auth_token = "Token "+cookies.get("malabar_token")
    axios.defaults.headers.get['Authorization'] =auth_token
    var search_in = this.state.search_in.join()
    axios.get(`${API_HOST}/repo/designs/list/?&search_term=${this.state.search_term
    }&search_in=${search_in}&page_number=${page_number}&page_size=${page_size}&designs_showing=${
        this.state.designs_showing
    }&page_view_as=${this.state.page_view_as}&attribute_values=${JSON.stringify(this.state.selected_attribute_filters)}`)
    .then(response => {
      if (response.status === 200) {
        // console.log("getdata success")
        this.setState({
          designs: response.data.designs,
          page_loading: false,
          statuses: response.data.statuses,
          page_data: {
            ...this.state.page_data,
            total: response.data.total,
            no_of_pages: response.data.no_of_pages,
            user_perm:response.data.user_perm,
          },
          designs_request_images_stage1:response.data.designs_request_images_stage1,
          designs_request_images_stage2:response.data.designs_request_images_stage2,
          designs_request_images_stage1_count: response.data.designs_request_images_stage1_count,
          designs_request_images_stage2_count: response.data.designs_request_images_stage2_count
        })
        $('.repo-inline-loader').fadeOut('slow')
        $('.card-loader').fadeOut('slow')

      }
    }).catch(response => {
        // console.log("getdata failed")
        $('.repo-inline-loader').fadeOut('slow')
        $('.card-loader').fadeOut('slow')
            // ErrorHandling(response)
      });
  }

  handleAttributeFilters = (attribute_name = null, seleted_attr_values=null)=>{
    $('.card-loader').fadeIn()
    if (attribute_name && seleted_attr_values){
      var selected_attributes = this.state.selected_attribute_filters
      selected_attributes[attribute_name] = seleted_attr_values
      this.setState({
        selected_attribute_filters :selected_attributes
      })
      // console.log(this.state.selected_attribute_filters)
      this.getData(this.state.page_data.default_page_number, this.state.page_data.page_size)
    }else if(attribute_name){
            var selected_attributes = this.state.selected_attribute_filters
            selected_attributes[attribute_name] = null
            this.setState({
              selected_attribute_filters :selected_attributes
            })
            // console.log(this.state.selected_attribute_filters)
            this.getData(this.state.page_data.page_number, this.state.page_data.page_size)
        }
  }

  componentDidMount() {
      $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
      $('.main-menu-sub-group').find('#repo-group').find('.circle-title').addClass('active-navbar')
      this.getUserPermissions()
  }

  getUserPermissions(){
    /*const auth_token = "Token "+localStorage.malbar_token*/
    const auth_token = "Token "+cookies.get("malabar_token")
    axios.defaults.headers.get['Authorization'] =auth_token
    $('.repo-inline-loader').fadeIn()
    axios.get(`${API_HOST}/users/user/permissions/read/`)
    .then(response => {
        if (response.status === 200) {
            var get_all_permissions = response.data.user_perm.get_all_permissions
            if(get_all_permissions.includes("repo.view_design")||get_all_permissions.includes("repo.view_variant")){
                var page_view_as = "product_manager"
                if(get_all_permissions.includes('repo.change_image')
                    || get_all_permissions.includes('repo.add_imagerequest')){
                    page_view_as = "product_manager"
                }
                else if(get_all_permissions.includes("repo.add_image")){
                    page_view_as = "studio_manager"   
                }
                // console.log("getdata")
                this.setState({"user_perm": response.data.user_perm, "page_view_as": page_view_as})
                this.getData(this.state.page_data.page_number, this.state.page_data.page_size)
            }else{
                notify("You don't have permission to aceess this page",'error')
                this.props.history.push('/permissiondenied/')
            }
        }
    }).catch(response => {
        $('.repo-inline-loader').fadeOut('slow')
        ErrorHandling(response)
      });
  }


  onSearch = (event) =>{
    event.preventDefault()
    $('.card-loader').fadeIn()
    let search_term = event.target.value
    this.setState({
      search_term: search_term,
      page_data: {...this.state.page_data, page_size: this.state.page_data.page_size,
                        page_number:this.state.page_data.default_page_number
                    }
    })
    this.getData(this.state.page_data.default_page_number, this.state.page_data.page_size)
  }

  handleChangeSearch(event){
    this.setState({search_term: event.target.value});
  }


  onPageNumberChange = (event, page_number) =>{
    $('.card-loader').fadeIn()
    this.setState({
      page_data: {...this.state.page_data, page_number: page_number}
    })
    this.getData(page_number, this.state.page_data.page_size)
  }
  onPageSizeChange = (event) =>{
    $('.card-loader').fadeIn()
    this.setState({
      page_data: {...this.state.page_data, page_size: parseInt(event.target.value), page_number: 1}
    })
    this.getData(1, event.target.value)
  }
  toggleFilters = (event) =>{
    if(this.state.show){
      this.setState({
        show:false
      })
    }else{
      this.setState({
        show:true
      })
    }
  }
  render() {    
    var active_designs = this.state.statuses && this.state.statuses.active_designs
    var inactive_designs = this.state.statuses && this.state.statuses.inactive_designs
    var total_designs = this.state.statuses && this.state.statuses.total_designs
    let design_row =  this.state.designs.map((design,index) => {
      if((this.state.designs_request_images_stage1.includes(design.id)) 
         || (this.state.designs_request_images_stage2.includes(design.id)))
        {
            var status_icon = null
            var has_count = false
            /*if(design.any_variant_status == 'new' || design.any_variant_status == 'hold' || design.any_variant_status == 'processed'){
              status_icon = PendingDesign
            }else if(design.any_variant_status == 'approved'){
              status_icon = ApprovedDesign
            }else if(design.any_variant_status == 'rejected' || design.any_variant_status == 'process-failed'){
              status_icon = RejectedDesign
            }*/
            if(this.state.designs_request_images_stage1.includes(design.id)){
                status_icon = PendingDesign 
            }
            else if(this.state.designs_request_images_stage2.includes(design.id)){
                status_icon = ApprovedDesign
                has_count = true
            }
            else {
                status_icon = RejectedDesign
            }
                return (
              <div className="custom-card mb-4 col-xs-12 col-sm-6 col-md-4 col-lg-3">
                  <div className="card repo-card">
                      <Link to={`/repo/design/${design.name_slug}`}>
                        {
                            has_count
                            ?
                            <div className="m-0 repo-studio-card-padding">
                                <RepoDesignCardImageWithCount count={design.new_images_count}  image={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${design.bucket_name}&batch_name=${design.batch_name}&image_name=${design.image_uri}&image_type=thumbnails`}/>
                            </div>
                            :
                            <div className="m-0 repo-studio-card-padding">
                                <DesignCardImageWithStatus image={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${design.bucket_name}&batch_name=${design.batch_name}&image_name=${design.image_uri}&image_type=thumbnails`}  status_icon={status_icon}/>
                            </div>

                        }
                        <div className="image-description-divider"></div>
                        {
                            this.state.page_view_as==="studio_manager"
                            ?
                            <div className="m-0 repo-studio-card-padding">
                                <RepoDesignCardFooterStudio design_name={design.name} 
                                    gd_number={design.gd_number} vdin={design.vdin} />
                            </div>
                            : 
                            <DesignCardFooter descriptionData={design} />
                        }
                        
                      </Link>
                  </div>
              </div>
            )
        }
      return (
        <div className="custom-card mb-4 col-xs-12 col-sm-6 col-md-4 col-lg-3">
            <div className="card repo-card">
                <Link to={`/repo/design/${design.name_slug}`}>
                <DesignCardImage image={design.image_uri} design={design} />
                <div className="image-description-divider"></div>
                <DesignCardFooter descriptionData={design} />
                </Link>
            </div>
        </div>
      )
          
    })

    return (
        <div className="relative repo-page-filters">
            <div className="repo-inline-loader bg-white" ><div className="dot-elastic"></div></div>
            {/* <h3 style={{'paddingLeft':'15px'}}>Repo </h3> */}
            {/* <button type="button" class="toggle-filter-font"  id="filter-toggle-btn" style={{background:"#f8f8f8",border:"none",fontSize:"23px"}} onClick={event =>this.toggleFilters(event)} ><i class="fa fa-filter"></i></button> */}
            {/* {this.state.show? */}
            <AttributeFilters handleAttributeFilters = {this.handleAttributeFilters} />
            {/* :null} */}
            <br/>
            {
                this.state.user_perm && this.state.page_view_as==="studio_manager" 
                ?
                <div className="row m-0 p-0">
                    <div className="mr-2 repo-rectangle-status-border repo-rectangle-status-color" onClick={event => this.getPendingOrUploadedDesigns(event,'pending_designs')}  style={{marginLeft:"15px;"}}>
                       <RepoRectangleStatus statusData={{title:"Requested Designs",value:this.state.designs_request_images_stage1_count }} />
                     </div>
                    <div  className="mr-2 repo-rectangle-status-border repo-rectangle-status-color"onClick={event => this.getPendingOrUploadedDesigns(event,'uploaded_designs')}>
                      <RepoRectangleStatus statusData={{title:"Uploaded Designs",value:this.state.designs_request_images_stage2_count}} />
                    </div>
                </div>
                :
                this.state.user_perm && this.state.page_view_as==="product_manager" 
                ?
                <div className="row m-0 p-0">
                    <div className="mr-2 repo-rectangle-status-border repo-rectangle-status-color" id="repo-index-total-designs" onClick={this.getActiveAndInactive.bind(this)}  style={{marginLeft:"15px"}}>
                       <RepoRectangleStatus statusData={{title:"Designs",value:total_designs ? total_designs : 0}} />
                     </div>
                    <div  className="mr-2 repo-rectangle-status-border repo-rectangle-status-color" id="repo-index-active-designs" onClick={this.getActive.bind(this)}>
                      <RepoRectangleStatus statusData={{title:"Active",value:active_designs ? active_designs : 0}}  />
                    </div>
                    <div  className="mr-2 repo-rectangle-status-border repo-rectangle-status-color" id="repo-index-inactive-designs" onClick={this.getInactive.bind(this)}>
                      <RepoRectangleStatus statusData={{title:"InActive",value:inactive_designs ? inactive_designs : 0 }} />
                    </div>
                    <div className="mr-2 repo-rectangle-status-border repo-rectangle-status-color" onClick={event => this.getPendingOrUploadedDesigns(event,'pending_designs')}>
                       <RepoRectangleStatus statusData={{title:"Requested Designs",value:this.state.designs_request_images_stage1_count }} />
                     </div>
                    <div  className="mr-2 repo-rectangle-status-border repo-rectangle-status-color"onClick={event => this.getPendingOrUploadedDesigns(event,'uploaded_designs')}>
                      <RepoRectangleStatus statusData={{title:"Uploaded Designs",value:this.state.designs_request_images_stage2_count}} />
                    </div>
                </div>
                :
                null
            }
            <div className="row mt-3  m-0 p-0 mb-3">
              <div className="col-md-12 pl-0  d-flex justify-content-between pr-0" style={{"alignItems": "center",marginLeft:"15px"}}>
                <div className="col-md-6">
                  <div className="row pl-0">
                    <div className="dropdown d-flex justify-content-center" id="repo-filter-dropdown">
                      <button type="button" className="repo-filter-font" data-toggle="dropdown" id="filter-dropdown-btn">
                        <i className="fa fa-filter"></i>
                      </button>
                      <div className="dropdown-menu">
                        <div className="checkbox dropdown-item">
                            <label className="m-0 d-flex justify-content-between">
                                <div>
                                    <span>Domain</span>
                                </div>
                                <div>
                                    <input className="ml-3" type="checkbox"
                                    name="filter_domain_checkbox"
                                    onChange={event => this.handleFilterOption(event)}/>
                                </div>
                            </label>
                        </div>
                        <div className="checkbox dropdown-item">
                            <label className="m-0 d-flex justify-content-between">
                                <div>
                                    <span>GD Number</span>
                                </div>
                                <div>
                                    <input className="ml-3" type="checkbox"
                                    name="filter_gd_number_checkbox"
                                    onChange={event => this.handleFilterOption(event)}/>
                                </div>
                            </label>
                        </div>
                        <div className="checkbox dropdown-item">
                            <label className="m-0 d-flex justify-content-between">
                                <div>
                                    <span>Location</span>
                                </div>
                                <div>
                                    <input className="ml-3" type="checkbox"
                                    name="filter_location_checkbox"
                                    onChange={event => this.handleFilterOption(event)}/>
                                </div>
                            </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9 m-0 p-0" id="repo-search-input-group">
                      <div className="input-group pl-3 pr-3">
                        <input id="repo-search-input" type="text" className="form-control pl-0 pr-0" 
                          placeholder="Search by GD#, Sourcing Location, Domain"
                          autoComplete="off"
                           onChange={this.handleChangeSearch.bind(this)} value={this.state.search_term}
                          onKeyPress={event => {if (event.key=== 'Enter') {this.onSearch(event)}}} name="search_term"/>
                        <div className="input-group-append ">
                          <button type="button" className="repo-search-icon p-0" onClick={event => this.onSearch(event)}>
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-end pl-3 pr-3">
                    <div className="">
                      <label className="m-0">Show&nbsp;
                        <select onChange={event => this.onPageSizeChange(event)}
                          value={this.state.page_data.page_size}>
                          <option value="1">1</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select> entries
                      </label>
                    </div>
                </div>
              </div>
            </div>
            
              <div>
              <div className="row p-0 m-0">
                <div className="card-loader"  style={{display:"none"}}><div className="dot-elastic"></div></div>

                {this.state.designs.length > 0 ?design_row:<div className='text-center' style={{width:"100%"}}>No Designs</div>}
              </div>
              { this.state.designs.length > 0 ?
              <div>
                {this.state.page_data.total > 0 ?
                  <Paginator page_data={this.state.page_data} onPageNumberChange={this.onPageNumberChange}/> : null
                }
              </div>:null}
            </div>
        </div>
    );
  }
}

export default withRouter(Repo);