import { Component } from 'react'
import { withRouter} from 'react-router-dom'
import Cookies from "universal-cookie";
const cookies = new Cookies();

class OauthRedirect extends Component {
    /*constructor(props){
        super(props)
    }*/
    componentDidMount(){
        var redirect_data = new URLSearchParams(this.props.location.search)
        if(redirect_data.get('success') === 'login'){
            cookies.set('malabar_token', redirect_data.get('token'), {path:'/'})
            localStorage.setItem('malbar_first_name',redirect_data.get('first_name'))
            localStorage.setItem('malbar_last_name',redirect_data.get('last_name'))
            localStorage.setItem('malbar_email',redirect_data.get('email'))
            /*localStorage.setItem('malbar_token', redirect_data.get('token'))*/
            localStorage.setItem('malbar_id',redirect_data.get('id'))
            localStorage.setItem('malbar_is_superuser', redirect_data.get('is_superuser'))
            this.props.history.push('/batches/')
            // window.location.replace = '/'
        }else{
            this.props.history.push({
                pathname:'/login',
                state:{params:{
                    message:'You cannot perform this action, Please contact your admin.'
                }}
            })
        }
        
    }
    render() {
        var style_div = {'font-size':'17px','font-style':'italic','padding':'13px'}
        return (
            <div id="loading" style={style_div}>
                    Loading.........
            </div>
        )
    }
}
export default withRouter(OauthRedirect)