import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {API_HOST} from '../../Settings.js'
import axios from 'axios';
import $ from 'jquery'
import './userdetails.css'
import ProfileIcon from '../../images/person.svg'
import notify from '../../CommonFunctions.js'
// import Select from 'react-select'
import ErrorHandling from '../../ErrorHandler'
import Cookies from "universal-cookie";
const cookies = new Cookies();

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "user_details": {},
            "user_id": localStorage.malbar_id?localStorage.malbar_id:null,
            "user_roles": [],
            "user_perm":{},
            "user_locale_data": {},
            
        }
    }

    componentDidMount() {
        $('.inline-loader').fadeIn()
        $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
        $('.main-menu-sub-group').find('#users-group').find('.circle-title').addClass('active-navbar')
        this.getData()
    }

    getData(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/users/user/${this.state.user_id}/details/`)
        .then(response => {
            if (response.status === 200) {
                if (response.data.has_user && response.data.user_details.length>0){
                    // var user_locale_data = response.data.user_locale_data
                    this.setState({
                        "user_details" : response.data.user_details[0],
                        "user_roles": response.data.dgs_user_perm_values,
                        "user_perm": response.data.user_perm,
                        "user_locale_data": response.data.user_locale_data,
                        
                    })
                    
                }
                else{
                    notify("Invalid user",'info')
                    this.props.history.push('/batches/')
                }
            }
            $('.inline-loader').fadeOut('slow')

        }).catch(response => {
                ErrorHandling(response)
                $('.inline-loader').fadeOut('slow')
            });
    }


    render() {
        var table_row = this.state.user_roles.map((row,index)=>{
            return  (
                    <tr id={"row_"+row.id}>
                        <td id={"row_"+row.id+1} className="roles-td  m-0" >{row.source_location_name}</td>
                        <td id={"row_"+row.id+2} className="roles-td  m-0">{row.source_domain_name}</td>
                        <td id={"row_"+row.id+3} className="roles-td  m-0">{row.source_name}</td>
                        <td id={"row_"+row.id+4} className="roles-td  m-0">{row.role_name}</td>
                    </tr>
                )
        })

        return (
            <div className="relative">
                <div className="inline-loader bg-white">
                    <div className="dot-elastic"></div>
                </div>
                {   this.state.user_details
                    ?
                    <div>
                        <div className="row m-0 p-0 ml-3 mr-3 justify-content-between">
                                <div className="d-flex justify-content-between user-profile m-0">
                                    <div className="user-profile-left align-items-center shadow p-0 mr-2">
                                        <div className="d-flex flex-column justify-content-center h-100 p-3">
                                            <div className="user-profile-image-div align-items-center justify-content-center">
                                                <img src={ProfileIcon} className="user-profile-image" alt='profile-icon'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-profile-right shadow d-flex  align-items-center p-3">
                                        <div className="h-100 d-flex flex-column justify-content-between">
                                            <div>
                                                <label className="m-0">First Name :&nbsp;&nbsp;<b>{this.state.user_details.first_name?this.state.user_details.first_name:"-"}</b></label>
                                            </div>
                                            <div >
                                                <label className="m-0">Last Name :&nbsp;&nbsp;<b>{this.state.user_details.last_name?this.state.user_details.last_name:"-"}</b></label>
                                            </div>
                                            <div >
                                                <label className="m-0">Email ID&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;<b>{this.state.user_details.email}</b></label>
                                            </div>
                                            <div>
                                                <label className="m-0">Time Zone :&nbsp;&nbsp;<b>{this.state.user_locale_data.time_zone ? this.state.user_locale_data.time_zone : '-'}</b></label>
                                            </div>
                                            <div >
                                                <label className="m-0">Last Login &nbsp;:&nbsp;&nbsp;<b>{this.state.user_details.last_login?this.state.user_details.last_login:"-"}</b></label>
                                            </div>
                                        </div>
                                    </div>
                                    {/*
                                        <div className="user-profile-left align-items-center shadow p-0 ml-2">
                                            <div className="d-flex flex-column justify-content-between h-100 p-3">
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div>
                                                            <label className="m-0">Time Zone :&nbsp;&nbsp;<b>{this.state.user_locale_data.time_zone}</b></label>
                                                        </div>                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    */}
                                </div>
                        </div>
                        <div className="row mt-1  m-0 p-0 ml-3 mr-3 mb-1 justify-content-end">
                        </div>
                        <div className="row mt-0  m-0 p-0 ml-3 mr-3 mb-4 justify-content-center">
                            {this.state && this.state.user_roles && this.state.user_roles.length>0 ?
                            <table className="table table-bordered text-left" id="users-details-table">
                                
                               <thead>
                                <tr>
                                  <th>Sourcing Location</th>
                                  <th>Sourcing Domain</th>
                                  <th>Product Category</th>
                                  <th>Role</th>
                                </tr>
                                </thead>
                                <tbody className="thead-light">
                                    {table_row}
                                </tbody>
                            </table>
                            :<h5 className="text-center">No permissions</h5>
                            }
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}

export default withRouter(UserProfile);