import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery'
import ErrorHandling from '../../ErrorHandler'
import {API_HOST} from '../../Settings.js'
import Paginator from '../../Paginator.js'
import axios from 'axios';
import './batches.css'
import RepoRectangleStatus from '.././Repos/RepoRectangleStatus'
import notify from '../../CommonFunctions.js'
import {objectIsNotEmpty} from '../customHelperFunctions'
import FilterListIcon from '../../images/filter_list.svg'
import Cookies from "universal-cookie";
const cookies = new Cookies();


class Batch extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      batches: [],
      new_count: 0,
      wip_count: 0,
      approved_count: 0,
      rejected_count: 0,
      unprocessed_count: 0,
      search_term: "",
      search_in:[],
      user_perm:{},
      page_loading: true,
      page_data: {
        page_number: 1,
        page_size: 10,
        no_of_pages: 0,
        total: 0,
        default_page_number: 1
      },
      order_by: {
        created_date: '-created_date',
      },
      batch_status_filter: [],
      date_option_selected: "",
      date_option_saved: "",
      from_date: "",
      to_date: "",
      original_from_date: "",
      original_to_date: "",
      max_date_for_from_date: new Date().toISOString().split("T")[0],
      max_date_for_to_date : new Date().toISOString().split("T")[0],
      min_date_for_from_date: -1,
      min_date_for_to_date: -1
    }
  }


  getData = (page_number, page_size, order_by) =>{
      $('.inline-loader').fadeIn()
      /*const auth_token = "Token "+localStorage.malbar_token*/
      const auth_token = "Token "+cookies.get("malabar_token")
      axios.defaults.headers.get['Authorization'] =auth_token
      var search_in = this.state.search_in.join()
      var selected_dates_range = [this.state.date_option_saved, this.state.original_from_date, this.state.original_to_date].join()
      var batch_status_filter = this.state.batch_status_filter.join()
    axios.get(`${API_HOST}/batches/list/?search_term=${this.state.search_term}&search_in=${search_in}&page_number=${page_number}&page_size=${page_size}&order_by=${order_by}&batch_status_filter=${batch_status_filter}&selected_dates_range=${selected_dates_range}`,
        ).then(response => {
        if(response.status === 200){
            var get_all_permissions = response.data.user_perm.get_all_permissions
            if (get_all_permissions.includes('batches.view_batch') || get_all_permissions.includes('batches.add_batch')){
                this.setState({
                  batches: response.data.batches,
                  new_count: response.data.new_count,
                  wip_count: response.data.wip_count,
                  approved_count: response.data.approved_count,
                  rejected_count: response.data.rejected_count,
                  unprocessed_count: response.data.unprocessed_count,
                  page_loading: false,
                  user_perm:response.data.user_perm,
                  page_data: {
                    ...this.state.page_data,
                    total: response.data.total,
                    no_of_pages: response.data.no_of_pages,
                  }
                })
            }else{
                notify("You don't have permission to aceess this page",'error')
                setTimeout(() =>{
                    this.props.history.push('/permissiondenied/')
                },1000)
            }
          
          $('.inline-loader').fadeOut('slow')

        }
      }).catch(response => {
          ErrorHandling(response)
      });
  }
  componentDidMount(){
    $('.inline-loader').fadeIn()
    $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
    $('.main-menu-sub-group').find('#batch-group').find('.circle-title').addClass('active-navbar')
    this.getUserPermissions()
  }

  getUserPermissions(){
      /*const auth_token = "Token "+localStorage.malbar_token*/
      const auth_token = "Token "+cookies.get("malabar_token")
      axios.defaults.headers.get['Authorization'] =auth_token
      axios.get(`${API_HOST}/users/user/permissions/read/`,
          ).then(response => {
              var get_all_permissions = response.data.user_perm.get_all_permissions;
              if(get_all_permissions.includes('batches.view_batch')|| get_all_permissions.includes('batches.add_batch')){
                  this.setState({
                      user_perm: response.data.user_perm
                  })
                  this.getData(this.state.page_data.page_number, this.state.page_data.page_size, this.state.order_by.created_date)
              }else{
                    setTimeout(() =>{
                        this.props.history.push('/permissiondenied/')
                    },1000)
                    notify("You don't have permission to aceess this page", "error")
              }
          }).catch(response => {
              $('.inline-loader').fadeOut('slow')
              ErrorHandling(response)
          });
  }

  onChange = (event) =>{
    event.preventDefault()
    let search_term = event.target.value
    this.setState({
      search_term: search_term,
      page_data: {...this.state.page_data, page_number: 1}
    })
  }

  onSearch = (event) => {
    this.setState(
            { 
                page_data: {...this.state.page_data, page_number: this.state.page_data.default_page_number,
                                page_size: this.state.page_data.page_size}
            },
            () => {
                this.getData(1, this.state.page_data.page_size, this.state.order_by.created_date)
            }
        )
  }
  
  onPageNumberChange = (event, page_number) =>{
    this.setState({
      page_data: {...this.state.page_data, page_number: page_number}
    })
    this.getData(page_number, this.state.page_data.page_size, this.state.order_by.created_date)
  }
  onPageSizeChange = (event) =>{
    this.setState({
      page_data: {...this.state.page_data, page_size: parseInt(event.target.value), page_number: 1}
    })
    this.getData(1, event.target.value, this.state.order_by.created_date)
  }
  handleOrderChange = (event, order_by) =>{
    this.setState({
      order_by: {...this.state.order_by, created_date: order_by},
      page_data: {...this.state.page_data, page_number: 1}
    })
    this.getData(1, this.state.page_data.page_size, order_by)
  }

  addOrRemoveItemFromSearchIn(condition,item,placeholder) {
        var search_in = this.state.search_in
        var element = $('#repo-search-input')
        var seperator = "";
        if (search_in.length===0){
            element.attr("placeholder",'Search By ')
        }else{
            seperator = ", "
        }
        var prev_placeholder = element.attr("placeholder");
        const index = search_in.indexOf(item);
        if(condition){
            if(index<0){
                search_in.push(item)
                element.attr("placeholder",prev_placeholder+seperator+placeholder)
            }            
        }
        else{
            if (index > -1) {
                search_in.splice(index, 1);
                if (prev_placeholder.includes(seperator+placeholder)){
                    element.attr("placeholder",prev_placeholder.replace(seperator+placeholder,""))    
                }
                else{
                    element.attr("placeholder",prev_placeholder.replace(placeholder+",",""))
                }
                
            }
        }
        if (search_in.length===0){

            element.attr("placeholder", "Search by Batch ID, Sourcing Location, Domain")
        }
        return search_in
  }


  handleFilterOption = (event) =>{
    const target = event.target;
    const name = target.name;
    var someProperty = null
    if(name === 'filter_location_checkbox'){
        someProperty = this.addOrRemoveItemFromSearchIn(target.checked,"source_location","Source Location")

        this.setState({
          search_in:someProperty
        }) 
    }
    else if(name==="filter_domain_checkbox"){
        someProperty = this.addOrRemoveItemFromSearchIn(target.checked,"source_domain","Domain")

        this.setState({
          search_in:someProperty
        })
    }
    else if(name==="filter_source_checkbox"){
        someProperty = this.addOrRemoveItemFromSearchIn(target.checked,"source","Source")

        this.setState({
          search_in:someProperty
        })
    }
    else if(name==="filter_status_checkbox"){
        someProperty = this.addOrRemoveItemFromSearchIn(target.checked,"status","Status")

        this.setState({
          search_in:someProperty
        })
    }

    else if(name==="filter_batch_checkbox"){
        someProperty = this.addOrRemoveItemFromSearchIn(target.checked,"batch","Batch ID")

        this.setState({
          search_in:someProperty
        })
    }
  }


  handleStatusFilterOption = (event) =>{
    const target = event.target;
    const value = target.value;
    var batch_status_filter = this.state.batch_status_filter
    const index = batch_status_filter.indexOf(value);
    const status = target.checked;
    if(batch_status_filter.includes('new')){ 
        batch_status_filter.splice(batch_status_filter.indexOf('new'),1)
    }
    if(status){
        if(index<0){
            batch_status_filter.push(value)
        }
    }else{
        if(index>-1){
            batch_status_filter.splice(index, 1);
        }
    }
    this.setState({
        "batch_status_filter": batch_status_filter
    })
  }

  handleDateRangeSelection(event){
    this.setState({
        "date_option_selected": event.target.value
    })
  }

  handleDateSelected(event){
    var name = event.target.name 
    if(name==="from_date"){
        this.setState({
            "from_date": event.target.value,
            "max_date_for_to_date": new Date().toISOString().split("T")[0],
            "min_date_for_to_date": event.target.value
        })
    }
    if(name==="to_date"){
        this.setState({
            "to_date": event.target.value,
            "max_date_for_from_date": event.target.value
        })
    }
  }

  resetDateRangeSelection= (event,value) =>{
    event.preventDefault()
    $('#daterangeModal').modal('hide');
    if(value==='reset'){

        this.setState({
            "date_option_selected": "",
            "date_option_saved": "",
            "from_date": "",
            "to_date": "",
            "original_from_date": "",
            "original_to_date": "",
            "max_date_for_from_date": new Date().toISOString().split("T")[0],
            "max_date_for_to_date" : new Date().toISOString().split("T")[0]
        },()=>{
            this.onSearch()
        })
    }
    if(value==='close'){
        var mystate = this.state
        this.setState({
            "date_option_selected": mystate.date_option_saved,
            "from_date":mystate.original_from_date,
            "to_date": mystate.original_to_date,
            "max_date_for_from_date": new Date().toISOString().split("T")[0],
            "max_date_for_to_date" : new Date().toISOString().split("T")[0],
            "min_date_for_to_date": mystate.original_from_date
        })
    }
  }

  handleSubmit(event){
    event.preventDefault()
    $('#daterangeModal').modal('hide');
    var date_option_selected = this.state.date_option_selected
    var from_date = this.state.from_date
    var to_date = this.state.to_date
    this.setState({
        "date_option_selected": date_option_selected,
        "date_option_saved": date_option_selected,
        "original_from_date": from_date,
        "original_to_date": to_date,
        "min_date_for_to_date": from_date

    },()=>{
        this.onSearch()
    })
  }

  getSelectedBatches(event, status_type){
    var batch_status_filter = this.state.batch_status_filter
    if(batch_status_filter.length===1 && batch_status_filter.includes(status_type)){
        this.setState(
                {"batch_status_filter":[],},
                () => {
                    this.onSearch()
                }
            )
    }else{
        this.setState(
                {
                    "batch_status_filter":[status_type],
                },
                () => {
                    this.onSearch()
                }
            )
    }
  }

  render(){
    let batches_tr = this.state.batches.map((batch, key)=>{
        return(
          <tr key={key}>
            <td className="batch-url"><Link to={`/batch/${batch.id}/details/`}>{batch.name}</Link></td>
            <td>{batch.created_date}</td>
            <td>{batch.source_location.name}</td>
            <td>{batch.source_domain.name}</td>
            <td>{batch.source.name}</td>
            <td>{batch.pending_count} / {batch.design_count}</td>
            <td>{batch.status.name}</td>
          </tr>
        )
    })
    if (objectIsNotEmpty(this.state.user_perm) && (this.state.user_perm.get_all_permissions.includes("batches.view_batch") || this.state.user_perm.get_all_permissions.includes("batches.add_batch")) ){
        return(
          <div className="batch-list-content relative">
            <div className="row m-0 p-0">
                <div className={this.state.batch_status_filter.length===1 && this.state.batch_status_filter[0]==="new"?  "mr-2 repo-rectangle-status-border repo-rectangle-status-color repo-index-status-selected":"mr-2 repo-rectangle-status-border repo-rectangle-status-color"} onClick={event => this.getSelectedBatches(event,'new')}>
                   <RepoRectangleStatus statusData={{title:"New",value:this.state.new_count}} />
                </div>
                {/*<div className="mr-2 repo-rectangle-status-border repo-rectangle-status-color">
                   <RepoRectangleStatus statusData={{title:"WIP",value:this.state.wip_count}} />
                </div>*/}
                <div className={this.state.batch_status_filter.length===1 && this.state.batch_status_filter[0]==="approved"?  "mr-2 repo-rectangle-status-border repo-rectangle-status-color repo-index-status-selected":"mr-2 repo-rectangle-status-border repo-rectangle-status-color"} onClick={event => this.getSelectedBatches(event,'approved')}>
                   <RepoRectangleStatus statusData={{title:"Approved",value:this.state.approved_count}} />
                </div>
                <div className={this.state.batch_status_filter.length===1 && this.state.batch_status_filter[0]==="rejected"?  "mr-2 repo-rectangle-status-border repo-rectangle-status-color repo-index-status-selected":"mr-2 repo-rectangle-status-border repo-rectangle-status-color"} onClick={event => this.getSelectedBatches(event,'rejected')}>
                   <RepoRectangleStatus statusData={{title:"Rejected",value:this.state.rejected_count}} />
                </div>
                <div className={this.state.batch_status_filter.length===1 && this.state.batch_status_filter[0]==="unprocessed"?  "mr-2 repo-rectangle-status-border repo-rectangle-status-color repo-index-status-selected":"mr-2 repo-rectangle-status-border repo-rectangle-status-color"} onClick={event => this.getSelectedBatches(event,'unprocessed')}>
                   <RepoRectangleStatus statusData={{title:"Unprocessed",value:this.state.unprocessed_count}} />
                </div>
                {/*
                    <div>
                        Filter by Date within 
                        S{this.state.date_option_selected} -
                        a{this.state.date_option_saved} -
                        f{this.state.from_date} -
                        t{this.state.to_date}
                    </div>
                */}
            </div>
             {/*<div className="row m-0 p-0">
               <div className="mr-3">
                 <div className="d-flex flex-column create-rectangle">
                   <div>
                     <b>{this.state.new_count}</b>
                   </div>
                   <div>
                     New
                   </div>
                 </div>
               </div>
               <div>
                 <div className="d-flex flex-column create-rectangle">
                   <div>
                     <b>{this.state.wip_count}</b>
                   </div>
                   <div>
                     WIP
                   </div>
                 </div>
               </div>
             </div>
            */}
            {/*
                <div className="row mb-4 mt-4  m-0">
                  <div className="col-md-12 pl-0 d-flex justify-content-between pr-0">
                    <div className="col-md-6">
                      <div className="row pl-0">                
                        <div className="col-md-10 m-0 p-0">
                            <div className="input-group">
                              <div className="input-group-append dropdown">
                                <button type="button" className="filter-font" data-toggle="dropdown">
                                  <i className="fa fa-filter filt"></i>
                                </button>
                                <div className="dropdown-menu">
                                  <div className="checkbox dropdown-item">
                                    <label><input type="checkbox" value=""/> Location</label>
                                  </div>
                                  <div className="checkbox dropdown-item">
                                    <label><input type="checkbox" checked="" value=""/> Domain</label>
                                  </div>
                                  <div className="checkbox dropdown-item">
                                    <label><input type="checkbox" value=""/> Source</label>
                                  </div>
                                  <div className="checkbox dropdown-item">
                                    <label><input type="checkbox" checked="" value=""/> Status</label>
                                  </div>
                                </div>
                              </div>
            */}
                              {/* <div className="input-group-append" hidden="">
                                <button type="button" className="filter-font">
                                  <i className="fa fa-filter filt"></i>
                                </button>
                              </div>                     */}
            {/*
                              <input id="search-input" type="text" className="form-control"
                                  placeholder="Search by Batch ID, Sourcing Location, Domain"
                                  onChange={event =>this.onChange(event)} name="search_term"
                                  value={this.state.search_term} />
                              <div className="input-group-append">
                                <button type="button" className="search-icon"><i className="fa fa-search ssicon"></i></button>
                              </div>
                            </div>
                          </div>
            */}
                        {/* <div className="col-md-1 p-0 m-0">
                        <button className="btn filter-font"   type="button">
                          <i className="fa fa-filter filt"></i>
                        </button>
                        </div> */}
                        {/* <div className="col-md-8 m-0 p-0">
                          <div className="input-group mb-3">
                             <input id="search-input" type="text" className="form-control"
                                placeholder="Search by Batch ID, Sourcing Location, Domain"
                                onChange={event =>this.onChange(event)} name="search_term"
                                value={this.state.search_term} style={{borderRight: '0px'}} />
                             
                           </div>
                          <div>
                          </div>
                        </div> */}
            {/*
                      </div>
                    </div>
                    <div>
                      <div className="col-md d-flex justify-content-between p-0">
                          <button className="btn" onClick={()=>{window.location = '.'}} id="refresh">
                            <i className="fas fa-redo-alt alt" ></i> Refresh
                          </button>
                          {objectIsNotEmpty(this.state.user_perm) && this.state.user_perm.get_all_permissions.includes("batches.add_batch")?
                          <Link to="/add/new/batch/">
                          <button type="button" className="btn btn-dark addb">Add Batch</button>
                          </Link>:null}
                      </div>
                    </div>
                  </div>
                </div>
            */}
            <div className="row mb-4 mt-4 m-0 " style={{'display':'none'}}>
              <button type="button" className=" badge badge-info" data-dismiss="alert" aria-label="Close">
                Domain<span aria-hidden="true">×</span>
              </button>
              <button type="button" className=" badge badge-info" data-dismiss="alert" aria-label="Close">
                Status<span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="row mb-3 mt-3 m-0">
                <div className="col-lg-12 pl-0 d-flex justify-content-between pr-0">
                    <div className="col-md-9">
                      <div className="row pl-0">
                        <div className="dropdown d-flex justify-content-center" id="batch-filter-dropdown">
                          <button type="button" className="repo-filter-font" data-toggle="dropdown" id="batch-filter-dropdown-btn">
                            <img src={FilterListIcon} width="20px" height="20px" className="" alt="design"/>
                          </button>
                          <div className="dropdown-menu">
                            <div className="checkbox dropdown-item">
                                <label className="m-0 d-flex justify-content-between">
                                    <div>
                                        <span>Approved</span>
                                    </div>
                                    <div>
                                        <input className="ml-3" type="checkbox"
                                        name="filter_approved_checkbox"
                                        value="approved"
                                        checked = {this.state.batch_status_filter.includes('approved') ? true : false}
                                        onChange={event => this.handleStatusFilterOption(event)}/>
                                    </div>
                                </label>
                            </div>
                            <div className="checkbox dropdown-item">
                                <label className="m-0 d-flex justify-content-between">
                                    <div>
                                        <span>Rejected</span>
                                    </div>
                                    <div>
                                        <input className="ml-3" type="checkbox"
                                        name="filter_rejected_checkbox"
                                        value="rejected"
                                        checked = {this.state.batch_status_filter.includes('rejected') ? true : false}
                                        onChange={event => this.handleStatusFilterOption(event)}/>
                                    </div>
                                </label>
                            </div>
                            <div className="checkbox dropdown-item">
                                <label className="m-0 d-flex justify-content-between">
                                    <div>
                                        <span>Unprocessed</span>
                                    </div>
                                    <div>
                                        <input className="ml-3" type="checkbox"
                                        name="filter_unprocessed_checkbox"
                                        value="unprocessed"
                                        checked = {this.state.batch_status_filter.includes('unprocessed') ? true : false}
                                        onChange={event => this.handleStatusFilterOption(event)}/>
                                    </div>
                                </label>
                            </div>
                            <div className="text-right mr-4 mt-2">
                                <button className="btn btn-sm btn-dark" id='batch_status_filter'
                                onClick={event => this.onSearch(event)}>Search</button>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown d-flex justify-content-center" style={{"borderTopLeftRadius": "0px", "borderBottomLeftRadius": "0px", "borderLeftWidth": "0px"}} id="repo-filter-dropdown">
                          <button type="button" className="repo-filter-font" data-toggle="dropdown" id="filter-dropdown-btn">
                            <i className="fa fa-filter"></i>
                          </button>
                          <div className="dropdown-menu">
                            <div className="checkbox dropdown-item">
                                <label className="m-0 d-flex justify-content-between">
                                    <div>
                                        <span>Batch ID</span>
                                    </div>
                                    <div>
                                        <input className="ml-3" type="checkbox"
                                        name="filter_batch_checkbox"
                                        onChange={event => this.handleFilterOption(event)}/>
                                    </div>
                                </label>
                            </div>
                            <div className="checkbox dropdown-item">
                                <label className="m-0 d-flex justify-content-between">
                                    <div>
                                        <span>Domain</span>
                                    </div>
                                    <div>
                                        <input className="ml-3" type="checkbox"
                                        name="filter_domain_checkbox"
                                        onChange={event => this.handleFilterOption(event)}/>
                                    </div>
                                </label>
                            </div>
                            <div className="checkbox dropdown-item">
                                <label className="m-0 d-flex justify-content-between">
                                    <div>
                                        <span>Location</span>
                                    </div>
                                    <div>
                                        <input className="ml-3" type="checkbox"
                                        name="filter_location_checkbox"
                                        onChange={event => this.handleFilterOption(event)}/>
                                    </div>
                                </label>
                            </div>
                            <div className="checkbox dropdown-item">
                              <label className="m-0 d-flex justify-content-between">
                                  <div>
                                      <span>Source</span>
                                  </div>
                                  <div>
                                    <input className="ml-3" type="checkbox"
                                    name="filter_source_checkbox"
                                    onChange={event => this.handleFilterOption(event)}/>
                                  </div>
                              </label>
                            </div>
                            {/*<div className="checkbox dropdown-item">
                                <label className="m-0 d-flex justify-content-between">
                                    <div>
                                        <span>Status</span>
                                    </div>
                                    <div>
                                        <input className="ml-3" type="checkbox"
                                        name="filter_status_checkbox"
                                        onChange={event => this.handleFilterOption(event)}/>
                                    </div>
                                </label>
                            </div>*/}
                          </div>
                        </div>
                        <div className="col-md-7 m-0 p-0 batch-search-input-group" id="repo-search-input-group">
                          <div className="input-group pl-3 pr-3">
                            <input id="repo-search-input" type="text" className="form-control pl-0 pr-0" 
                              placeholder="Search by Batch ID, Sourcing Location, Domain, Source"
                              autoComplete="off"
                               onChange={event =>this.onChange(event)} name="search_term"
                               value={this.state.search_term} onKeyPress={event => {if (event.key=== 'Enter') {this.onSearch(event)}}}/>
                            <div className="input-group-append ">
                              <button type="button" className="repo-search-icon p-0" onClick={event => this.onSearch(event)}>
                                <i className="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-3 m-0 p-0' style={{"position": "relative"}}>
                            <button type="button" className="btn no-effects" data-toggle="modal" id="date_range_btn" data-target="#daterangeModal">
                              Date within
                            </button>
                            <form onSubmit={event => this.handleSubmit(event) }>
                            <div className="modal fade" id="daterangeModal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Filter by Date within
                                        {/*
                                            S:{this.state.date_option_selected} - A:{this.state.date_option_saved}
                                            - F:{this.state.from_date} -  T:{this.state.to_date}
                                        */}
                                    </h5>
                                    <button type="button" className="close" onClick={event => this.resetDateRangeSelection(event,'close')}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" name="date_range" id="one_day" checked={this.state.date_option_selected==="one_day"} onChange={event =>this.handleDateRangeSelection(event)} value="one_day" required />
                                      <label className="form-check-label" htmlFor="one_day">
                                        <span>1 day</span>
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" name="date_range" id="one_month" checked={this.state.date_option_selected==="one_month"} onChange={event =>this.handleDateRangeSelection(event)} value="one_month" required/>
                                      <label className="form-check-label" htmlFor="one_month">
                                        <span>1 month</span>
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" name="date_range"  id="six_months" checked={this.state.date_option_selected==="six_months"} onChange={event =>this.handleDateRangeSelection(event)} value="six_months" required/>
                                      <label className="form-check-label" htmlFor="six_months">
                                        <span>6 months</span>
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" name="date_range" id="one_year" checked={this.state.date_option_selected==="one_year"} onChange={event =>this.handleDateRangeSelection(event)} value="one_year" required/>
                                      <label className="form-check-label" htmlFor="one_year">
                                        <span>1 year</span>
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" name="date_range" id="custom_range" checked={this.state.date_option_selected==="custom_range"} onChange={event =>this.handleDateRangeSelection(event)} value="custom_range" required/>
                                      <label className="form-check-label" htmlFor="custom_range">
                                        <span>custom</span>
                                      </label>
                                    </div>
                                    {
                                        this.state.date_option_selected==="custom_range"
                                        ?
                                        <div className="mt-4 d-flex justify-content-center">
                                            <div>
                                                <label className="mr-2"  htmlFor="from_date">
                                                  <span>From</span>
                                                </label>
                                                <input type="date" className="mr-2" id="from_date" onChange={event => this.handleDateSelected(event,'from_date')} value={this.state.from_date} name="from_date" max={this.state.max_date_for_from_date} required/>
                                            </div>
                                            <div>
                                                <label className="mr-2"  htmlFor="from_date">
                                                  <span>To</span>
                                                </label>
                                                <input type="date" className="mr-2" id="to_date" onChange={event => this.handleDateSelected(event,'to_date')} value={this.state.to_date}  name="to_date" min={this.state.min_date_for_to_date!==-1? this.state.min_date_for_to_date:null} max={this.state.max_date_for_to_date} required/>
                                            </div>
                                        </div>
                                        :null
                                    }
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary"  onClick={event => this.resetDateRangeSelection(event,'reset')}>Reset</button>
                                    <button type="submit" className="btn btn-dark">Apply</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </form>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="col-md d-flex justify-content-between p-0">
                          <button className="btn" onClick={()=>{window.location = '.'}} id="refresh">
                            <i className="fas fa-redo-alt alt" ></i> Refresh
                          </button>
                          {objectIsNotEmpty(this.state.user_perm) && this.state.user_perm.get_all_permissions.includes("batches.add_batch")?
                          <Link to="/add/new/batch/">
                          <button type="button" className="btn btn-dark" id="addbatch">Add Batch</button>
                          </Link>:null}
                      </div>
                    </div>
                </div>
            </div>
                
            { this.state.batches.length > 0 ?
              <div className="batch-main-content">
                <div className="inline-loader" ><div className="dot-elastic"></div></div>    
                <div className="d-flex justify-content-end">
                  <label>Show&nbsp;
                    <select onChange={event => this.onPageSizeChange(event)}
                      value={this.state.page_data.page_size}>
                      <option value="1">1</option>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select> entries
                  </label>
                </div>            
                <div id="batch-table">
                          
                  <table className="table table-bordered " id="tab">
                   <thead>
                     <tr>
                      <th>Batch ID</th>
                      <th>Date & Time
                        { this.state.order_by.created_date === '-created_date' ?
                          <a  className="batch-filter" title="Oldest First"
                            onClick={event => this.handleOrderChange(event, 'created_date')}>
                            <i className="fa fa-angle-down" aria-hidden="true"></i>
                          </a> :
                          <a className="batch-filter" title="Newest First"
                           onClick={event => this.handleOrderChange(event, '-created_date')}>
                            <i className="fa fa-angle-up" aria-hidden="true"></i>
                          </a>
                        }
                      </th>
                      <th>Sourcing Location</th>
                      <th>Sourcing Domain</th>
                      <th>Source</th>
                      <th>Pending/Designs</th>
                      <th>Status</th>
                    </tr>
                    </thead>

                    <tbody className="thead-light">
                      {/* <tr id="table-first-row" > <td colSpan="7" style={{margin: '0px', padding: '0px'}}></td></tr> */}
                      {batches_tr}
                    </tbody>
                  </table>
                </div>
                {this.state.page_data.total > 0 ?
                  <Paginator page_data={this.state.page_data}
                    onPageNumberChange={this.onPageNumberChange}/> : null
                }
              </div>:
              <div>
                    <div className="inline-loader" >
                        <div className="dot-elastic"></div>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '20px'}}>No Batches found</div>
              </div>
            }
          </div>
        )    
    }else{
        return(
            <div>
                
            </div>
        )
    }
    
  }
}

export default withRouter(Batch);