import React, { Component } from 'react';
import {customNumberFormat} from '../../customNumberFormat'

class RepoRectangleStatus extends Component {
 	render() {
    	const {statusData} = this.props
        

	    return (
	    	  <div className="m-0 p-0">
	    	  	<button className="btn m-0  p-0 shadow-none">
	    	  		<div className="d-flex flex-column repo-create-rectangle m-0">
	    	  		  <div> <span className="repo-status-count">{statusData.value ? customNumberFormat(statusData.value) : 0}</span> </div>
	    	  		  <div> <span className="repo-status-title">{statusData.title}</span> </div>
	    	  		</div>
	    	  	</button>
	    	  </div>
    	)
 	}
}


export default RepoRectangleStatus