import React from 'react';
import {ProgressBarsContext} from './ProgressBarsContext'
import $ from 'jquery'

class ProgressBarsProvider extends React.Component{
    state = {
        progress_bars : {
          // 'download_batch_MANU0001':  {'progress_id': 'download_batch_MANU0001', 'min_value':0, 'max_value': 100, 'valuenow': 25, 'progress_text': 'Batch downloading 1'}
        },
        progress_show_all:true
    }

    render () {
        return (
            <ProgressBarsContext.Provider value={{
                        progress_bars: this.state.progress_bars,
                        progress_show_all: this.state.progress_show_all,
                        addNewProgress: (batch_name, valuenow, progress_text, progress_id, progress_icon_type) => {
                            const progress_bars = Object.assign({}, this.state.progress_bars)
                            progress_bars[progress_id] = {
                                'name': batch_name,
                                'progress_id': progress_id,
                                'min_value':0, 'max_value': 100, 'valuenow': valuenow,
                                'progress_text': progress_text,
                                "progress_icon_type": progress_icon_type
                            }
                            this.setState({
                               progress_bars: progress_bars 
                            })
                        },
                        updatePercentValue: (selectedID, new_value) => {
                            // console.log('updatePercentValue called'+selectedID+'new'+new_value)
                            const progress_bars = Object.assign({}, this.state.progress_bars)
                            if(progress_bars.hasOwnProperty(selectedID)){
                                progress_bars[selectedID]['valuenow'] = new_value
                                this.setState({
                                   progress_bars: progress_bars 
                                })
                            }
                        },
                        updateProgressText: (selectedID, progress_text) => {
                            const progress_bars = Object.assign({}, this.state.progress_bars)
                            progress_bars[selectedID].progress_text = progress_text
                            this.setState({
                               progress_bars: progress_bars 
                            })
                        },
                        removeProgress: (selectedID) => {
                            const progress_bars = Object.assign({}, this.state.progress_bars)
                            delete progress_bars[selectedID]
                            this.setState({
                               progress_bars: progress_bars 
                            })
                        },
                        updateProgressShowAll: (value)=>{
                            if ((value===true) || (value===false)){
                                this.setState(
                                    {progress_show_all: value},
                                    ()=>{
                                        $("#progressbars").slideToggle()
                                    }
                                )
                            }
                        }
                    }}>
                        {this.props.children}
            </ProgressBarsContext.Provider>
        );
    }
}

export default ProgressBarsProvider

