import React, { Component } from 'react';
import { API_HOST } from '../../Settings.js'


class DesignCardImage extends Component {
  render() {
      // const {image} = this.props

      return (

      	<div className="img-parent">
    	     <img className="image image-inside-div " src={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${this.props.design.bucket_name}&batch_name=${this.props.design.batch_name}&image_name=${this.props.design.image_uri}&image_type=thumbnails`} alt="design" />
        </div>
      )
  }
}

export default DesignCardImage
