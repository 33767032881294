import React, { Component } from 'react';

class DesignCardImageWithStatus extends Component {
  render() {

      return (
        <div>
        	<div className="repo-index-design-img-parent m-0 p-0">
      	     <img className="image repo-index-design-inside-div" src={this.props.image} alt="design" />
          </div>
          <img src={this.props.status_icon} className="repo-index-design-status-icon" alt="status-icon" />
        </div>
      )
  }
}

export default DesignCardImageWithStatus
