import React from 'react';
import { withRouter } from 'react-router-dom';
// import ErrorHandling from '../../CommonFunctions'
import {API_HOST} from '../../Settings.js'
import axios from 'axios';
import './uploadimage.css'
import $ from 'jquery'
// import { Redirect } from "react-router-dom";
import ErrorHandling from '../../ErrorHandler'
import notify from '../../CommonFunctions.js'
import Cookies from "universal-cookie";
const cookies = new Cookies();



class UploadImage extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        multiple_images: null,
        designID: -1,
        designName: this.props.match.params.designName,
        image_counts:[],
        upload_image_selected_variant_id: -1,
        
        variant_ids_user_has_to_upload_images:[],
        user_perm: {}
      }
    }  

    componentDidMount() {
        $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
        $('.main-menu-sub-group').find('#repo-group').find('.circle-title').addClass('active-navbar')
        this.getUserPermissions()
              
    }

    getUserPermissions(){
      /*const auth_token = "Token "+localStorage.malbar_token*/
      const auth_token = "Token "+cookies.get("malabar_token")
      axios.defaults.headers.get['Authorization'] =auth_token
      $('.inline-loader').fadeIn()
      axios.get(`${API_HOST}/users/user/permissions/read/`)
      .then(response => {
          if (response.status === 200) {
            var get_all_permissions = response.data.user_perm.get_all_permissions
            if(get_all_permissions.includes("repo.view_design") && get_all_permissions.includes("repo.add_image")){
                this.setState({"user_perm": response.data.user_perm})
                this.getDesignBasicDetails()
            }else{
                $('.inline-loader').fadeOut('slow')
                notify("You don't have permission to aceess this page",'error')
                this.props.history.push('/permissiondenied/')
            }
          }
      }).catch(response => {
          $('.inline-loader').fadeOut('slow')
          ErrorHandling(response)
        });
    }

    getDesignBasicDetails(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/repo/design/${this.state.designName}/basicdetail/`, ).then(response => {
            if (response.status === 200) {
                if(response.data.data.length<1){
                    $('.inline-loader').fadeOut('slow')
                    alert("Design not found")
                    this.props.history.push('/repo')
                }else{
                    this.setState({
                        design: response.data.data[0],
                        designID: response.data.data[0].id,
                        user_perm: response.data.user_perm
                    })
                    axios.get(`${API_HOST}/repo/design/${response.data.data[0].id}/imagescountbyvariants/`, ).then(response => {
                          if (response.status === 200) {
                            this.setState({
                                  image_counts: response.data.images_count_by_variant_name,
                                  upload_image_selected_variant_id:(
                                    response.data.images_count_by_variant_name.length>0) ? response.data.images_count_by_variant_name[0].variant_id : -1

                              })
                              
                          }

                      }).catch(response => {
                            $('.inline-loader').fadeOut('slow')
                            ErrorHandling(response)
                            this.props.history.push('/repo')
                      });
                      this.getcomments()
                      $('.inline-loader').fadeOut('slow')
                }
            }

        }).catch(response => {
            $('.inline-loader').fadeOut()
            ErrorHandling(response)
            this.props.history.push('/repo')
            
        });
    }

    getcomments = (event) => {
        var design_id = this.state.designID;
        var url = `${API_HOST}/repo/imagerequest/${design_id}/list/`;
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(url,).then( response => {
              if(response.status === 200){
                if(response.data.variant_ids_user_has_to_upload_images.length>0){
                    this.setState({
                    variant_ids_user_has_to_upload_images: response.data.variant_ids_user_has_to_upload_images
                    })
                }else{
                    $('.inline-loader').fadeOut('slow')
                    alert("No pending requests to upload image")
                    this.props.history.push('/repo')
                }
              }
            }
          ).catch(response => {
                $('.inline-loader').fadeOut('slow')
                ErrorHandling(response)
          });
    }

    getFileInputDetails = (event) =>{
        // var input_file = document.getElementById('repo-drag-drop-file-upload-image').value;
        var div_element = document.getElementById('repo-drag-drop-file-upload-image-div');
        // var x = input_file.replace(/C:\\fakepath\\/i, '');
        if (event.target.files && event.target.files[0]) {
            let no_selected_images = event.target.files.length;
            if (no_selected_images === 1){
                let imgName = event.target.files[0].name;
                div_element.setAttribute('data-title',imgName);  
            }
            else{
              div_element.setAttribute('data-title',no_selected_images+" images selected");  
            }
            
            this.setState({
              multiple_images: event.target.files
            })
        }
        
    };

    handleValidation(){
      let form_is_valid = true;
      if (typeof this.state.upload_image_selected_variant_id=="undefined"){
        form_is_valid = false
      }
      else if (this.state.upload_image_selected_variant_id < 0){
        form_is_valid = false;
      }
      return form_is_valid;
    }


    handleSubmit = (event) =>  {
        event.preventDefault();
        if (this.handleValidation()){
          $('.inline-loader').fadeIn()
          let form_data = new FormData();
          form_data.append("design_id",this.state.designID)
          form_data.append("variant_id",this.state.upload_image_selected_variant_id)
          for (var i = 0; i < this.state.multiple_images.length; i++) {
            var file = this.state.multiple_images[i];
            form_data.append(i,file,file.name);
          }
          
          let url = `${API_HOST}/repo/uploadimages/`;
          /*const auth_token = "Token "+localStorage.malbar_token*/
          const auth_token = "Token "+cookies.get("malabar_token")
          axios.defaults.headers.post['Authorization'] =auth_token  
          axios.post(url, form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then(response => {
            
            if(response.status === 200){
              alert('Image upload success')
              $('.inline-loader').fadeOut('slow')
              if (this.state.designName && this.state.designID){
                  this.props.history.push(`/repo/design/${this.state.designName}`)
              }
              else{
                this.props.history.push('/repo')
              }
              
              /*this.props.history.push(`/repo/repodesigndetails/${this.state.designID}/`)*/

            }
            
          })
          .catch(error => {
            alert('Image upload failed '+ error.response.data.detail);
            
            $('.inline-loader').fadeOut('slow')
          })
        }
        else{
          alert("select the variant")
        }
    };

    handleCommentVariantSelected(event) {
      this.setState({upload_image_selected_variant_id: event.target.value});
    }



    render() {

        let variant_names_comment_select = this.state.image_counts.map((row, index) => {

          if (this.state.variant_ids_user_has_to_upload_images.includes(row.variant_id)){
            return (
                  <option value={row.variant_id}>
                    {row.variant__name}{row.variant_id}
                  </option>
              )

          }
        })

        return (
            <div>
              <div className="inline-loader" ><div className="dot-elastic"></div></div>
            <form method="post" action="#" id="#" onSubmit={this.handleSubmit}>
            <div className="row m-0 justify-content-center bg-white pb-5">
              <div className="col-lg-9">
                <div className="mb-4 mt-3">
                  <span id="repo-upload-images-title"> Add Images - GD Number</span>
                </div>
                <div className="repo-image-upload-wrapper d-flex justify-content-center align-items-center">
                  
                    <div className="form-group files" data-title="Drag & Drop an image to upload" id="repo-drag-drop-file-upload-image-div">
                      <label id="drag-drop-file-label">
                      <input type="file"  name="new_image_file" accept="image/*" onChange={event =>this.getFileInputDetails(event)} className="form-0 custom-file-input" id="repo-drag-drop-file-upload-image" multiple="multiple" required/>
                      </label>
                    </div>
                </div>          
              </div>            
          </div>
          <div className="row m-0 bg-white pb-2 justify-content-center">
              <div className="col-md-4"></div>
              <div className="form-group col-md-3 ">
                <label for="comment_selected_variant">select variant</label>
                <select class="form-control" id="comment_selected_variant" required value={this.state.upload_image_selected_variant_id} onChange={this.handleCommentVariantSelected.bind(this)}>
                  <option value="-1">
                    ---------
                  </option>
                  {variant_names_comment_select}
                </select>
              </div>
              <div className="col-md-5"></div>
          </div>
          <div className="row m-0 justify-content-center bg-white pb-5">
            <div className="col-md-4"></div>       
              <button type="submit" className="btn btn-dark nohover-btn col-md ml-2" id="upload-btn">
                Upload
              </button>
            <div className="col-md-5"></div>  
          </div>
        </form>
        </div>
        )
    }
}

export default withRouter(UploadImage);

