import React, { Component } from 'react';

class DesignCardFooter extends Component {
  render() {
      const {descriptionData} = this.props

      return (
        <div className="design-description-div">
          <div className="row m-0 design-description-each-row justify-content-between">
            <label className="gd-number">GD# {descriptionData.gd_number}</label>
            <label className="variant-number">{descriptionData.variants_count} Variant(s)</label>
          </div>
          <div className="row m-0 design-description-each-row">
            <label className="product-name">{descriptionData.name}</label>
          </div>
          <div className="row m-0">
            <label className="vendor-design-number">VDI # {descriptionData.vdin} </label>
          </div>
        </div>
      )
  }
}


export default DesignCardFooter
