import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {API_HOST} from '../../Settings.js'
import axios from 'axios';
import $ from 'jquery'
import './userdetails.css'
import ProfileIcon from '../../images/person.svg'
import notify from '../../CommonFunctions.js'
import Select from 'react-select'
import ErrorHandling from '../../ErrorHandler'
import Cookies from "universal-cookie";
const cookies = new Cookies();

class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "user_details": {},
            "user_id": this.props.match.params.user_id,
            "user_state": null,
            "user_roles": [],
            "source_locations": [],
            "source_domains": [],
            "source_product_category": [],
            "role_or_group": [],
            "selected_sourcing_location_ids": -1,
            "selected_source_product_category_ids": -1,
            "selected_source_domains_ids": -1,
            "selected_role_or_group_ids": -1,
            "editable_row_id": -1,
            "modal_state": false,
            "add_selected_sourcing_location_ids": [],
            "add_selected_source_product_category_ids": [],
            "add_selected_source_domains_ids": [],
            "add_selected_role_or_group_ids": [],
            "user_perm":{},
            "edit_time_zone": false,
            "new_time_zone_selected": "",
            "countries": [],
            "new_country_selected": "",
            "new_city_selected": "",
            "cities": {"India":["hyd","vjz","delhi"],"China":["bejing"]},
            "time_zone_options": [],
            "country_code_time_zone": {},
            "a_new_time_zone_selected": "",
            "existing_user_time_zone": "",
            "user_locale_data": {},
            "has_existing_time_zone": false

        }
    }

    componentDidMount() {
        $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
        $('.main-menu-sub-group').find('#users-group').find('.circle-title').addClass('active-navbar')
        this.getData()
    }

    getData(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        $('.inline-loader').fadeIn()
        axios.get(`${API_HOST}/users/user/${this.state.user_id}/details/`)
        .then(response => {
            if (response.status === 200) {
                if(!response.data.has_user){
                    notify("Invalid user",'error')
                    this.props.history.push('/users/')
                }
                else if(!response.data.user_perm.is_superuser){
                    notify("You don't have access to this page",'error')
                    this.props.history.push('/permissiondenied/')
                }
                else if (response.data.user_details.length>0){
                    // var country_code_time_zones = response.data.country_code_time_zone
                    // var is_user_time_zone_exists = false
                    var user_locale_data = response.data.user_locale_data
                    var has_existing_time_zone = false
                    var time_zone_options = []
                    var edit_time_zone = true
                    var a_new_time_zone_selected =""
                    if (user_locale_data.hasOwnProperty('id')){
                        has_existing_time_zone = true
                        edit_time_zone = false
                        time_zone_options = response.data.country_code_time_zone[
                                response.data.user_locale_data.country.iso_code_1
                            ]
                        a_new_time_zone_selected = response.data.user_locale_data.time_zone
                    }
                    this.setState({
                        "user_details" : response.data.user_details[0],
                        "user_state": response.data.user_details[0].is_active,
                        "user_roles": response.data.dgs_user_perm_values,
                        "source_locations": response.data.source_locations,
                        "source_domains": response.data.source_domains,
                        "source_product_category": response.data.source_product_category,
                        "role_or_group": response.data.role_or_group,
                        "user_perm": response.data.user_perm,
                        "edit_time_zone": edit_time_zone,
                        "new_time_zone_selected":"",
                        "countries": response.data.countries,
                        "country_code_time_zone": response.data.country_code_time_zone,
                        "time_zone_options": time_zone_options,
                        "a_new_time_zone_selected": a_new_time_zone_selected,
                        "user_locale_data": response.data.user_locale_data,
                        "has_existing_time_zone": has_existing_time_zone

                    })
                    
                }
                else{
                    notify("Invalid user",'info')
                    this.props.history.push('/users/')
                }
            }
            $('.inline-loader').fadeOut('slow')

        }).catch(response => {
                ErrorHandling(response)
            });
    }

    handleToggleState(event){
        let user_state = !this.state.user_state
        var confir_msg = ''
        if (user_state===true){
            confir_msg = "Are you sure, make this user Active?";
        }
        if (user_state===false){
            confir_msg = "Are you sure, make this user InActive?";
        }
        if(window.confirm(confir_msg)){
            $('.inline-loader').fadeIn()
            let form_data = new FormData();
            form_data.append('user_id',this.state.user_details.id)
            form_data.append('user_state',user_state)
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.post['Authorization'] =auth_token
            let url = `${API_HOST}/users/user/update/active/inactive/`
            axios.post(url, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            }).then(response => {
                if (response.status === 200) {
                    this.setState({"user_state": user_state})
                    notify("User status updated successfully",'success')
                    setTimeout(() =>{
                        window.location.reload()
                    },2000)             
                }
            }).catch(response => {
                $('.inline-loader').fadeOut('slow')
                alert("error")
                // ErrorHandling(response)
            });
        }
    }


    handleCloseModal(event,value){
        this.setState((prevState, props)=>({
            "modal_state": value
        }),(console.log(value,this.state.modal_state)))
        $('#add-permission-model').modal('hide');
    }

    handleDeleteUserPermission(event,selected_user_perm_id){
        let confirm_msg = "Are you sure, You want to delete Permission ?"
        let data = {"dgs_user_perm_id_for_delete":selected_user_perm_id}
        if(window.confirm(confirm_msg)){
            $('.inline-loader').fadeIn()
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.delete['Authorization'] =auth_token  
            axios.delete(
                `${API_HOST}/users/user/dgs/userpermission/delete/`,
                {data:data}
                ).then(response => {
                    if (response.status === 200) {
                        notify('Permission Deleted successfully','success');
                        setTimeout(() =>{
                            window.location.reload()
                        },2000)
                    }
                    else{
                        $('.inline-loader').fadeOut('slow')
                    }
                }).catch(response => {
                    $('.inline-loader').fadeOut('slow')
                });
        }
    }

    /*onChangeTimeZone(event){
        var value = event.value
        this.setState({"a_new_time_zone_selected": value})
    }*/

    onChangeCountry(event){
        var value = event.value
        var time_zone_options = []
        if(this.state.country_code_time_zone.hasOwnProperty(value)){
            time_zone_options = this.state.country_code_time_zone[value]
        }
        /*else{
            time_zone_options = []
        }*/
        this.setState({
            "new_country_selected": value,
            "time_zone_options": time_zone_options,
            "a_new_time_zone_selected": ""
        })
    }

    onChangeTimeZone(event){
        var value = event.value
        this.setState({"a_new_time_zone_selected": value})
    }

    handleEditTimeZone(event,value){
        if(this.state.has_existing_time_zone){
            var existing_time_zone = this.state.user_locale_data.time_zone;
            var existing_country = this.state.user_locale_data.country.iso_code_1;
            var time_zone_options = this.state.country_code_time_zone[existing_country]
            this.setState({
                "edit_time_zone": value,"new_time_zone_selected":"",
                "a_new_time_zone_selected":existing_time_zone,
                "new_country_selected":existing_country,
                "time_zone_options": time_zone_options
            })
        }else{
            alert("Please create new time zone")
        }
    }


    handleUpdateTimeZone(event){
        var has_existing_time_zone = this.state.has_existing_time_zone
        var new_time_zone_selected = this.state.a_new_time_zone_selected
        var new_country_selected = this.state.new_country_selected
        if(has_existing_time_zone){
            var existing_user_time_zone = this.state.user_locale_data.time_zone
            // var existing_country = this.state.user_locale_data.country.iso_code_1
        }else{
            existing_user_time_zone = ""
            // existing_country = ""
        }
        if(new_country_selected===""){
            alert("Select the country")
        }else if (new_time_zone_selected===""){
            alert("Select the timezone")
        }
        else if(has_existing_time_zone &&  (new_time_zone_selected===existing_user_time_zone)){
            alert("There is no changes to apply")
        }
        else{
            $('.inline-loader').fadeIn()
            let form_data = new FormData();
            form_data.append('user_id',this.state.user_details.id)
            form_data.append('new_time_zone_selected',new_time_zone_selected)
            form_data.append("new_country_selected",new_country_selected)
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.post['Authorization'] =auth_token
            let url = `${API_HOST}/users/user/update/timezone/`
            axios.post(url, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            }).then(response => {
                if (response.status === 200) {
                    notify("Timezone updated successfully",'success')
                    this.getData()             
                }
            }).catch(response => {
                $('.inline-loader').fadeOut('slow')
                alert("error")
                // ErrorHandling(response)
            });
        }
    }

    onChangeSourceLocation(event){
        var value = Number(event.target.value);
        this.setState({
            "selected_sourcing_location_ids":value
        })
    }

    onChangeSourceProductCategory(event){
        var value = Number(event.target.value);
        this.setState({
            "selected_source_product_category_ids": value
        })   
    }

    onChangeSourceDomains(event){
        var value = Number(event.target.value);
        this.setState({
            "selected_source_domains_ids": value
        })   
    }

    onChangeRoleOrGroup(event){
        var value = Number(event.target.value);
        this.setState({
            "selected_role_or_group_ids": value
        })
    }

    handleEditUserPermission(event,selected_user_perm_id,index){
        /*var x = $("#row_"+selected_user_perm_id+"> td");*/
        var selected_sourcing_location_ids = -1
        var access_selected_sourcing_location_ids = -1
        this.state.source_locations.map((row,index_row)=>{
                    if (row.id === this.state.user_roles[index].source_location){
                        selected_sourcing_location_ids = row.id
                        access_selected_sourcing_location_ids = index_row
                    }
                })
        var selected_source_domains_ids = -1
        var access_selected_source_domains_ids = -1
        this.state.source_domains.map((row,index_row)=>{
                    if (row.id === this.state.user_roles[index].source_domain){
                        access_selected_source_domains_ids = index_row
                        selected_source_domains_ids = row.id
                    }
                })
        var selected_source_product_category_ids = -1
        var access_selected_source_product_category_ids = -1
        this.state.source_product_category.map((row,index_row)=>{
                    if (row.id === this.state.user_roles[index].source){
                        access_selected_source_product_category_ids = index_row
                        selected_source_product_category_ids = row.id
                    }
                })
        var selected_role_or_group_ids = -1
        var access_selected_role_or_group_ids = -1
        this.state.role_or_group.map((row,index_row)=>{
                    if (row.id === this.state.user_roles[index].role){
                        access_selected_role_or_group_ids = index_row
                        selected_role_or_group_ids = row.id
                    }
                })

        this.setState({
            "editable_row_id":selected_user_perm_id,
            "access_selected_sourcing_location_ids": access_selected_sourcing_location_ids,
            "access_selected_source_product_category_ids":access_selected_source_product_category_ids ,
            "access_selected_source_domains_ids": access_selected_source_domains_ids,
            "access_selected_role_or_group_ids":access_selected_role_or_group_ids ,
            "selected_sourcing_location_ids": selected_sourcing_location_ids,
            "selected_source_product_category_ids":selected_source_product_category_ids ,
            "selected_source_domains_ids": selected_source_domains_ids,
            "selected_role_or_group_ids":selected_role_or_group_ids ,
            "actual_selected_sourcing_location_ids": selected_sourcing_location_ids,
            "actual_selected_source_product_category_ids":selected_source_product_category_ids ,
            "actual_selected_source_domains_ids": selected_source_domains_ids,
            "actual_selected_role_or_group_ids":selected_role_or_group_ids ,
        })
    }

    handleEditCancelUserPermission(event,selected_user_perm_id){
        this.setState({
            "editable_row_id":-1,
            "selected_sourcing_location_ids": -1,
            "selected_source_product_category_ids": -1,
            "selected_source_domains_ids": -1,
            "selected_role_or_group_ids": -1,
            "actual_selected_sourcing_location_ids": -1,
            "actual_selected_source_product_category_ids": -1,
            "actual_selected_source_domains_ids": -1,
            "actual_selected_role_or_group_ids": -1,
        })
    }

    handleUpdateUserPermission(event,selected_user_perm_id){
        var selected_sourcing_location_ids = this.state.selected_sourcing_location_ids
        var actual_selected_sourcing_location_ids = this.state.actual_selected_sourcing_location_ids
        var selected_source_product_category_ids = this.state.actual_selected_source_product_category_ids
        var actual_selected_source_product_category_ids = this.state.actual_selected_source_product_category_ids
        var selected_source_domains_ids = this.state.selected_source_domains_ids
        var actual_selected_source_domains_ids = this.state.actual_selected_source_domains_ids
        var selected_role_or_group_ids = this.state.selected_role_or_group_ids
        var actual_selected_role_or_group_ids = this.state.actual_selected_role_or_group_ids
        var is_user_changed_the_perms = false
        if(true || (selected_sourcing_location_ids!==actual_selected_sourcing_location_ids)){
            is_user_changed_the_perms = true
        }else if(selected_source_product_category_ids!==actual_selected_source_product_category_ids){
            is_user_changed_the_perms = true
        }else if(selected_source_domains_ids!==actual_selected_source_domains_ids){
            is_user_changed_the_perms = true
        }else if(selected_role_or_group_ids!==actual_selected_role_or_group_ids){
            is_user_changed_the_perms = true
        }
        if (!is_user_changed_the_perms){
            alert("There is no changes to apply")
        }else if(this.state.editable_row_id<0){
            alert("Invalid row Selection");
        }
        else{
                
                selected_sourcing_location_ids = this.state.selected_sourcing_location_ids;
                selected_source_product_category_ids = this.state.selected_source_product_category_ids;
                selected_source_domains_ids = this.state.selected_source_domains_ids;
                selected_role_or_group_ids = this.state.selected_role_or_group_ids;
                var editable_row_id = this.state.editable_row_id;

                if(false && selected_sourcing_location_ids<0){
                    alert("Select sourcing location");
                    return false;
                }
                if(selected_source_product_category_ids<0){
                    alert("Select product category");
                    return false;
                }
                if(selected_source_domains_ids<0){
                    alert("Select sourcing domain");
                    return false;
                }
                if(selected_role_or_group_ids<0){
                    alert("Select role ")
                    return false;
                }
                var is_updating_perm_alreay_exists = false;
                this.state.user_roles.map((row,index)=>{
                    if((selected_sourcing_location_ids===row.source_location)
                       && (selected_source_product_category_ids===row.source)
                       && (selected_source_domains_ids===row.source_domain)
                       && (selected_role_or_group_ids===row.role)
                    ){
                        is_updating_perm_alreay_exists=true
                    }
                })
                if (is_updating_perm_alreay_exists){
                    alert("Permission already exists");
                    return false;
                }
                if(
                    selected_source_product_category_ids>-1 &&
                    selected_source_domains_ids>-1 &&
                    selected_role_or_group_ids>-1 
                ){
                    $('.inline-loader').fadeIn()
                    let form_data = new FormData();
                    // form_data.append('selected_sourcing_location_ids',selected_sourcing_location_ids)
                    form_data.append("selected_source_product_category_ids",selected_source_product_category_ids)
                    form_data.append("selected_source_domains_ids",selected_source_domains_ids)
                    form_data.append("selected_role_or_group_ids",selected_role_or_group_ids)
                    form_data.append("editable_row_id",editable_row_id)
                    /*const auth_token = "Token "+localStorage.malbar_token*/
                    const auth_token = "Token "+cookies.get("malabar_token")
                    axios.defaults.headers.post['Authorization'] =auth_token
                    let url = `${API_HOST}/users/user/dgs/userpermission/update/`;
                    axios.post(url, form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                    })
                    .then(response => {
                      if(response.status === 200){
                        this.setState({
                            "selected_sourcing_location_ids": -1,
                            "selected_source_product_category_ids": -1,
                            "selected_source_domains_ids": -1,
                            "selected_role_or_group_ids": -1,
                            "editable_row_id": this.state.editable_row_id
                        })
                        notify('Permissions updated successfully','success');
                        setTimeout(() =>{
                            window.location.reload()
                        },2000)
                      }
                      
                    })
                    .catch(response => {
                        $('.inline-loader').fadeOut('slow')
                    })
                }
        }
    }

    onChangeAddSourceLocation(event){
        var value = Array.from(event, option => option.value);
        this.setState({
            "add_selected_sourcing_location_ids":value
        })
        
    }

    onChangeAddSourceProductCategory(event){
        var value = Array.from(event, option => option.value);
        this.setState({
            "add_selected_source_product_category_ids": value
        })
    }

    onChangeAddSourceDomains(event){
        var value = Array.from(event, option => option.value);
        this.setState({
            "add_selected_source_domains_ids": value
        })
    }

    onChangeAddRoleOrGroup(event){
        var value = Array.from(event, option => option.value);
        this.setState({
            "add_selected_role_or_group_ids": value
        })
    }

    handleAddPermission(event){
        var selected_sourcing_location_ids = this.state.add_selected_sourcing_location_ids;
        var selected_source_product_category_ids = this.state.add_selected_source_product_category_ids;
        var selected_source_domains_ids = this.state.add_selected_source_domains_ids;
        var selected_role_or_group_ids = this.state.add_selected_role_or_group_ids;
        if(false && selected_sourcing_location_ids.length<1){
            alert("Select sourcing location");
            return false;
        }
        if(selected_source_product_category_ids.length<1){
            alert("Select product category");
            return false;
        }
        if(selected_source_domains_ids.length<1){
            alert("Select sourcing domain");
            return false;
        }
        if(selected_role_or_group_ids.length<1){
            alert("Select role ")
            return false;
        }
        if(
            selected_source_product_category_ids.length>0 &&
            selected_source_domains_ids.length>0 &&
            selected_role_or_group_ids.length>0 
        ){
            $('#add-permission-model').modal('hide');
            $('.inline-loader').fadeIn()
            const data = JSON.stringify({
                // selected_sourcing_location_ids: selected_sourcing_location_ids,
                selected_source_product_category_ids: selected_source_product_category_ids,
                selected_source_domains_ids: selected_source_domains_ids,
                selected_role_or_group_ids: selected_role_or_group_ids,
                new_user_name:this.state.user_details.username,
                new_user_email: this.state.user_details.email,
                user_id: this.state.user_details.id
            })
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.post['Authorization'] =auth_token
            let url = `${API_HOST}/users/user/dgs/userpermission/add/`;
            axios.post(url, data, {
              headers: {
                'content-type': 'application/json'
              }
            })
            .then(response => {
              if(response.status === 200){
                this.setState({
                    "add_selected_sourcing_location_ids": [],
                    "add_selected_source_product_category_ids": [],
                    "add_selected_source_domains_ids": [],
                    "add_selected_role_or_group_ids": []
                })
                notify("Permission added successfully",'success')
                setTimeout(() =>{
                    window.location.reload()
                },2000)
              }
              
            })
            .catch(response => {
                $('.inline-loader').fadeOut('slow')
            })
        }
        else{
            alert("missing value");
        }
    }

    render() {

        var country_options = this.state.countries.map((country,index)=>{
            return ({ value: country.iso_code_1, label: country.name })
        })
        var a_new_time_zone_options = this.state.time_zone_options.map((time_zone,index)=>{
            return ({ value: time_zone, label: time_zone })
        })

        var add_source_locations_row = this.state.source_locations.map((row,index)=>{
            return (
                { value: row.id, label: row.name }
            )
        })

        var add_source_domains_row = this.state.source_domains.map((row,index)=>{
            return (
                { value: row.id, label: row.name}
            )
        })

        var add_source_product_category_row = this.state.source_product_category.map((row,index)=>{
            return (
                { value: row.id, label: row.name }
            )
        })
        
        var add_role_or_group_row = this.state.role_or_group.map((row,index)=>{
            return (
                { value: row.id, label: row.name }
            )
        })


        var tables_to_show = [];
        var table_row = this.state.user_roles.map((row,index)=>{
            if (this.state.editable_row_id > -1 && this.state.editable_row_id === row.id ){

                /*var role_or_group_row = this.state.role_or_group.map((row,index)=>{
                    return (
                        { value: row.id, label: row.name}
                    )
                })*/

                var source_locations_row_options = this.state.source_locations.map((row,index)=>{
                    return (
                        <option value={row.id} >{row.name}</option>
                    )
                })
                var source_domains_row_options = this.state.source_domains.map((row,index)=>{
                    return (
                        <option value={row.id} >{row.name}</option>
                    )
                })
                var source_product_category_row_options = this.state.source_product_category.map((row,index)=>{
                    return (
                        <option value={row.id} >{row.name}</option>
                    )
                })

                var role_or_group_row_options = this.state.role_or_group.map((row,index)=>{
                    return (
                        <option value={row.id} >{row.name}</option>
                    )
                })

                var temp_row = <tr>
                    <td id={"row_"+row.id} className="roles-td">
                        <div>
                          <select className="form-control" 
                            value={this.state.selected_sourcing_location_ids} 
                            id="source_locations_dropdown"
                            onChange={event => this.onChangeSourceLocation(event)}>
                            {source_locations_row_options}
                          </select>
                        </div>
                    </td>
                    <td className="roles-td">
                        <div>
                          <select className="form-control" 
                            value={this.state.selected_source_domains_ids} 
                            id="source_domains_row_options"
                            onChange={event => this.onChangeSourceDomains(event)}>
                            {source_domains_row_options}
                          </select>
                        </div>
                    </td>
                    <td className="roles-td">
                        <div>
                          <select className="form-control" 
                            value={this.state.selected_source_product_category_ids} 
                            id="source_product_category_row_options"
                            onChange={event => this.onChangeSourceProductCategory(event)}>
                            {source_product_category_row_options}
                          </select>
                        </div>
                    </td>
                    <td className="roles-td">
                        <div>
                          <select className="form-control" 
                            value={this.state.selected_role_or_group_ids} 
                            id="role_or_group_row_options"
                            onChange={event => this.onChangeRoleOrGroup(event)}>
                            {role_or_group_row_options}
                          </select>
                        </div>
                    </td>
                    <td className="roles-td">
                        <div className="d-flex justify-content-center m-0 p-0">
                            <div className="bg-transparent ml-2 mr-2">
                                <button type="button" className="btn" onClick={event => this.handleEditCancelUserPermission(event,row.id)}>
                                <i className="fas fa-times"></i>
                                </button>
                            </div>
                            <div className="bg-transparent ml-2 mr-2">
                                <button type="button" className="btn" onClick={event => this.handleUpdateUserPermission(event,row.id)}>
                                <i className="fas fa-save"></i>
                                </button>
                            </div> 
                        </div>
                    </td>
                </tr>
                tables_to_show.push(temp_row)

                return temp_row; 
            }
            var temp_non_edit_row = (
                    <tr id={"row_"+row.id}>
                        <td id={"row_"+row.id+1} className="roles-td  m-0" >{row.source_location_name}</td>
                        <td id={"row_"+row.id+2} className="roles-td  m-0">{row.source_domain_name}</td>
                        <td id={"row_"+row.id+3} className="roles-td  m-0">{row.source_name}</td>
                        <td id={"row_"+row.id+4} className="roles-td  m-0">{row.role_name}</td>
                        <td id={"row_"+row.id+5} className="roles-td p-0  m-0">
                            <div className="d-flex justify-content-center m-0 p-0">
                                <div className="bg-transparent ml-2 mr-2">
                                <button type="button" className="btn" onClick={event => this.handleEditUserPermission(event,row.id,index)}>
                                <i className="fas fa-pen "></i>
                                </button>
                                </div>
                                <div className="bg-transparent ml-2 mr-2">
                                <button type="button" className="btn" onClick={event => this.handleDeleteUserPermission(event,row.id)}>
                                <i className="fas fa-trash"></i>
                                </button>
                                </div>                        
                            </div>
                        </td>
                    </tr>
                )
            tables_to_show.push(temp_non_edit_row);
            return(
                    temp_non_edit_row
                )
        })

        return (
            <div className="relative">
                <div className="inline-loader bg-white">
                    <div className="dot-elastic"></div>
                </div>
                <div className="d-flex justify-content-between mb-0 mt-0" style={{"alignItems": 'center'}} id="gos-group">
                    <label id="current-url-total-path">
                    <Link to="/users/" className="current-url">Users</Link> /&nbsp;
                    <Link to={`/users/${this.state.user_id}/details/`} className="current-url">{this.state.user_details.first_name}</Link>
                    </label>
                </div>
                <div className="row m-0 p-0 mt-2 justify-content-between">
                        <div className="d-flex justify-content-between user-profile m-0">
                            <div className="user-profile-left align-items-center shadow p-0 mr-2">
                                <div className="d-flex flex-column justify-content-center h-100 p-3">
                                    <div className="user-profile-image-div align-items-center justify-content-center">
                                        <img src={ProfileIcon} className="user-profile-image" alt="profile"/>
                                    </div>
                                </div>
                            </div>
                            <div className="user-profile-right shadow d-flex  align-items-center p-3">
                                <div className="h-100 d-flex flex-column justify-content-between">
                                    <div>
                                        <label className="m-0">First Name :&nbsp;&nbsp;<b>{this.state.user_details.first_name ? this.state.user_details.first_name:"-"}</b></label>
                                    </div>
                                    <div >
                                        <label className="m-0">Last Name :&nbsp;&nbsp;<b>{this.state.user_details.last_name?this.state.user_details.last_name:"-"}</b></label>
                                    </div>
                                    <div >
                                        <label className="m-0">Email ID&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;<b>{this.state.user_details.email}</b></label>
                                    </div>
                                    <div >
                                        <label className="m-0">Last Login &nbsp;:&nbsp;&nbsp;<b>{this.state.user_details.last_login?this.state.user_details.last_login:"-"}</b></label>
                                    </div>
                                </div>
                            </div>
                            <div className="user-profile-left align-items-center shadow p-0 ml-2">
                                <div className="d-flex flex-column justify-content-between h-100 p-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex justify-content-center align-items-center">
                                            {this.state.edit_time_zone?
                                                <div className=" mr-2 ml-2">
                                                    <Select                            
                                                        className="" id="country-select" 
                                                        onChange={event => this.onChangeCountry(event)}
                                                        defaultValue={this.state.has_existing_time_zone?{value:this.state.user_locale_data.country.iso_code_1, label:this.state.user_locale_data.country.name}:null}
                                                        options={country_options}
                                                        isMulti={false} 
                                                        placeholder={<div>Country</div>}                               
                                                    />
                                                </div>
                                                :null
                                            }
                                            {this.state.edit_time_zone?
                                                <div className=" mr-2 ml-2">
                                                    <Select                            
                                                        className="" id="country-select"
                                                        value = {this.state.a_new_time_zone_selected?{value:this.state.a_new_time_zone_selected,label:this.state.a_new_time_zone_selected}:null}
                                                        options={a_new_time_zone_options}
                                                        defaultValue={this.state.has_existing_time_zone?{value:this.state.user_locale_data.time_zone, label:this.state.user_locale_data.time_zone}:null}
                                                        onChange={event => this.onChangeTimeZone(event)}
                                                        isMulti={false}
                                                        placeholder={<div>Time Zone</div>}                              
                                                    />
                                                </div>
                                                :
                                                <div>
                                                    <label className="m-0">Time Zone :&nbsp;&nbsp;<b>{this.state.user_locale_data.time_zone}</b></label>
                                                </div>
                                            }
                                            {this.state.edit_time_zone?
                                                <div className="d-flex justify-content-center m-0 p-0">
                                                    {this.state.has_existing_time_zone ?
                                                        <div className="bg-transparent ml-2 mr-2">
                                                            <button type="button" className="btn" onClick={event => this.handleEditTimeZone(event,false)}>
                                                            <i className="fas fa-times" ></i>
                                                            </button>
                                                        </div>
                                                        :null
                                                    }                                                    
                                                    <div className="bg-transparent ml-2">
                                                        <button type="button" className="btn" onClick={event => this.handleUpdateTimeZone(event)}>
                                                        <i className="fas fa-save"></i>
                                                        </button>
                                                    </div> 
                                                </div>
                                                :
                                                <div className="bg-transparent ml-2">
                                                <button type="button" className="btn" onClick={event => this.handleEditTimeZone(event,true)}>
                                                <i className="fas fa-pen "></i>
                                                </button>
                                                </div>
                                            }                        
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-left align-items-center">
                                        <div className="mr-2">
                                            <label className="switch m-0 mt-1">
                                              <input type="checkbox" onChange={this.handleToggleState.bind(this)} checked={this.state.user_details.is_active} />
                                              <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div>
                                            <label className="m-0"><b>{this.state.user_details.is_active ? "Active User" : "InActive User"}</b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="row mt-1  m-0 p-0 mb-2 justify-content-end">
                    <div className="align-self-end">
                            <button type="button" className="btn btn-dark nohover-btn mr-1" 
                                onClick={event => this.handleCloseModal(event,true)} 
                                data-toggle="modal" data-target="#add-permission-model">
                                <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
                                Add Permission
                            </button>
                            {
                                this.state.modal_state
                                ?
                                <div className="modal fade" id="add-permission-model" role="dialog" data-backdrop="static" data-keyboard="false" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" id="user-model-dialog">
                                      <div className="modal-content pl-4 pr-4" id="user-model-content">
                                        <div className="modal-header dashed-row-bottom pl-0 pr-0">
                                          <div>
                                            <span id="add-user-text">
                                             Add Permission
                                            </span>  
                                          </div>
                                          <div>
                                            <button type="button" onClick={event => this.handleCloseModal(event,false)} className="close" data-dismiss="modal">×</button>
                                          </div>
                                        </div>
                                        <div className="modal-body pl-0 pr-0">
                                          <div className="row pb-3 m-0 d-flex justify-content-between">
                                            <div className=" p-0  form-group option_width">
                                             <label for="sourcing-location" id="sourcing-location-text">Sourcing Location</label>
                                              <Select                            
                                                  className="select-attribute-value"
                                                  onChange={event => this.onChangeAddSourceLocation(event)} 
                                                  options={add_source_locations_row}
                                                  isMulti={true}

                                              />
                                            </div>
                                            <div className=" p-0 option_width  form-group">
                                             <label for="product-category" id="product-category-text">Product Category</label>
                                              <Select                            
                                                  className="select-attribute-value" 
                                                  onChange={event => this.onChangeAddSourceProductCategory(event)}
                                                  options={add_source_product_category_row}
                                                  isMulti={true}
                                              />
                                            </div>
                                          </div>
                                          <div className="row pb-3 m-0 d-flex justify-content-between">
                                            <div className="p-0 form-group option_width">
                                             <label for="domain" id="domain-text">Domain</label>
                                                <Select                            
                                                    className="select-attribute-value"
                                                    onChange={event => this.onChangeAddSourceDomains(event)}
                                                    options={add_source_domains_row}
                                                    isMulti={true}
                                                />
                                            </div>
                                            <div className=" p-0  form-group option_width">
                                             <label for="role" id="role-text">Role</label>
                                              <Select                            
                                                  className="select-attribute-value"
                                                  onChange={event => this.onChangeAddRoleOrGroup(event)}
                                                  options={add_role_or_group_row}
                                                  isMulti={true}
                                              />
                                            </div>
                                          </div>
                                          <div className="row pb-3 m-0">
                                            <span id="note-text">
                                              Note: can assign multiple locations and roles to same user
                                            </span>
                                          </div>
                                        </div>
                                        <div className="modal-footer justify-content-between pl-0 pr-0 dashed-row-top">
                                          <button type="button" onClick={event => this.handleCloseModal(event,true)} className="btn  pl-4 pr-4 m-0" id="cancel-modal">
                                            Cancel
                                          </button>
                                          <button type="button" className="btn btn-dark nohover-btn m-0 pl-5 pr-5" onClick={event => this.handleAddPermission(event,true)}>
                                            Add Permission
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                    </div>
                </div>
                <div className="row mt-0  m-0 p-0 mb-4 justify-content-center">
                    {this.state && this.state.user_roles && this.state.user_roles.length>0 ?
                    <table className="table table-bordered text-left" id="users-details-table">
                        
                       <thead>
                        <tr>
                          <th>Sourcing Location</th>
                          <th>Sourcing Domain</th>
                          <th>Product Category</th>
                          <th>Role</th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody className="thead-light">
                            {/*<tr id="table-first-row">
                              <td colSpan="5" className="m-0 p-0"></td>
                            </tr>*/}
                            {table_row}
                        </tbody>
                    </table>
                    :<h5 className="text-center">No permissions</h5>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(UserDetails);