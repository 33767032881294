import React, {CSSProperties} from 'react';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select'
import { API_HOST } from './Settings.js'
import $, { event } from 'jquery'
import axios from 'axios';
import Cookies from "universal-cookie";
import './attribute_filters.css'
import ErrorHandling from './ErrorHandler.js'

const cookies = new Cookies();

const search_btn_style = {
    background: "#182027 0 0 no-repeat padding-box",
    borderRadius: "4px",
    opacity: "1;",
}
const a_tag_css = {
    color: "#292728",
    textDecoration:"none"
}
  

class AttributeFilters extends React.Component {
    constructor(props) {
        super(props);        
    }

    handleWeightRange = (event, attribute_name) =>{
        var weight_val = event.currentTarget.value
        if(weight_val){
            this.props.handleAttributeFilters(attribute_name, weight_val)
        }
        else{
            this.props.handleAttributeFilters(attribute_name, weight_val)
        }
    
    }
    render(){
        return(
            [<div className='row col-lg-12 attribute-filters' style={this.props.attr_style} >
                <AttributeItem attribute_label = "Category" attribute_name_slug = "category" seleted_attributes={this.props.handleAttributeFilters}/>
                < AttributeItem attribute_label = "ProductType" attribute_name_slug = "product_type" seleted_attributes={this.props.handleAttributeFilters}/>
                < AttributeItem attribute_label = "Collection" attribute_name_slug = "collection" seleted_attributes={this.props.handleAttributeFilters}/>
                < AttributeItem attribute_label = "SubBrand" attribute_name_slug = "sub_brand" seleted_attributes={this.props.handleAttributeFilters}/>
                < AttributeItem attribute_label = "ManufacturingType" attribute_name_slug = "manufacturing_type" seleted_attributes={this.props.handleAttributeFilters}/>
                < AttributeItem attribute_label = "Form" attribute_name_slug = "form" seleted_attributes={this.props.handleAttributeFilters}/>
                < AttributeItem attribute_label = "Theme" attribute_name_slug = "theme" seleted_attributes={this.props.handleAttributeFilters}/>
                < AttributeItem attribute_label = "Vendor" attribute_name_slug = "vendor" seleted_attributes={this.props.handleAttributeFilters}/> 
                <div className="attribute-dropdown" >
                  <div className="attribute-btn">
                    <input className='form-control minimum-weight' name='minimum-weight' type='number' placeholder='Min Weight' title='Minimum Weight' onBlur={event => this.handleWeightRange(event, 'minimum_weight')}/> 
                  </div>
                </div>
                <div className="attribute-dropdown" >
                  <div className="attribute-btn">
                  <input className='form-control maximum-weight' name='maximum-weight' type='number' placeholder='Max Weight' title='Maximum Weight' onBlur={event => this.handleWeightRange(event, 'maximum_weight')}/> 
                  </div>
                </div>

                   
            </div>,
            // <div className='row col-lg-12 weight-filters' style={this.props.weight_style}>
            //     <div className='col-lg-1 weight-label'>
            //         <label>Weight</label>
            //     </div>
            //     <div className='col-lg-1 weight-input' >
            //         <label>Min</label>
            //     </div>
            //     <div className='col-lg-1 weight-input' >
            //         <label>Max</label>
            //     </div>        
            // </div>
        ]
        )
    }
}

export default withRouter(AttributeFilters);


class AttributeItem extends React.Component { 
    constructor(props){
        super(props)
        this.state = {
            attribute_values : [],
            selected_attribute_options:[],
        }
    }
    componentWillMount(){
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(
            `${API_HOST}/repo/attributes/${this.props.attribute_name_slug}/options/list/`
            ).then(response => {
            if (response.status == 200) {
                this.setState({
                    attribute_values:response.data.data 
                })
            }
        }).catch(response => {
            ErrorHandling(response)
        });

    }
    selectAttributeOptions = (e,attribute_value) =>{
        e.preventDefault()
        $(e.currentTarget).addClass('active')
        var selected_attr_val = this.state.selected_attribute_options
        if( !selected_attr_val.includes(attribute_value)){
            selected_attr_val.push(attribute_value)
            this.setState({
                selected_attribute_options :selected_attr_val
            })
            this.props.seleted_attributes(this.props.attribute_name_slug, this.state.selected_attribute_options)  
        }

    }
    removeSelectedAttributeValues = (e,selected_value,selected_val_indx) =>{
        e.preventDefault()
        var kk = $(e.currentTarget).parent().find('.dropdown-content')  
        $(e.currentTarget).parent().find('.dropdown-content').find('.page-link').filter((indx, html_node)=>{
            var this_attr = $(html_node).attr('attr-name')            
            if(selected_value == this_attr){
                $(html_node).removeClass('active')
            }else if(selected_value == "clear"){
                $(html_node).removeClass('active')
            }

        })
        var selected_attribute_values = this.state.selected_attribute_options
        if(selected_value == "clear"){
            selected_attribute_values = []
        }else{
            selected_attribute_values.splice(selected_val_indx,1)            
        }
        this.setState(
            {selected_attribute_options:selected_attribute_values},
            () => {
            this.props.seleted_attributes(this.props.attribute_name_slug, this.state.selected_attribute_options)
            }
        )
    }

    render(){
        var item_attributes = this.state.attribute_values.map((itemattrbute,indx)=>{            
                  return <a key={indx} className="page-link" href="javascript:void(0);" onClick={event => this.selectAttributeOptions(event, itemattrbute.value)} attr-name={itemattrbute.value}  >{itemattrbute.value}</a>
        })
        var selected_attr_values = this.state.selected_attribute_options.map((attr_value,indx)=>{
            return  <a className="item-attr-link active" href="javascript:void(0);" onClick={event => this.removeSelectedAttributeValues(event,attr_value,indx)}>{attr_value}</a>

        })       
          return(
            <div className="attribute-dropdown" >
                  <div className="attribute-btn">
                      <a href="javascript:void(0);" className="dropbtn" name_slug={this.props.attribute_label} style={a_tag_css}  ><span className='attribute-name'>{this.props.attribute_label}</span>  <i className="fas fa-angle-down arrow-down-icon"></i></a>
                    <div className="dropdown-content">
                        {item_attributes}
                    </div>
                </div>
                {selected_attr_values.length > 0?
                    [selected_attr_values,
                    <a className="item-attr-link clear-filter fa fa-times" href="javascript:void(0)" onClick={event => this.removeSelectedAttributeValues(event,"clear",0)} ></a>]
                :null}

            </div>
          )
    }
}
