import  { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Logo from '../../images/logo.svg'
import Lock from '../../images/ios-lock.svg'
import notify from '../../CommonFunctions.js'
import { API_HOST } from '../../Settings.js'
import './login_page.css'
import microsoft from './microsoft.svg'
import $ from 'jquery'
import Cookies from "universal-cookie";
const axios = require('axios').default;
const cookies = new Cookies();


class LoginPage extends Component {
    /*constructor(props){
        super(props)
    }*/
    componentDidMount(){
        $(".inline-loader").fadeOut('slow')
        if(this.props.location.state?this.props.location.state.params.message:''){
            notify(this.props.location.state.params.message,'information')
        }
    }

    loginSubmit = (event) =>{
        event.preventDefault();
          document.getElementById("error_msg").innerText = ''
          const username =  document.getElementById("email").value
          const password =  document.getElementById("pwd").value
          if(username && password){
            $(".inline-loader").fadeIn()
            const form_data = 'username='+username+'&password='+password
            //'username=prashanthk432@gmail.com&password=brio@1234',
            axios({
                method: 'post', 
                url: API_HOST+'/users/login/',
                data:form_data,
                headers: { 
                "Content-Type": "application/x-www-form-urlencoded",
                }
            }).then((response) =>{
                $(".inline-loader").fadeOut('slow')
                if(response.data.first_name && response.data.last_name){
                    localStorage.setItem('malbar_first_name',response.data.first_name)
                    localStorage.setItem('malbar_last_name',response.data.last_name)    
                }else{
                    localStorage.setItem('malbar_first_name','User')
                    localStorage.setItem('malbar_last_name','Name')
                }
                localStorage.setItem('malbar_email',response.data.email)
                /*localStorage.setItem('malbar_token',response.data.token)*/
                cookies.set('malabar_token', response.data.token, {path:'/'})
                localStorage.setItem('malbar_id',response.data.id)
                localStorage.setItem('malbar_is_superuser',response.data.is_superuser)
                this.props.history.push('/batches/')
            })
            .catch(function (response) {
                $(".inline-loader").fadeOut('slow')
                var error_msg = response.response.data.non_field_errors[0]
                notify(error_msg,'error')
                /*document.getElementById("error_msg").appendChild(document.createTextNode(error_msg));
                setTimeout(() => {
                    document.getElementById("error_msg").innerText = '';
                }, 10000);*/ 
                
            });
        }else{
            $(".inline-loader").fadeOut('slow')
            var error_msg = 'Please enter valid email and password'
            document.getElementById("error_msg").appendChild(document.createTextNode(error_msg));
            setTimeout(() => {
                document.getElementById("error_msg").innerText = '';
            }, 10000);
        }   
    }
    render() {
        return (
            <div className="Login-main-container">                
                <div className="p-5 mt-5" id="sign-in-card">
                <div className="inline-loader" style={{"minHeight": "85%"}} ><div className="dot-elastic"></div></div> 
                    <div className="text-center">
                        <img src={Logo} className="login-image" alt="login-logo"/>
                    </div>
                    <div className="text-center mt-4">
                        <span id="sign-in-title">SIGN IN</span>
                    </div>
                    <form onSubmit={this.loginSubmit} className="login-form">
                        <p id="error_msg" className="text-danger"></p>
                        <div className="form-group">
                            <label htmlFor="email" id="email-id-label">Email Id</label>
                            <div className="d-flex justify-content-between custom-border-bottom">
                                <span className="d-flex align-items-center"><i className="fas fa-envelope"></i></span>
                                <input type="email" className="form-control no-border shadow-none" placeholder="Enter Your Email Id" id="email"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="pwd" id="pwd-label">Password</label>
                            <div className="d-flex justify-content-between custom-border-bottom">
                                <img src={Lock} alt="lock-icon" />
                                <input type="password" className="form-control no-border shadow-none" placeholder="Enter Your Password" id="pwd"/>
                        {/* <button className="btn"><img src="../images/ios-eye.svg"></button> */}
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-start mt-3">
                            <div className="round mr-5">
                                <input type="checkbox" id="checkbox" />
                                <label for="checkbox"></label>
                            </div>
                            <div><label id="remember-label">Remember Me</label></div>
                        </div> */}
                    

                    <div className="d-flex justify-content-center mt-3" >
                        {/* <button className="btn pl-0"><span id="forgort-pwd-span">Forget Password ?</span></button> */}
                        {/*<a href={API_HOST+'/users/azure/ad/authentication/'} className="o365icon" title="Azure Login"></a>*/}
                        <button type="submit" className="btn btn-dark" id="sign-in-btn">Sign In</button>
                    </div>
                    <div className="or-seperator"><i>or</i></div>
                    <div className="d-flex justify-content-center">
                        <a href={API_HOST+'/users/azure/ad/authentication/'} className="d-flex justify-content-between btn microsoft-btn shadow-none ">
                            <img src={microsoft} width="20px" height="20px" className="mr-2 pt-1" alt="m365-icon" /> Sign in with Microsoft 365
                        </a>
                    </div>
                    </form>
                </div>
        </div>
        )
    }
}

export default withRouter(LoginPage)
