import React from 'react'
import { Route } from 'react-router-dom'
import NavBar from './NavBar'
import ErrorBoundary from './ErrorBoundary'
import ProgressBarsProvider from './pages/ProgressBarsProvider'
import Cookies from "universal-cookie";
const cookies = new Cookies();

// export const PrivateRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={props => (
//         <NavBar><Component/></NavBar>
//     )} />
// )

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        cookies.get('malabar_token')
            ? <ErrorBoundary><ProgressBarsProvider><NavBar><Component/></NavBar></ProgressBarsProvider></ErrorBoundary>
            :props.history.push('/login')
    )} />
)