import React, { Component } from 'react';

class RepoDesignCardFooterStudio extends Component {
  render() {

      return (
        <div>
          <div className="row m-0 p-0 pb-1 ">
            <label className="repo-index-footer-vdi-number">VDI # {this.props.vdin} </label>
          </div>
          <div className="row m-0 p-0 pb-1">
            <label className="repo-index-footeer-design-name">{this.props.design_name}</label>
          </div>
          <div className="row m-0 p-0 pb-1">
              {this.props.gd_number
                ?
                <label className="repo-index-footer-vendor-design-number">GD# {this.props.gd_number}</label>
                :
                <label className="repo-index-footer-vendor-design-number">GD# </label>
              }
            
          </div>
        </div>
      )
  }
}


export default RepoDesignCardFooterStudio
