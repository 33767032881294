import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import $ from 'jquery'


class RejectModal extends Component {
    componentDidMount(){
        $('#reject-model').modal('show');
        $('#reject-model').on('hidden.bs.modal', this.props.handleHideModal);
    }
    render() {
        return (
                <div className="modal" id="reject-model" aria-modal="true" role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered" id="reject-design-model-dialog">
                  <div className="modal-content pl-4 pr-4" id="reject-design-model-content">
                    {/* <div className="modal-header dashed-row-bottom pl-0 pr-0">                      
                      <div>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.hideModal}>×</button>
                      </div>
                    </div> */}
                    <div className="modal-body pl-0 pr-0">
                      <div className="row m-0">
                        <div className="col-md-12 p-0">
                          <div>
                            <label className="color-red pr-2">*</label>
                            <label id="reject-design-text">Type your message to reject</label>  
                          </div>
                        </div>
                      </div>
                      <div className="row p-0 m-0">
                        <textarea className="form-control" id="modal-reject-design-comment" placeholder="Type your message"></textarea>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between pl-0 pr-0 dashed-row-top">
                      <button type="button" className="btn  pl-4 pr-4 m-0" id="cancel-modal" data-dismiss="modal" onClick={this.props.hideModal}>
                        Cancel
                      </button>
                      <button type="button" className="btn btn-dark pl-5 pr-5 m-0" onClick={event => this.props.rejectAction(event,'rejected',$('textarea#modal-reject-design-comment').val())}>
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </div>
        )
    }
}

export default withRouter(RejectModal)