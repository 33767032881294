import React, { Component } from 'react';

class RepoCardImageWithNameFooter extends Component {
  render() {

      return (

      	<div className={this.props.id_component_name+"img-parent m-0 p-0"}>
    	     <img className={this.props.id_component_name+"img-inside-div image" }src={this.props.image} alt="design" />
        </div>
      )
  }
}

export default RepoCardImageWithNameFooter
