import React from 'react';
import { withRouter } from 'react-router-dom';


class Paginator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        	page_data: this.props.page_data
        }
    }

    componentDidUpdate(prevProps, prevState) {
    	/*console.log(prevProps, prevState)*/
	    if (this.props.page_data !== prevProps.page_data) {
	        this.setState({
	        	page_data: this.props.page_data
	        });
	    }
    }

    render(){
    	return(
    		<div className="d-flex justify-content-between">
              <div >
                Showing {(this.state.page_data.page_size * (this.state.page_data.page_number -1)) + 1 } to {(this.state.page_data.page_size * this.state.page_data.page_number) > this.state.page_data.total ? this.state.page_data.total : (this.state.page_data.page_size * this.state.page_data.page_number)} of {this.state.page_data.total} entries
              </div>
              { this.state.page_data.no_of_pages > 1 &&
                <ul className="pagination pagination-sm justify-content-end">
                  { this.state.page_data.page_number - 2 > 0 &&
                    <>
                      <li className="page-item">
                        <button className="btn btn-link page-link shadow-none" onClick={event => this.props.onPageNumberChange(event, 1)}>
                          First
                        </button>
                      </li>
                      <li className="page-item">
                        <button className="btn btn-link page-link shadow-none" onClick={event => this.props.onPageNumberChange(event, this.state.page_data.page_number - 2)}>
                          Previous
                        </button>
                      </li>
                    </>
                  }
                  { this.state.page_data.page_number - 1  !== 0 &&
                    <li className="page-item">
                      <button className="btn btn-link page-link shadow-none" onClick={event => this.props.onPageNumberChange(event, this.state.page_data.page_number - 1)}>
                        {this.state.page_data.page_number - 1}
                      </button>
                    </li>
                  }
                  <li className="page-item active">
                    <button className="btn btn-link page-link shadow-none" disabled>
                      {this.state.page_data.page_number}
                    </button>
                  </li>
                  { this.state.page_data.page_number + 1 <= this.state.page_data.no_of_pages &&
                    <li className="page-item">
                      <button className="btn btn-link page-link shadow-none" onClick={event => this.props.onPageNumberChange(event, this.state.page_data.page_number + 1)}>
                        {this.state.page_data.page_number + 1}
                      </button>
                    </li>
                  }
                  { this.state.page_data.page_number + 2 <=  this.state.page_data.no_of_pages &&
                    <>
                      <li className="page-item">
                        <button className="btn btn-link page-link shadow-none" onClick={event => this.props.onPageNumberChange(event, this.state.page_data.page_number + 2)}>
                          Next
                        </button>
                      </li>
                      <li className="page-item">
                        <button className="btn btn-link page-link shadow-none" onClick={event => this.props.onPageNumberChange(event, this.state.page_data.no_of_pages)}>
                          Last
                        </button>
                      </li>
                    </>
                  }
                </ul>
              }
            </div>
    	)
    }
}

export default withRouter(Paginator);