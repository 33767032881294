// import $ from 'jquery'
import notify from './CommonFunctions'
import Cookies from "universal-cookie";
const cookies = new Cookies();

const ErrorHandling = (Response) => {
    if(Response){
        var error_response = Response.response
        var status_code = error_response.status
        if(status_code >= 400 && status_code <= 600){
        var clear_local_storage = false
            if(status_code===403){
                notify('Permission Denied','error')
                setTimeout(function(){ 
                    window.location.href = '/permissiondenied/'
                }, 3000);
            }
            else if(status_code === 404){
                notify('Page Not Found','error')
                setTimeout(function(){ 
                    window.location.href = '/permissiondenied/'
                }, 3000);
            }else if(status_code === 401){
                if(error_response.data.detail.toLowerCase() === "invalid token."){
                    notify('Invalid token','error')
                    clear_local_storage = true
                }else if(error_response.data.detail.toLowerCase()==='token expired'){
                    notify('Session Expired.','error')
                    clear_local_storage = true
                }else{
                    notify('You cannot perform this action.','error')
                }                
            }else if(status_code >= 500 && status_code <= 600){
                notify('Server encounter error.','error')
                //clear_local_storage = true
            }else{
                notify('Something went wrong, Try again later.','error')  
            }
            setTimeout(function(){
                if(clear_local_storage){
                    cookies.remove('malabar_token',{path:'/'})
                    localStorage.clear() 
                }                
            },1000)
        }
    }
    return('')
}

export default ErrorHandling
