import React from 'react';
import { withRouter } from 'react-router-dom';
import './permission_denied.css'
import $ from 'jquery';

class PermissionDeniedPage extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      }
    }
  


  componentDidMount(){
    $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
  }

  render(){
        return(
          <div className="batch-list-content relative">
            <div className="row m-0 p-0 d-flex justify-content-center">
                <div><h2 className="permission-denied">Permission Denied</h2></div>
            </div>
          </div>
        )    
    }

}
export default withRouter(PermissionDeniedPage);