import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {API_HOST} from '../../Settings.js'
// import Paginator from '../../Paginator.js'
import axios from 'axios';
// import DesignCardFooter from "./DesignCardFooter"
// import ImagePlaceholder from '../../images/image_placeholder.jpg'
import DesignCardImage from './DesignCardImage'
import './repodesigndetails.css'
import $ from 'jquery'
import DesignCardImageCheckbox from './DesignCardImageCheckbox'
// import Select from 'react-select'
import notify from '../../CommonFunctions.js'
import DesignCardImageDelete from './DesignCardImageDelete'
import RepoCardImageWithNameFooter from './RepoCardImageWithNameFooter'
import ErrorHandling from '../../ErrorHandler'
import VariantAttribute from '../batches/VariantAttribute'
import {objectIsNotEmpty} from '../customHelperFunctions'
import Cookies from "universal-cookie";
const cookies = new Cookies();

class RepoDesignDetails extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	page_loading: true,
	    	designID: -1,
	    	design: {},
	    	design_active_state: false,
	    	designName: this.props.match.params.design_name,
	    	batch_details: {
	    		'vendor': '',
	    		'location': '',
	    		'dateTime': '',
	    		'status': ''
	    	},
	    	user_perm:{},
	    	modal_index: -1,
	    	modal_design_id:'',
	    	image_counts:[],
	    	attributes_history: [],
	    	existing_images : [],
	    	new_upload_images_for_studio_manager:[],
            new_upload_images_for_product_manager:[],
	    	request_comment: "",
	    	comments: [],
	    	selected_variant_name: "",
	    	selected_variant_id: -1,
	    	comment_selected_variant_id: -1,
	    	variant_ids_available_for_add_new_request_by_user:[],
            variant_ids_user_has_to_upload_images:[],
	    	no_of_accepted_variants_for_new_request: 0,
	    	checked_image_ids: [],
	    	all_new_images_selected: false,
	    	has_received_comments_response: false,
            show_add_variant_modal: false,
            selected_images_for_new_variant: [],
            show_add_variant_btn: false,
            showAttributeTab: false,
            roles_and_perms:{},
			batchID:null,
			showSimilarDesigns:true,
			similar_designs:[],
			batch_similar_designs:[],
			repo_similar_designs:[],
			selected_type:'repo',
	    }
	    
	}


	handleValidation(){
		let form_is_valid = true;
		if (typeof this.state.comment_selected_variant_id=="undefined"){
			form_is_valid = false
		}
		else if (this.state.comment_selected_variant_id < 0){
			form_is_valid = false;
		}
		return form_is_valid;
	}


	handleSubmit = (event) =>  {
	    event.preventDefault();
	    if (this.handleValidation()){
	    	$('#request-images-modal').modal('hide');
            $('.repo-inline-loader').fadeIn()
	    	var comment_selected_variant_id = this.state.comment_selected_variant_id
	    	let form_data = new FormData();
	    	form_data.append('variant_id',comment_selected_variant_id)
	    	form_data.append('design_id',this.state.designID)
	    	form_data.append('request_comment',this.state.request_comment)
	    	/*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
	    	axios.defaults.headers.post['Authorization'] =auth_token
	    	let url = `${API_HOST}/repo/imagerequest/request`;
	    	axios.post(url, form_data, {
	    	  headers: {
	    	    'content-type': 'multipart/form-data'
	    	  }
	    	})
	    	.then(response => {
	    	  if(response.status === 200){
	    	  	/*$('#request-images-modal').modal('hide')*/
	    	    this.setState({"request_comment":""})
	    	    notify('Your request has sent successfully', 'success')
                $('.repo-inline-loader').fadeOut('slow')
	    	    this.getcomments(event)
	    	  }
	    	  
	    	})
	    	.catch(error => {
	    	  alert('Request failed '+ error.response.data.detail);
              $('.repo-inline-loader').fadeOut('slow')
	    	})
	    }
	    else{
	    	alert("select the variant to request images")
	    }
	    
	};

	handleTextarea(event) {
	    this.setState({request_comment: event.target.value});
	  }

	handleToggleState(event){
	    let design_active_state = !this.state.design_active_state
        var confir_msg = ''
	    if (design_active_state===true){
	    	confir_msg = "Do you want to make this design as Active?";
	    }
	    if (design_active_state===false){
	    	confir_msg = "Do you want to make this design as InActive?";
	    }
	    if(window.confirm(confir_msg)){
		    $('.repo-inline-loader').fadeIn()
		    /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
		    axios.defaults.headers.get['Authorization'] =auth_token
            var design_id = this.state.designID
            this.setState({"designID":-1})
		    axios.get(
		    	`${API_HOST}/repo/design/${design_id}/update/state/active/inactive/?design_state=${design_active_state}`
		    	).then(response => {
		        if (response.status === 200) {
		        	this.getDesignDetails()
		            notify("status updated successfully",'success')
		            $('.repo-inline-loader').fadeOut('slow')
                    setTimeout(() =>{
                        window.location.reload()
                    },2000)             
		        }
		    }).catch(response => {
                $('.repo-inline-loader').fadeOut('slow')
		        ErrorHandling(response)
		    });
		}
	}

	handleCommentVariantSelected(event) {
	    this.setState({comment_selected_variant_id: event.target.value});
	  }

	getVariantImages(variant_name,variant_id,event){
		$('.table-row-selected').removeClass('table-row-selected');
		if (variant_name===this.state.selected_variant_name){
			this.getData('', '', true)
            if(this.state.roles_and_perms.group_perms.includes("repo.view_attributehistory")){
                this.getAttributeEditHistory(event, -1)
            }
		}
		else {
			
			if (variant_name){
				var current_row = event.target.parentElement;
				$(current_row).addClass("table-row-selected");
				this.getData(variant_name,variant_id, true);
                if(this.state.roles_and_perms.group_perms.includes("repo.view_attributehistory")){
                        this.getAttributeEditHistory(event, variant_id)
                }
			}
		  	
		}

	}

	openModal(index,design_id,event){
		/*alert('openModal')
		alert(index)*/
		if (this.state.modal_index > -1){
			this.setState({
				modal_index:index
			})
		}else{
			/*alert('failed')*/
		}

		$('#repo-myModal').modal()
	}

    changeStateAddVariantModal(event){
        this.setState(
                (prevState, props) => ({
                    show_add_variant_modal: !(prevState.show_add_variant_modal)
                }), this.handleAddVariantModal
            )
    }

    handleAddVariantModal(){
        if (this.state.show_add_variant_modal){
            $('#approve-as-variant-model').modal('show');
        }
        else{
            $('#approve-as-variant-model').modal('hide');
            this.setState({
                selected_images_for_new_variant:[]
            })
        }
    }

    selectVariantImages = (event) =>{
        var selected_images = this.state.selected_images_for_new_variant
        if(event.currentTarget.checked){
            selected_images.push(event.currentTarget.value)
            this.setState({
                selected_images_for_new_variant:selected_images
            })
        }else{
            var index = selected_images.indexOf(event.currentTarget.value)
            selected_images.splice(index,1)
            this.setState({
                selected_images_for_new_variant:selected_images
            })
        }
    }

    handleAddVariantValidation(variant_data){
        let isvalid = true
        var variant_image_dict = {}
        var image_variant_dict = {}
        var existing_images_data = this.state.existing_images
        var temp_variant_id = -1
        var temp_image_id = -1
        let each;
        for(each in existing_images_data){
            temp_image_id = existing_images_data[each].id
            temp_variant_id = existing_images_data[each].variant
            image_variant_dict[temp_image_id]=temp_variant_id;
            if(temp_variant_id in variant_image_dict){
                variant_image_dict[temp_variant_id].push(temp_image_id)
            }
            else{
                variant_image_dict[temp_variant_id]=[temp_image_id]
            }
        }
        let key;
        var selected_images = variant_data.selected_images
        for (key in variant_image_dict){
            if (variant_image_dict[key].length<=selected_images.length){
                var issubset = true;
                let img_id;
                for (img_id in variant_image_dict[key]){
                    var condition =  selected_images.includes(String(variant_image_dict[key][img_id]));
                    if(condition){
                        continue
                    }else{
                        issubset=false;
                        break;
                    }
                }
                if(issubset){
                    return false;
                }

            }
        }
        return isvalid
    }


    saveApproveAsVariant = (event) =>{
        var variant_name = $('#new-variant-name').val()
        if(!variant_name){
            notify('Please enter variant name','warning')
            return false
        }        
        
        var variant_data = {
            variant_name:variant_name,
            selected_design_id:this.state.designID,
            selected_images:this.state.selected_images_for_new_variant
        }

        if(this.handleAddVariantValidation(variant_data)){
            if(window.confirm('Are you sure, you want add a variant?')){
                $('#approve-as-variant-model').modal('hide');
                $('.repo-inline-loader').fadeIn()
                this.changeStateAddVariantModal()
                /*const auth_token = "Token "+localStorage.malbar_token*/
                const auth_token = "Token "+cookies.get("malabar_token")
                axios.defaults.headers.post['Authorization'] =auth_token      
                axios.post(`${API_HOST}/repo/design/${this.state.designID}/variant/add/`, variant_data)
                .then(response => {
                    this.setState({
                        selected_images_for_new_variant:[]
                    })
                    $('.repo-inline-loader').fadeOut()
                    if (response.status === 200 && response.data.success) {                    
                        notify('Variant added successfully.','success')
                        setTimeout(() =>{
                            window.location.reload()
                        },1000)
                    }else{
                        notify('Something went wrong, Try again later.','error')
                    }
                }).catch(response => {
                    $('.repo-inline-loader').fadeOut()
                    this.setState({
                        selected_images_for_new_variant:[]
                    })
                    ErrorHandling(response)
                });
            }
        }else{
            notify('You are selected all images of same variant','warning')
        }

        
    }


	handleChildCheckboxClick(event){
		let checked_image_id = event.target.id.split("_")[4];
		let checked_image_ids_array = [...this.state.checked_image_ids];
		if (event.target.checked){
			if ((this.state.checked_image_ids.length + 1) === this.state.new_upload_images_for_product_manager.length){
				this.setState({"all_new_images_selected":true})
			}
			checked_image_ids_array.push(checked_image_id);
			this.setState({checked_image_ids:checked_image_ids_array});
		}else{
			if (checked_image_ids_array.includes(checked_image_id)){
				let index = checked_image_ids_array.indexOf(checked_image_id);
				checked_image_ids_array.splice(index);
				this.setState({checked_image_ids:checked_image_ids_array});
				this.setState({"all_new_images_selected":false})
			}	
		}
	}

    
	handleImageDeleteClick(event){
		let confirm_msg = "Are sure, You want to delete image ?"
		let success_msg = "Image Deleted successfully!"
		let selected_image_id = event.target.id.split("_")[3];
		let data = {"img_id_for_delete":selected_image_id}
		if(window.confirm(confirm_msg)){
            $('.repo-inline-loader').fadeIn()
			/*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
		    axios.defaults.headers.delete['Authorization'] =auth_token  
		    axios.delete(
		    	`${API_HOST}/repo/imagerequest/images/studiomanager/delete/`,
		    	{data:data}
		    	).then(response => {
		        if (response.status === 200) {
                    $('.repo-inline-loader').fadeOut('slow')
                    if(response.data.message_type==="info"){
                        alert(response.data.response_message);
                    }
                    else if(response.data.message_type==="error"){
                        alert(response.data.response_message)
                    }
                    else if(response.data.message_type==="success"){
                        notify(success_msg,'success')
                    }
                    this.setState({
                        new_upload_images_for_studio_manager:[],
                        new_upload_images_for_product_manager:[],
                    },()=>{
                        $('.nav-tabs li a:first').tab('show')
                        this.getData('','', true);
                        this.getImageCounts();
                    })

		        }
		    }).catch(response => {
                $('.repo-inline-loader').fadeOut('slow')
		        ErrorHandling(response)
		    });
		}
	}

	approveSelectedImages(event){
		if (this.state.checked_image_ids.length > 0){
			$('.repo-inline-loader').fadeIn()
			var checked_image_ids_array = this.state.checked_image_ids;
			const data = JSON.stringify({checked_image_ids_array: checked_image_ids_array})
			/*let form_data = new FormData();
			form_data.append('string_json_array',data)*/
			/*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
			axios.defaults.headers.post['Authorization'] =auth_token
			let url = `${API_HOST}/repo/imagerequest/images/productmanager/approve/`;
			axios.post(url, data, {
			  headers: {
			    'content-type': 'application/json'
			  }
			})
			.then(response => {
			  if(response.status === 200){
			  	$('#request-images-modal').modal('hide')
			  	this.setState({
			  		"existing_images": [],
			  		"checked_image_ids": [],
                    new_upload_images_for_studio_manager:[],
                    new_upload_images_for_product_manager:[],
			  	})
			  	$('.repo-inline-loader').fadeOut()
			    notify('Images approved successfully','success')
                $('.repo-inline-loader').fadeOut('slow')
                $('.nav-tabs li a:first').tab('show')
                this.getData('','', true);
                this.getImageCounts();
			  }
			  
			})
			.catch(response => {
				$('.repo-inline-loader').fadeOut()
                ErrorHandling(response)
			})
			
		}else{
			alert("select the images you want to approve");
		}
	}

	rejectSelectedImages(event){
		if (this.state.checked_image_ids.length > 0 ){
			$('.repo-inline-loader').fadeIn()
			var checked_image_ids_array = this.state.checked_image_ids;
			const data = JSON.stringify({checked_image_ids_array: checked_image_ids_array})
			/*let form_data = new FormData();
			form_data.append('string_json_array',data)*/
			/*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
			axios.defaults.headers.post['Authorization'] =auth_token
			let url = `${API_HOST}/repo/imagerequest/images/productmanager/reject/`;
			axios.post(url, data, {
			  headers: {
			    'content-type': 'application/json'
			  }
			})
			.then(response => {
			  if(response.status === 200){
			  	$('#request-images-modal').modal('hide')
			  	this.setState({
			  		"existing_images": [],
			  		"checked_image_ids":[],
                    new_upload_images_for_studio_manager:[],
                    new_upload_images_for_product_manager:[],
			  	})
			  	$('.repo-inline-loader').fadeOut()
			    notify('Images rejected successfully','success')
                $('.repo-inline-loader').fadeOut('slow') 
                $('.nav-tabs li a:first').tab('show')                
                this.getData('','', true);
                this.getImageCounts();
			  }
			  
			})
			.catch(response => {
				$('.repo-inline-loader').fadeOut()
                ErrorHandling(response)
			})
			
		}
		else{
			alert("select the images you want to reject");
		}
	}


	handleSelectAllImagesChecked(event){
		if (event.target.checked){
			this.setState({
				"all_new_images_selected": true,
				"checked_image_ids": this.state.new_upload_images_for_product_manager.map(each_image => each_image["id"].toString())
			})
			
			$(".checkbox_new_images").prop("checked",true);

		}
		else{
			this.setState({
				"all_new_images_selected": false,
				"checked_image_ids": []
			})
			$(".checkbox_new_images").prop("checked",false);
		}
	}


	prevImage(index,event){
		/*alert('prev');*/
		if ((this.state.modal_index) > -1){
			this.setState((state, props) => {
			  return {modal_index: state.modal_index - 1};
			});	
		}
		else{
			/*alert("start backword no images")*/
		}
		
	}

	nextImage(index,event){
		/*alert('next');
		alert(this.state.modal_index)*/
		if ((this.state.existing_images.length-1) > (this.state.modal_index)){
			this.setState((state, props) => {
			  return {modal_index: state.modal_index + 1};
			});
		}
		else{
			/*alert("end of images")*/
		}
	}


	getImageCounts = () => {
		axios.get(`${API_HOST}/repo/design/${this.state.designID}/imagescountbyvariants/`, ).then(response => {
		    if (response.status === 200) {
				this.setState({
		            image_counts: response.data.images_count_by_variant_name,
					batchID:response.data.images_count_by_variant_name[0].variant__batch_id,
		            comment_selected_variant_id:(
                            response.data.images_count_by_variant_name.length>0
                        ) ? response.data.images_count_by_variant_name[0].variant_id : -1
		        })
		    }

		}).catch(response => {
                // ErrorHandling(response)
            });
	}

	getDesignDetails = () => {
			$('.repo-inline-loader').fadeIn()
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
			axios.defaults.headers.get['Authorization'] =auth_token
		    axios.get(`${API_HOST}/repo/design/${this.state.designName}/basicdetail/`, ).then(response => {
		        if (response.status === 200) {
                    if(response.data.data.length>0){

                         this.setState({
    		                design: response.data.data[0],
                            designID: response.data.data[0].id,
    		                design_active_state:response.data.data[0].active,
                            "roles_and_perms": response.data.roles_and_perms
    		            })
                        $('.repo-inline-loader').fadeOut()
                        this.getData('','', false);
                        this.getImageCounts();
                    }
                    else{
                        alert("Invalid design")
                        this.props.history.push('/repo')
                    }
		        }

		    }).catch(response => {
                ErrorHandling(response)
                /*$('.repo-inline-loader').fadeOut()*/
		        /*this.props.history.push('/repo')*/
		    });
	}

	getData =(selected_name,selected_id, start_loader) => {
		this.setState({
			page_loading: true,
	        existing_images: [],
	        modal_index:-1,
	        new_upload_images_for_product_manager: [],
	        new_upload_images_for_studio_manager:[]
	    })
	    this.getcomments();
		/*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
		axios.defaults.headers.get['Authorization'] =auth_token
        if (start_loader===true){
            $('.repo-existing-images-inline-loader').fadeIn()
        }
        
		var url;
		if (selected_name){
			this.setState({"selected_variant_name":selected_name,"selected_variant_id":selected_id})
			url = `${API_HOST}/repo/design/${this.state.designID}/images/list/?selected_variant_id=${selected_id}`;	
		}
		else {
			url = `${API_HOST}/repo/design/${this.state.designID}/images/list/`;
			this.setState({"selected_variant_name":'',"selected_variant_id":-1})
		}
		axios.get(url, ).then(response => {
		    if (response.status === 200) {
                this.setState({
	    	        existing_images: response.data.images,
	    	        modal_index: response.data.images ? 0 : -1,
                    show_add_variant_btn: true
	    	    })
		    		this.setState({
	    		        new_upload_images_for_studio_manager:response.data.new_upload_images_for_studio_manager,
                        new_upload_images_for_product_manager: response.data.new_upload_images_for_product_manager
	    		    })
		    	this.setState({page_loading: false})
                $('.repo-existing-images-inline-loader').fadeOut('slow')
                $('.inline-loader').fadeOut('slow')
		    }
		    

		}).catch(response => {
		    // ErrorHandling(response)
		});
	}

	
	componentDidMount() {
        $('.repo-existing-images-inline-loader').fadeOut()
        $('.repo-inline-loader').fadeIn()
        $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
        $('.main-menu-sub-group').find('#repo-group').find('.circle-title').addClass('active-navbar')
        $('#approve-as-variant-model').on('hidden.bs.modal', this.state.changeStateAddVariantModal)
        this.getUserPermissions()
	}

    componentWillUnmount(){
        $('.modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    }


    getUserPermissions(){
      /*const auth_token = "Token "+localStorage.malbar_token*/
      const auth_token = "Token "+cookies.get("malabar_token")
      axios.defaults.headers.get['Authorization'] =auth_token
      /*$('.repo-inline-loader').fadeIn()*/
      axios.get(`${API_HOST}/users/user/permissions/read/`)
      .then(response => {
          if (response.status === 200) {
            var get_all_permissions = response.data.user_perm.get_all_permissions
            if(get_all_permissions.includes("repo.view_design")||get_all_permissions.includes("repo.view_variant")){
                this.setState({"user_perm": response.data.user_perm})
                this.getDesignDetails()
            }else{
                notify("You don't have permission to aceess this page",'error')
                this.props.history.push('/permissiondenied/')
            }
          }
      }).catch(response => {
          $('.repo-inline-loader').fadeOut('slow')
          ErrorHandling(response)
        });
    }

	getAttributeEditHistory = (event, selected_variant_id) =>{
		event.preventDefault()
        $('.history-inline-loader').find('.inline-loader').fadeIn()
		/*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
		axios.defaults.headers.get['Authorization'] =auth_token
		axios.get(`${API_HOST}/repo/design/${this.state.designID}/attributes/history/list/?selected_variant_id=${selected_variant_id}`, ).then(response => {
	        if (response.status === 200) {
	            this.setState({
	                attributes_history: response.data.attributes_history,
	            })
	            $('.history-inline-loader').find('.inline-loader').fadeOut('slow')

	        }
	    }).catch(response => {
            $('.inline-loader').fadeIn()
	        ErrorHandling(response)
	    });
	}

    getVariantAttributes = () =>{
        this.setState({
            showAttributeTab:true
        })
        
    }
	

	getcomments = (event) => {
		this.setState({
			comments:[],
			variant_ids_available_for_add_new_request_by_user:[],
            variant_ids_user_has_to_upload_images:[],
			no_of_accepted_variants_for_new_request: 0,
			has_received_comments_response: false
	    })
		$('.repo-comments-inline-loader').fadeIn()
        var design_id = this.state.designID;
        var url = `${API_HOST}/repo/imagerequest/${design_id}/list/`;
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(url,).then( response => {
        			if(response.status === 200){
        				this.setState({
    						comments:response.data.comments,
    						no_of_accepted_variants_for_new_request: response.data.no_of_accepted_variants_for_new_request,
                            variant_ids_available_for_add_new_request_by_user: response.data.variant_ids_available_for_add_new_request_by_user,
                            variant_ids_user_has_to_upload_images: response.data.variant_ids_user_has_to_upload_images,
    						has_received_comments_response: true,
    				    })
        			}
        		}
        	).catch(response => {
        		// ErrorHandling(response)
        	});
        	$('.repo-comments-inline-loader').fadeOut('slow')
	}

	get_similar_designs = (event) =>{
        event.preventDefault()
                $('.similar-design-content').find('.inline-loader').fadeIn()
                let form_data = new FormData();
                // form_data.append('images_data',JSON.stringify(this.state.existing_images))
                form_data.append('from_repo',"true")
                const auth_token = "Token "+cookies.get("malabar_token")
                axios.defaults.headers.post['Authorization'] =auth_token
                axios.post(`${API_HOST}/repo/design/${this.state.designID}/detail/`, form_data)
                .then((response) => {
                if (response.status == 200) {
					this.setState({
						batch_similar_designs:response.data.batch_similar_images,
						repo_similar_designs:response.data.repo_similar_images,
						similar_designs:response.data.repo_similar_images,
						showSimilarDesigns:false
					  })
					  $('.similar-design-content').find('.inline-loader').fadeOut()      
                 
                }
            })
    
    }

	get_batch_or_repo_similar_designs = (event, btn_type) =>{
        event.preventDefault()
        if(btn_type == 'repo'){
            this.setState({
                similar_designs:this.state.repo_similar_designs,
                selected_type:btn_type

            })
        }else{
            this.setState({
                similar_designs:this.state.batch_similar_designs,
                selected_type:btn_type
            })
        }
    }

	   
	render() {
        var add_variant_images = [];
        if (this.state.existing_images.length>0){
            add_variant_images =  this.state.existing_images.map((image,index) =>{
               return  <div className="new-cell-image">
                          <img src={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${image.bucket_name}&batch_name=${image.batch_name}&image_name=${image.image_uri}&image_type=thumbnails`} className="new-design-image card-img-top" alt="design"/>
                          <div className="card-img-overlay text-left m-0 variant-img-overlay">
                            <input type="checkbox" className="status-change-checkbox variant-image-checkbox" value={image.id} onClick={this.selectVariantImages.bind(this)} id={"add_variant_images_id_"+image.id} />
                          </div>
                        </div>
            })
        }

		let existing_images_row =  this.state.existing_images.map((image,index) => {
			if (this.state.user_perm.is_studio_manager){
				return (
				  <div className="custom-card mb-4 col-xs-12 col-sm-6 col-md-4 col-lg-4">
				      <div className="card">
				          
				          <button type="button" className="btn m-0 p-0" onClick={this.openModal.bind(this,index,image.id)}>
				            <label style={{display:"none"}}>var name: {image.variant.name} </label>
				            <label style={{display:"none"}}>img id: {image.id} , var id: {image.variant.id}</label>
				            <div className="pt-3 pl-3 pr-3  pb-0">
				            	<RepoCardImageWithNameFooter image={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${image.bucket_name}&batch_name=${image.batch_name}&image_name=${image.image_uri}&image_type=thumbnails`} id_component_name="repo-studio-manager-" />
				            	<div className="text-left mb-2"><span className="repo-studio-variant-name">{image.variant_name}</span></div>
				            </div>
				          </button>
				          
				      </div>
				  </div>
				)
			}
		  return (
		    <div className="custom-card mb-4 col-xs-12 col-sm-6 col-md-4 col-lg-4">
		        <div className="card">
		            
		            <button type="button" className="btn m-0 p-0" onClick={this.openModal.bind(this,index,image.id)}>
		              <label style={{display:"none"}}>var name: {image.variant.name} </label>
		              <label style={{display:"none"}}>img id: {image.id} , var id: {image.variant.id}</label>
		              <DesignCardImage image={image.image_signed_url} design={image} />
		            </button>
		            
		        </div>
		    </div>
		  )
		      
		})

		let new_uploaded_images_row =  this.state.new_upload_images_for_studio_manager.map((image,index) => {
		  return (
            	<div className="custom-card mb-4 col-xs-12 col-sm-6 col-md-4 col-lg-4">
	            	<div className="card">
                        {   
                            this.state.roles_and_perms.group_perms.includes("repo.delete_image")
                            ?
                            <DesignCardImageDelete id_component_name="uploaded_images" 
                                img_id={image.id} image={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${image.bucket_name}&batch_name=${image.batch_name}&image_name=${image.image_uri}&image_type=thumbnails`}
                                onChildDeleteClick={this.handleImageDeleteClick.bind(this)} enable_delete={true} />
                            :
                            <DesignCardImageDelete id_component_name="uploaded_images" 
                            img_id={image.id} image={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${image.bucket_name}&batch_name=${image.batch_name}&image_name=${image.image_uri}&image_type=thumbnails`} enable_delete={false} />
                        }
	            		<div className="uploaded-image-description-div">
						<div className="row m-0">
							<label className="">{image.variant_name}</label>
						</div>
	            		</div>
	            	</div>
	            </div>
		  )
		      
		})

		let image_counts_row = this.state.image_counts.map((row,index) => {
				
				return (
					<tr onClick={this.getVariantImages.bind(this,row.variant__name, row.variant_id)}>
					  <td>{row.variant__name}</td>
					  <td>{row.count}</td>
					</tr>
				)
			}
		)

		var variant_names_comment_select = [];
		this.state.image_counts.map((row, index) => {
			if (this.state.variant_ids_available_for_add_new_request_by_user.includes(row.variant_id)){
				variant_names_comment_select.push(
						<option value={row.variant_id}>{row.variant__name}</option>
					)
			}
		}) 

		let new_images_row =  this.state.new_upload_images_for_product_manager.map((image,index) => {
		      
		  return (
		    <div className="custom-card mb-4 col-xs-12 col-sm-6 col-md-4 col-lg-4">
		        <div className="card">
		            
		            <DesignCardImageCheckbox image={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${image.bucket_name}&batch_name=${image.batch_name}&image_name=${image.image_uri}&image_type=thumbnails`} id_component_name="new_images"
		            	 img_id = {image.id} checkbox_id = {index} onChildCheckboxClick = {this.handleChildCheckboxClick.bind(this)} />
		            
		        </div>
		    </div>
		  )
		      
		})

		

		let attributes_history_tr = this.state.attributes_history.map((history, key)=>{
    		return(
    			<tr className={(key + 1) % 2 === 1 ? 'bg-white' : 'repo-edithistory-table-alt-color'} key={key}>
                    <td scope="row">{history.created_date}</td>
                    <td>{history.user}</td>
                    <td>{history.attribute.name}</td>
                    <td>{history.old_value}</td>
                    <td>{history.new_value}</td>
                </tr>
    		)
    	})

    	let batch_details_table_row = this.state.image_counts.map((variant_image_count, key)=>{
    		return(

    			<tbody className="table-borderless">
    				<tr className={(key + 1) % 2 === 1 ? 'bg-white' : ""}> 
    					<td> {variant_image_count.variant__name}</td>
    					<td>{variant_image_count.variant__batch__name}</td>
    					<td>{variant_image_count.variant__batch__source_domain__name}</td>
    					<td>{variant_image_count.variant__batch__created_date}</td>
    					<td>{variant_image_count.variant__batch__status__name}</td>
    				</tr>
    			</tbody>
    			
    		)
    	})

        
    	let comments_row = this.state.comments.map((comment, key)=>{
    		return(
    		<div>
    			{ key<1 ?
    				<div>

	    			<div className="row m-0 p-0 mb-2">
	    				<div className="comment-cell-image">
	    					<div className="comment-img-parent">
	    						{ this.state.existing_images.length > 0 
	    							?
	    								<img src={this.state.existing_images[0].image_signed_url} className="comment-image-inside-div" alt="design" />
	    							:
	    							<img src="" className="comment-image-inside-div" alt="design"/>
	    						}
	    					</div>
	    					<div className="comment-variant-name">
	    						<span className="comment-variant-name-span">{comment.variant.name}</span>
	    					</div>
	    				</div>
	    			</div>
	    			<div className="row p-0 m-0">
	    				<div className="col-md-12 p-0">
	    					<label id="comment-product-manager">{comment.requested_user_name}</label>  
	    				</div>
	    				<div className="col-md-12 p-0">
	    					<label id="comment-hd-image-request-comment">
	    						&nbsp;&nbsp;{comment.message}
	    						<label className="repo-comment-date">&nbsp;&nbsp;&nbsp;{comment.created_date}</label>
	    					</label>
	    				</div>
	    			</div>
	    			</div>
	    			:
	    			<div className="row p-0 m-0">
	    				<div className="col-md-12 p-0">
	    					<label id="comment-product-manager">{comment.requested_user_name}</label>  
	    				</div>
	    				<div className="col-md-12 p-0">
	    					<label id="comment-hd-image-request-comment">
	    						&nbsp;&nbsp;{comment.message}
	    						<label className="repo-comment-date">&nbsp;&nbsp;&nbsp;{comment.created_date}</label>
	    					</label>  
	    				</div>
	    			</div>
	    		}
			</div>
    		)
    	})
		let similar_images_list = []
		this.state.similar_designs.map((images_data,indx)=>{
			var image_keys = Object.keys(images_data)
			image_keys.map((key,inx)=>{
				var similar_images_details = images_data[key]
				similar_images_details.map((similar_image,idx)=>{
					similar_images_list.push(
						<div className="similar-cell-image d-flex flex-column justify-content-between">
							<img src={`${API_HOST}/repo/get/image/signed/url/?${similar_image.singned_url}&image_type=thumbnails`} className="similar-design-image" />
							<div className="d-flex justify-content-between">
								{/*<label className="description">Batch# <b>{similar_image.batch_name}</b></label>*/}
                                <label className="description" >Batch# <Link to={`/batch/${similar_image.batch_pk}/details`}  target="_blank" className="description" ><b>{similar_image.batch_name}</b></Link></label>
								  <label className="description">Score: <b>{similar_image.score}</b></label>
							</div>
							<div className="d-flex justify-content-between">
    							{similar_image.gd_number?
    							  <label className="description">GD# <b>{similar_image.gd_number}</b></label>:null
                                }
							     {/*<label className="description">Variant: <b>{similar_image.variant_name}</b></label>*/}
                                 {similar_image.gd_number?
                                     <label className="description" >Variant:  <Link to={`/repo/design/${similar_image.design_name_slug}/`}  target="_blank" className="description"><b>{similar_image.variant_name}</b></Link></label>
                                     :
                                     <label className="description">Variant: <Link to={`/batch/${similar_image.batch_pk}/design/${similar_image.design_id}/details/`}  target="_blank" className="description"><b>{similar_image.variant_name}</b></Link></label>
                                 }
							</div>
						  </div>)
				})	
			})
		})

	  	return (
	      <div className="relative">
	      	<div className="repo-inline-loader bg-white" ><div className="dot-elastic"></div></div>
	      	<div className="d-flex justify-content-between">
		      	<div>
		      		<label id="current-url-total-path">
		            <Link to="/repo/" className="current-url">Repo</Link> /&nbsp;
		            {this.state.image_counts.length > 0 
		            	?
		            	<Link to={`/batch/${this.state.image_counts[0].variant__batch_id}/design/${this.state.designID}/details/`} className="current-url" >
		            	{this.state.design.gd_number}
		            	</Link>
		            	:
		            	<a className="current-url" href="#">{this.state.design.gd_number}</a> 
		            }
		        	&nbsp;/&nbsp;
		            <a href="#" className="current-url">{this.state.design.name}</a></label>
	            </div>
                <div className="d-flex justify-content-between">
	            {   objectIsNotEmpty(this.state.roles_and_perms)
                    && this.state.roles_and_perms.group_perms.includes("repo.add_image")
                    ?
	            	<div>
		            	{this.state.variant_ids_user_has_to_upload_images.length > 0 
		            	?
				            <div className="status-group">
				            	<Link to={`/repo/uploadimage/design/${this.state.designName}/`}>
									<button type="button" class="btn status-button" id="upload-images">
										<i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Upload Images
									</button>
								</Link>
				            </div>
				        :
				        <div className="status-group tooltip_request_image">
								<button type="button" disabled class="btn repo-upload-button-disabled" id="upload-images">
									<i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Upload Images
								</button>
								<span className="tooltiptext"> No Pending Requests </span>
			            </div>
			        	}
			        </div>
		           	:
		           	null
		        }
		        {   objectIsNotEmpty(this.state.roles_and_perms)
                    && this.state.roles_and_perms.group_perms.includes("repo.add_imagerequest") 
                    && this.state.has_received_comments_response?
		        	<div>{this.state.no_of_accepted_variants_for_new_request > 0 ?
				      	<div className="status-group">
				      	    <button type="button" className="btn status-button ml-2" id="request-images" 
				      	    	 data-toggle="modal" data-target="#request-images-modal">
				      	      <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
				      	      Request Images 
				      	    </button>
				      	    <div className="modal fade" id="request-images-modal" role="dialog" data-backdrop="static" data-keyboard="false">
				      	    	<form method="post" action="#" id="#" onSubmit={this.handleSubmit}>
				      	      <div className="modal-dialog modal-dialog-centered" id="request-images-model-dialog">
				      	        <div className="modal-content pl-4 pr-4" id="request-images-model-content">
				      	            <div className="modal-header dashed-row-bottom pl-0 pr-0">
					      	            <div>
					      	              <span id="request-images-confirm-text">
					      	                Request Images
					      	              </span>  
					      	            </div>
					      	            <div>
					      	              <button type="button" className="close" data-dismiss="modal">×</button>
					      	            </div>
				      	            </div>
									<div className="modal-body pl-0 pr-0">
										<div class="form-group">
										  <label for="slected_variant">Select Variant</label>
										  <select class="form-control" id="comment_selected_variant" required value={this.state.comment_selected_variant_id} onChange={this.handleCommentVariantSelected.bind(this)}>
										    <option value="-1">
										    	---------
										    </option>
										    {variant_names_comment_select}
										  </select>
										</div>
										<div className="row m-0">
										  <div className="col-md-12 p-0">
										    <div>
										      <label id="request-images-text">Type your message</label>  
										    </div>
										  </div>
										</div>
										<div className="row p-0 m-0">
										  <textarea className="form-control" value={this.state.request_comment} onChange={this.handleTextarea.bind(this)} id="modal-request-images-comment" name="modal_request_images_comment" placeholder="Type your message" required></textarea>
										</div>
									</div>
				      	          	<div className="modal-footer justify-content-between pl-0 pr-0 dashed-row-top">
					      	            <button type="button" className="btn  pl-4 pr-4 m-0" id="cancel-modal" data-dismiss="modal">
					      	              Cancel
					      	            </button>
					      	            <button type="submit" id="request-img-btn" className="btn btn-dark nohover pl-5 pr-5 m-0">
					      	              Request Images
					      	            </button>
				      	          	</div>
				      	        </div>
				      	      </div>
				      	      </form>
				      	    </div>
				      	</div>
				      	: 
				      	<div className="status-group tooltip_request_image">
				      		<button type="button" className="btn repo-status-button-disabled ml-2" id="request-images" disabled>
				      		  <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
				      		   Request Images 
				      		</button>
				      		<span className="tooltiptext"> Previous request in process</span>
				      	</div>
				    }</div>
			      	:
			      	null
			    }
                </div>
	      	</div>

	      	<div className="row no-gutters mt-3">
	      	  <div className="col-md-3 mr-2">
	      	    <div id="product-info-card">
	      	      <div className="d-flex justify-content-between">
	      	        <div className="tooltip_gd_number">
	      	          <span className="navigation-text-font text-left">{this.state.design.gd_number}
	      	          </span>
	      	          <span className="tooltiptext">Global Design-No</span>
	      	        </div>
	      	        <div>
                        {   this.state.designID>0 && objectIsNotEmpty(this.state.roles_and_perms)
                            && this.state.roles_and_perms.group_perms.includes("repo.change_design") ?
    	      	            <label className="switch">
    	      	                <input type="checkbox" onChange={this.handleToggleState.bind(this)} checked={this.state.design_active_state} />
    	      	                <span className="slider round"></span>
    	      	            </label>
                            :null
                        }
	      	        </div>
	      	      </div>
	      	      <div className="design-info">
	      	        <span id="repo-design-name-product-info-card" className="text-left">{this.state.design.name}</span>
	      	      </div>
	      	      <div className="design-info">
	      	        <span id="vendor-design-number" className="text-left">VDI # {this.state.design.vdin}</span>  
	      	      </div>
	      	      <div className="d-flex justify-content-between design-info">
	      	        <div className="design-info ml-2">
	      	        	{ this.state.image_counts.length > 0
	      	        		?
	      	          		<label id="variants" className= "text-center">Variants</label>
	      	          		:null
	      	        	}
	      	        </div>
	      	        <div>
	      	        	{  objectIsNotEmpty(this.state.roles_and_perms)
                            && this.state.roles_and_perms.group_perms.includes("repo.add_variant")
                            && this.state.show_add_variant_btn
                            ?
                            <button type="button" className="btn" onClick={this.changeStateAddVariantModal.bind(this)}>
	      	        		  <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
	      	        		    Add Variant
	      	        		</button>
                            :null
	      	        	}
	      	        </div>
	      	      </div>
	      	    {this.state.image_counts.length > 0
	      	    	?
	      	    	<table className="table table-bordered"  id="table_images_count_by_variant_name">
	      	    	  	<thead className="table-borderless">
	      	    	  		<tr>
	      	    	  			<th scope="col">Name</th>
	      	    	    		<th scope="col">Images</th>
	      	    	  		</tr>
	      	    	 	</thead>
	      	    	  	<tbody className="table-borderless">
	      	    	    	{image_counts_row}
	      	    	 	 </tbody>
	      	    	</table>
	      	    	:
	      	    	null
	      	    }
	      	    </div>
	      	  </div>
	      	  <div className="col-md sub-content">
	      	    <div className="row">
	      	      <div className="col-md-12 no-gutters">
	      	        <ul className="nav nav-tabs p-0" role="tablist" id="tablist">
	      	          <li className="nav-item m-0">
	      	            <a className="nav-link p-0 active" data-toggle="tab" id="navtab-tab-content-existing-images" href="#tab-content-existing-images">
	      	           		{this.state.existing_images.length > 0 ?
	      	           			<div className="repo-tab-similar-design-menu-group">
	      	           			  <span id="repo-tab-existing-images">Images {this.state.existing_images.length}</span>
	      	           			</div>
	      	           			:
	      	           			<div className="repo-tab-similar-design-menu-group">
	      	           			  <span id="repo-tab-existing-images">Images </span>
	      	           			</div>
	      	           		}
	      	              
	      	            </a>
	      	          </li>
						<li className="nav-item m-0">
	      	            <a className="nav-link p-0" data-toggle="tab" id="navtab-tab-content-similar-images" href="#tab-content-similar-images">
	      	           			<div className="repo-tab-similar-design-menu-group">
	      	           			  <span id="repo-tab-existing-images">Similar Designs</span>
	      	           			</div>      	           			
	      	            </a>
	      	          </li>
	      	          	{    objectIsNotEmpty(this.state.roles_and_perms)
                            && (this.state.roles_and_perms.group_perms.includes("repo.change_image")
                                )
                            && this.state.new_upload_images_for_product_manager.length > 0?
	      	          		<li className="nav-item m-0">
	      	          				<a className="nav-link p-0" data-toggle="tab"  href="#tab-content-new-images">
	      	          				  	<div className="repo-tab-similar-design-menu-group">
		  	      	                  		<span id="repo-tab-new-images">
	        		                            New Images <span id="new-images-count">{this.state.new_upload_images_for_product_manager.length}</span>
	        		                            <sup> <span class="dot"></span></sup>
	        		                        </span>
	        		                    </div>
	        		                </a>
	      	          		</li>
		      	          	: null
		      	        }
		      	       	{   objectIsNotEmpty(this.state.roles_and_perms)
                            && this.state.roles_and_perms.group_perms.includes("repo.add_image") 
                            && this.state.new_upload_images_for_studio_manager.length>0?
			      	          <li className="nav-item m-0">
			      	            <a className="nav-link p-0" data-toggle="tab"  href="#tab-content-uploaded-images">
			      	              <div className="repo-tab-similar-design-menu-group">
			      	              	    <span id="uploaded-images" className="m-0">
      			      	                	Uploaded Images
      		                            	<span id="new-images-count"> &nbsp;{this.state.new_upload_images_for_studio_manager.length}</span>
      		                            	<sup> <span class="dot "></span></sup>
      		                          	</span>
			      	              </div>
			      	            </a>
			      	          </li>
			      	        :
			      	        null
			      	    }
                        {
                            objectIsNotEmpty(this.state.roles_and_perms)
                            && (this.state.roles_and_perms.group_perms.includes("repo.view_variantattribute")
                                ||this.state.roles_and_perms.group_perms.includes("repo.add_variantattribute")
                                ||this.state.roles_and_perms.group_perms.includes("repo.change_variantattribute")
                                ||this.state.roles_and_perms.group_perms.includes("repo.delete_variantattribute")
                            )
                            ?
                            <li className="nav-item m-0">
                              <a className="nav-link p-0" onClick={event => this.getVariantAttributes(event)} data-toggle="tab" href="#tab-content-attribute">
                                <div className="repo-tab-similar-design-menu-group">
                                  <span id="repo-tab-attribute">Attributes</span>
                                </div>
                              </a>
                            </li>
                            :
                            null

                        }
                        {
                            objectIsNotEmpty(this.state.roles_and_perms)
                            && this.state.roles_and_perms.group_perms.includes("repo.view_attributehistory")
                            ?
                            <li className="nav-item m-0">
                              <a className="nav-link p-0" onClick={event => this.getAttributeEditHistory(event,this.state.selected_variant_id)} data-toggle="tab" href="#tab-content-edit-history">
                                <div className="repo-tab-similar-design-menu-group">
                                  <span id="repo-tab-edit-history">Edit History</span>
                                </div>
                              </a>
                            </li>
                            :null

                        }
                        {
                            objectIsNotEmpty(this.state.roles_and_perms)
                            && this.state.roles_and_perms.group_perms.includes("batches.view_batch")
                            ?
                            <li className="nav-item m-0">
                                {this.state.image_counts.length > 0 
                                    ?
                                    <a className="nav-link p-0" data-toggle="tab" href="#tab-content-batch-details">
                                      <div className="repo-tab-similar-design-menu-group">
                                        <span id="repo-tab-batch-details">Batch Details</span>
                                      </div>
                                    </a>
                                    :
                                    <a className="nav-link p-0" data-toggle="tab" href="#tab-content-batch-details">
                                      <div className="repo-tab-similar-design-menu-group">
                                        <span id="repo-tab-batch-details">Batch Details</span>
                                      </div>
                                    </a>

                                }
                            </li>
                            :null
                        }
                        {
                            objectIsNotEmpty(this.state.roles_and_perms)
                            && (this.state.roles_and_perms.group_perms.includes("repo.view_imagerequest"))
                            ?
                            <li className="nav-item m-0">
                              <a className="nav-link p-0" data-toggle="tab" onClick={event => this.getcomments(event)} href="#tab-content-comment-details">
                                <div className="repo-tab-similar-design-menu-group">
                                  <span id="repo-tab-comment-details">Comments</span>
                                </div>
                              </a>
                            </li>
                            :null    
                        }
	      	        </ul>
	      	      </div>
	      	    </div>
	      	    <div className="row">
	      	      <div className="col-md-12 no-gutters pl-0 pr-0">
	      	        <div className="tab-content">
	      	          <div id="tab-content-existing-images" className="row container tab-pane active p-0 m-0">
	      	            <div className="row p-0 m-0">
	      	            	<div className="repo-existing-images-inline-loader" style={{"display": "none"}} >
	      	            		<div className="dot-elastic">
	      	            		</div>
	      	            	</div>
	      	            </div>
	      	            { this.state.existing_images.length > 0
	      	            	?
	      	            	<div className="row p-0 m-0"> 
	      	            		{existing_images_row}
	      	            	</div>
	      	            	:
	      	            	<div className="row p-3 m-3">
	      	            	<h5>No images</h5>
	      	            	</div>
	      	            }

	      	            { ((this.state.existing_images.length > 0) && ((this.state.modal_index>-1)) )?
	      	              	<div className="modal fade" id="repo-myModal" role="dialog" data-backdrop="static" data-keyboard="false" >
	      	              	  <div className="modal-dialog modal-dialog-centered" id="repo-image-preview-model-dialog">
	      	              	    <div className="modal-content pl-4 pr-4 pt-2 pb-2">
	      	              	      <div className="d-flex justify-content-between pb-2 dashed-row-bottom">
	      	              	        <div >
	      	              	          <span id="design-preview-text">{this.state.existing_images[this.state.modal_index].variant_name}</span>  
	      	              	        </div>
	      	              	        <div>
	      	              	          <button type="button" className="close text-center" data-dismiss="modal">
	      	              	            &times;
	      	              	          </button>
	      	              	        </div>
	      	              	      </div>
	      	              	      	<div className="row">
		      	              	      	<div className="col-lg-1 repo-model-center text-right" >
		      	              	      		<button className="btn carousel-controls-icon-style" id="repo-myModal-left-navigation-btn" disabled={this.state.modal_index>0 ? false:true} onClick={this.prevImage.bind(this,1)} >
		      	              	      		      <i className="fas fa-chevron-circle-left"></i>
		      	              	      		</button>
		      	              	      	</div>
			          	                	<div className="col-lg-10 pt-4 pb-4 repo-preview-img-parent">
			          	                		{ ((this.state.existing_images) && (this.state.modal_index>-1) ) ?
			          	                	      <img className="image repo-preview-image-inside-div mx-auto d-block" 
													src={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${this.state.existing_images[this.state.modal_index].bucket_name}&batch_name=${this.state.existing_images[this.state.modal_index].batch_name}&image_name=${this.state.existing_images[this.state.modal_index].image_uri}`}  
													 id="repo-modal-main-image" alt="main-design" />
			          	                	      : <img className="image image-inside-div" alt="missing"/>
			          	                		}
			          	                	</div>
			          	                
			          	                <div className="col-lg-1 repo-model-center text-left" >
			          	                	<button className="btn carousel-controls-icon-style" id="repo-myModal-right-navigation-btn" disabled={((this.state.modal_index>-1) && (this.state.existing_images.length > this.state.modal_index+1)) ? false:true} onClick={this.nextImage.bind(this,2)}>
			          	                	      <i className="fas fa-chevron-circle-right"></i>
			          	                	</button>
			          	                </div>
		          	                </div> 
	      	              	    </div>
	      	              	  </div>
	      	              	</div>
	      	              	:null }
	      	          </div>
						<div className='row container tab-pane  p-0 m-0 similar-design-content' id="tab-content-similar-images">
							<div className="inline-loader" ><div className="dot-elastic"></div></div>
							{this.state.showSimilarDesigns?'':
								<div class="btn-group btn-group-sm pl-2 pb-2" role="group" aria-label="...">
									<button type="button" className={this.state.selected_type == 'batch'?"btn btn-outline-secondary group-similar-designs active":"btn btn-outline-secondary group-similar-designs"} onClick={event => this.get_batch_or_repo_similar_designs(event,'batch')}>Batch</button>
                                    <button type="button" className={this.state.selected_type == 'repo'?"btn btn-outline-secondary group-similar-designs active":"btn btn-outline-secondary group-similar-designs"} onClick={event => this.get_batch_or_repo_similar_designs(event,'repo')}>Repo</button>                                           
                                </div>
							}
                            <div className="row d-flex justify-content-start similar-design-image-group" >
								{this.state.showSimilarDesigns?
									<div className=" col-md-12 text-center" style={{marginTop:'140px'}}>
										<button className="btn btn-dark" onClick={event => this.get_similar_designs(event)}>
											Get Similar Designs
                                        </button>
									</div>:''
								}
								{this.state.showSimilarDesigns?'':similar_images_list.length > 0?similar_images_list:
									<div className=" col-md-12 text-center" style={{marginTop:'140px'}}>
										<span>No similar designs found</span>
                                    </div>
                                }
                            </div>
						</div>
                       {    objectIsNotEmpty(this.state.roles_and_perms)
                            && this.state.roles_and_perms.group_perms.includes("repo.change_image")
                            && this.state.new_upload_images_for_product_manager.length > 0
                            ?
    	      	          <div id="tab-content-new-images" className="row container tab-pane p-0 m-0">
    	      	          	<div className="inline-loader"> <div className="dot-elastic"></div> </div>
    	      	            <div className="row m-3 p-0">
    	      	            	{this.state.new_upload_images_for_product_manager.length > 0 ?
    	      	              <div className="col-md-6 m-0 p-0 d-flex">
    	      	                <div>

    	      	                  <input type="checkbox" checked={this.state.all_new_images_selected}  onClick={this.handleSelectAllImagesChecked.bind(this)} className="select-all-status-change-checkbox"/>
    	      	                </div>
    	      	                <div className="pl-3">
    	      	                  <button type="button" disabled className="btn p-0">Select All</button>
    	      	                </div>
    	      	                <div className="ml-4 nav-item-breaker"></div>
    	      	                <div className="pl-3 pr-3">
    	      	                  <button type="button" onClick={this.approveSelectedImages.bind(this)} className="btn p-0">Approve</button>
    	      	                </div>
    	      	                <div className="ml-4">
    	      	                  <button type="button" onClick={this.rejectSelectedImages.bind(this)} className="btn p-0">Reject</button>
    	      	                </div>
    	      	              </div>
    	      	              : null}
    	      	            </div>
    	      	            <div className="row p-0 m-0">
    	      	              {new_images_row}
    	      	            </div>
    	      	          </div>
                          :null
                        }
                        {
                            objectIsNotEmpty(this.state.roles_and_perms)
                            && (this.state.roles_and_perms.group_perms.includes("repo.view_variantattribute")
                                ||this.state.roles_and_perms.group_perms.includes("repo.add_variantattribute")
                                ||this.state.roles_and_perms.group_perms.includes("repo.change_variantattribute")
                                ||this.state.roles_and_perms.group_perms.includes("repo.delete_variantattribute")
                            )
                            ?
                            <div id="tab-content-attribute" className="row container tab-pane fade p-0 m-0 attr-inline-loader">
                              <div className="inline-loader" ><div className="dot-elastic"></div></div> 
                              {
                                  this.state.showAttributeTab?<VariantAttribute key={'va'+this.state.selected_variant_id} selected_variant_id={this.state.selected_variant_id===-1 ? this.state.image_counts[0].variant_id:this.state.selected_variant_id } designID={this.state.designID} dgs_perms={this.state.roles_and_perms} />:null
                              }
                            </div>
                            :null
                        }
                        {
                            objectIsNotEmpty(this.state.roles_and_perms)
                            && this.state.roles_and_perms.group_perms.includes("repo.view_attributehistory")
                            ?
                            <div id="tab-content-edit-history" className="row container tab-pane fade p-0 m-0 history-inline-loader">
                                <div className="inline-loader" >
                                    <div className="dot-elastic"></div>
                                </div> 
                                <div className="col-md-12">
                                    <div className="attribute-background">
                                        { this.state.attributes_history.length > 0 ?
                                            <table className="table table-striped table-bordered w-100 text-left" id="repo-edit-history-table">
                                                <thead className=" bg-white head">
                                                    <th scope="col">Date & Time</th>
                                                    <th scope="col">User</th>
                                                    <th scope="col">Attribute</th>
                                                    <th>Old Value</th>
                                                    <th>New Value</th>
                                                </thead>
                                                <tbody className="text-secondary small">
                                                    { attributes_history_tr }
                                                </tbody>
                                            </table> :
                                            <div className="text-center">No Changes found</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            :null
                        }
	      	          <div id="tab-content-batch-details" className="row container tab-pane fade p-0 m-0">
	      	            <div className="col-md-12">
	      	            	<div className="inline-loader" >
	      	            		<div className="dot-elastic">
	      	            		</div>
	      	            	</div>
	      	            	{this.state.image_counts.length > 0 
	      	            		?
			      	              <div className="batch-background">
			      	                <table className="table table-bordered table-striped  w-100 text-left" id="batch-table">
			      	                  <thead className="table-borderless">
			      	                    <tr>
			      	                    	<th scope="col">Variant</th>
			      	                    	<th scope="col">Batch</th>
			      	                    	<th scope="col">Location</th>
			      	                    	<th scope="col">Date & Time</th>
			      	                    	<th scope="col">Status</th>
			      	                  	</tr>
			      	                  </thead>
			      	                  
			      	                  {batch_details_table_row}
			      	                </table>
			      	              </div>
			      	             :
			      	             <div className="batch-background">
			      	               <div className="row m-0 p-0">
			      	               	<div className="row m-3 p-0"><h5>Batch details not available</h5></div>
			      	               </div>
			      	             </div>
			      	        }
	      	            </div>
	      	          </div>
	      	          <div id="tab-content-comment-details" className="row container tab-pane fade p-0 m-0">
	      	            <div className="row p-0 m-0">
                            <div className="repo-comments-inline-loader" >
                                <div className="dot-elastic">
                                </div>
                            </div>
                        </div>
                        <div className="row p-0 m-0">
	      	            	{this.state.comments.length > 0 ?

	      	            		<div>
	      	            			{comments_row}
	      	            		</div>
	      	            		:
	      	            		<div className="row m-0 p-0">
	      	            			<div className="row m-3 p-0"><h5>No comments</h5></div>
	      	            		</div>
	      	            	}
	      	            	
	      	            </div>
	      	          </div>
                      {   objectIsNotEmpty(this.state.roles_and_perms)
                            && this.state.roles_and_perms.group_perms.includes("repo.add_image") 
                            && this.state.new_upload_images_for_studio_manager.length>0
                            ?
                            <div id="tab-content-uploaded-images" className="row container tab-pane fade p-0 m-0">

                            <div className="row p-0 m-0">
                                <div className="col-md-12">
                                	<div className="inline-loader" >
                                		<div className="dot-elastic">
                                		</div>
                                	</div>
                                </div>
                            </div>
                                {this.state.new_upload_images_for_studio_manager.length > 0 ?
                                	<div className="row m-0 p-0">
                                		{new_uploaded_images_row}
                                	</div>
                                	:
                                	null
                                } 
                            </div>
                            :null
                        }

	      	        </div>
	      	      </div>
	      	    </div>
	      	  </div>
	      	</div>
            {
                this.state.show_add_variant_modal
                && objectIsNotEmpty(this.state.roles_and_perms)
                && this.state.roles_and_perms.group_perms.includes("repo.add_variant")
                && this.state.show_add_variant_btn
                ?
                <div>
                    <div className="modal fade" id="approve-as-variant-model" aria-modal="true" role="dialog" data-backdrop="static" data-keyboard="false" >
                        <div className="modal-dialog modal-dialog-centered" id="approve-as-variant-model-dialog">
                          <div className="modal-content pl-4 pr-4" id="approve-as-variant-model-content">
                            <div className="modal-header dashed-row-bottom pl-0 pr-0">
                              <div>
                                <span id="variant-confirm-text">
                                  Do you want to add a variant ?
                                </span>  
                              </div>
                              <div>
                                {/*<button type="button" className="close" data-dismiss="modal">×</button>*/}
                              </div>
                            </div>
                            <div className="modal-body pl-0 pr-0">
                              <div className="row pb-3 m-0">
                                <div className="col-md-6 p-0">
                                  <div className="mr-4">
                                  </div>
                                </div>
                                <div className="col-md-6 p-0">
                                  <div>
                                    <label className="color-red pr-2">*</label>
                                    <label id="modal-variant-name-text">Variant Name</label>
                                  </div>
                                  <div className="mr-4">
                                    <input type="text" className="form-control" placeholder="Type your Variant Name" autocomplete="off" id="new-variant-name"/>
                                  </div>
                                </div>
                              </div>
                              <div className="row p-0 m-0">
                                <div className="p-0 col-md-12">
                                {add_variant_images}
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer justify-content-between pl-0 pr-0 dashed-row-top">
                              <button type="button" className="btn  pl-4 pr-4 m-0" id="cancel-modal" onClick={this.changeStateAddVariantModal.bind(this)} data-dismiss="modal">
                                Cancel
                              </button>
                              <button type="button" id="repo-addvariant" className="btn btn-dark nohover-btn pl-5 pr-5 m-0" onClick={this.saveApproveAsVariant.bind(this)}>
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
	      </div>
	  );
	}
}


export default withRouter(RepoDesignDetails);
