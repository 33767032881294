import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery'
import ErrorHandling from '../../ErrorHandler'
import {API_HOST} from '../../Settings.js'
import Paginator from '../../Paginator.js'
import axios from 'axios';
import '../batches/batches.css'
import './jobs_index.css'
import DownloadIcon from '../../images/download.svg'
import Cookies from "universal-cookie";
const cookies = new Cookies();


class Jobs extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      logs_open_list: [],
      page_data: {
        page_number: 1,
        page_size: 10,
        no_of_pages: 0,
        total: 0
      },
      order_by: {
        created_date: '-created_date'
      },
      interval_count:0
    }
  }


  getData = (page_number, page_size, order_by, loader=true, reset_logs_open_list=true) =>{
      /*const auth_token = "Token "+localStorage.malbar_token*/
      if(loader){
            $(".collapse").collapse('hide');
            $('.inline-loader').fadeIn()
      }
      const auth_token = "Token "+cookies.get("malabar_token")
      axios.defaults.headers.get['Authorization'] =auth_token
      // if(loader){
      // $('.inline-loader').fadeIn()
      // }
      axios.get(`${API_HOST}/jobs/list/?page_number=${page_number}&page_size=${page_size}&order_by=${order_by}`,
        ).then(response => {
        if(response.status === 200){
            var logs_open_list = this.state.logs_open_list
            if (reset_logs_open_list){
                logs_open_list = []
            }
          this.setState({
            jobs: response.data.jobs,
            logs_open_list: logs_open_list,
            page_data: {
              ...this.state.page_data,
              total: response.data.total,
              no_of_pages: response.data.no_of_pages,
            }
          })
          $('.inline-loader').fadeOut('slow')

        }
      }).catch(response => {
          ErrorHandling(response)
      });
  }

  onPageSizeChange = (event) =>{
    this.setState({
      page_data: {...this.state.page_data, page_size: parseInt(event.target.value), page_number: 1}
    })
    this.getData(1, event.target.value, this.state.order_by.created_date)
  }

  onPageNumberChange = (event, page_number) =>{
    this.setState({
      page_data: {...this.state.page_data, page_number: page_number}
    })
    this.getData(page_number, this.state.page_data.page_size, this.state.order_by.created_date)
  }

    handleCollapse = (event, job_id, job_number) =>{
        var logs_open_list = this.state.logs_open_list
        const index = logs_open_list.indexOf(job_id);
        if (index > -1) {
          logs_open_list.splice(index, 1);
        }else{
            logs_open_list.push(job_id)
        }
        this.setState({
            "logs_open_list": logs_open_list
        })
    }

  downloadErrorFile = (event, job_id) =>{
    /*const auth_token = "Token "+localStorage.malbar_token*/
    const auth_token = "Token "+cookies.get("malabar_token")
    axios.defaults.headers.post['Authorization'] =auth_token
    let form_data = new FormData();
    form_data.append('job_id',job_id);

    axios.post(`${API_HOST}/jobs/errorfile/download/`, form_data,{
            responseType: 'arraybuffer',
        }).then(res => {
            var the_response_data = res.data
            var blob = new Blob(
                    [the_response_data]
                );
            var url = URL.createObjectURL(blob);
                   var a = document.createElement('a');
                   document.body.appendChild(a);
                   a.style = "display: none";
                   a.href = url;
                   a.download = "report.xlsx";
                   a.click();

                   setTimeout(function() {
                       URL.revokeObjectURL(url);
                       document.body.removeChild(a);
                   }, 100);
        }).catch(err => {
        })
  }
  
  componentDidMount(){
    $('.inline-loader').fadeIn()
    $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
    $('.main-menu-sub-group').find('#jobs-nav-title').addClass('active-navbar')
    var uploaded_job_id = -1;
    if(localStorage.getItem("uploaded_job_id")){
        uploaded_job_id=Number(localStorage.getItem("uploaded_job_id"))
        var logs_open_list = this.state.logs_open_list
        if(logs_open_list.indexOf(uploaded_job_id)<0){
            logs_open_list.push(uploaded_job_id)
            this.setState({
                logs_open_list: logs_open_list
            })
        }
    }
    this.getData(this.state.page_data.page_number, this.state.page_data.page_size, this.state.order_by.created_date, true, false)
    this.getJobData = setInterval(
      () => {
        this.setState((prevState, props) => ({
          interval_count: prevState.interval_count + 1
        }));
        if(this.state.interval_count>100){
            clearInterval(this.getJobData);
        }
        this.getData(this.state.page_data.page_number, this.state.page_data.page_size, this.state.order_by.created_date, false, false)
      },
      30000
    );
  }
  componentWillUnmount() {
    clearInterval(this.getJobData);
  }

  render(){
    // var uploaded_job_id = -1;
    // if(localStorage.getItem("uploaded_job_id")){
    //     uploaded_job_id=Number(localStorage.getItem("uploaded_job_id"))
    // }
    let jobs_tr = this.state.jobs.map((job, key)=>{
        // var has_collapse_show = false
        // if ((uploaded_job_id>0) && (job.id===uploaded_job_id)){
        //     has_collapse_show = true
        // }
        return(
          [<tr key={"key_"+job.job_number}>
            <td>{job.job_number}</td>
            <td>{job.created_date}</td>
            <td>{job.status.name}</td>
            <td>{job.job_types.name}</td>
            <td className="batch-url">{
                    job.batch && job.status.name_slug==="batch-created"
                    ?
                    <Link to={`/batch/${job.batch.id}/details/`}>{job.batch.name}</Link>
                    :
                    '-'
                }
            </td>
            {   this.state.logs_open_list.includes(job.id)
                ?
                <td data-toggle="collapse show" onClick={event => this.handleCollapse(event,job.id, job.job_number)} data-target={'#'+job.job_number}  style={{'cursor': 'pointer'}}>See Logs <a  className="batch-filter"> <i className="fa fa-angle-down" aria-hidden="true"></i></a></td>
                :
                <td  onClick={event => this.handleCollapse(event,job.id, job.job_number)}   style={{'cursor': 'pointer'}}>See Logs <a  className="batch-filter"> <i className="fa fa-angle-down" aria-hidden="true"></i></a></td>
            }
          </tr>,
          <tr key={"log_"+job.job_number} id={job.job_number}  className={this.state.logs_open_list.includes(job.id) ? "collapse show":"collapse" }>
            <td colSpan="6"> 
                <pre className="text-left">{job.logs_formated.replace(/^\s*[\r\n]/gm, '')}
                    {job.batch_upload_id && job.logs_formated.includes('Failed in Validation Stage')?
                        <button type="button" className="btn ml-2 mr-2 mb-1" onClick={event => this.downloadErrorFile(event,job.id)}>
                            <img src={DownloadIcon} className="" width="20px" height="20px" alt="download-icon" />
                        </button>
                        :null
                    }
                </pre>
            </td>
          </tr>
        ]
        )
    })
    return(
      <div>     
        { this.state.jobs.length > 0 ?
          <div className="batch-main-content">
            <div className="inline-loader" ><div className="dot-elastic"></div></div>
            <div className="d-flex justify-content-end">
              <label>Show&nbsp;
                <select onChange={event => this.onPageSizeChange(event)}
                  value={this.state.page_data.page_size}>
                  <option value="1">1</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select> entries
              </label>
            </div>   
            <div id="batch-table">    
              <table className="table table-bordered " id="jobs-table">
               <thead>
                 <tr>
                  <th>Job ID</th>
                  <th>Date & Time </th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Batch</th>
                  <th>Logs</th>
                </tr>
                </thead>

                <tbody className="thead-light">
                  {/* <tr id="table-first-row" > <td colSpan="7" style={{margin: '0px', padding: '0px'}}></td></tr> */}
                  {jobs_tr}
                </tbody>
              </table>
            </div>
            {this.state.page_data.total > 0 ?
              <Paginator page_data={this.state.page_data}
                onPageNumberChange={this.onPageNumberChange}/> : this.state.page_data.total
            }
          </div>:
          <div className="batch-main-content">
            <div className="inline-loader" ><div className="dot-elastic"></div></div>
            <div style={{ textAlign: 'center', marginTop: '20px'}}>No Jobs found</div>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(Jobs);