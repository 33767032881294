import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select'
import { API_HOST } from '../../Settings.js'
import ArrowLeft from '../../images/arrow-left.svg'
import ArrowRight from '../../images/arrow-right.svg'
import ThreeDots from '../../images/three-dots.svg'
import DesignArrowLeft from '../../images/design-arrow-left.svg'
import DesignArrowRight from '../../images/design-arrow-right.svg'
import ImagePlaceholder from '../../images/image_placeholder.jpg'
import notify from '../../CommonFunctions.js'
import ErrorHandling from '../../ErrorHandler'
import $, { event } from 'jquery'
import axios from 'axios';
import  '../batches/design_details.css'
import {objectIsNotEmpty} from '../customHelperFunctions'
import Cookies from "universal-cookie";
const cookies = new Cookies();



class FindSimilarImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_img:null,
            image_name:null,
            repo_similar_images:[],
            batch_similar_images:[],
            batch_or_repo_similar_images:[],
            selected_type:'repo',
            uploaded_image_file:null
        }
        
    }
    
    componentDidMount() {
        $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
        $('.main-menu-sub-group').find('#find-group').find('.circle-title').addClass('active-navbar')
        // this.getUserPermissions()    
        $('.inline-loader').fadeOut()
    }   
    
    onChangeImage = e => {
        if (e.target.files[0]) {
            console.log("picture: ", e.target.files);
            // setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                this.setState({
                    selected_img:reader.result,
                    image_name:e.target.files[0].name,
                    uploaded_image_file:e.target.files[0]
                })
               
            });
            reader.readAsDataURL(e.target.files[0]);
            this.getSimilarImages(e, e.target.files[0])
          }
          
    };
    getSimilarImages = (event, image_file) =>{
        const auth_token = "Token "+cookies.get("malabar_token")
        var formdata = new FormData();
        formdata.append('image',image_file)
        axios.defaults.headers.post['Authorization'] =auth_token
        $('.inline-loader').fadeIn()
        axios.post(`${API_HOST}/repo/get/designs/similar/`,formdata,
        {   headers: {'content-type': 'multipart/form-data'} } 
            ).then(response => {
                if(response.status == 200){
                    this.setState({
                        repo_similar_images:response.data.repo.data,
                        batch_similar_images:response.data.batch.data,
                        batch_or_repo_similar_images:response.data.repo.data,
                        selected_type:'repo',
                    })
                }
                $('.inline-loader').fadeOut('slow')
            }).catch(response => {
                $('.inline-loader').fadeOut('slow')
                ErrorHandling(response)
            });
        
    }
    get_batch_or_repo_similar_designs = (event, btn_type) =>{
        event.preventDefault()
        if(btn_type == 'repo'){
            this.setState({
                batch_or_repo_similar_images:this.state.repo_similar_images,
                selected_type:btn_type

            })
        }else{
            this.setState({
                batch_or_repo_similar_images:this.state.batch_similar_images,
                selected_type:btn_type
            })
        }
    }

    render() { 
        var similar_images_list =[]
         this.state.batch_or_repo_similar_images.map((respone_data,indx) =>{
             respone_data.similar_images.map((data,i)=>{
                similar_images_list.push(
                    <div className="similar-cell-image d-flex flex-column justify-content-between">
                                <img src={data.url+"&image_type=thumbnails"} className="similar-design-image" />
                                <div className="d-flex justify-content-between">
                                    {/*<label className="description">Batch# <b>{respone_data.batch.batch_id}</b></label>*/}
                                    <label className="description" >Batch# <Link to={`/batch/${respone_data.batch_pk}/details`}  target="_blank" className="description" ><b>{respone_data.batch.batch_id}</b></Link></label>
                                    <label className="description">Score: <b>{parseFloat(data.score).toFixed(2)}</b></label>
                                </div>
                                <div className="d-flex justify-content-between">
                                {/*<label className="description">Name: <b>{respone_data.name}</b></label>*/}
                                {respone_data.global_design_number?
                                    <label className="description" >Name: <Link to={`/repo/design/${respone_data.design_name_slug}/`}  target="_blank" className="description"><b>{respone_data.name}</b></Link></label>
                                    :
                                    <label className="description">Name: <Link to={`/batch/${respone_data.batch_pk}/design/${respone_data.design_pk}/details/`}  target="_blank" className="description"><b>{respone_data.name}</b></Link></label>
                                }
                                {respone_data.global_design_number?
                                    <label className="description">GD# <b>{respone_data.global_design_number}</b></label>:null
                                }                                    
                                </div>
                            </div>
                )
             })
            
        })
        return (
          <div className="variant-main-content">
              <div className="inline-loader" ><div className="dot-elastic"></div></div> 
            <div>
                <div>
                    <label id="current-url-total-path" style={{marginBottom:'15px'}}>
	            	<Link to="#" onClick={()=>{window.location = '.'}} className="current-url" >Search</Link> 
                    {this.state.image_name?
                            <span>&nbsp;/&nbsp;</span>:null
                    }
                    {this.state.image_name?<Link to="#" className="current-url" >{this.state.image_name}</Link>:null}
                    </label>
                </div>               
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <div id="product-info-card">                  
                    
                      <div id="main-design-image-div" className="m-0">
                        <button type="button" className="btn m-0 p-0" data-toggle="modal" data-target="#design-preview-model" id="main-design-image-btn"   >
                          <img src={this.state.selected_img? this.state.selected_img:ImagePlaceholder}  className="main-design-image" id="main-design-image-id"/>
                        </button>
                        <input type="file" accept="image/*" onChange={event => this.onChangeImage(event)} style={{fontSize:'12px',marginLeft:'20px'}}></input>
                      </div>              
                    </div>
                  </div>
                  <div className="col-md-9 design-data-tabs">
                        <div className="row">
                            <div className="col-md-12 no-gutters">
                                <ul className="nav nav-tabs" role="tablist" id="tablist">                                 
                                    <li className="nav-item m-0">
                                        <a className="nav-link p-0 active design-nav-link" data-toggle="tab" href="#tab-content-similar-design">
                                            <div className="batches-tab-similar-design-menu-group">
                                                <span className="batches-tab-span-text">Similar Images</span>
                                            </div>
                                        </a>
                                    </li>                                       
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 no-gutters">
                                <div className="tab-content">                                    
                                    <div id="tab-content-similar-design" className="row container tab-pane active p-0 m-0 similar-design-content relative">
                                        {/* <div className="inline-loader" ><div className="dot-elastic"></div></div> */}
                                        {this.state.image_name?
                                        [<div class="btn-group btn-group-sm pl-2 pb-2" role="group" aria-label="...">
                                            <button type="button" className={this.state.selected_type == 'batch'?"btn btn-outline-secondary group-similar-designs active":"btn btn-outline-secondary group-similar-designs"}  onClick={event => this.get_batch_or_repo_similar_designs(event,'batch')}>Batch</button>
                                            <button type="button" className={this.state.selected_type == 'repo'?"btn btn-outline-secondary group-similar-designs active":"btn btn-outline-secondary group-similar-designs"} onClick={event => this.get_batch_or_repo_similar_designs(event,'repo')} >Repo</button>                                           
                                        </div>,
                                        <div className="row d-flex justify-content-start similar-design-image-group" >
                                            {similar_images_list.length > 0?similar_images_list:
                                            <div className=" col-md-12 text-center" style={{marginTop:'140px'}}>
                                                <span>No similar images found</span>
                                        </div>
                                            }
                                        </div>]
                                        :
                                        <div className="row d-flex justify-content-start similar-design-image-group" >
                                            <div className=" col-md-12 text-center" style={{marginTop:'140px'}}>
                                                <span>Please Upload Image for Similar Images.</span>
                                            </div>
                                        </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        )
    }
}

export default withRouter(FindSimilarImages);





var batch_similar_images = [
   
    
            {
                "name": "Bangle Design 03",
                "path": "NKNGS10049_1.JPG",
                "score": "0.71",
                "products": [],
                "variant_id": 4589,
                "variant_name": "NKNGS10049",
                "design_id": 4589,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS10049_1.JPG"
            },
            {
                "name": "Bangle Design 05",
                "path": "NKNGS10417_1.JPG",
                "score": "0.64",
                "products": [],
                "variant_id": 4595,
                "variant_name": "NKNGS10417",
                "design_id": 4595,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS10417_1.JPG"
            },
            {
                "name": "NKNGS13099",
                "path": "NKNGS13099_1.JPG",
                "score": "0.63",
                "products": [],
                "variant_id": 4643,
                "variant_name": "NKNGS13099",
                "design_id": 4643,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS13099_1.JPG"
            },
            {
                "name": "Bangle Design 01",
                "path": "NKNGS10044_1.JPG",
                "score": "0.63",
                "products": [],
                "variant_id": 4584,
                "variant_name": "NKNGS10044",
                "design_id": 4584,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS10044_1.JPG"
            },
            {
                "name": "NKNGS11958",
                "path": "NKNGS11958_1.JPG",
                "score": "0.62",
                "products": [],
                "variant_id": 4611,
                "variant_name": "NKNGS11958",
                "design_id": 4611,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS11958_1.JPG"
            },
            {
                "name": "Bangle Design 07",
                "path": "NKNGS10160_1.JPG",
                "score": "0.62",
                "products": [],
                "variant_id": 4593,
                "variant_name": "NKNGS10160",
                "design_id": 4593,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS10160_1.JPG"
            },
            {
                "name": "Anklet_06549",
                "path": "NKNGS13103_1.JPG",
                "score": "0.61",
                "products": [],
                "variant_id": 4647,
                "variant_name": "NKNGS13103",
                "design_id": 4647,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS13103_1.JPG"
            },
            {
                "name": "NKNGS13102",
                "path": "NKNGS13102_1.JPG",
                "score": "0.61",
                "products": [],
                "variant_id": 4646,
                "variant_name": "NKNGS13102",
                "design_id": 4646,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS13102_1.JPG"
            },
            {
                "name": "NKANC13515",
                "path": "NKANC13515_2.JPG",
                "score": "0.61",
                "products": [],
                "variant_id": 4180,
                "variant_name": "NKANC13515",
                "design_id": 4180,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKANC13515_2.JPG"
            },
            {
                "name": "Mangalsutra_22794",
                "path": "NKANC13515_2.JPG",
                "score": "0.61",
                "products": [],
                "variant_id": 5826,
                "variant_name": "NKANC13515",
                "design_id": 5826,
                "batch_name": "MANU00057",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00057&image_name=NKANC13515_2.JPG"
            },
            {
                "name": "NKANC13518",
                "path": "NKANC13518_3.JPG",
                "score": "0.60",
                "products": [],
                "variant_id": 4183,
                "variant_name": "NKANC13518",
                "design_id": 4183,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKANC13518_3.JPG"
            },
            {
                "name": "Mangalsutra_22793",
                "path": "NKANC13518_3.JPG",
                "score": "0.60",
                "products": [],
                "variant_id": 5829,
                "variant_name": "NKANC13518",
                "design_id": 5829,
                "batch_name": "MANU00057",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00057&image_name=NKANC13518_3.JPG"
            },
            {
                "name": "Bangle Design 06",
                "path": "NKNGS10416_1.JPG",
                "score": "0.60",
                "products": [],
                "variant_id": 4594,
                "variant_name": "NKNGS10416",
                "design_id": 4594,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS10416_1.JPG"
            },
            {
                "name": "Bracelet_26736",
                "path": "NKNGS13088_1.JPG",
                "score": "0.60",
                "products": [],
                "variant_id": 4632,
                "variant_name": "NKNGS13088",
                "design_id": 4632,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS13088_1.JPG"
            },
            {
                "name": "Bracelet_26736",
                "path": "NKNGS13093_1.JPG",
                "score": "0.59",
                "products": [],
                "variant_id": 4637,
                "variant_name": "NKNGS13093",
                "design_id": 4637,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS13093_1.JPG"
            },
            {
                "name": "Bangle Design 04",
                "path": "NKNGS10046_1.JPG",
                "score": "0.59",
                "products": [],
                "variant_id": 4586,
                "variant_name": "NKNGS10046",
                "design_id": 4586,
                "batch_name": "MANU00044",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": null,
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00044&image_name=NKNGS10046_1.JPG"
            }     
]

var repo_similar_images = [
  
            {
                "name": "NKNGS10049",
                "path": "NKNGS10049_1.JPG",
                "score": "0.71",
                "products": [],
                "variant_id": 3818,
                "variant_name": "NKNGS10049",
                "design_id": 3818,
                "batch_name": "MANU00019",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000175",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00019&image_name=NKNGS10049_1.JPG"
            },
            {
                "name": "NKNGS10417",
                "path": "NKNGS10417_1.JPG",
                "score": "0.64",
                "products": [],
                "variant_id": 3824,
                "variant_name": "NKNGS10417",
                "design_id": 3824,
                "batch_name": "MANU00019",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000181",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00019&image_name=NKNGS10417_1.JPG"
            },
   
            {
                "name": "NKNGS13085",
                "path": "NKNGS13085_1.JPG",
                "score": "0.73",
                "products": [],
                "variant_id": 3858,
                "variant_name": "NKNGS13085",
                "design_id": 3858,
                "batch_name": "MANU00019",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000215",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00019&image_name=NKNGS13085_1.JPG"
            },
            {
                "name": "NKNGS13085",
                "path": "NKNGS13085_1.JPG",
                "score": "0.73",
                "products": [],
                "variant_id": 2198,
                "variant_name": "NKNGS13085",
                "design_id": 2198,
                "batch_name": "MANU00016",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000136",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00016&image_name=NKNGS13085_1.JPG"
            },
            {
                "name": "NKNGS13085",
                "path": "NKNGS13085_1.JPG",
                "score": "0.73",
                "products": [],
                "variant_id": 1593,
                "variant_name": "NKNGS13085",
                "design_id": 1593,
                "batch_name": "MANU00015",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000057",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00015&image_name=NKNGS13085_1.JPG"
            },
            {
                "name": "NKNGS12330",
                "path": "NKNGS12330_1.JPG",
                "score": "0.72",
                "products": [],
                "variant_id": 1592,
                "variant_name": "NKNGS12330",
                "design_id": 1592,
                "batch_name": "MANU00015",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000056",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00015&image_name=NKNGS12330_1.JPG"
            },
            {
                "name": "NKNGS12330",
                "path": "NKNGS12330_1.JPG",
                "score": "0.72",
                "products": [],
                "variant_id": 2197,
                "variant_name": "NKNGS12330",
                "design_id": 2197,
                "batch_name": "MANU00016",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000135",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00016&image_name=NKNGS12330_1.JPG"
            },
            {
                "name": "NKNGS12330",
                "path": "NKNGS12330_1.JPG",
                "score": "0.72",
                "products": [],
                "variant_id": 3857,
                "variant_name": "NKNGS12330",
                "design_id": 3857,
                "batch_name": "MANU00019",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000214",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00019&image_name=NKNGS12330_1.JPG"
            },
            {
                "name": "NKNGS12329",
                "path": "NKNGS12329_1.JPG",
                "score": "0.71",
                "products": [],
                "variant_id": 3856,
                "variant_name": "NKNGS12329",
                "design_id": 3856,
                "batch_name": "MANU00019",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000213",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00019&image_name=NKNGS12329_1.JPG"
            },
            {
                "name": "NKNGS12329",
                "path": "NKNGS12329_1.JPG",
                "score": "0.71",
                "products": [],
                "variant_id": 2196,
                "variant_name": "NKNGS12329",
                "design_id": 2196,
                "batch_name": "MANU00016",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000134",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00016&image_name=NKNGS12329_1.JPG"
            },
            {
                "name": "NKNGS12329",
                "path": "NKNGS12329_1.JPG",
                "score": "0.71",
                "products": [],
                "variant_id": 1591,
                "variant_name": "NKNGS12329",
                "design_id": 1591,
                "batch_name": "MANU00015",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000055",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00015&image_name=NKNGS12329_1.JPG"
            },
            {
                "name": "NKNGS13095",
                "path": "NKNGS13095_1.JPG",
                "score": "0.70",
                "products": [],
                "variant_id": 2207,
                "variant_name": "NKNGS13095",
                "design_id": 2207,
                "batch_name": "MANU00016",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000145",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00016&image_name=NKNGS13095_1.JPG"
            },
            {
                "name": "NKNGS13095",
                "path": "NKNGS13095_1.JPG",
                "score": "0.70",
                "products": [],
                "variant_id": 3868,
                "variant_name": "NKNGS13095",
                "design_id": 3868,
                "batch_name": "MANU00019",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000225",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00019&image_name=NKNGS13095_1.JPG"
            },
            {
                "name": "NKNGS13095",
                "path": "NKNGS13095_1.JPG",
                "score": "0.70",
                "products": [],
                "variant_id": 1603,
                "variant_name": "NKNGS13095",
                "design_id": 1603,
                "batch_name": "MANU00015",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000067",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00015&image_name=NKNGS13095_1.JPG"
            },
            {
                "name": "NKNGS10035",
                "path": "NKNGS10035_1.JPG",
                "score": "0.70",
                "products": [],
                "variant_id": 3804,
                "variant_name": "NKNGS10035",
                "design_id": 3804,
                "batch_name": "MANU00019",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000161",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00019&image_name=NKNGS10035_1.JPG"
            },
            {
                "name": "NKNGS10035",
                "path": "NKNGS10035_1.JPG",
                "score": "0.70",
                "products": [],
                "variant_id": 1539,
                "variant_name": "NKNGS10035",
                "design_id": 1539,
                "batch_name": "MANU00015",
                "bucket_name": "malabar-dgs-batches-uat-2",
                "gd_number": "NKNGS-000003",
                "singned_url": "bucket_name=malabar-dgs-batches-uat-2&batch_name=MANU00015&image_name=NKNGS10035_1.JPG"
            }
]