import React from 'react';
import { Link, withRouter } from 'react-router-dom';
// import ErrorHandling from '../../CommonFunctions'
import {API_HOST} from '../../Settings.js'
import axios from 'axios';
import './add_role.css'
import $ from 'jquery'
// import { Redirect } from "react-router-dom";
import notify from '../../CommonFunctions.js';
import ErrorHandling from '../../ErrorHandler'
import {objectIsNotEmpty} from '../customHelperFunctions'
import Cookies from "universal-cookie";
const cookies = new Cookies();

class AddRole extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        user_perm:{},
        new_role_name: "",
        all_privileges: [],
        default_privileges_list: [],
        selected_privilege_ids: [],
        custom_privileges: [],
        tooltip_for_privilege: {}
      }
    }  

    componentDidMount(){
      $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
      $('.main-menu-sub-group').find('#roles-group').find('.circle-title').addClass('active-navbar')
      $('.inline-loader').fadeIn()
      this.getUserPermissions()
    }

    getUserPermissions(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/users/user/permissions/read/`,
            ).then(response => {
                // var get_all_permissions = response.data.user_perm.get_all_permissions;
                if(!response.data.user_perm.is_superuser){
                    notify("You don't have access to this page",'error')
                    this.props.history.push('/permissiondenied/')
                }
                if(true){
                    this.setState({
                        user_perm: response.data.user_perm
                    })
                    this.getExistingPrivileges()
                }else{
                    setTimeout(() =>{
                        this.props.history.push('/permissiondenied/')
                    },1000)
                    notify("You don't have permission to aceess this page", "error")
                }
            }).catch(response => {
                $('.inline-loader').fadeOut('slow')
                ErrorHandling(response)
            });
    }

    getExistingPrivileges = () => {
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/users/groups/add/`
            ).then(response => {
                this.setState({
                    "all_privileges": response.data.all_privileges,
                    "default_privileges_list": response.data.default_privileges_list,
                    "custom_privileges": response.data.custom_privileges,
                    'tooltip_for_privilege': response.data.tooltip_for_privilege
                })
                $('.inline-loader').fadeOut('slow')
            }).catch(error_response =>{
                $('.inline-loader').fadeOut('slow')
            })
    }

    handleSubmit(event){
        $('.inline-loader').fadeIn()
        event.preventDefault()
        this.handleAddRole()
    }

    handleInputChange = (event) =>{
        this.setState({
            "new_role_name": event.target.value
        })
    }

    handlePrivilegeSelection = (event, selected_privilege_id) =>{
        var selected_privilege_ids = this.state.selected_privilege_ids
        if (event.currentTarget.checked){
            if(selected_privilege_ids.indexOf(selected_privilege_id)===-1){
                selected_privilege_ids.push(selected_privilege_id)
            }
        }else{
            var index = selected_privilege_ids.indexOf(selected_privilege_id)
            if(index>-1){
                selected_privilege_ids.splice(index,1)
            }
        }
        this.setState({
            "selected_privilege_ids": selected_privilege_ids
        })
    }

    handleAddRole = () => {
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.post['Authorization'] =auth_token
        let form_data = new FormData();
        form_data.append('group_name',this.state.new_role_name)
        form_data.append('selected_privilege_ids', this.state.selected_privilege_ids)
        axios.post(`${API_HOST}/users/groups/add/`,form_data
            ).then(response => {
                if(response.data.success===false){
                   var element_role =  $('#new-role-name');
                   element_role.addClass('invalid-role')

                }else{
                    notify("Role added successfully", "info")
                    this.props.history.push('/roles/list/')

                }
                $('.inline-loader').fadeOut()
            }).catch(error_response => {

            });
    }

    render() {
        let custom_privileges2 = this.state.custom_privileges.map((each_privilege, key)=>{
            return (
                    <div className="row m-0 p-0 mt-1 bg-white permission-row">
                        <div className="col-md-2">
                          <div className="vertical-center">
                            <div className="d-flex justify-content-start">
                              <span className="mr-3">{each_privilege[0]}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                -
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                -
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                -
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                -
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div data-toggle="tooltip" data-placement="top" title={this.state.tooltip_for_privilege.hasOwnProperty(each_privilege[1]) ? this.state.tooltip_for_privilege[each_privilege[1]] : "Info not available" }>
                                <label className="checkbox-label">
                                  <input type="checkbox" checked={this.state.selected_privilege_ids.includes(each_privilege[1])? true : false} onChange={event => this.handlePrivilegeSelection(event, each_privilege[1])}/>
                                  <span className="checkbox-custom rectangular"></span>
                                </label>
                            </div>
                          </div>
                        </div>
                    </div>
                )
        })

        let default_privilege2 = this.state.default_privileges_list.map((each_privilege, key)=>{
            return(
                    <div className="row m-0 p-0 mt-1 bg-white permission-row">
                        <div className="col-md-2">
                          <div className="vertical-center">
                            <div className="d-flex justify-content-start">
                              <span className="mr-3">{each_privilege[0].split('.')[1]}</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                    {
                                        each_privilege[1].hasOwnProperty('view')
                                        ?
                                        <div data-toggle="tooltip" data-placement="top" title={this.state.tooltip_for_privilege.hasOwnProperty(each_privilege[1].view) ? this.state.tooltip_for_privilege[each_privilege[1].view] : "Info not available" }>
                                            <label className="checkbox-label">
                                                <input className="ml-3" type="checkbox"
                                                checked={this.state.selected_privilege_ids.includes(each_privilege[1].view)? true : false}
                                                onChange={event => this.handlePrivilegeSelection(event, each_privilege[1].view)}/>
                                                <span className="checkbox-custom rectangular"></span>
                                            </label>
                                        </div>
                                        :
                                        <div>
                                            -
                                        </div>
                                    }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                {
                                    each_privilege[1].hasOwnProperty('add')
                                    ?
                                    <div data-toggle="tooltip" data-placement="top" title={this.state.tooltip_for_privilege.hasOwnProperty(each_privilege[1].add) ? this.state.tooltip_for_privilege[each_privilege[1].add] : "Info not available" }>
                                    <label className="checkbox-label">
                                        <input className="ml-3" type="checkbox"
                                        checked={this.state.selected_privilege_ids.includes(each_privilege[1].add)? true : false}
                                        onChange={event => this.handlePrivilegeSelection(event, each_privilege[1].add)}/>
                                        <span className="checkbox-custom rectangular"></span>
                                    </label>
                                    </div>
                                    :
                                    <div>
                                        -
                                    </div>
                                }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                {
                                    each_privilege[1].hasOwnProperty('change')
                                    ?
                                    <div data-toggle="tooltip" data-placement="top" title={this.state.tooltip_for_privilege.hasOwnProperty(each_privilege[1].change) ? this.state.tooltip_for_privilege[each_privilege[1].change] : "Info not available" }>
                                        <label className="checkbox-label">
                                            <input className="ml-3" type="checkbox"
                                            checked={this.state.selected_privilege_ids.includes(each_privilege[1].change)? true : false}
                                            onChange={event => this.handlePrivilegeSelection(event, each_privilege[1].change)}/>
                                            <span className="checkbox-custom rectangular"></span>
                                        </label>
                                    </div>
                                    :
                                    <div>
                                        -
                                    </div>
                                }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                {
                                    each_privilege[1].hasOwnProperty('delete')
                                    ?
                                    <div data-toggle="tooltip" data-placement="top" title={this.state.tooltip_for_privilege.hasOwnProperty(each_privilege[1].delete) ? this.state.tooltip_for_privilege[each_privilege[1].delete] : "Info not available" }>
                                        <label className="checkbox-label">
                                            <input className="ml-3" type="checkbox"
                                            checked={this.state.selected_privilege_ids.includes(each_privilege[1].delete)? true : false}
                                            onChange={event => this.handlePrivilegeSelection(event, each_privilege[1].delete)}/>
                                            <span className="checkbox-custom rectangular"></span>
                                        </label>
                                    </div>
                                    :
                                    <div>
                                        -
                                    </div>
                                }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                -
                            </div>
                          </div>
                        </div>
                    </div>
                )
        })

    return (
            <div className="relative">
                <div className="inline-loader">
                    <div className="dot-elastic"></div>
                </div>
                <div className=" d-flex justify-content-between mb-0" style={{"alignItems": 'center'}} id="gos-group">
                    <label id="current-url-total-path">
                    <Link to="/roles/list/" className="current-url">Roles</Link> /&nbsp;
                    <a href="#" className="current-url">Add Role </a>
                    </label>
                </div>
                { objectIsNotEmpty(this.state.user_perm) 
                    ?
                    <form onSubmit={event => this.handleSubmit(event)}>
                        <div className="row m-0 p-0">
                            <div className="col-md-12 p-0 text-center">
                                <h4>Add Role</h4>
                            </div>
                        </div>
                        <div className="row m-0 p-0">
                            <div className="d-flex justify-content-start mb-2">
                                <div className="mr-4 d-flex align-items-center">
                                    <label id="role-name" for="new-role-name" className="m-0 mr-2">Role Name</label>
                                </div>
                                <div>
                                    <input type="text" onChange={event =>this.handleInputChange(event)} className="form-control mr-4" autocomplete="off" id="new-role-name" required/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row m-0 p-0 bg-white permission-row">
                              <div className="col-md-2">
                                <div className="vertical-center">
                                  <div className="d-flex justify-content-start">
                                    <span className="mr-3">Privilege</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 align-self-center">
                                <div className="text-center">
                                  <div><span>View</span></div>
                                </div>
                              </div>
                              <div className="col-md-2 align-self-center">
                               <div className="text-center">
                                 <div><span>Add</span></div>
                               </div>
                              </div>
                              <div className="col-md-2 align-self-center">
                                <div className="text-center">
                                  <div><span>Update</span></div>
                                </div>
                              </div>
                              <div className="col-md-2 align-self-center">
                                <div className="text-center">
                                  <div><span>Delete</span></div>
                                </div>
                              </div>
                              <div className="col-md-2 align-self-center">
                                <div className="text-center">
                                  <div><span>Yes/No</span></div>
                                </div>
                              </div>
                            </div>
                            {default_privilege2}
                            {custom_privileges2}
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <button type="submit" className="btn btn-dark" id="role-save-btn">Save</button>
                        </div>
                    </form>
                    :null
                }
            </div>
        )
    }
}

export default withRouter(AddRole);