import { API_HOST } from '../../Settings.js'
// import $ from 'jquery'
import Cookies from "universal-cookie";
const axios = require('axios').default;
const cookies = new Cookies();

export const signOut = (e) =>{
    e.preventDefault();
    /*const auth_token = "Token "+localStorage.malbar_token*/
    const auth_token = "Token "+cookies.get("malabar_token")
    axios.defaults.headers.get['Authorization'] =auth_token
          axios.get(API_HOST+'/users/logout/',
          ).then(response => {
            if(response.status === 200){ 
                localStorage.clear()  
                cookies.remove('malabar_token',{path:'/'})
                window.location.href="/login"
                  }
               })    
  }
