import React from 'react';
import {Router, Switch, Route, withRouter} from 'react-router-dom';
import {PrivateRoute} from './PrivateRoutes'
// import Home from './pages/Home';
import Batch from './pages/batches/Index';
import AddBatch from './pages/batches/AddBatch'
import BatchDetails from './pages/batches/BatchDetails';
import DesignDetails from './pages/batches/DesignDetails';
import LoginPage from './pages/Login/LoginPage'
import history from './history';
// import $ from 'jquery'; 
import 'popper.js/dist/popper.min.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Repo from './pages/Repos/RepoIndex'
import RepoDesignDetails from './pages/Repos/RepoDesignDetails'
import UploadImage from './pages/Repos/UploadImage'
import Jobs from './pages/Jobs/Index'
import OauthRedirect from './pages/Login/OauthRedirect'
import { Redirect } from 'react-router';
import RepoAddVariant from './pages/Repos/RepoAddVariant'
import Users from './pages/Users/Users'
import UserDetails from './pages/Users/UserDetails'
import UserProfile from './pages/Users/UserProfile'
import Roles from './pages/Roles/RolesList'
import AddRole from './pages/Roles/AddRole'
import UpdateRole from './pages/Roles/UpdateRole'
import FindSimilarImages from './pages/Find/FindSimilarImges'
import PermissionDeniedPage from './pages/PermissionDeniedPage';

export default class Pages extends React.Component{
	render(){
		return(
			<React.Fragment>
				<Router history={history}>
					<Switch>
						<Route exact path="/login" component={withRouter(LoginPage)}/>
                        <PrivateRoute exact path="/permissiondenied/" component={withRouter(PermissionDeniedPage)}></PrivateRoute>
						<PrivateRoute exact path="/batches/" component={Batch}></PrivateRoute>
						<PrivateRoute exact path="/add/new/batch/" component={AddBatch}></PrivateRoute>
						<PrivateRoute exact path="/batch/:batchID/details/" component={BatchDetails}></PrivateRoute>
						<PrivateRoute exact path="/batch/:batchID/design/:designID/details/" component={DesignDetails}></PrivateRoute>
						<PrivateRoute exact path="/repo" component={Repo}></PrivateRoute>
						<PrivateRoute exact path="/repo/repodesigndetails/:designID/" component = {RepoDesignDetails}></PrivateRoute>
						<PrivateRoute exact path="/repo/design/:design_name/" component = {RepoDesignDetails}></PrivateRoute>
						<PrivateRoute exact path="/repo/uploadimage/design/:designName" component={UploadImage}></PrivateRoute>
						<PrivateRoute exact path="/jobs/" component={Jobs}></PrivateRoute>
						<Route path="/oauth/login/redirect/" exact component={withRouter(OauthRedirect)}/>
                        <PrivateRoute exact path="/repo/design/:design_name/addvariant/" component = {RepoAddVariant}></PrivateRoute>
                        <PrivateRoute exact path="/users/" component={Users}></PrivateRoute>
                        <PrivateRoute exact path="/users/:user_id/details/" component={UserDetails}></PrivateRoute>
                        <PrivateRoute exact path="/profile/" component={UserProfile}></PrivateRoute>
                        <PrivateRoute exact path="/roles/list/" component={Roles}></PrivateRoute>
                        <PrivateRoute exact path="/roles/add/" component={AddRole}></PrivateRoute>
                        <PrivateRoute exact path="/roles/:role_id/update" component={UpdateRole}></PrivateRoute>
						<PrivateRoute exact path="/find/similar/images/" component={FindSimilarImages}></PrivateRoute>
                        <Redirect to="/batches/" />
					</Switch>
				</Router>
			</React.Fragment>
		)
	}
}