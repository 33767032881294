import React, { Component } from 'react';

class DesignCardImageDelete extends Component {
  render() {

      return (

      	<div className="uploaded-img-parent">
    	     <img className="image uploaded-image-inside-div " src={this.props.image} alt="new" />
             {  this.props.enable_delete
                ?
                <button type="button" className="btn delete-btn status-change-delete shadow-none">
                    <i className="fas fa-trash fa-lg" onClick={this.props.onChildDeleteClick} id={this.props.id_component_name+"_delete_"+this.props.img_id}></i>
                </button>
                :null
             }
        </div>
      )
  }
}

export default DesignCardImageDelete
