 
import { Component } from 'react'
import {withRouter} from 'react-router-dom'
import notify from '../../CommonFunctions.js'
import Cookies from "universal-cookie";
const cookies = new Cookies();


class CheckToken extends Component {
    constructor(props) {
        super(props);
        this.offline_count = 1
        this.state = {
          token: cookies.get("malabar_token"),
          online:true,
          offline_count:0
        };
      }
      componentDidMount() {
        this.timerID = setInterval(
          () => this.checkstorage(),
          1000
        );
      }
      componentWillUnmount() {
        clearInterval(this.timerID);
      }
      checkstorage(){
        this.setState({
            token: cookies.get("malabar_token"),
            online:navigator.onLine,
          });
    }
    render(){
        if(this.state.online){
          this.offline_count = 1
        }else{
          if(this.offline_count === 1){
          notify('You are offline! No Internet connection', 'error')
          this.offline_count += 1
          }
        }
        return(
            this.state.token === null|| this.state.token === ""? window.location.href="/login":''
        )
    }   
}

export default withRouter(CheckToken)