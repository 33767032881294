import React from 'react';
import { Link, withRouter } from 'react-router-dom';
// import ErrorHandling from '../../CommonFunctions'
import Select from 'react-select'
import { API_HOST } from '../../Settings.js'
// import ArrowLeft from '../../images/arrow-left.svg'
// import ArrowRight from '../../images/arrow-right.svg'
// import ThreeDots from '../../images/three-dots.svg'
import DesignArrowLeft from '../../images/design-arrow-left.svg'
import DesignArrowRight from '../../images/design-arrow-right.svg'
import ImagePlaceholder from '../../images/image_placeholder.jpg'
import notify from '../../CommonFunctions.js'
import ErrorHandling from '../../ErrorHandler'
import RejectModal from './RejectModal'
import VariantAttribute from './VariantAttribute'
import $, { event } from 'jquery'
import axios from 'axios';
import './design_details.css'
import {objectIsNotEmpty} from '../customHelperFunctions'
import Cookies from "universal-cookie";
const cookies = new Cookies();



class DesignDetails extends React.Component {
    constructor(props) {
        super(props);
        // const selectRef = null        
        this.state = {
            batch: {},
            design: {},
            design_images:[],
            design_images_length:0,
            attributes_history: [],
            gd_number_data:[],
            user_perm:{},            
            approval:{},
            showModal:false,
            rejectModal:false,
            selected_design_id:'',
            selected_images:[],
            next_design:null,
            prev_design:null,
            batchID: this.props.match.params.batchID,
            designID: this.props.match.params.designID,
            designPreviewModal:false,
            comments:[],
            hide_carousel_left: true,
            hide_carousel_right: false,
            showAttributeTab:false,
            similar_designs:[],
            batch_similar_designs:[],
            repo_similar_designs:[],
            simailar_designs_with_image_urls:[],
            batch_simailar_designs_with_image_urls:[],
            repo_simailar_designs_with_image_urls:[],
            showSimilarDesigns:true,
            last_index_time:0,
            last_index_response:false,
            variant_id_for_req_image: -1,
            has_received_comments_response: false,
            request_comment: "",
            ids_of_stage_1_or_2_approved_variants: [],
            roles_for_this_batch:[],
            dgs_perms: {},
            wait_time_in_minutes: 60,
            batch_similar_designs_url_for_modal: {},
            batch_and_repo_similar_designs_url_for_modal: {},
            repo_similar_designs_url_for_modal: {},
            main_designs_urls_for_modal: [],
            random_key: 0,
            main_design_list:[],
            urls_with_info_for_modal: {},
            unique_similar_gd_numbers: [],
            disable_approve: true,
            disable_approve_as_variant: true,
            random_key_for_similar_images: 0
        }
        
    }
    
    componentDidMount() {
        $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
        $('.main-menu-sub-group').find('#batch-group').find('.circle-title').addClass('active-navbar')
        $('.similar-design-content').fadeOut()
        $('.variant-main-content').find('.inline-loader').fadeIn()
        this.getUserPermissions()    
    }

    componentWillUnmount(){
        /*if($("#design-preview-model").length > 0){
            if(($("#design-preview-model").data('bs.modal') || {})._isShown){
                $('#design-preview-model').hide()
            }
        }*/
        $('.modal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    }

    componentDidUpdate() {
        if($('.tooltip')){
            $('.tooltip').tooltip('hide');
        }

        if($('[data-toggle="tooltip"]').length){
            $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
        }
    }

    getBatchDetails(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token  
        // $('.variant-main-content').find('.inline-loader').fadeIn()
        // $('.similar-design-content').fadeOut()
        axios.get(`${API_HOST}/batches/batch/${this.state.batchID}/detail/`, ).then(response => {
            if (response.status === 200) {
                var group_perms = response.data.dgs_perms.group_perms;
                if (group_perms.includes('batches.view_batch')||group_perms.includes('batches.add_batch')){
                    this.setState({
                        batch: response.data.data,
                        roles_for_this_batch: response.data.roles_for_this_batch,
                        dgs_perms: response.data.dgs_perms
                    }, ()=>{ 
                        this.getDesignsData(event,this.state.designID)
                    })
                }else{
                    notify("You don't have permission to aceess this page",'error')
                    setTimeout(() =>{
                        this.props.history.push('/permissiondenied/')
                    },1000)
                }   
            }
        }).catch(response => {
            ErrorHandling(response)
        });
    }

    getUserPermissions(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        // $('.inline-loader').fadeIn()
        axios.get(`${API_HOST}/users/user/permissions/read/`,
            ).then(response => {
                var get_all_permissions = response.data.user_perm.get_all_permissions;
                if(get_all_permissions.includes('batches.view_batch')||get_all_permissions.includes('batches.add_batch')){
                    this.setState({
                        user_perm: response.data.user_perm
                    })
                    this.getBatchDetails()
                }else{
                      setTimeout(() =>{
                          this.props.history.push('/permissiondenied/')
                      },1000)
                      notify("You don't have permission to aceess this page", "error")
                }
                // $('.inline-loader').fadeOut('slow')
            }).catch(response => {
                // $('.inline-loader').fadeOut('slow')
                ErrorHandling(response)
            });
    }

    getDesignsData = (event, design_id) =>{
        $('.variant-main-content').find('.inline-loader').fadeIn()
        $('.design-data-tabs').fadeOut()
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/repo/design/${design_id}/detail/?batch_id=${this.state.batchID}`, ).then(response => {
            if (response.status === 200) {
                var design_images_lists = []
                var counter = 0
                var counter_list = []
                var des_len = 0
                var this_des_len = response.data.design_images.length
                response.data.design_images.map((image,indx)=>{
                    des_len += 1
                    if (counter === 3){
                        design_images_lists.push(counter_list)
                        counter_list = []
                        counter_list.push(image)
                        counter = 1
                        if (this_des_len === des_len){
                            design_images_lists.push(counter_list)
                        }
                    }else{
                        counter += 1
                        counter_list.push(image)
                        if (this_des_len === des_len){
                            design_images_lists.push(counter_list)
                        }
                    }
                })
                $('.design-data-tabs').fadeIn()
                $('.similar-design-content').fadeIn()
                // let form_data = new FormData();
                // form_data.append('images_data',JSON.stringify(response.data.design_images))
                // form_data.append('batch_id',this.state.batch.name)
                // axios.defaults.headers.post['Authorization'] =auth_token
                // var avialable_promise =  axios.post(`${API_HOST}/repo/design/${design_id}/detail/`, form_data) 
                // avialable_promise.then((response) => {
                //   $('.similar-design-content').find('.inline-loader').fadeOut('slow')
                //     this.setState({
                //       batch_similar_designs:response.data.batch_similar_images,
                //       repo_similar_designs:response.data.repo_similar_images,
                //       last_index_time:response.data.index_time,
                //       last_index_response:true,
                //       disable_approve: response.data.disable_approve,
                //       disable_approve_as_variant: response.data.disable_approve_as_variant,
                //       gd_number_data :response.data.repo_designs_id_and_gd_number
                //     })
                // });
                
                if(objectIsNotEmpty(this.state.dgs_perms) && this.state.dgs_perms.group_perms.includes('repo.add_imagerequest')){
                    var url = `${API_HOST}/repo/imagerequest/${design_id}/list/?from=batch`;
                    axios.defaults.headers.get['Authorization'] =auth_token
                    axios.get(url,).then( response => {
                                if(response.status === 200){
                                    this.setState({
                                        has_received_comments_response: true,
                                        ids_of_stage_1_or_2_approved_variants: response.data.ids_of_stage_1_or_2_approved_variants
                                    })

                                }
                            }
                        ).catch(response => {
                            // ErrorHandling(response)
                        });    
                }

                var variant_id_for_req_image = -1
                if (response.data.design.length>1){
                    variant_id_for_req_image = response.data.design[1].id
                }
                else{
                    variant_id_for_req_image = response.data.design[0].id   
                }
                var main_designs_urls_for_modal = []
                response.data.design_images.map((data,index)=>{
                    main_designs_urls_for_modal.push(data['image_gsutil_url'])
                })

                this.setState({
                    designID:design_id,
                    design: response.data.design[0],
                    design_images:design_images_lists,
                    design_images_length:this_des_len,
                    user_perm:response.data.user_perm,
                    approval:response.data.approval,
                    main_design_list:response.data.design_images,
                    // gd_number_data:response.data.designs_data,
                    next_design:response.data.next_design,
                    prev_design:response.data.prev_design,
                    similar_designs:[],
                    batch_similar_designs:[],
                    repo_similar_designs:[],
                    simailar_designs_with_image_urls:[],
                    batch_simailar_designs_with_image_urls:[],
                    repo_simailar_designs_with_image_urls:[],
                    showSimilarDesigns:true,
                    last_index_time:0,
                    last_index_response:false,
                    variant_id_for_req_image: variant_id_for_req_image,
                    roles_for_this_batch: response.data.roles_for_this_batch,
                    main_designs_urls_for_modal: main_designs_urls_for_modal
                })
                $('.inline-loader').fadeOut('slow')
                $('.similar-design-content').find('.inline-loader').fadeOut('slow')
            }
        }).catch(response => {
            ErrorHandling(response)
        });
    }


    clickedCarousel = (event,which_element) => {
        var totalItems = $('.carousel-item').length;
        var currentIndex = $('.active').index() + 1;
        if (which_element==="left-click"){
            currentIndex = currentIndex -1 
        }
        if (which_element==="right-click"){   
            currentIndex = currentIndex + 1   
        }
        if(currentIndex===totalItems){
            this.setState({"hide_carousel_right":true})
        }
        else{
            this.setState({"hide_carousel_right":false})   
        }
        if(currentIndex===1){
            this.setState({"hide_carousel_left":true})   
        }
        else{
            this.setState({"hide_carousel_left":false})   
        }
        if((currentIndex === totalItems) && (currentIndex === 1)){
            this.setState({"hide_carousel_left": true, "hide_carousel_right": true})
        }
        
    }

    getAttributeEditHistory = (event) =>{
    	event.preventDefault()
        $('.history-inline-loader').find('.inline-loader').fadeIn()
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token  
    	axios.get(`${API_HOST}/repo/design/${this.state.designID}/attributes/history/list/?from_batch_batch_id=${this.state.batchID}`, ).then(response => {
            if (response.status === 200) {
                this.setState({
                    attributes_history: response.data.attributes_history,
                    showAttributeTab:false
                })
                $('.history-inline-loader').find('.inline-loader').fadeOut('slow')

            }
        }).catch(response => {
            ErrorHandling(response)
        });
    }

    viewDesign = (event) =>{        
        document.getElementById('main-design-image-id').src=event.target.src;
        var this_gsutil_url = event.currentTarget.getAttribute('image_gsutil_url')
        document.getElementById('main-design-image-id').parentElement.setAttribute('image_gsutil_url', this_gsutil_url)
        var x = document.getElementsByClassName("carousel-child-img");
        var i;
        for (i = 0; i < x.length; i++) {
            x[i].style.border = "none";
        }
        event.target.style.border="1px solid blue";
    };

    approveOrRejectDesign = (event,status, msg=null) =>{
        var confir_msg = ''
        var success_msg = ''
        if(status === 'approved'){
            confir_msg = 'Are you sure, you want approve this design?'
            success_msg = 'Design approved successfully!'
        }else{
            confir_msg = 'Are you sure, you want reject this design?'
            success_msg = 'Design rejected successfully!'
        }
        if(window.confirm(confir_msg)){
            if(status === 'rejected'){
                if(msg){

                }else{
                    notify('Please type your message','warning')
                    return false
                }
            }
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.get['Authorization'] =auth_token  
            axios.get(`${API_HOST}/repo/design/${this.state.designID}/approve/or/reject/?status=${status}&msg=${msg}`).then(response => {
              if (response.status === 200 && response.data.success) {
                    $('#reject-model').modal('hide');                   
                    this.setState({
                        approval:response.data.approval,
                        rejectModal:false
                    })
                    notify(success_msg,'success')
                }else{
                    notify(response.data.message, 'warning')
                }
            }).catch(response => {
                ErrorHandling(response)
            });
        }
    }

    handleHideModal = () =>{
        this.setState({showModal: false})
    }

    approveAsVariant = () =>{
        this.setState({showModal: true})
    }

    showRejectModal = () =>{
        this.setState({rejectModal:true})
    }

    hideRejectModal = () =>{
        this.setState({rejectModal:false})
    }
    
    gdNumberChange = (event) =>{
        if(event.value){
            this.setState({
                selected_design_id:event.value
            })
        }
    }
    selectVariantImages = (event) =>{
        var selected_images = this.state.selected_images
        if(event.currentTarget.checked){
            selected_images.push(event.currentTarget.value)
            this.setState({
                selected_images:selected_images
            })
        }else{
            var index = selected_images.indexOf(event.currentTarget.value)
            selected_images.splice(index,1)
            this.setState({
                selected_images:selected_images
            })
        }

    }

    saveApproveAsVariant = (event) =>{
        if(!this.state.selected_design_id){
            notify('Please select Global design number','warning')
            return false
        }
        var variant_name = $('#new-variant-name').val()
        if(!variant_name){
            notify('Please enter variant name','warning')
            return false
        }        
        if(this.state.selected_images.length === 0){
            notify('Please select images','warning')
            return false
        }
        var variant_data = {
            variant_name:variant_name,
            selected_design_id:this.state.selected_design_id,
            selected_images:this.state.selected_images,
            variant_id:this.state.design.id            
        }
        if(window.confirm('Are you sure, you want approve as variant?')){
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
                axios.defaults.headers.post['Authorization'] =auth_token      
                axios.post(`${API_HOST}/repo/design/${this.state.designID}/approve/or/reject/`, variant_data)
                .then(response => {
                    if (response.status === 200 && response.data.success) {                    
                        notify('Approved as variant successfully.','success')
                        setTimeout(() =>{
                            $('#approve-as-variant-model').modal('hide');
                            this.props.history.push('/batch/'+this.state.batchID+'/details/')
                        },1000)
                    }else{
                        if((response.data.message==="Matching design code does not match with the existing design")
                            ||(response.data.message==='Matching Design Code different with existing design')){
                            notify("Matching design code does not match with the existing design", 'warning')
                        }else{
                            notify('Something went wrong, Try again later.','error')
                        }
                    }
                }).catch(response => {
                    ErrorHandling(response)
                });
        }
    }

    handleGoToAnotherDesign = (event, page_type) =>{
        $('#custom-carousel').carousel(0);
        if (this.state.design_images_length > 3){
            this.setState({
                "hide_carousel_left": true, "hide_carousel_right": false, showAttributeTab:false,
                "design_images": []
            })    
        }
        else{
            this.setState({"hide_carousel_left": true, "hide_carousel_right": true,showAttributeTab:false, "design_images": []})
        }
        
        $('.nav-link').removeClass('active')
        $('.tab-content').find('.tab-pane').removeClass('active')
        $('.design-nav-link').addClass('active')
        $('#tab-content-similar-design').addClass('active show')
        if(page_type === 'next'){
            this.getDesignsData(event,this.state.next_design)
            this.props.history.push('/batch/'+this.state.batchID+'/design/'+this.state.next_design+'/details/')
        }else if(page_type === 'prev'){
            this.getDesignsData(event,this.state.prev_design)
            this.props.history.push('/batch/'+this.state.batchID+'/design/'+this.state.prev_design+'/details/')
        }
    }

    showDesignPreviewModal = (event) =>{
        var random_key = this.state.random_key
        if(this.state.showSimilarDesigns===false){
            this.setState({
                designPreviewModal:true,
                random_key: random_key?0:1
            })
        }else{
            notify('Please click on Get Similar Designs first','information')
        }
    }


    handleTextarea(event) {
        this.setState({request_comment: event.target.value});
    }

    handleSubmit = (event) =>  {
        event.preventDefault();
        if (true){
            $('.variant-main-content').find('.inline-loader').fadeIn()
            var comment_selected_variant_id = this.state.variant_id_for_req_image
            let form_data = new FormData();
            form_data.append('variant_id',comment_selected_variant_id)
            form_data.append('design_id',this.state.designID)
            form_data.append('request_comment',this.state.request_comment)
            form_data.append('request_from',"batch")
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.post['Authorization'] =auth_token
            let url = `${API_HOST}/repo/imagerequest/request`;
            axios.post(url, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
            .then(response => {
              if(response.status === 200){
                /*$('#request-images-modal').modal('hide')*/
                this.setState({"request_comment":"","has_received_comments_response":false})
                notify('Your request has sent successfully')
                $('.variant-main-content').find('.inline-loader').fadeOut('slow')
                setTimeout(() =>{
                    window.location.reload()
                },2000)
              }
              
            })
            .catch(error => {
              alert('Request failed '+ error.response.data.detail);
              $('.variant-main-content').find('.inline-loader').fadeOut('slow')
              setTimeout(() =>{
                  window.location.reload()
              },2000)
            })
        }
        else{
            alert("select the variant to request images")
        }
        
    };

    getDesignComments = (event) =>{
    	event.preventDefault()
        $('.history-inline-loader').find('.inline-loader').fadeIn()
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token  
    	axios.get(`${API_HOST}/repo/design/${this.state.designID}/get/design/comments/`, ).then(response => {
            if (response.status === 200) {
                this.setState({
                    comments: response.data.comments,
                    showAttributeTab:false
                })
                $('.history-inline-loader').find('.inline-loader').fadeOut('slow')

            }
        }).catch(response => {
            ErrorHandling(response)
        });
    }

    get_similar_designs = (event) =>{
        event.preventDefault()
            // if(this.state.batch_similar_designs.length > 0 || this.state.repo_similar_designs.length > 0){
                $('.similar-design-content').find('.inline-loader').fadeIn()
                /*const auth_token = "Token "+localStorage.malbar_token*/
                let form_data = new FormData();
                form_data.append('images_data',JSON.stringify(this.state.main_design_list))
                form_data.append('batch_id',this.state.batch.name)
                const auth_token = "Token "+cookies.get("malabar_token")
                axios.defaults.headers.post['Authorization'] =auth_token
                axios.post(`${API_HOST}/repo/design/${this.state.designID}/detail/`, form_data)
                .then((response) => {
                    this.setState({
                      batch_similar_designs:response.data.batch_similar_images,
                      repo_similar_designs:response.data.repo_similar_images,
                      last_index_time:response.data.index_time,
                      last_index_response:true,
                      disable_approve: response.data.disable_approve,
                      disable_approve_as_variant: response.data.disable_approve_as_variant,
                      gd_number_data :response.data.repo_designs_id_and_gd_number
                    })
                // const auth_token = "Token "+cookies.get("malabar_token")
                // axios.defaults.headers.post['Authorization'] =auth_token
                // form_data = new FormData()
                // if(this.state.batch_similar_designs.length > 0){
                //   form_data.append('batch_similar_designs',JSON.stringify(this.state.batch_similar_designs))
                // }
                // if(this.state.repo_similar_designs.length > 0){
                //   form_data.append('repo_similar_designs',JSON.stringify(this.state.repo_similar_designs))
                // }
                // axios.post(`${API_HOST}/repo/get/similar/design/bucket/urls/`, form_data)
                // .then(response => {
                //     if (response.status == 200) {
                //       var batch_similar_designs_url_for_modal = {}
                //       var batch_and_repo_similar_designs_url_for_modal = {}
                //       var urls_with_info_for_modal = {}
                //       let batch_similar_images_list = []
                //       var array_scores_for_order = []
                //       response.data.batch_similar_designs_url.map((images_data,indx)=>{
                //                 var image_keys = Object.keys(images_data)
                //                 image_keys.map((key,inx)=>{
                //                   var image_signed_urls = images_data[key]
                //                   batch_and_repo_similar_designs_url_for_modal[key]=[]
                //                   batch_similar_designs_url_for_modal[key]=[]
                //                   image_signed_urls.map((url_obj,ix)=>{
                //                       if(url_obj.singned_url){
                //                         batch_and_repo_similar_designs_url_for_modal[key].push(url_obj.singned_url)
                //                         batch_similar_designs_url_for_modal[key].push(url_obj.singned_url)
                //                         let batch = url_obj.products.filter((product,key)=>{
                //                           if(product.product_type == 'batch'){
                //                             return product.value
                //                           }
                //                         })

                //                         urls_with_info_for_modal[url_obj.singned_url]={'batch': batch[0].value, 'score': url_obj.score, 'gd_numer': '', 'variant_name': url_obj.variant_name}

                //                         var array_length = batch_similar_images_list.length
                //                         array_scores_for_order.push([array_length, parseFloat(url_obj.score), url_obj.variant_id, url_obj.variant_name])
                //                         batch_similar_images_list.push(
                //                               <div className="similar-cell-image d-flex flex-column justify-content-between">
                //                                   <img src={url_obj.singned_url} className="similar-design-image" />
                //                                   <div className="d-flex justify-content-between">
                //                                       <label className="description">Batch# <b>{batch[0].value}</b></label>
                //                                         <label className="description">Score: <b>{url_obj.score}</b></label>
                //                                     </div>
                //                                     <label className="description">Variant Name: <b>{url_obj.variant_name}</b></label>
                //                                 </div>)
                //                         }
                //                   })
                //                 })
                //         })
                //         array_scores_for_order.sort(function(a,b){return a[1]-b[1]})
                //         array_scores_for_order.reverse()


                //         var unique_batch_array_scores_for_order = []
                //         array_scores_for_order = array_scores_for_order.filter((element, index)=>{
                //             if(! unique_batch_array_scores_for_order.includes(element[2])){
                //                 unique_batch_array_scores_for_order.push(element[2])
                //                 return true
                //             }
                //             return false
                //         })

                //         var ordered_indexes_score = array_scores_for_order.map((element, index)=>{return element[0]})
                //         batch_similar_images_list = ordered_indexes_score.map(index=>batch_similar_images_list[index])
                //         var repo_array_scores_for_order = []
                //         let repo_similar_images_list = []
                //         var repo_similar_designs_url_for_modal = {}
                //         var unique_similar_gd_numbers = []
                //         response.data.repo_similar_designs_url.map((images_data,indx)=>{
                //                 var image_keys = Object.keys(images_data)
                //                 image_keys.map((key,inx)=>{
                //                   var image_signed_urls = images_data[key]
                //                   image_signed_urls.map((url_obj,ix)=>{
                //                       if(url_obj.singned_url){
                //                         if(key in batch_and_repo_similar_designs_url_for_modal){
                //                             batch_and_repo_similar_designs_url_for_modal[key].push(url_obj.singned_url)
                //                         }else{
                //                             batch_and_repo_similar_designs_url_for_modal[key]=[url_obj.singned_url]
                //                         }
                //                         if(key in repo_similar_designs_url_for_modal){
                //                             repo_similar_designs_url_for_modal[key].push(url_obj.singned_url)
                //                         }else{
                //                             repo_similar_designs_url_for_modal[key]=[url_obj.singned_url]
                //                         }
                //                         var batch = ''
                //                         var gdn = ''
                //                         url_obj.products.map((product,key)=>{
                //                           if(product.product_type == 'batch'){
                //                             batch = product.value
                //                           }
                //                           if(product.product_type == 'gdn'){
                //                             gdn = product.value
                //                           }
                //                         })
                //                         if(gdn && (!unique_similar_gd_numbers.includes(gdn))){
                //                             unique_similar_gd_numbers.push(gdn)
                //                         }
                //                         urls_with_info_for_modal[url_obj.singned_url]={'batch': batch, 'score': url_obj.score, 'gd_number': gdn, "variant_name": ''}

                //                         var array_length = repo_similar_images_list.length
                //                         repo_array_scores_for_order.push([array_length, parseFloat(url_obj.score), gdn])
                //                         repo_similar_images_list.push(
                //                               <div className="similar-cell-image d-flex flex-column justify-content-between">
                //                                   <img src={url_obj.singned_url} className="similar-design-image" />
                //                                     <div>
                //                                     <div className="d-flex justify-content-between">
                //                                       <label className="description">GD# <b>{gdn}</b></label>
                //                                         <label className="description">Score: <b>{url_obj.score}</b></label>
                //                                     </div>
                //                                     <div className="d-flex justify-content-between">
                //                                       <label className="description">Batch# <b>{batch}</b></label>
                //                                     </div>
                //                                     </div>
                //                                     {/* <label className="description">Variant Name: {url_obj.name}</label> */}
                //                                 </div>)
                //                         }
                //                   })
                //                 })
                //         })

                //         repo_array_scores_for_order.sort(function(a,b){return a[1]-b[1]})
                //         repo_array_scores_for_order.reverse()
                //         var unique_repo_array_scores_for_order = []
                //         repo_array_scores_for_order = repo_array_scores_for_order.filter((element, index)=>{
                //             if(! unique_repo_array_scores_for_order.includes(element[2])){
                //                 unique_repo_array_scores_for_order.push(element[2])
                //                 return true
                //             }
                //             return false
                //         })

                //         var ordered_indexes_score = repo_array_scores_for_order.map((element, index)=>{return element[0]})
                //         repo_similar_images_list = ordered_indexes_score.map(index=>repo_similar_images_list[index])
                //         this.setState({
                //             repo_simailar_designs_with_image_urls: repo_similar_images_list,
                //             batch_simailar_designs_with_image_urls:batch_similar_images_list,
                //             showSimilarDesigns:false,
                //             similar_designs:batch_similar_images_list,
                //             batch_similar_designs_url_for_modal: batch_similar_designs_url_for_modal,
                //             batch_and_repo_similar_designs_url_for_modal: batch_and_repo_similar_designs_url_for_modal,
                //             repo_similar_designs_url_for_modal: repo_similar_designs_url_for_modal,
                //             urls_with_info_for_modal: urls_with_info_for_modal,
                //             unique_similar_gd_numbers: unique_similar_gd_numbers
                //         })
                //         $('.similar-design-content').find('.inline-loader').fadeOut('slow')
                //     }
                // }).catch(response => {
                //     ErrorHandling(response)
                // });

                if (response.status === 200) {
                  var batch_similar_designs_url_for_modal = {}
                  var batch_and_repo_similar_designs_url_for_modal = {}
                  var urls_with_info_for_modal = {}
                  let batch_similar_images_list = []
                  var array_scores_for_order = []
                  response.data.batch_similar_images.map((images_data,indx)=>{
                            var image_keys = Object.keys(images_data)
                            image_keys.map((key,inx)=>{
                              var image_signed_urls = images_data[key]
                              batch_and_repo_similar_designs_url_for_modal[key]=[]
                              batch_similar_designs_url_for_modal[key]=[]
                              image_signed_urls.map((url_obj,ix)=>{
                                  if(url_obj.singned_url){
                                    batch_and_repo_similar_designs_url_for_modal[key].push(url_obj.singned_url)
                                    batch_similar_designs_url_for_modal[key].push(url_obj.singned_url)
                                    
                                    let batch = [{'value': url_obj.batch_name}]
                                    // let batch = url_obj.products.filter((product,key)=>{
                                    //   if(product.product_type === 'batch'){
                                    //     return product.value
                                    //   }
                                    // })

                                    urls_with_info_for_modal[url_obj.singned_url]={'batch': batch[0].value, 'score': url_obj.score, 'gd_numer': '', 'variant_name': url_obj.variant_name}

                                    var array_length = batch_similar_images_list.length
                                    array_scores_for_order.push([array_length, parseFloat(url_obj.score), url_obj.variant_id, url_obj.variant_name])
                                    batch_similar_images_list.push(
                                          <div className="similar-cell-image d-flex flex-column justify-content-between">
                                              <img src={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${url_obj.bucket_name}&batch_name=${url_obj.batch_name}&image_name=${url_obj.path}&image_type=thumbnails`} className="similar-design-image" alt="design" />
                                              <div className="d-flex justify-content-between">
                                                  {/*<label className="description">Batch# <b>{batch[0].value}</b></label>*/}
                                                  <label className="description" >Batch# <Link to={`/batch/${url_obj.batch_pk}/details`}  target="_blank" className="description" ><b><b>{batch[0].value}</b></b></Link></label>
                                                    <label className="description">Score: <b>{url_obj.score}</b></label>
                                                </div>
                                                {/*<label className="description">Variant Name: <b>{url_obj.variant_name}</b></label>*/}
                                                <label className="description">Variant Name: <Link to={`/batch/${url_obj.batch_pk}/design/${url_obj.design_id}/details/`}  target="_blank" className="description"><b>{url_obj.variant_name}</b></Link></label>
                                            </div>)
                                    }
                              })
                            })
                    })
                    array_scores_for_order.sort(function(a,b){return a[1]-b[1]})
                    array_scores_for_order.reverse()


                    var unique_batch_array_scores_for_order = []
                    array_scores_for_order = array_scores_for_order.filter((element, index)=>{
                        if(! unique_batch_array_scores_for_order.includes(element[2])){
                            unique_batch_array_scores_for_order.push(element[2])
                            return true
                        }
                        return false
                    })

                    var ordered_indexes_score = array_scores_for_order.map((element, index)=>{return element[0]})
                    batch_similar_images_list = ordered_indexes_score.map(index=>batch_similar_images_list[index])
                    var repo_array_scores_for_order = []
                    let repo_similar_images_list = []
                    var repo_similar_designs_url_for_modal = {}
                    var unique_similar_gd_numbers = []
                    response.data.repo_similar_images.map((images_data,indx)=>{
                            var image_keys = Object.keys(images_data)
                            image_keys.map((key,inx)=>{
                              var image_signed_urls = images_data[key]
                              image_signed_urls.map((url_obj,ix)=>{
                                  if(url_obj.singned_url){
                                    if(key in batch_and_repo_similar_designs_url_for_modal){
                                        batch_and_repo_similar_designs_url_for_modal[key].push(url_obj.singned_url)
                                    }else{
                                        batch_and_repo_similar_designs_url_for_modal[key]=[url_obj.singned_url]
                                    }
                                    if(key in repo_similar_designs_url_for_modal){
                                        repo_similar_designs_url_for_modal[key].push(url_obj.singned_url)
                                    }else{
                                        repo_similar_designs_url_for_modal[key]=[url_obj.singned_url]
                                    }
                                    var batch = url_obj.batch_name
                                    var gdn = url_obj.gd_number
                                    // url_obj.products.map((product,key)=>{
                                    //   if(product.product_type == 'batch'){
                                    //     batch = product.value
                                    //   }
                                    //   if(product.product_type == 'gdn'){
                                    //     gdn = product.value
                                    //   }
                                    // })
                                    if(gdn && (!unique_similar_gd_numbers.includes(gdn))){
                                        unique_similar_gd_numbers.push(gdn)
                                    }
                                    urls_with_info_for_modal[url_obj.singned_url]={'batch': batch, 'score': url_obj.score, 'gd_number': gdn, "variant_name": ''}

                                    var array_length = repo_similar_images_list.length
                                    repo_array_scores_for_order.push([array_length, parseFloat(url_obj.score), gdn])
                                    repo_similar_images_list.push(
                                          <div className="similar-cell-image d-flex flex-column justify-content-between">
                                              <img src={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${url_obj.bucket_name}&batch_name=${url_obj.batch_name}&image_name=${url_obj.path}&image_type=thumbnails`} className="similar-design-image" alt='design' />
                                                <div>
                                                <div className="d-flex justify-content-between">
                                                  {/*<label className="description">GD# <b>{gdn}</b></label>*/}
                                                  <label className="description" >GD# <Link to={`/repo/design/${url_obj.design_name_slug}/`}  target="_blank" className="description"><b>{gdn}</b></Link></label>
                                                    <label className="description">Score: <b>{url_obj.score}</b></label>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                  {/*<label className="description">Batch# <b>{batch}</b></label>*/}
                                                  <label className="description" >Batch# <Link to={`/batch/${url_obj.batch_pk}/details`}  target="_blank" className="description" ><b>{batch}</b></Link></label>
                                                </div>
                                                </div>
                                                {/* <label className="description">Variant Name: {url_obj.name}</label> */}
                                            </div>)
                                    }
                              })
                            })
                    })

                    repo_array_scores_for_order.sort(function(a,b){return a[1]-b[1]})
                    repo_array_scores_for_order.reverse()
                    var unique_repo_array_scores_for_order = []
                    repo_array_scores_for_order = repo_array_scores_for_order.filter((element, index)=>{
                        if(! unique_repo_array_scores_for_order.includes(element[2])){
                            unique_repo_array_scores_for_order.push(element[2])
                            return true
                        }
                        return false
                    })

                    ordered_indexes_score = repo_array_scores_for_order.map((element, index)=>{return element[0]})
                    repo_similar_images_list = ordered_indexes_score.map(index=>repo_similar_images_list[index])
                    this.setState({
                        repo_simailar_designs_with_image_urls: repo_similar_images_list,
                        batch_simailar_designs_with_image_urls:batch_similar_images_list,
                        showSimilarDesigns:false,
                        similar_designs:batch_similar_images_list,
                        batch_similar_designs_url_for_modal: batch_similar_designs_url_for_modal,
                        batch_and_repo_similar_designs_url_for_modal: batch_and_repo_similar_designs_url_for_modal,
                        repo_similar_designs_url_for_modal: repo_similar_designs_url_for_modal,
                        urls_with_info_for_modal: urls_with_info_for_modal,
                        unique_similar_gd_numbers: unique_similar_gd_numbers
                    })
                    $('.similar-design-content').find('.inline-loader').fadeOut('slow')
                }

            });
    
            // }else{
            //     this.setState({
            //         simailar_designs_with_image_urls: []
            //     })
            //     notify('No similar designs found', 'information')
            //     $('.similar-design-content').find('.inline-loader').fadeOut('slow') 
            // }
    }
    getVariantAttributes = () =>{
        this.setState({
            showAttributeTab:true
        })
        
    }

    get_batch_or_repo_similar_designs = (event,click_from) =>{
      $('button.group-similar-designs').removeClass('active')
      $(event.currentTarget).addClass('active')
      var random_key_for_similar_images = this.state.random_key_for_similar_images
      if(click_from === 'batch'){
        this.setState({
          similar_designs:this.state.batch_simailar_designs_with_image_urls,
          showSimilarDesigns:false,
          random_key_for_similar_images:random_key_for_similar_images?0:1
        })
      }else{
        this.setState({
          similar_designs:this.state.repo_simailar_designs_with_image_urls,
          showSimilarDesigns:false,
          random_key_for_similar_images: random_key_for_similar_images?0:1
        })
      }
    }   

    render() {
    	let attributes_history_tr = this.state.attributes_history.map((history, key)=>{
    		return(
    			<tr className={(key + 1) % 2 === 1 ? 'bg-white' : 'repo-edithistory-table-alt-color'} key={key}>
                    <td scope="row">{history.created_date}</td>
                    <td>{history.user}</td>
                    <td>{history.attribute.name}</td>
                    <td>{history.old_value}</td>
                    <td>{history.new_value}</td>
                </tr>
    		)
    	})     

        let design_images = this.state.design_images.map((images_list,key)=>{
            var images_element = images_list.map((image,inx)=>{
                return <img src={image.image_uri?`${API_HOST}/repo/get/image/signed/url/?bucket_name=${image.bucket_name}&batch_name=${image.batch_name}&image_name=${image.image_uri}&image_type=thumbnails`:ImagePlaceholder}  className="carousel-child-img" image_gsutil_url={image.image_gsutil_url} onClick={(event) => this.viewDesign(event)} alt="design"/>
            })
            return <div className={key === 0?"carousel-item active":"carousel-item"}>
                    <div className="d-flex justify-content-start">
                        {images_element}
                    </div>
                </div>
        })
        var comments_str = this.state.comments.map((comment,key)=>{
            return <div className="text-left" key={key}>{comment.message}</div>
        })


        return (
          <div className="variant-main-content">
              <div className="inline-loader" ><div className="dot-elastic"></div></div> 
            <div>
                <div>
                    <label id="current-url-total-path">
	            	<Link to="/batches/" className="current-url">Batches</Link> /&nbsp;
                    <Link to={`/batch/${this.state.batchID}/details/`} className="current-url">{this.state.batch.name}</Link>
                    {/* <a href="#" className="current-url"> Necklace</a> */}
                    </label>
                </div>
                
                <div className="d-flex justify-content-between">
                    <div>
                        <label id="product-name">{this.state.design.name}</label>
                    </div>
                  {
                    this.state.last_index_response && this.state.batch.status.name_slug!=="approved" && this.state.batch.status.name_slug!=="rejected"?
                      this.state.last_index_time < this.state.wait_time_in_minutes && this.state.last_index_time > 0?
                        objectIsNotEmpty(this.state.dgs_perms) && (
                                this.state.dgs_perms.group_perms.includes("repo.can_approve_design")
                                ||  this.state.dgs_perms.group_perms.includes("repo.can_reject_design")
                                ||  this.state.dgs_perms.group_perms.includes("repo.can_approve_design_as_variant")
                            )?
                          <div id="status-group">
                                {   this.state.dgs_perms.group_perms.includes('repo.can_approve_design_as_variant')
                                    ?
                                    <button type="button" className="btn status-button mr-3 disabled-btn" id="status-approve-variant" disabled>Approve as Variant</button>
                                    :null
                                }
                                {   this.state.dgs_perms.group_perms.includes('repo.can_approve_design')
                                    ?
                                    <button type="button" className="btn status-button mr-3 disabled-btn" id="status-approve" disabled>Approve</button>
                                    :null
                                }
                                {   this.state.dgs_perms.group_perms.includes('repo.can_reject_design')
                                    ?
                                    <button type="button" className="btn status-button disabled-btn" id="status-reject" disabled>Reject</button>
                                    :null
                                }
                          </div>
                        :
                          null
                      :
                        objectIsNotEmpty(this.state.dgs_perms) && (
                                this.state.dgs_perms.group_perms.includes("repo.can_approve_design")
                                ||  this.state.dgs_perms.group_perms.includes("repo.can_reject_design")
                                ||  this.state.dgs_perms.group_perms.includes("repo.can_approve_design_as_variant")
                            ) ?
                          Object.keys(this.state.approval).length === 0?
                            <div id="status-group">
                                {
                                    this.state.dgs_perms.group_perms.includes('repo.can_approve_design_as_variant')
                                    ?
                                    this.state.showSimilarDesigns===false
                                        ?
                                        (!this.state.disable_approve_as_variant)
                                            ?
                                            (this.state.unique_similar_gd_numbers.length>0)
                                                ?
                                                <button type="button" className="btn status-button mr-3" id="status-approve-variant" onClick={this.approveAsVariant}>Approve as Variant</button>
                                                :
                                                <div className="tooltip-disabled-position" key='tooltip_1'>
                                                    <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="No similar designs exists">
                                                    <button type="button" className="btn status-button mr-3 disabled-btn remove-pointer-event" id="status-approve-variant" disabled>Approve as Variant</button>
                                                    </span>
                                                </div>
                                            :
                                            <div className="tooltip-disabled-position" key='tooltip_2'>
                                                <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="This design can't be approved. Similar design exists in Repo" >
                                                <button type="button" className="btn status-button mr-3 disabled-btn remove-pointer-event" id="status-approve-variant" disabled>Approve as Variant</button>
                                                </span>
                                            </div>
                                        :
                                        <div className="tooltip-disabled-position" key='tooltip_3'>
                                            <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Click on Get Similar Designs">
                                            <button type="button" className="btn status-button mr-3 disabled-btn remove-pointer-event" id="status-approve-variant" disabled>Approve as Variant</button>
                                            </span>
                                        </div>
                                    :
                                    null
                                    
                                }
                                &nbsp;
                                {
                                    this.state.dgs_perms.group_perms.includes("repo.can_approve_design")
                                    ?
                                    (this.state.showSimilarDesigns===false)
                                        ?
                                        (!this.state.disable_approve)
                                            ?
                                            <button type="button" className="btn status-button mr-3" id="status-approve" onClick={event => this.approveOrRejectDesign(event,'approved')}>Approve</button>
                                            :
                                            <div className="tooltip-disabled-position" key='tooltip_4'>
                                                <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="This design can't be approved. Similar design exists in Repo">
                                                <button type="button" className="btn status-button mr-3 disabled-btn remove-pointer-event" id="status-approve" disabled>Approve</button>
                                                </span>
                                            </div>
                                        :
                                        <div className="tooltip-disabled-position" key='tooltip_5'>
                                            <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Click on Get Similar Designs">
                                            <button type="button" className="btn status-button mr-3 disabled-btn remove-pointer-event" id="status-approve" disabled>Approve</button>
                                            </span>
                                        </div>
                                    :
                                    null
                                }
                                &nbsp;
                                {
                                    this.state.dgs_perms.group_perms.includes("repo.can_reject_design")
                                    ?
                                    <button type="button" className="btn status-button" id="status-reject" onClick={this.showRejectModal}>Reject</button>
                                    :null
                                }
                            </div>
                          :
                            this.state.approval.stage.name_slug === 'stage-2'?
                              null
                            :
                              <div id="status-group">
                                  { this.state.approval.stage.name_slug === 'stage-1' && this.state.approval.status.name_slug === 'rejected'
                                    ?
                                        <div>
                                        {this.state.dgs_perms.group_perms.includes("repo.can_approve_design_as_variant")
                                        ?
                                        (this.state.showSimilarDesigns===false)
                                            ?
                                            (!this.state.disable_approve_as_variant)
                                                ?
                                                (this.state.unique_similar_gd_numbers.length>0)
                                                    ?
                                                    <button type="button" className="btn status-button mr-3" id="status-approve-variant" onClick={this.approveAsVariant} >Approve as Variant</button>
                                                    :
                                                    <div className="tooltip-disabled-position" key='tooltip_6'>
                                                        <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="No similar designs exists">
                                                        <button type="button" className="btn status-button mr-3 disabled-btn remove-pointer-event" id="status-approve-variant" disabled>Approve as Variant</button>
                                                        </span>
                                                    </div>
                                                :
                                                <div className="tooltip-disabled-position" key='tooltip_7'>
                                                    <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="This design can't be approved. Similar design exists in Repo">
                                                    <button type="button" className="btn status-button mr-3 disabled-btn remove-pointer-event" id="status-approve-variant" disabled>Approve as Variant</button>
                                                    </span>
                                                </div>
                                            :
                                            <div className="tooltip-disabled-position" key='tooltip_8'>
                                                <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Click on Get Similar Designs">
                                                <button type="button" className="btn status-button mr-3 disabled-btn remove-pointer-event" id="status-approve-variant" disabled>Approve as Variant</button>
                                                </span>
                                            </div>
                                        :null
                                        }
                                        {
                                            this.state.dgs_perms.group_perms.includes("repo.can_approve_design")
                                            ?
                                            (this.state.showSimilarDesigns===false)
                                                ?
                                                (!this.state.disable_approve)
                                                    ?
                                                    <button type="button" className="btn status-button mr-3" id="status-approve" onClick={event => this.approveOrRejectDesign(event,'approved')}>Approve</button>
                                                    :
                                                    <div className="tooltip-disabled-position" key='tooltip_9'>
                                                        <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="This design can't be approved. Similar design exists in Repo">
                                                        <button type="button" className="btn status-button mr-3 disabled-btn remove-pointer-event" id="status-approve" disabled>Approve</button>
                                                        </span>
                                                    </div>
                                                :
                                                <div className="tooltip-disabled-position" key='tooltip_10'>
                                                    <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Click on Get Similar Designs">
                                                    <button type="button" className="btn status-button mr-3 disabled-btn remove-pointer-event" id="status-approve" disabled>Approve</button>
                                                    </span>
                                                </div>
                                            :
                                            null
                                        }
                                        {
                                        this.state.dgs_perms.group_perms.includes("repo.can_reject_design")
                                        ?
                                        <div className="tooltip-disabled-position" key='tooltip_11'>
                                            <span class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Design already rejected">
                                            <button type="button" className="btn status-button disabled-btn remove-pointer-event" id="status-reject" disabled>Reject</button>
                                            </span>
                                        </div>
                                        :null
                                        }
                                        </div>

                                  :
                                    <div>
                                        {
                                            this.state.dgs_perms.group_perms.includes("repo.can_approve_design_as_variant")
                                            ?
                                            <button type="button" className="btn status-button mr-3 disabled-btn" id="status-approve-variant" disabled>Approve as Variant</button>
                                            :null
                                        }
                                        {
                                            this.state.dgs_perms.group_perms.includes("repo.can_approve_design")
                                            ?
                                            <button type="button" className="btn status-button mr-3 disabled-btn" id="status-approve" disabled>Approve</button>
                                            :null
                                        }
                                        {
                                            this.state.dgs_perms.group_perms.includes("repo.can_reject_design")
                                            ?
                                            <button type="button" className="btn status-button" id="status-reject" onClick={this.showRejectModal}>Reject</button>
                                            :null
                                        }
                                    </div>
                                  }
                              </div>
                        :
                          null
                    :
                      null
                  }
                  </div>
                <div>
                    <label id="product-date">{this.state.design.created_date} </label> 
                    {this.state.last_index_time < this.state.wait_time_in_minutes && this.state.last_index_time > 0?
                      <span className="wrng-msg">Batch upload in progress. Please try after {Math.ceil(this.state.wait_time_in_minutes-this.state.last_index_time)} mins.</span>:null
                    }                   
                </div>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <div id="product-info-card">
                      <div className="d-flex justify-content-between" id="">
                        <div>
                        {this.state.prev_design?
                            <button className="btn btn-default p-0" onClick={event => this.handleGoToAnotherDesign(event,'prev')}>
                                <img src={DesignArrowLeft} width="20px" height="20px" className="" alt="left-icon"/>
                                {/*<i className="fas fa-chevron-left pr-1 text-right"></i>*/}
                                <span id="previous-design" className="navigation-text-font text-left pt-1">
                                Previous Design
                                </span>
                            </button>
                            :null
                        }
                        </div>                        
                        <div >
                            {this.state.next_design?
                                <button className="btn btn-default p-0 " onClick={event => this.handleGoToAnotherDesign(event,'next')}>
                                    <span id="next-design" className="navigation-text-font text-right pt-1">Next Design</span>
                                    <img src={DesignArrowRight} className="" width="20px" height="20px" alt="right-icon"/>
                                    {/*<i className="fas fa-chevron-right pl-1 text-right" ></i>*/}
                                </button>
                            :null
                          }
                        </div>
                      </div>
                      <div className="d-flex justify-content-between" id="vdi" style={{"flex-direction": "column"}}>
                        <span id="vdi-number" className="text-left" title='Vendor Design Id'>VDI # <b className="">{this.state.design.vdin}</b></span><br/>
                        <span id="vdi-number" className="text-left" title='Matching Design Code'>MDC # <b className="">{this.state.design.matching_design_code}</b></span><br/>
                      </div>
                      {this.state.design.design?this.state.design.design.gd_number?
                      <div className="d-flex justify-content-between" id="vdi">
                        <span id="vdi-number" className="text-left">GD #: <b className=""> {this.state.design.design.gd_number}</b></span>
                      </div>:null:null}
                      <div id="main-design-image-div" className="m-0">
                        <button type="button" className="btn m-0 p-0" data-toggle="modal" data-target="#design-preview-model" id="main-design-image-btn" image_gsutil_url={this.state.design_images.length > 0?this.state.design_images[0][0].image_gsutil_url:''}  onClick={event => this.showDesignPreviewModal(event)}>
                          <img src={this.state.design_images.length > 0? `${API_HOST}/repo/get/image/signed/url/?bucket_name=${this.state.design_images[0][0].bucket_name}&batch_name=${this.state.design_images[0][0].batch_name}&image_name=${this.state.design_images[0][0].image_uri}&image_type=thumbnails`:ImagePlaceholder}  className="main-design-image" id="main-design-image-id" alt="design"/>
                        </button>
                        {/*<div className="modal fade " id="design-preview-model">
                          <div className="modal-dialog modal-dialog-centered" id="design-preview-model-dialog">
                            <div className="modal-content pl-4 pr-4 pt-2 pb-4" >
                              <div className="d-flex justify-content-between pb-2 dashed-row-bottom">
                                <div >
                                  <span id="design-preview-text"> Design Preview</span>  
                                </div>
                                <div>
                                  <button type="button" className="close text-center" data-dismiss="modal">
                                    &times;
                                  </button>
                                </div>
                              </div>                     
                            </div>
                          </div>
                        </div>*/}
                      </div>
                      {this.state.design_images_length > 1?
                      <div id="custom-carousel" className="carousel slide carousel-main-design-image-group mt-3 mb-3" data-wrap="false" data-interval="false">
                        <div className="row p-0 m-0 ">
                          <div className="col-md-1 p-0 m-0 d-flex justify-content-center align-center">
                          {this.state.design_images_length > 3?
                            <a className="carousel-controls-icon-style" hidden={this.state.hide_carousel_left} onClick={event => this.clickedCarousel(event,"left-click")} href="#custom-carousel" data-slide="prev" id="carousel-left-icon">
                              <i className="fas fa-chevron-circle-left"></i>
                            </a>:null}  
                          </div>
                          <div className="carousel-inner col-md-10 p-0 m-0 text-center">
                                {design_images}                          
                          </div>
                          <div className="col-md-1 p-0 m-0 text-right d-flex justify-content-center align-center">
                          {this.state.design_images_length > 3?
                            <a className="carousel-controls-icon-style" hidden={this.state.hide_carousel_right} onClick={event => this.clickedCarousel(event,'right-click')} href="#custom-carousel" data-slide="next" id="carousel-right-icon">
                              <i className="fas fa-chevron-circle-right"></i>
                            </a>:null}
                          </div>
                        </div>
                      </div>
                      :null}
                    </div>
                  </div>
                  <div className="col-md-9 design-data-tabs">
                        <div className="row">
                            <div className="col-md-12 no-gutters">
                                <ul className="nav nav-tabs" role="tablist" id="tablist">
                                    {   objectIsNotEmpty(this.state.dgs_perms)
                                        && (
                                            this.state.dgs_perms.group_perms.includes("batches.view_batch")
                                            ||  this.state.dgs_perms.group_perms.includes("repo.view_design")
                                            ||  this.state.dgs_perms.group_perms.includes("repo.view_variant")
                                            || this.state.dgs_perms.group_perms.includes("batches.add_batch")
                                            )
                                        ?
                                        <li className="nav-item m-0">
                                          <a className="nav-link p-0 active design-nav-link" data-toggle="tab" href="#tab-content-similar-design">
                                                <div className="batches-tab-similar-design-menu-group">
                                                  <span className="batches-tab-span-text">Similar Designs</span>
                                                </div>
                                          </a>
                                        </li>
                                        :null
                                    }
                                    
                                    {objectIsNotEmpty(this.state.dgs_perms) && (
                                            this.state.dgs_perms.group_perms.includes('repo.view_variantattribute')
                                            ||this.state.dgs_perms.group_perms.includes('repo.add_variantattribute')
                                            || this.state.dgs_perms.group_perms.includes('repo.change_variantattribute')
                                            ||this.state.dgs_perms.group_perms.includes('repo.delete_variantattribute')
                                        )
                                        ?
                                        <li className="nav-item m-0" onClick={event => this.getVariantAttributes(event)}>
                                            <a className="nav-link p-0" data-toggle="tab" href="#tab-content-attribute">
                                                <div className="batches-tab-similar-design-menu-group">
                                                    <span className="batches-tab-span-text">Attributes</span>
                                                </div>
                                            </a>
                                        </li>
                                        :
                                        null
                                    }
                                    {objectIsNotEmpty(this.state.dgs_perms) && this.state.dgs_perms.group_perms.includes('repo.view_attributehistory')
                                        ?
                                        <li className="nav-item m-0" onClick={event => this.getAttributeEditHistory(event)}>
                                            <a className="nav-link p-0" data-toggle="tab" href="#tab-content-edit-history">
                                                <div className="batches-tab-similar-design-menu-group">
                                                    <span className="batches-tab-span-text">Edit History</span>
                                                </div>
                                            </a>
                                        </li>
                                        :
                                        null
                                    }
                                    {objectIsNotEmpty(this.state.dgs_perms) && this.state.dgs_perms.group_perms.includes('repo.view_comment')
                                        ?
                                        <li className="nav-item m-0" onClick={event => this.getDesignComments(event)}>
                                            <a className="nav-link p-0" data-toggle="tab" href="#tab-content-comments">
                                                <div className="batches-tab-similar-design-menu-group">
                                                    <span className="batches-tab-span-text">Comments</span>
                                                </div>
                                            </a>
                                        </li>
                                        :
                                        null
                                    }
                                    {   objectIsNotEmpty(this.state.dgs_perms) && this.state.dgs_perms.group_perms.includes('repo.add_imagerequest')
                                        && Object.keys(this.state.batch).length>0
                                        ?
                                        <li className="nav-item m-0">
                                            <a className="nav-link p-0" data-toggle="tab" href="#tab-content-request-images">
                                                <div className="batches-tab-similar-design-menu-group">
                                                    <span className="batches-tab-span-text">Request Images</span>
                                                </div>
                                            </a>
                                        </li>
                                        :
                                        null
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 no-gutters">
                                <div className="tab-content">
                                    <div id="tab-content-similar-design" className="row container tab-pane active p-0 m-0 similar-design-content relative">
                                        <div className="inline-loader" ><div className="dot-elastic"></div></div>
                                        {this.state.showSimilarDesigns?'':
                                        <div class="btn-group btn-group-sm pl-2 pb-2" role="group" aria-label="...">
                                            <button type="button" className="btn btn-outline-secondary group-similar-designs active" onClick={event => this.get_batch_or_repo_similar_designs(event,'batch')}>Batch</button>
                                            <button type="button" className="btn btn-outline-secondary group-similar-designs" onClick={event => this.get_batch_or_repo_similar_designs(event,'repo')}>Repo</button>                                           
                                        </div>}
                                        <div className="row d-flex justify-content-start similar-design-image-group" key={"similar"+this.state.random_key_for_similar_images}>
                                            {this.state.showSimilarDesigns? 
                                            <div className=" col-md-12 text-center" style={{marginTop:'140px'}}>
                                              <button className="btn btn-dark mb-3" onClick={event => this.get_similar_designs(event)}>
                                                Get Similar Designs
                                              </button>
                                            </div>:''}
                                            {this.state.showSimilarDesigns?'':this.state.similar_designs.length > 0?this.state.similar_designs:
                                              <div className=" col-md-12 text-center" style={{marginTop:'140px'}}>
                                                <span>No similar designs found</span>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    {objectIsNotEmpty(this.state.dgs_perms) && (
                                            this.state.dgs_perms.group_perms.includes('repo.view_variantattribute')
                                            || this.state.dgs_perms.group_perms.includes('repo.add_variantattribute')
                                            || this.state.dgs_perms.group_perms.includes('repo.change_variantattribute')
                                            || this.state.dgs_perms.group_perms.includes('repo.delete_variantattribute')
                                        )
                                        ?
                                        <div id="tab-content-attribute" className="row container tab-pane fade p-0 m-0 attr-inline-loader">
                                            <div className="inline-loader" ><div className="dot-elastic"></div></div> 
                                            {
                                                this.state.showAttributeTab?<VariantAttribute designID={this.state.designID} dgs_perms = {this.state.dgs_perms} batchID={this.state.batchID}/>:null
                                            }

                                        </div>
                                        :null
                                    }
                                    {objectIsNotEmpty(this.state.dgs_perms) && this.state.dgs_perms.group_perms.includes('repo.view_attributehistory')
                                        ?
                                        <div id="tab-content-edit-history" className="row container tab-pane fade p-0 m-0 history-inline-loader">
                                        <div className="inline-loader" ><div className="dot-elastic"></div></div> 
                                            <div className="col-md-12">
                                                <div className="attribute-background">
                                                    { this.state.attributes_history.length > 0 ?
                                                        <table className="table table-bordered w-100 text-left tab ">
                                                            <thead className=" bg-white head">
                                                                <th scope="col">Date & Time</th>
                                                                <th scope="col">User</th>
                                                                <th scope="col">Attribute</th>
                                                                <th>Old Value</th>
                                                                <th>New Value</th>
                                                            </thead>
                                                            <tbody className="text-secondary small">
                                                                { attributes_history_tr }
                                                            </tbody>
                                                        </table> :
                                                        <div className="text-center">No Changes found</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :null
                                    }
                                    {objectIsNotEmpty(this.state.dgs_perms) && this.state.dgs_perms.group_perms.includes('repo.view_comment')
                                        ?
                                        <div id="tab-content-comments" className="row container tab-pane fade p-0 m-0 comments-inline-loader">
                                            <div className="inline-loader" ><div className="dot-elastic"></div></div> 
                                            <div className="col-md-12">
                                                <div className="attribute-background">
                                                    {this.state.comments.length > 0?
                                                    <div>{comments_str}</div>
                                                    :
                                                        <div className="text-center">No Comments found</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :null
                                    }
                                    {   objectIsNotEmpty(this.state.dgs_perms) && this.state.dgs_perms.group_perms.includes('repo.add_imagerequest')
                                        && Object.keys(this.state.batch).length>0
                                        ?
                                        <div id="tab-content-request-images" className="row container tab-pane fade p-0 m-0 comments-inline-loader">
                                            <div className="col-md-12">
                                                { this.state.user_perm
                                                    && Object.keys(this.state.batch).length>0
                                                    ?
                                                    <div className="attribute-background">
                                                        {   this.state.batch.status.name_slug!=="approved"
                                                            && this.state.batch.status.name_slug!=="rejected"
                                                            ?
                                                            <div>
                                                                {   Object.keys(this.state.approval).length>0 
                                                                    ?
                                                                    <div>
                                                                        {   this.state.approval.stage.name_slug === 'stage-1' 
                                                                            && this.state.approval.status.name_slug === 'rejected'
                                                                        ?
                                                                        <h5>You can not request images for rejected design</h5>
                                                                        :
                                                                        <div>
                                                                            { this.state.has_received_comments_response
                                                                                && this.state.variant_id_for_req_image > 0 
                                                                                ?
                                                                                <div>
                                                                                {   (!this.state.ids_of_stage_1_or_2_approved_variants.includes(this.state.variant_id_for_req_image)) ?
                                                                                    <div>
                                                                                        <div>
                                                                                            <form method="post" action="#" id="#" onSubmit={this.handleSubmit}>
                                                                                            <div className="row m-0">
                                                                                              <div className="col-md-12 p-0">
                                                                                                <div>
                                                                                                  
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                            <div className="row p-0 m-0">
                                                                                              <textarea className="form-control" value={this.state.request_comment} onChange={this.handleTextarea.bind(this)} id="modal-request-images-comment" name="modal_request_images_comment" placeholder="Type your message" required></textarea>
                                                                                            </div>
                                                                                            <div className="row p-0 m-0 mt-2 justify-content-center">
                                                                                                <button type="submit" id="request-img-btn" className="btn btn-dark nohover pl-5 pr-5 m-0">
                                                                                                  Request Images
                                                                                                </button>
                                                                                            </div>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        <div>
                                                                                            <div className="row m-0">
                                                                                                <div class="form-check">
                                                                                                  <label class="form-check-label">
                                                                                                    <input type="checkbox" class="form-check-input" checked={true}/>Requested
                                                                                                  </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                    
                                                                                }
                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        { this.state.has_received_comments_response
                                                                            && this.state.variant_id_for_req_image > 0 
                                                                            ?
                                                                            <div>
                                                                            {   (!this.state.ids_of_stage_1_or_2_approved_variants.includes(this.state.variant_id_for_req_image)) ?
                                                                                <div>
                                                                                    <div>
                                                                                        <form method="post" action="#" id="#" onSubmit={this.handleSubmit}>
                                                                                        <div className="row m-0">
                                                                                          <div className="col-md-12 p-0">
                                                                                            <div>
                                                                                              
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                        <div className="row p-0 m-0">
                                                                                          <textarea className="form-control" value={this.state.request_comment} onChange={this.handleTextarea.bind(this)} id="modal-request-images-comment" name="modal_request_images_comment" placeholder="Type your message" required></textarea>
                                                                                        </div>
                                                                                        <div className="row p-0 m-0 mt-2 justify-content-center">
                                                                                            <button type="submit" id="request-img-btn" className="btn btn-dark nohover pl-5 pr-5 m-0">
                                                                                              Request Images
                                                                                            </button>
                                                                                        </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    <div>
                                                                                        <div className="row m-0">
                                                                                            <div class="form-check">
                                                                                              <label class="form-check-label">
                                                                                                <input type="checkbox" class="form-check-input" checked={true}/>Requested
                                                                                              </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                                
                                                                            }
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            <h5>You can not request images for {this.state.user_perm && this.state.dgs_perms.group_perms.includes('repo.add_imagerequest') ? this.state.batch.status.name_slug :"this"} batch</h5>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="attribute-background">
                                                        --
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.showModal ? <ApproveAsVariantModal handleHideModal={this.handleHideModal} 
                                        design_images={this.state.main_design_list} 
                                        gd_number_data={this.state.gd_number_data}
                                        gdNumberChange={this.gdNumberChange}
                                        saveApproveAsVariant={this.saveApproveAsVariant}
                                        selectVariantImages = {this.selectVariantImages}
                                        unique_similar_gd_numbers = {this.state.unique_similar_gd_numbers}
                                    />
                     : null}
            {
                this.state.designPreviewModal?<DesignPreviewModal 
                                                key={"design_preview"+this.state.random_key}
                                                design_name={this.state.design.name}
                                                my_id={"design_preview"+this.state.random_key}
                                                design_images={this.state.main_design_list}
                                                similar_designs={this.state.batch_similar_designs_url_for_modal}
                                                repo_similar_designs={this.state.repo_similar_designs_url_for_modal}
                                                showSimilarDesigns = {this.state.showSimilarDesigns}
                                                urls_with_info_for_modal = {this.state.urls_with_info_for_modal}
                                            />:null

            }
            { this.state.rejectModal ? <RejectModal hideModal={this.hideRejectModal}
                                        rejectAction={this.approveOrRejectDesign}
                                        actionFrom='design'
                                       />:null

            }
        </div>
        )
    }
}

export default withRouter(DesignDetails);

class ApproveAsVariantModal extends React.Component {

    componentDidMount(){
        $('#approve-as-variant-model').modal('show');
        $('#approve-as-variant-model').on('hidden.bs.modal', this.props.handleHideModal);
    }
    render() {
        var unique_similar_gd_numbers = this.props.unique_similar_gd_numbers
        var variant_images =  this.props.design_images.map((variant,inx) =>{
               return  <div className="new-cell-image">
                          <img src={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${variant.bucket_name}&batch_name=${variant.batch_name}&image_name=${variant.image_uri}&image_type=thumbnails`} className="new-design-image card-img-top" alt="design" />
                          <div className="card-img-overlay text-left m-0 variant-img-overlay">
                            <input type="checkbox" className="status-change-checkbox variant-image-checkbox" value={variant.id} onClick={this.props.selectVariantImages} />
                          </div>
                        </div>
        })
        var gd_numbers = []
        this.props.gd_number_data.map((design,inx)=>{
            if(unique_similar_gd_numbers.includes(design.gd_number)){
                
                gd_numbers.push(
                    { value: design.id, label: design.gd_number }
                    // <option value={value.id}>{value.value}</option>
                )
            }
        })
        return (
            <div className="modal fade" id="approve-as-variant-model" aria-modal="true" role="dialog" data-backdrop="static" data-keyboard="false" >
                <div className="modal-dialog modal-dialog-centered" id="approve-as-variant-model-dialog">
                  <div className="modal-content pl-4 pr-4" id="approve-as-variant-model-content">
                    <div className="modal-header dashed-row-bottom pl-0 pr-0">
                      <div>
                        <span id="variant-confirm-text">
                          Do you want to save it as a variant ?
                        </span>  
                      </div>
                      <div>
                        <button type="button" className="close" data-dismiss="modal">×</button>
                      </div>
                    </div>
                    <div className="modal-body pl-0 pr-0">
                      <div className="row pb-3 m-0">
                        <div className="col-md-6 p-0">
                          <div>
                            <label className="color-red pr-2">*</label>
                            <label id="modal-design-id-text">Global Design #</label>  
                          </div>
                          <div className="mr-4">
                          <Select                            
                            className="select-attribute-value" id="new-value"
                            options={gd_numbers}
                            onChange={event => this.props.gdNumberChange(event)}
                        />
                          </div>
                        </div>
                        <div className="col-md-6 p-0">
                          <div>
                            <label className="color-red pr-2">*</label>
                            <label id="modal-variant-name-text">Variant Name</label>
                          </div>
                          <div className="mr-4">
                            <input type="text" className="form-control" placeholder="Type your Variant Name" id="new-variant-name"/>
                          </div>
                        </div>
                      </div>
                      <div className="row p-0 m-0">
                        <div className="p-0 col-md-12">
                        {variant_images}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between pl-0 pr-0 dashed-row-top">
                      <button type="button" className="btn  pl-4 pr-4 m-0" id="cancel-modal" data-dismiss="modal">
                        Cancel
                      </button>
                      <button type="button" className="btn btn-dark pl-5 pr-5   m-0" onClick={this.props.saveApproveAsVariant}>
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
        )
    }
}

class DesignPreviewModal extends React.Component {
    constructor(props) {
        super(props);
        var similar_image_hd = this.props.design_images.length>0 && (this.props.design_images[0]['image_gsutil_url'] in this.props.similar_designs)? this.props.similar_designs[this.props.design_images[0]['image_gsutil_url']][0]: ''
        var main_image_gs = this.props.design_images.length>0?this.props.design_images[0]['image_gsutil_url']:''
        var similar_design_data = main_image_gs in this.props.similar_designs ? this.props.similar_designs[main_image_gs]: []
        this.state = {
            design_name: this.props.design_name,
            design_images: this.props.design_images,
            similar_designs: this.props.similar_designs,
            batch_similar_designs: this.props.similar_designs,
            repo_similar_designs: this.props.repo_similar_designs,
            my_id: this.props.my_id,
            designs:{},
            main_image_hd: this.props.design_images.length>0? this.props.design_images[0]:'',//this.props.design_images.length>0? this.props.design_images[0]['image_preview']:'',
            similar_image_hd: similar_image_hd,
            similar_image_batch:'',
            similar_image_score:'',
            main_image_gs:  main_image_gs,
            main_design_controls:{
                start: this.props.design_images.length>0?0:-1,
                end:  this.props.design_images.length-1,
                cursor_index: 0
            },
            similar_design_controls:{
                start: similar_design_data.length>0?0:-1,
                end:  similar_design_data.length-1,
                cursor_index: 0
            },
            showing_batch_or_repo: 'batch',
            showSimilarDesigns: this.props.showSimilarDesigns,
            urls_with_info_for_modal: this.props.urls_with_info_for_modal

        }
    }
    componentDidMount() {
          var designs = {}
          this.state.design_images.map((data, index)=>{
            designs[data['image_gsutil_url']]={
                'image_signed_url':data['image_signed_url'],
                'image_preview': data['image_preview'],
                'index': index
            }
          })
          this.setState({
            "designs": designs
          })

    }

    switchBatchRepo =(event, batch_or_repo)=>{
        
        
        var similar_design_controls = {
            start: -1,
            end: -1,
            cursor_index: 0
        }

        var similar_designs = {}
        var similar_image_hd = ''
        var urls = []
        var main_image_gs = this.state.main_image_gs
        if(batch_or_repo==='batch'){
            if(main_image_gs in this.state.batch_similar_designs){
                urls = this.state.batch_similar_designs[main_image_gs]
                similar_image_hd = urls.length>0?urls[0]:''
                similar_designs[main_image_gs]=urls
                similar_design_controls.start = urls.length>0?0:-1
                similar_design_controls.end = urls.length-1


            }else{
                similar_designs = {}
            }

        }else if(batch_or_repo==='repo'){
            if(main_image_gs in this.state.repo_similar_designs){
                urls =  this.state.repo_similar_designs[main_image_gs]
                similar_image_hd = urls.length>0?urls[0]:''
                similar_designs[main_image_gs] = urls
                similar_design_controls.start = urls.length>0?0:-1
                similar_design_controls.end = urls.length-1

            }else{
                similar_designs = {}
            }
        }

        this.setState({
            "similar_designs": similar_designs,
            "similar_design_controls": similar_design_controls,
            'similar_image_hd': similar_image_hd,
            "showing_batch_or_repo": batch_or_repo
        })
    }

    handleMainImageSelect = (event, image_gsutil_url, index, element_id)=>{
        this.styleForMainSelected(element_id)
        var image_obj =  this.state.design_images.filter((design,indx) =>{
            if(design.image_gsutil_url === image_gsutil_url){
                return design
            }
        }) //this.state.designs[image_gsutil_url]
        var main_design_controls = this.state.main_design_controls
        var similar_image_hd = ""
        var similar_design_controls = {
                start: -1,
                end:  -1,
                cursor_index: 0
            }
        
        if(image_gsutil_url in this.state.batch_similar_designs){
            similar_image_hd = this.state.batch_similar_designs[image_gsutil_url][0]
            similar_design_controls.start = 0
            similar_design_controls.end = this.state.batch_similar_designs[image_gsutil_url].length-1
            similar_design_controls.cursor_index = 0
        }
        
        // {/*main_design_controls.cursor_index=image_obj['index']*/}
        main_design_controls.cursor_index=index
        

        this.setState({
            "main_image_hd": image_obj[0],// image_obj['image_preview'],
            "main_image_gs": image_gsutil_url,
            "similar_image_hd": similar_image_hd,
            "main_design_controls": main_design_controls,
            "similar_design_controls": similar_design_controls,
            "showing_batch_or_repo": 'batch',
            "similar_designs": this.state.batch_similar_designs

        })
    }

    styleForMainSelected = (element_id)=>{
        
        
    }


    styleForSimilarSelected = (element_id)=>{
        
    }

    handleSimilarImageSelect = (event, singned_url, index, element_id)=>{
        this.styleForSimilarSelected(element_id)
        var similar_design_controls = this.state.similar_design_controls
        // var selected_index = this.state.similar_designs[this.state.main_image_gs].indexOf(singned_url)
        // similar_design_controls.cursor_index = selected_index
        similar_design_controls.cursor_index = index
        this.setState({
            "similar_image_hd": singned_url
        })
    }

    handleMainDesignPrevNextImage = (event, prev_or_next)=>{
        var main_design_controls = this.state.main_design_controls
        var batch_similar_designs = this.state.batch_similar_designs
        var cursor_index = main_design_controls.cursor_index
        if(prev_or_next==='prev'){
            if(cursor_index>0){
                main_design_controls.cursor_index= cursor_index -1
            }
        }else if(prev_or_next==='next'){
            if(cursor_index<main_design_controls.end){
                main_design_controls.cursor_index = cursor_index + 1
            }
        }

        cursor_index = main_design_controls.cursor_index
        this.styleForMainSelected('modal_main_design_child'+cursor_index)
        var image_gsutil_url =  this.state.design_images[cursor_index]['image_gsutil_url']
        var similar_image_hd = ''
        var similar_design_controls = {
                start: -1,
                end:  -1,
                cursor_index: 0
            }
        if(image_gsutil_url in batch_similar_designs){
            similar_image_hd = batch_similar_designs[image_gsutil_url][0]
            similar_design_controls.start = batch_similar_designs[image_gsutil_url].length>0?0:-1
            similar_design_controls.end = batch_similar_designs[image_gsutil_url].length-1
        }


        this.setState({
            "main_design_controls": main_design_controls,
            "main_image_hd": this.state.design_images[cursor_index],//this.state.design_images[cursor_index]['image_preview'],
            "main_image_gs": this.state.design_images[cursor_index]['image_gsutil_url'],
            "similar_image_hd": similar_image_hd,
            "showing_batch_or_repo": 'batch',
            "similar_designs": batch_similar_designs,
            "similar_design_controls": similar_design_controls
        })
    }

    handleSimilarDesignPrevNextImage = (event, prev_or_next)=>{
        var similar_design_controls = this.state.similar_design_controls
        var cursor_index = similar_design_controls.cursor_index
        if(prev_or_next==='prev'){
            if(cursor_index>0){
                similar_design_controls.cursor_index= cursor_index -1
            }
        }else if(prev_or_next==='next'){
            if(cursor_index<similar_design_controls.end){
                similar_design_controls.cursor_index = cursor_index + 1
            }
        }
        cursor_index = similar_design_controls.cursor_index
        this.styleForSimilarSelected('modal_similar_design_child'+cursor_index)

        var similar_image_hd = this.state.similar_designs[this.state.main_image_gs][cursor_index]

        this.setState({
            similar_design_controls: similar_design_controls,
            similar_image_hd: similar_image_hd
        })
    }

    render() {
        var designs_row = this.state.design_images.map((data, index)=>{
            if(this.state.main_design_controls.cursor_index===index){
                return (
                    <div className="btn child-btn">
                      <img src={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${data.bucket_name}&batch_name=${data.batch_name}&image_name=${data.image_uri}&image_type=thumbnails`} onClick={event => this.handleMainImageSelect(event, data['image_gsutil_url'], index,"modal_main_design_child"+index)} id={"modal_main_design_child"+index}  className="child-img preview-modal-main-design-carousel-active" alt="design"/>
                    </div>
                )    
            }
            return (
                <div className="btn child-btn">
                  <img src={`${API_HOST}/repo/get/image/signed/url/?bucket_name=${data.bucket_name}&batch_name=${data.batch_name}&image_name=${data.image_uri}&image_type=thumbnails`} onClick={event => this.handleMainImageSelect(event, data['image_gsutil_url'],index, "modal_main_design_child"+index)} id={"modal_main_design_child"+index}  className="child-img" alt="design" />
                </div>
            )
        })

        var similar_designs_row = []
        if(this.state.main_image_gs in this.state.similar_designs){
            similar_designs_row = this.state.similar_designs[this.state.main_image_gs].map((singned_url,index)=>{
                if(this.state.similar_design_controls.cursor_index===index){

                    return (
                        <div className=" btn child-btn">
                          <img  src={ singned_url? `${API_HOST}/repo/get/image/signed/url/?${singned_url}&image_type=thumbnails` : ImagePlaceholder} onClick={event => this.handleSimilarImageSelect(event, singned_url, index, "modal_similar_design_child"+index)} id={"modal_similar_design_child"+index} className="child-img preview-modal-similar-design-carousel-active" alt="design" />
                        </div>
                    )

                }
                return (
                    <div className=" btn child-btn">
                      <img src={singned_url? `${API_HOST}/repo/get/image/signed/url/?${singned_url}&image_type=thumbnails`: ImagePlaceholder} onClick={event => this.handleSimilarImageSelect(event, singned_url, index, "modal_similar_design_child"+index)} id={"modal_similar_design_child"+index} className="child-img" alt="design"/>
                    </div>
                )
            })
        }
        return (
            <div className="modal fade show" id="design-preview-model" data-backdrop="static" data-keyboard="false"  aria-modal="true" role="dialog">
                  <div className="modal-dialog modal-dialog-centered" id="design-preview-model-dialog">
                    <div className="modal-content pl-4 pr-4 pt-2 pb-4">
                      <div className="d-flex justify-content-between pb-2 dashed-row-bottom">
                        <div className='d-flex justify-content-between'>
                          <span id="design-preview-text"> Design Preview</span>
                          <span className='ml-4 similar-design-text'>Similar Designs-{this.state.similar_designs[this.state.main_image_gs]?this.state.similar_designs[this.state.main_image_gs].length:0}</span>
                        </div>
                        <div>
                          <button type="button" className="close text-center" data-dismiss="modal">
                            ×
                          </button>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6">
                          <span id="design-product-name-text">{this.state.design_name}</span>
                        </div>
                        <div className="col-md-6 padding-left-percent">
                          <div className="row d-flex justify-content-between flex-column pl-4">
                            <div className="d-flex justify-content-between">
                                <div>
                                  <span className="similar-design-text"> Batch# {this.state.similar_image_hd in this.state.urls_with_info_for_modal?  this.state.urls_with_info_for_modal[this.state.similar_image_hd].batch :"-"}</span>
                                </div>
                                <div>
                                  <span className="similar-design-text"> Score: {this.state.similar_image_hd in this.state.urls_with_info_for_modal?  this.state.urls_with_info_for_modal[this.state.similar_image_hd].score :"-"}</span>
                                </div>
                                {
                                  this.state.showSimilarDesigns
                                  ?
                                  null
                                  :
                                  <div class="btn-group btn-group-sm pl-2" role="group" aria-label="..." id="modal_batch_or_repo">
                                    <button type="button" className={this.state.showing_batch_or_repo==='batch'?'btn btn-outline-secondary group-similar-designs active':'btn btn-outline-secondary group-similar-designs'} onClick={event => this.switchBatchRepo(event,'batch')}>Batch</button>
                                    <button type="button" className={this.state.showing_batch_or_repo==='repo'?"btn btn-outline-secondary group-similar-designs active":'btn btn-outline-secondary group-similar-designs'} onClick={event => this.switchBatchRepo(event,'repo')}>Repo</button>                                           
                                  </div>
                                }
                            </div>
                            <div>
                                {
                                  this.state.showing_batch_or_repo==="repo"
                                  ?
                                  <div>
                                    <span className="similar-design-text"> GD# {this.state.similar_image_hd in this.state.urls_with_info_for_modal?  this.state.urls_with_info_for_modal[this.state.similar_image_hd].gd_number :"-"}</span>
                                  </div>
                                  :
                                  <div>
                                    <span className="similar-design-text"> Variant Name: {this.state.similar_image_hd in this.state.urls_with_info_for_modal?  this.state.urls_with_info_for_modal[this.state.similar_image_hd].variant_name :"-"}</span>
                                  </div>
                                }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 " id="left-design-preview">
                          <div className="mt-2 text-left d-flex justify-content-between">
                            <div className="d-flex justify-content-center align-center">
                                {
                                    this.state.main_design_controls.end>0 && this.state.main_design_controls.cursor_index>0
                                    ?
                                    <button type="button" onClick={event => this.handleMainDesignPrevNextImage(event,'prev')} className="btn btn-sm child-btn shadow-none">
                                        <i className="fa fa-chevron-circle-left"></i>
                                    </button>
                                    :
                                    <button type="button" disabled={true} className="btn btn-sm child-btn shadow-none">
                                        <i className="fa fa-chevron-circle-left"></i>
                                    </button>
                                }  
                            </div>


                            <div className="d-flex ml-4 mr-4 flex-column justify-content-between" style={{"max-width":"400px"}}>
                              <div className="row m-0 p-0">
                                <div className="d-flex flex-column justify-content-between">
                                    <div className="batch-design-preview-img-parent">
                                        <img src={this.state.main_image_hd.image_uri?`${API_HOST}/repo/get/image/signed/url/?bucket_name=${this.state.main_image_hd.bucket_name}&batch_name=${this.state.main_image_hd.batch_name}&image_name=${this.state.main_image_hd.image_uri}`:this.state.main_image_hd.image_preview} id="batch-design-preview-img" className="design-preview-img" alt="design" />
                                    </div>
                                </div> 
                              </div>
                              <div className="row m-0 p-0 mt-4">
                                <div className="col-12 m-0 p-0">
                                    <div className="row flex-row flex-nowrap d-flex justify-content-start" style={{"overflow-x":"auto"}}>
                                        {designs_row}
                                    </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center align-center">
                            {
                                this.state.main_design_controls.end>0 && this.state.main_design_controls.cursor_index<this.state.main_design_controls.end
                                ?
                                <button type="button" onClick={event => this.handleMainDesignPrevNextImage(event,'next')} className="btn btn-sm child-btn shadow-none">
                                    <i className="fa fa-chevron-circle-right"></i>
                                </button>
                                :
                                <button type="button" disabled={true} className="btn btn-sm child-btn shadow-none">
                                    <i className="fa fa-chevron-circle-right"></i>
                                </button>
                            }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6" id="right-design-preview">
                          <div className="mt-2 text-right d-flex justify-content-between">
                            <div className="d-flex justify-content-center align-center">
                                {
                                    this.state.similar_design_controls.end>0 && this.state.similar_design_controls.cursor_index>0
                                    ?
                                    <button type="button" onClick={event => this.handleSimilarDesignPrevNextImage(event,'prev')} className="btn btn-sm child-btn shadow-none">
                                      <i className="fa fa-chevron-circle-left"></i>
                                    </button>
                                    :
                                    <button type="button" disabled={true} className="btn btn-sm child-btn shadow-none">
                                      <i className="fa fa-chevron-circle-left"></i>
                                    </button>
                                }
                            </div>
                            <div className="d-flex ml-4 mr-4 flex-column justify-content-between" style={{"max-width":"400px"}}>
                              <div className="row m-0 p-0">
                                <div className="d-flex flex-column justify-content-between">
                                    <div className="batch-design-preview-img-parent">
                                        <img src={ this.state.similar_image_hd? `${API_HOST}/repo/get/image/signed/url/?${this.state.similar_image_hd}` : ImagePlaceholder}  className="design-preview-img" alt="design" />
                                    </div>
                                </div> 
                              </div>
                              <div className="row m-0 p-0 mt-4">
                                <div className="col-12 m-0 p-0">
                                    <div className="row flex-row flex-nowrap d-flex justify-content-start" style={{"overflow-x":"auto"}}>
                                        {similar_designs_row}
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-center">
                            {
                                this.state.similar_design_controls.end>0 && this.state.similar_design_controls.cursor_index<this.state.similar_design_controls.end
                                ?
                                <button type="button" onClick={event => this.handleSimilarDesignPrevNextImage(event,'next')} className="btn btn-sm child-btn shadow-none">
                                  <i className="fa fa-chevron-circle-right"></i>
                                </button>
                                :
                                <button type="button" disabled={true} className="btn btn-sm child-btn shadow-none">
                                  <i className="fa fa-chevron-circle-right"></i>
                                </button>
                            }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        )
    }
}
