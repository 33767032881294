import React from 'react';
import { Link, withRouter } from 'react-router-dom';
// import ErrorHandling from '../../CommonFunctions'
import {API_HOST} from '../../Settings.js'
import axios from 'axios';
import './update_role.css'
import $ from 'jquery'
// import { Redirect } from "react-router-dom";
import notify from '../../CommonFunctions.js';
import ErrorHandling from '../../ErrorHandler'
import {objectIsNotEmpty} from '../customHelperFunctions'
import Cookies from "universal-cookie";
const cookies = new Cookies();

class UpdateRole extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        role_id: this.props.match.params.role_id,
        user_perm:{},
        new_role_name: "",
        all_privileges: [],
        default_priviliges_list: [],
        selected_privilege_ids: [],
        existing_group_name: "",
        existing_permission_ids: [],
        default_privileges_list: [],
        custom_privileges: [],
        tooltip_for_privilege: {}
      }
    }  

    componentDidMount(){
        $('.main-menu-sub-group').find('.circle-title').removeClass('active-navbar')
        $('.main-menu-sub-group').find('#roles-group').find('.circle-title').addClass('active-navbar')
        $('.inline-loader').fadeIn()
        this.getUserPermissions()
    }

    getUserPermissions(){
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/users/user/permissions/read/`,
            ).then(response => {
                // var get_all_permissions = response.data.user_perm.get_all_permissions;
                if(!response.data.user_perm.is_superuser){
                    notify("You don't have access to this page",'error')
                    this.props.history.push('/permissiondenied/')
                }
                if(true){
                    this.setState({
                        user_perm: response.data.user_perm
                    })
                    this.getExistingPrivileges()
                }else{
                    setTimeout(() =>{
                        this.props.history.push('/permissiondenied/')
                    },1000)
                    notify("You don't have permission to aceess this page", "error")
                }
            }).catch(response => {
                $('.inline-loader').fadeOut('slow')
                ErrorHandling(response)
            });
    }

    getExistingPrivileges = () => {
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/users/groups/${this.state.role_id}/retrieve/update/destroy/`
            ).then(response => {
                if(response.data.success===false){
                    notify('Role does not exists','info')
                    this.props.history.push('/roles/list/')
                }else{
                    this.setState({
                        "existing_group_name": response.data.existing_group_name,
                        "new_role_name": response.data.existing_group_name,
                        "existing_permission_ids": response.data.existing_permission_ids,
                        "default_privileges_list": response.data.default_privileges_list,
                        "selected_privilege_ids": response.data.existing_permission_ids,
                        "custom_privileges": response.data.custom_privileges,
                        "tooltip_for_privilege": response.data.tooltip_for_privilege
                    })
                }
                $('.inline-loader').fadeOut('slow')
            }).catch(error_response =>{
                $('.inline-loader').fadeOut('slow')
            })
    }

    handleSubmit(event){
        $('.inline-loader').fadeIn()
        event.preventDefault()
        this.handleUpdateRole()
    }

    handleInputChange = (event) =>{
        this.setState({
            "new_role_name": event.target.value
        })
    }

    handleDeleteRole = (event) =>{
        if(window.confirm("Are you sure, you want to delete role?")){
            /*const auth_token = "Token "+localStorage.malbar_token*/
            const auth_token = "Token "+cookies.get("malabar_token")
            axios.defaults.headers.delete['Authorization'] =auth_token      
            axios.delete(`${API_HOST}/users/groups/${this.state.role_id}/retrieve/update/destroy/`
                ).then(response => {
                    if(response.data.success===false){
                        if(response.data.reason==='INVALID_GROUP_ID'){
                            setTimeout(() =>{
                                this.props.history.push('/roles/list')
                            },1000)
                            notify("Role does not exists", "error")
                        }
                        this.props.history.push('/roles/list/')
                    }else {
                        notify("Role deleted successfully", "success")
                        this.props.history.push('/roles/list/')
                    }
                }).catch(response_error =>{

                })
        }
    }

    handlePrivilegeSelection = (event, selected_privilege_id) =>{
        var selected_privilege_ids = this.state.selected_privilege_ids
        if (event.currentTarget.checked){
            if(selected_privilege_ids.indexOf(selected_privilege_id)===-1){
                selected_privilege_ids.push(selected_privilege_id)
            }
        }else{
            var index = selected_privilege_ids.indexOf(selected_privilege_id)
            if(index>-1){
                selected_privilege_ids.splice(index,1)
            }
        }
        this.setState({
            "selected_privilege_ids": selected_privilege_ids
        })
    }

    handleUpdateRole = () => {
        /*const auth_token = "Token "+localStorage.malbar_token*/
        const auth_token = "Token "+cookies.get("malabar_token")
        axios.defaults.headers.patch['Authorization'] =auth_token
        let form_data = new FormData();
        form_data.append('group_name',this.state.new_role_name)
        form_data.append('selected_privilege_ids', this.state.selected_privilege_ids)
        axios({
                method: 'patch', 
                url: `${API_HOST}/users/groups/${this.state.role_id}/retrieve/update/destroy/`,
                data:form_data,                
            }).then(response => {
                if(response.data.success===false){
                    if(response.data.reason==='GROUP_NAME_EXISTS' || response.data.reason==='INVALID_GROUP_NAME'){
                        var element_role =  $('#new-role-name');
                        element_role.addClass('invalid-role')
                    }else if(response.data.reason==='INVALID_GROUP_ID'){
                        setTimeout(() =>{
                            this.props.history.push('/roles/list')
                        },1000)
                        notify("Role does not exists", "error")
                    }

                }else{
                    notify("Role updated successfully", "info")
                    this.props.history.push('/roles/list/')

                }
                $('.inline-loader').fadeOut()
            }).catch(error_response => {

            });
    }

    render() {
        

        let custom_privileges2 = this.state.custom_privileges.map((each_privilege, key)=>{
            return (
                    <div className="row m-0 p-0 mt-1 bg-white permission-row">
                        <div className="col-md-2">
                          <div className="vertical-center">
                            <div className="d-flex justify-content-start">
                              <span className="mr-3">{each_privilege[0]}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                -
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                -
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                -
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                -
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div data-toggle="tooltip" data-placement="top" title={this.state.tooltip_for_privilege.hasOwnProperty(each_privilege[1]) ? this.state.tooltip_for_privilege[each_privilege[1]] : "Info not available" }>
                                <label className="checkbox-label">
                                  <input type="checkbox" checked={this.state.selected_privilege_ids.includes(each_privilege[1])? true : false} onChange={event => this.handlePrivilegeSelection(event, each_privilege[1])}/>
                                  <span className="checkbox-custom rectangular"></span>
                                </label>
                            </div>
                          </div>
                        </div>
                    </div>
                )
        })

        let default_privilege2 = this.state.default_privileges_list.map((each_privilege, key)=>{
            return(
                    <div className="row m-0 p-0 mt-1 bg-white permission-row">
                        <div className="col-md-2">
                          <div className="vertical-center">
                            <div className="d-flex justify-content-start">
                              <span className="mr-3">{each_privilege[0].split('.')[1]}</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                    {
                                        each_privilege[1].hasOwnProperty('view')
                                        ?
                                        <div data-toggle="tooltip" data-placement="top" title={this.state.tooltip_for_privilege.hasOwnProperty(each_privilege[1].view) ? this.state.tooltip_for_privilege[each_privilege[1].view] : "Info not available" }>
                                            <label className="checkbox-label">
                                                <input className="ml-3" type="checkbox"
                                                checked={this.state.selected_privilege_ids.includes(each_privilege[1].view)? true : false}
                                                onChange={event => this.handlePrivilegeSelection(event, each_privilege[1].view)}/>
                                                <span className="checkbox-custom rectangular"></span>
                                            </label>
                                        </div>
                                        :
                                        <div>
                                            -
                                        </div>
                                    }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                {
                                    each_privilege[1].hasOwnProperty('add')
                                    ?
                                    <div data-toggle="tooltip" data-placement="top" title={this.state.tooltip_for_privilege.hasOwnProperty(each_privilege[1].add) ? this.state.tooltip_for_privilege[each_privilege[1].add] : "Info not available" }>
                                    <label className="checkbox-label">
                                        <input className="ml-3" type="checkbox"
                                        checked={this.state.selected_privilege_ids.includes(each_privilege[1].add)? true : false}
                                        onChange={event => this.handlePrivilegeSelection(event, each_privilege[1].add)}/>
                                        <span className="checkbox-custom rectangular"></span>
                                    </label>
                                    </div>
                                    :
                                    <div>
                                        -
                                    </div>
                                }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                {
                                    each_privilege[1].hasOwnProperty('change')
                                    ?
                                    <div data-toggle="tooltip" data-placement="top" title={this.state.tooltip_for_privilege.hasOwnProperty(each_privilege[1].change) ? this.state.tooltip_for_privilege[each_privilege[1].change] : "Info not available" }>
                                        <label className="checkbox-label">
                                            <input className="ml-3" type="checkbox"
                                            checked={this.state.selected_privilege_ids.includes(each_privilege[1].change)? true : false}
                                            onChange={event => this.handlePrivilegeSelection(event, each_privilege[1].change)}/>
                                            <span className="checkbox-custom rectangular"></span>
                                        </label>
                                    </div>
                                    :
                                    <div>
                                        -
                                    </div>
                                }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                {
                                    each_privilege[1].hasOwnProperty('delete')
                                    ?
                                    <div data-toggle="tooltip" data-placement="top" title={this.state.tooltip_for_privilege.hasOwnProperty(each_privilege[1].delete) ? this.state.tooltip_for_privilege[each_privilege[1].delete] : "Info not available" }>
                                        <label className="checkbox-label">
                                            <input className="ml-3" type="checkbox"
                                            checked={this.state.selected_privilege_ids.includes(each_privilege[1].delete)? true : false}
                                            onChange={event => this.handlePrivilegeSelection(event, each_privilege[1].delete)}/>
                                            <span className="checkbox-custom rectangular"></span>
                                        </label>
                                    </div>
                                    :
                                    <div>
                                        -
                                    </div>
                                }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div className="text-center">
                            <div>
                                -
                            </div>
                          </div>
                        </div>
                    </div>
                )
        })

        return (
            <div className="relative">
                <div className="inline-loader" ><div className="dot-elastic"></div></div>
                <div className=" d-flex justify-content-between mb-0 mt-0" style={{"alignItems": 'center'}} id="gos-group">
                    <label id="current-url-total-path">
                    <Link to="/roles/list/" className="current-url">Roles</Link> /&nbsp;
                    <a href="#" className="current-url"> {this.state.existing_group_name} </a>
                    </label>
                </div>
                { objectIsNotEmpty(this.state.user_perm) 
                    ?
                    <div>
                    <div className="row m-0 p-0">
                        <div className="col-md-12 text-center p-0">
                            <h4 className="m-0">Change Role</h4>
                        </div>
                    </div>
                    <form onSubmit={event => this.handleSubmit(event)}>
                        <div className="row m-0 p-0 mt-2 mb-2">
                        <div className="d-flex justify-content-start">
                            <div className="mr-4 d-flex align-items-center">
                                <label id="role-name" for="new-role-name" className="m-0">Role Name</label>
                            </div>
                            <div>
                                <input type="text" value={this.state.new_role_name} onChange={event =>this.handleInputChange(event)} className="form-control" autocomplete="off" id="new-role-name" required/>
                            </div>
                        </div>
                        </div>
                        <div>
                            <div className="row m-0 p-0 bg-white permission-row">
                              <div className="col-md-2">
                                <div className="vertical-center">
                                  <div className="d-flex justify-content-start">
                                    <span className="mr-3">Privilege</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 align-self-center">
                                <div className="text-center">
                                  <div><span>View</span></div>
                                </div>
                              </div>
                              <div className="col-md-2 align-self-center">
                               <div className="text-center">
                                 <div><span>Add</span></div>
                               </div>
                              </div>
                              <div className="col-md-2 align-self-center">
                                <div className="text-center">
                                  <div><span>Update</span></div>
                                </div>
                              </div>
                              <div className="col-md-2 align-self-center">
                                <div className="text-center">
                                  <div><span>Delete</span></div>
                                </div>
                              </div>
                              <div className="col-md-2 align-self-center">
                                <div className="text-center">
                                  <div><span>Yes/No</span></div>
                                </div>
                              </div>
                            </div>
                            {default_privilege2}
                            {custom_privileges2}
                        </div>
                        <div className="row m-0 p-0">
                            <div className="col-md-12 p-0">
                                <div className="d-flex justify-content-between mt-2">
                                    <div>
                                        <button type="button"onClick={event => this.handleDeleteRole(event)} className="btn btn-danger">Delete</button>
                                    </div>
                                    <div>
                                        <button type="submit" className="btn btn-dark" id="update-role-save-btn">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    </div>
                    :null
                }
            </div>
        )
    }
}

export default withRouter(UpdateRole);