import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {API_HOST} from '../../Settings.js'
// import Paginator from '../../Paginator.js'
import axios from 'axios';
import $ from 'jquery'
import './repoaddvariant.css'
import notify from '../../CommonFunctions.js'
import ErrorHandling from '../../ErrorHandler'

class RepoAddVariant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            designName: this.props.match.params.design_name,
            designID: -1,
            design: {},
            existing_images: []
        }
    }

    componentDidMount() {
        notify("You don't have permission to aceess this page",'error')
        this.props.history.push('/permissiondenied/')
        /*this.getDesignDetails()*/
    }

    getDesignDetails(){
        $('.inline-loader').fadeIn()
        const auth_token = "Token "+localStorage.malbar_token
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/repo/design/${this.state.designName}/basicdetail/`, ).then(response => {
            if (response.status === 200) {
                if(response.data.data.length>0){
                    this.setState({
                        design: response.data.data[0],
                        designID: response.data.data[0].id,
                        design_active_state:response.data.data[0].active,
                        user_perm: response.data.user_perm
                    })
                    this.getData()
                }
                else{
                    $('.inline-loader').fadeOut()
                    alert("Invalid design")
                    this.props.history.push('/repo')
                }
            }

        }).catch(response => {
            $('.inline-loader').fadeOut()
            ErrorHandling(response)
            this.props.history.push('/repo')
            
        });

        $('.inline-loader').fadeOut()
    }


    getData =() => {
        this.setState({
            existing_images: [],
        })
        const auth_token = "Token "+localStorage.malbar_token
        axios.defaults.headers.get['Authorization'] =auth_token
        $('.inline-loader').fadeIn()
        var url = `${API_HOST}/repo/design/${this.state.designID}/images/list/`;
        axios.get(url, ).then(response => {
            if (response.status === 200) {
                this.setState({
                    existing_images: response.data.images,
                })
                $('.inline-loader').fadeOut('slow')
            }
            

        }).catch(response => {
            ErrorHandling(response)
            $('.inline-loader').fadeOut('slow')
        });
    }

    render() {
        return (
            <div className="relative">
                <div className="inline-loader bg-white" ><div className="dot-elastic"></div></div>
                <div><h3>Page {this.state.design.name}</h3></div>
            </div>
        );
    }

}


export default withRouter(RepoAddVariant);