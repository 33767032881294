import React, { Component } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './custom_progress_bar.css'

class CustomProgressBar extends Component {
    render() {
        // const {image} = this.props
        return (
            <div className={"mb-2 pl-3 pr-3 progressbar-style "+this.props.custom_class}>
                <div className="d-flex justify-content-between progress-card" id={this.props.custom_id}>
                    <div className="mr-2">
                        {
                            this.props.progress_icon_type==='DOWNLOAD'?
                            <span><i className="fas fa-file-download"></i></span>
                            :null
                        }
                        {
                            this.props.progress_icon_type==='UPLOAD'?
                            <span><i className="fas fa-file-upload"></i></span>
                            :null

                        }
                    </div>
                    <div className="m-0 p-0">
                        <span className="progress-text">{(this.props.message+'')}</span>
                    </div>
                    <div className="ml-2">
                        {/*<div className="spinner-border spinner-border-sm text-dark"></div>*/}
                        {/*<div className="cirlce-load"></div>*/}
                        <div className="m-1" style={{ width: 40, height: 40 }}>
                            <CircularProgressbar value={this.props.valuenow}  text={`${this.props.valuenow}%`}  styles={buildStyles({textSize: '25px', pathColor:"#222222", fontWeight: "bold", textColor: '#0D131A'})} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CustomProgressBar
